<template>
  <div class="box">
    <div class="formClass">
      <el-form
        ref="queryFormCope"
        class="queryFormClass"
        :model="formData"
        :label-width="$i18n.locale=='zh'?'120px':''"
        :label-position="$i18n.locale!='zh'?'top':'right'"
      >
        <el-row :gutter="20">
          <el-col v-if="operationType === 2" :span="24">
            <!--费用编码-->
            <el-form-item :label="$t('basicData.FeeCode')" prop="code">
              <span>{{ formData.code }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--费用名称-->
            <el-form-item
              :label="$t('basicData.ExpenseName')"
              prop="name"
              :rules="{ required: true, message: $i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-input
                v-model.trim="formData.name" :placeholder="$t('basicData.PleaseEnter')" :maxlength="20"
                show-word-limit
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--费用名称(PU)-->
            <el-form-item
              :label="$t('basicData.ExpenseName') + '(PU)'"
              prop="namePu"
              :rules="{ required: true, message: $i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-input v-model.trim="formData.namePu" :placeholder="$t('basicData.PleaseEnter')" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--业务类型-->
            <el-form-item
              :label="$t('collectionCenter.businessT')"
              prop="businessType"
              :rules="{ required: true, message: $i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-select v-model="formData.businessType" :placeholder="$t('GlobalSetObj.pleaseChoose')">
                <el-option
                  v-for="item in BUSINESS_TYPE_TWO"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--状态-->
            <el-form-item :label="$t('basicData.status')" prop="state">
              <el-radio-group v-model="formData.state" size="small">
                <!--禁用-->
                <el-radio-button :label="false">{{ $t('basicData.disable') }}</el-radio-button>
                <!--启用-->
                <el-radio-button :label="true">{{ $t('basicData.enable') }}</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--备注-->
            <el-form-item :label="$t('basicData.remark')" prop="remark">
              <el-input
                v-model="formData.remark" type="textarea" :rows="4"
                :maxlength="200" show-word-limit :placeholder="$t('basicData.PleaseEnter')"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div v-permit:remove="'btn:finance:basicData:payable:feesType-editAdd'" style="text-align: right;padding: 10px;border-top: 1px solid #ccc;">
      <!--保存-->
      <el-button type="primary" size="small" @click="saveFormData(1)">{{ $t('basicData.Save') }}</el-button>
      <!--取消-->
      <el-button size="small" @click="saveFormData(2)">{{ $t('basicData.Cancel') }}</el-button>
    </div>
  </div>
</template>

<script>
import {
  addFeeType,
  editFeeType
} from '@/api/finance/partition';
import { BUSINESS_TYPE_TWO } from '@/views/finance/utils.js';

export default {
  name: 'CheckDetail',
  props: {
    detailData: {
      type: Object,
      default: () => {
        return {
          name: '',
          namePu: '',
          remark: '',
          operationMode: 1, // 经营模式 默认自营
          state: true,
          businessType: null,
          id: '',
          attribute: 2
        };
      }
    },
    operationType: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      formData: {
        name: '',
        namePu: '',
        remark: '',
        state: true,
        businessType: null,
        id: '',
        attribute: 2
      }
    };
  },

  watch: {
    'detailData': {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.code) {
          this.formData = {
            ...this.formData,
            ...val
          };
        }
      }
    }
  },

  created() {
    this.BUSINESS_TYPE_TWO = BUSINESS_TYPE_TWO; // 业务类型
  },
  
  methods: {
    saveFormData(type) {
      if (type === 1) {
        this.$refs['queryFormCope'].validate((valid) => {
          if (valid) {
            const { name, namePu, id, remark, state, businessType, attribute } = this.formData;
            const param = {
              name,
              namePu,
              id,
              remark,
              state,
              businessType,
              attribute
            };

            if (this.operationType === 1) {
              //  新增
              addFeeType(param).then(res => {
                if (res.status === 'OK') {
                  this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
                  this.$emit('saveChange', type);
                }
              }).catch(err => {
                console.error(err);
              });
            } else {
              editFeeType(param).then(res => {
                if (res.status === 'OK') {
                  this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
                  this.$emit('saveChange', type);
                }
              }).catch(err => {
                console.error(err);
              });
            }
          } else {
            console.error('error submit!!');
            return false;
          }
        });
      } else {
        this.$emit('saveChange', type);
      }
    },
  }
};
</script>

<style scoped>
.formClass {
  height: calc(100vh - 130px);
  overflow-y: auto;
  padding: 20px;
}
</style>
