<template>
  <div class="app-container">
    <div style="margin-bottom: 10px;">
      <el-card class="box-card">
        <el-form ref="formQuery" label-position="top" size="small">
          <el-row :gutter="10">
            <el-col :span="5">
              <el-form-item :label="$t('collectionCenter.CustomerName')" prop="region">
                <el-select
                  v-model="queryForm.customerName"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  filterable
                  style="width: 100%;"
                >
                  <el-option :label="$t('GlobalSetObj.all')" :value="null" />
                  <el-option
                    v-for="(item,index) in customerList" :key="index" :label="item.key"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="Region" prop="region">
                <el-select
                  v-model="queryForm.region"
                  :placeholder="$t('operationCenter.PleaseSelect')"
                  clearable
                  filterable
                  style="width: 100%;"
                >
                  <el-option key="0" :label="$t('GlobalSetObj.all')" :value="null" />
                  <el-option key="1" label="Midwest" value="Midwest" />
                  <el-option key="2" label="North" value="North" />
                  <el-option key="3" label="Northeast" value="Northeast" />
                  <el-option key="4" label="South" value="South" />
                  <el-option key="5" label="Southeast" value="Southeast" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="State" prop="state">
                <el-select-virtual-list
                  v-model="queryForm.state"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  :select-data="selectData"
                  clearable
                  @change="selectChange"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Date">
                <el-date-picker
                  v-model="dateArr"
                  :clearable="false"
                  :default-time="['00:00:00', '23:59:59']"
                  :end-placeholder="$t('operationCenter.endDate')"
                  :picker-options="pickerOptions"
                  :range-separator="$t('operationCenter.to')"
                  :start-placeholder="$t('operationCenter.startDate')"
                  popper-class="dateTimeClass"
                  style="width: 100%"
                  type="datetimerange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  @blur="refreshDate"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div style="display: flex;justify-content: space-between">
                <div>
                  <el-button
                    icon="el-icon-search" size="small" type="primary"
                    @click="searchTable(1)"
                  >
                    {{ $t('GlobalSetObj.search') }}
                  </el-button>
                  <el-button icon="el-icon-refresh-left" size="small" @click="searchTable(2)">{{
                    $t('operationCenter.reset')
                  }}
                  </el-button>
                  <el-button size="small" @click="exportsTable()">{{ $t('operationCenter.exports') }}</el-button>
                </div>
                <div>
                  <el-radio-group v-model="queryForm.dataType" size="mini" @input="()=>searchTable(1)">
                    <el-radio-button :label="1">{{ $t('collectionCenter.ballot') }}</el-radio-button>
                    <el-radio-button :label="2">{{ $t('orderCenterCont.proportions') }}</el-radio-button>
                  </el-radio-group>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </div>
    <div class="sheind-table">
      <el-card class="box-card">
        <el-table
          :cell-class-name="tableCellClassName"
          :data="tableData"
          :row-class-name="tableRowClassName"
          :span-method="arraySpanMethod"
          border
        >
          <el-table-column
            v-for="(item, ind) in columnList"
            :key="ind"
            :align="item.align"
            :label="item.label"
            :min-width="item.width"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div>
                {{
                  (scope.row[item.prop] !== '' && scope.row[item.prop] !== null && scope.row[item.prop] !== undefined) ? scope.row[item.prop] : '--'
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(item, ind) in currentColumnList"
            :key="ind + item.prop"
            :align="item.align"
            :label="item.label"
            :min-width="item.width"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div
                :class="queryForm.dataType===1&&!['Orders','Delivered','Delivery Rate','On Time','SLA'].includes(scope.row.Region)?'table-active':''"
                @click="getVoteDetails(scope.row,item.prop)"
              >
                {{
                  (scope.row[item.prop] !== '' && scope.row[item.prop] !== null && scope.row[item.prop] !== undefined) ? scope.row[item.prop] : '--'
                }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
    <!--    票数详情-->
    <el-drawer
      :title="$t('orderCenterCont.votesDetails')"
      :visible.sync="dialogVisible"
      center
      direction="ltr"
      size="990px"
      @close="closeDrawer"
    >
      <detail
        ref="votesDetails"
        :query-data="queryData"
        :select-data="selectData"
        @call-parent-method="parentMethod"
      />
    </el-drawer>
  </div>
</template>

<script>
import {
  aipSheinAgingAreaGetState,
  deliveredDailyCustomers,
  reportSheinDeliveredDaily,
  reportSheinDeliveredDailyExport
} from '@/api/orderApi';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';

export default {
  name: 'DailyDeliveryData',
  components: {
    'detail': () => import('./components/detail'),
    'el-select-virtual-list': () => import('@/components/elSelectVirtualList')
  },
  data() {
    return {
      queryForm: {
        region: null,
        state: '',
        beginTime: '',
        endTime: '',
        dataType: 1, // 1：票数 2：比例
        customerName: null
      },
      dateArr: [this.getAWeek().startDate + ' 00:00:00', this.getAWeek().endDate + ' 23:59:59'],
      pickerOptions: {
        // 设置不能选择的日期
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate0 = minDate.getTime();
          if (maxDate) {
            this.choiceDate0 = '';
          }
        },
        disabledDate: (time) => {
          const choiceDateTime = new Date(this.choiceDate0).getTime();
          const minTime = new Date(choiceDateTime).setDate(
            new Date(choiceDateTime).getDate() - 30
          );
          const maxTime = new Date(choiceDateTime).setDate(
            new Date(choiceDateTime).getDate() + 30
          );
          const min = minTime;
          const newDate =
            new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000;
          const max = newDate < maxTime ? newDate : maxTime;
          // 如果已经选中一个日期 则 返回 该日期前后一个月时间可选
          if (this.choiceDate0) {
            return time.getTime() < min || time.getTime() > max;
          }
          // 若一个日期也没选中 则 返回 当前日期以前日期可选
          return time.getTime() > newDate;
        }
      },
      columnList: [
        { label: 'Region', prop: 'Region', width: 160, align: 'center' },
        { label: 'State', prop: 'State', width: 180, align: 'center' },
        { label: 'Cluster', prop: 'Cluster', width: 180, align: 'center' },
        { label: 'Target', prop: 'Target', width: 180, align: 'center' }
      ],
      currentColumnList: [],
      tableData: [],
      stateOptionList: [],
      dialogVisible: false, // 票数明细弹窗
      // 虚拟下拉框列表组件的参数
      selectData: {
        data: [], // 下拉框数据
        label: 'state', // 下拉框需要显示的名称
        value: 'twoCode', // 下拉框绑定的值
        isRight: true// 右侧是否显示
      },
      queryData: {
        moduleType: '', // 票数明细弹窗模块类型
        expectedTime: '', // 理应送达时间
        region: '', // 区域
        cluster: '', // 集群
        customerName: null
      },
      customerList: []
    };
  },
  created() {
    this.getTableData(1);
    this.getStateOption();
    this.getUserOption();
  },
  methods: {
    parentMethod(arg) {
      this.dialogVisible = arg;
    },
    // 获取州省选项
    getStateOption() {
      aipSheinAgingAreaGetState().then(res => {
        if (res.status === 'OK') {
          this.stateOptionList = res.data;
          this.selectData.data = res.data.map((item) => {
            return { state: item.state + '(' + item.twoCode + ')', twoCode: item.twoCode };
          });
          this.selectData.data.unshift({ twoCode: '', state: this.$t('GlobalSetObj.all') });
        }
      });
    },
    // 客户名称列表
    getUserOption() {
      deliveredDailyCustomers().then(res => {
        if (res.status === 'OK') {
          console.log(res, 'getUserOption');
          this.customerList = res.data;
        }
      });
    },
    // 导出
    exportsTable() {
      if (this.dateArr && this.dateArr.length > 0) {
        this.queryForm.beginTime = this.dateArr[0];
        this.queryForm.endTime = this.dateArr[1];
      } else {
        this.queryForm.beginTime = '';
        this.queryForm.endTime = '';
        this.$message.warning(this.$t('trajectory.DatesCannotBeEmpty'));
        return;
      }
      reportSheinDeliveredDailyExport(this.queryForm).then(res => {
        goExportList(this);
      });
    },
    // 指定列设置样式
    tableCellClassName({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 && (row.Region === 'Orders' || row.Region === 'Delivered' || row.Region === 'Delivery Rate' || row.Region === 'On Time' || row.Region === 'SLA')) {
        return 'choose-cell';
      }
    },
    // 指定行设置背景色
    tableRowClassName({ row, rowIndex }) {
      if (row.Region === 'Orders' || row.Region === 'Delivered' || row.Region === 'Delivery Rate' || row.Region === 'On Time' || row.Region === 'SLA') {
        return 'choose-row';
      }
    },
    // 搜索
    searchTable(type) {
      if (type === 1) {
        this.getTableData();
      } else {
        this.queryForm = {
          region: null,
          state: '',
          beginTime: '',
          endTime: '',
          dataType: 1,
          customerName: null
        };
        this.dateArr = [this.getAWeek().startDate + ' 00:00:00', this.getAWeek().endDate + ' 23:59:59'];
        this.getTableData();
      }
    },
    // 获取列表数据
    getTableData(val) {
      if (this.dateArr && this.dateArr.length > 0) {
        this.queryForm.beginTime = this.dateArr[0];
        this.queryForm.endTime = this.dateArr[1];
      } else {
        this.queryForm.beginTime = '';
        this.queryForm.endTime = '';
        this.$message.warning(this.$t('trajectory.DatesCannotBeEmpty'));
        return;
      }
      let loading = null;
      if (val === 1) {
        loading = this.$loading({
          fullscreen: true,
          lock: true,
          spinner: 'el-icon-loading',
          text: 'loading...',
          background: 'rgba(250, 250, 250, 0.6)'
        });
      }
      reportSheinDeliveredDaily({ ...this.queryForm }).then(res => {
        if (res.status === 'OK') {
          this.tableData = [];
          this.currentColumnList = [];
          res.data.rows.map(item => {
            const rowsObj = { label: item, prop: item, width: 100, align: 'center' };
            this.currentColumnList.push(rowsObj);
          });
          if (this.queryForm.dataType === 1) {
            const orders = res.data.data.orders.map((item) => {
              item.type = 'orders';
              return item;
            });
            const delivered = res.data.data.delivered.map((item) => {
              item.type = 'delivered';
              return item;
            });
            const onTime = res.data.data.onTime.map((item) => {
              item.type = 'onTime';
              return item;
            });
            this.tableData = [...orders, ...delivered, ...onTime];
          } else if (this.queryForm.dataType === 2) {
            this.tableData = [...res.data.data.orders, ...res.data.data.percentDelivered, ...res.data.data.percentOnTime];
          }
          if (val === 1) {
            loading.close();
          }
        }
      }).catch(() => {
        if (val === 1) {
          loading.close();
        }
      });
    },
    // 获取最近15天时间
    getAWeek() {
      return {
        endDate: dayjs().format('YYYY-MM-DD'),
        startDate: dayjs().subtract(15, 'day').format('YYYY-MM-DD')
      };
    },
    // 日期选择器失去焦点
    refreshDate() {
      this.choiceDate0 = '';
    },
    /**
     * 合并单元格表格州相同的数据
     * @param row
     * @param column
     * @param rowIndex
     * @param columnIndex
     * @returns {{colspan: number, rowspan: number}}
     */
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        // 不合并 region 为 "~" 的情况
        if (row.Region === '~') {
          return [1, 1];
        }
        if (rowIndex > 0 && row.Region === this.tableData[rowIndex - 1].Region) {
          return [0, 0];
        } else {
          let rowspan = 1;
          for (let i = rowIndex + 1; i < this.tableData.length; i++) {
            if (this.tableData[i].Region === row.Region) {
              rowspan++;
            } else {
              break;
            }
          }
          return [rowspan, 1];
        }
      }
      if (columnIndex === 1) {
        // 不合并 state 为 "~" 的情况
        if (row.State === '~') {
          return [1, 1];
        }
        if (rowIndex > 0 && row.State === this.tableData[rowIndex - 1].State) {
          return [0, 0];
        } else {
          let rowspan = 1;
          for (let i = rowIndex + 1; i < this.tableData.length; i++) {
            if (this.tableData[i].State === row.State) {
              rowspan++;
            } else {
              break;
            }
          }
          return [rowspan, 1];
        }
      }
      return [1, 1];
    },
    /**
     * @description 获取票数详情
     * @param row
     */
    getVoteDetails(row, prop) {
      console.log(row, 'ROW');
      if (this.queryForm.dataType === 2) {
        return;
      }
      if (['Orders', 'Delivered', 'Delivery Rate', 'On Time', 'SLA'].includes(row.Region)) {
        return;
      }
      this.dialogVisible = true;
      // 查询票数详情列表参数
      this.queryData = {
        moduleType: row.type,
        expectedTime: prop,
        region: this.queryForm.region,
        cluster: row.Cluster,
        state: row.State,
        customerName: this.queryForm.customerName
      };
      this.$nextTick(() => {
        this.$refs.votesDetails.search();
      });
    },
    /**
     * @description 关闭抽屉
     */
    closeDrawer() {
      this.dialogVisible = false;
      this.$refs.votesDetails.resetForm();
    },
    /**
     * @description 虚拟输入框的选中值
     * @param val
     */
    selectChange(val) {
      console.log('下拉框选择的值', val);
    }
  }
};
</script>

<style>
.sheind-table .el-table {
  color: #333;
  font-size: 16px;
}

.sheind-table .el-table thead {
  color: #fff;
}

.sheind-table .el-table th.el-table__cell {
  background-color: #000;
}

.sheind-table .choose-row {
  background-color: #FFD964;
}

.sheind-table .choose-cell {
  font-weight: bold;
}

.sheind-table .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background-color: transparent !important;
}

.dateTimeClass.el-picker-panel .el-picker-panel__footer .el-button--text {
  display: none;
}

.table-active {
  color: #409EFF;
  cursor: pointer;
}
</style>
