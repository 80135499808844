// @ts-check

import i18n from '@/lang';

// 输入框最大输入长度（不是字符串长度，是转成数组后的长度）
export const NUMBERLIST_MAX_LENGTH = 20000;

// 业务类型
export const BUSINESS_TYPE_ARR = Object.freeze({
  1: {

    label: /** @type {string} */ (i18n.t('collectionCenter.lanshou')), // 揽收
    value: 1
  },
  0: {
    label: /** @type {string} */ (i18n.t('collectionCenter.expressDelivery')), // 快递
    value: 0
  },
  2: {
    label: /** @type {string} */ (i18n.t('collectionCenter.dispatch')), // 派送 含尾程揽收
    value: 2
  }
});
