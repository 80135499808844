<!-- 揽收应付账户 -->
<template>
  <div>
    <el-card class="box-card">
      <el-form>
        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item label="">
              <el-select v-model="typeVal" :placeholder="$t('GlobalSetObj.pleaseChoose')" style="width: 110px">
                <el-option
                  v-for="item in QUERY_NAME_ACCOUNT_PARAMS_OBJ" :key="item.value" :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <el-input
                v-model.trim="inputVal" clearable :placeholder="$t('GlobalSetObj.pleaseInputContent')"
                style="display: block;width: 100%;"
              />
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <!-- 供应商 -->
            <el-form-item :label="$t('GlobalSetObj.supplier')">
              <QueryProviderV2Cmp class="form-bottom" :val="queryForm.params.providerId" @change="val => queryForm.params.providerId = val" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 网点 -->
            <el-form-item :label="$t('GlobalSetObj.Outlets')">
              <QuerySiteCmp
                class="form-bottom"
                :val="queryForm.params.siteId"
                :provider-id="queryForm.params.providerId"
                @change="val => queryForm.params.siteId = val"
              />
              <!-- <QuerySiteV2Cmp
                :provider-id="queryForm.params.providerId" class="form-bottom" :val="queryForm.params.siteId"
                @change="val => queryForm.params.siteId = val"
              /> -->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-row type="flex" justify="start">
        <el-col :span="8">
          <div>
            <!--查询-->
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="onSearch"
            >{{ $t('basicData.Search') }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh"
              size="mini"
              @click="onReset"
            >{{ $t('basicData.reset') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-card>

    <el-divider />

    <el-card>
      <div class="table">
        <div />
        <div>
          <!-- 导出账户信息 -->
          <el-button
            v-permit:remove="'but:finance:basicData:payable:accountsReceivablePayable:exportAccountInformation'"
            icon="el-icon-download" type="primary" size="mini"
            @click="onExportAccountInformation"
          >{{ $t('basicData.ExportAccountInformation') }}</el-button>

          <!-- 导出账户流水 -->
          <el-button
            v-permit:remove="'but:finance:basicData:payable:accountsReceivablePayable:exportAccountStatement'"
            icon="el-icon-download" type="primary" size="mini"
            @click="onExportAccountStatement"
          >{{ $t('basicData.ExportAccountRollover') }}</el-button>
        </div>
      </div>

      <el-table
        ref="elTable"
        :data="tableData"
        border
        class="table-height"
        :height="tableHeightRow.height"
      >
        <!--序号-->
        <el-table-column :label="$t('basicData.serialNumber')" width="80px" align="center">
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!-- 应付账号 -->
        <el-table-column prop="username" :label="$t('route.AccountsPayable')">
          <template slot-scope="scope">
            <el-link type="primary" @click="onDetails(scope.row)">{{ scope.row.username }}</el-link>
          </template>
        </el-table-column>

        <!-- 名称 -->
        <el-table-column prop="userRealName" :label="$t('GlobalSetObj.Name')" />

        <!-- 付款总计 -->
        <el-table-column prop="totalPaymentAmount" label="付款总计" />

        <!-- 未付总计 -->
        <el-table-column prop="unpaidAmount" label="未付总计" />

        <!-- 已付总计 -->
        <el-table-column prop="paidAmount" label="已付总计" />

        <!-- 所属网点 -->
        <el-table-column prop="siteName" :label="$t('collectionCenter.belongSite')" />

        <!-- 所属供应商 -->
        <el-table-column prop="providerName" :label="$t('GlobalSetObj.SubordinateSupplier')" />
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="asyncGetTableData"
          @size-change="asyncGetTableData"
        />
      </div>
    </el-card>

    <!--  对账批次详情  -->
    <el-drawer
      :title="$t('basicData.AccountDetails')"
      :append-to-body="true"
      :visible.sync="batchNumberDetailRow.isBool"
      direction="ltr"
      :label="$t('receivablePayable.ReconcileBatchDetails')"
      size="70%"
    >
      <CheckDetail
        :detail-data="batchNumberDetailRow.row"
        @closeDetail="() => batchNumberDetailRow.isBool = false"
      />
    </el-drawer>
  </div>
</template>

<script>
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import { QUERY_NAME_ACCOUNT_PARAMS_OBJ } from '@/views/finance/basicData/cope/accountsReceivablePayable/utils.js';
import QueryProviderV2Cmp from '@/views/finance/cmp/Query/QueryProviderV2Cmp.vue';
// import QuerySiteV2Cmp from '@/views/finance/cmp/Query/QuerySiteV2Cmp.vue';
import { apiCollectionPayableAccountPage, apiCollectionPayableAccountExport, apiExportOfBillFlow } from '@/api/finance/basicData';
import { goExportList } from '@/utils/goExportList.js';
import QuerySiteCmp from '@/views/finance/cmp/Query/QuerySiteCmp';

const PARAMS = {
  username: '', // 账号
  realName: '', //  真实姓名
  providerId: '', // 供应商
  siteId: '' // 网点
};

export default {
  name: '',

  components: {
    QuerySiteCmp,
    QueryProviderV2Cmp,
    // DetailsCmp: () => import('./cmp/DetailsCmp.vue')
    CheckDetail: () => import('@/views/finance/basicData/cope/accountsPayable/cmp/checkDetail.vue')
  },

  mixins: [estimateTableHeight],

  data() {
    return {
      typeVal: QUERY_NAME_ACCOUNT_PARAMS_OBJ['username'].value,
      inputVal: '',
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          ...PARAMS
        }
      },

      total: 0,
      tableData: [],
      options: [],

      // 详情
      // detailsRow: {
      //   isBool: false,
      //   title: '',
      //   id: 0
      // }
      // 对账单详情
      batchNumberDetailRow: {
        isBool: false,
        row: {
          requestSource: 1, // 请求来源 1:应付-揽收账户 2:应付-派送账户
          collectionPayableAccountCondition: { // 应付-揽收账户查询条件
            // ...PARAMS,
            id: 0
          },
          deliveryPayableAccountCondition: undefined, // 应付-派送账户查询条件
          // id: undefined,
          // collectionPayableAccountId: 0, // 应付-揽收账户id
          // deliveryPayableAccountId: 0, // 应付-派送账户id
          name: '', // 网点名称
          providerBody: '', // 应付主体.
          payableUnsettledAmount: 0, // 应付合计
          payedAmount: 0, // 已付合计
          unpavedAmount: 0// 未付合计
        }
      }
    };
  },

  created() {
    this.QUERY_NAME_ACCOUNT_PARAMS_OBJ = QUERY_NAME_ACCOUNT_PARAMS_OBJ;

    this.asyncGetTableData();
  },

  methods: {

    getQuery() {
      const { queryForm, inputVal, typeVal } = this;
      const obj = {
        ...queryForm,
        params: {
          ...queryForm.params,
          username: undefined,
          realName: undefined
        }
      };

      obj.params[typeVal] = inputVal;

      return obj;
    },

    asyncGetTableData() {
      const obj = this.getQuery();

      apiCollectionPayableAccountPage(obj).then(res => {
        const { records, total } = res.data;
        this.total = total;
        this.tableData = (records || []).map(item => {
          const { id, username, userRealName, totalPaymentAmount, unpaidAmount, paidAmount, siteId, siteName, providerId, providerName } = item;
          const obj = {
            id,
            username, // 应付账户
            userRealName, // 名称
            totalPaymentAmount, // 付款总计
            unpaidAmount, // 未付总计
            paidAmount, // 已付总计
            siteId,
            siteName, // 所属网点
            providerId,
            providerName// 所属供应商
          };

          return Object.freeze(obj);
        });
      }).catch(err => {
        console.error(err);
      });
    },

    onSearch() {
      this.queryForm.pageNumber = 1;
      this.asyncGetTableData();
    },

    onReset() {
      // Object.assign(this.queryForm, QUERY);
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = 10;
      this.queryForm.params = {
        ...PARAMS
      };
      this.inputVal = '';
      this.typeVal = QUERY_NAME_ACCOUNT_PARAMS_OBJ['username'].value;

      this.asyncGetTableData();
    },

    // 详情
    onDetails(row) {
      const { id, siteName, providerName, totalPaymentAmount, paidAmount, unpaidAmount } = row;
      const obj = {
        collectionPayableAccountCondition: {
          id
        },
        // collectionPayableAccountId: id,
        name: siteName, // 网点名称
        providerBody: providerName, // 应付主体
        payableUnsettledAmount: totalPaymentAmount, // 应付合计
        payedAmount: paidAmount, // 已付合计
        unpavedAmount: unpaidAmount// 未付合计
      };

      Object.assign(this.batchNumberDetailRow.row, obj);
      this.batchNumberDetailRow.isBool = true;
    },

    // 导出账户信息
    onExportAccountInformation() {
      const { params } = this.getQuery();
      apiCollectionPayableAccountExport(params).then(res => {
        goExportList(this);
      }).catch(err => {
        console.error(err);
      });
    },

    // 导出账户流水
    onExportAccountStatement() {
      const query = this.getQuery();
      const { requestSource } = this.batchNumberDetailRow.row;
      // obj.params.requestSource = requestSource;
      const obj = {

        startTime: '',
        endTime: '',
        requestSource,
        isSiteDetailsRequest: false,
        collectionPayableAccountCondition: {
          ...query.params

        }

      };
      apiExportOfBillFlow(obj).then(res => {
        if (res.status === 'OK') {
          goExportList(this);
        }
      });
    }
  }

};
</script>

<style src="./style.css" scoped></style>
<style lang="scss" scoped>

.form-bottom {
  margin-top: 0px;
  // width: 100%;
}
</style>
