var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nodeTable" },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.dataList, "max-height": "300", border: "" },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("operationCenter.ind"),
              width: "60px",
              type: "index",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.operationTime"),
              prop: "createTime",
              width: "180",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("userManage.node"),
              prop: "operationNode",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type:
                            (_vm.status[scope.row.operationNode] &&
                              _vm.status[scope.row.operationNode].type) ||
                            "",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              (_vm.status[scope.row.operationNode] &&
                                _vm.status[scope.row.operationNode].name) ||
                                ""
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.operator"),
              prop: "createUserName",
              width: "180",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("GlobalSetObj.details"),
              prop: "remarks",
              "show-overflow-tooltip": true,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }