<template>
  <div class="Box">
    <div style="margin-bottom: 20px;">
      <el-form class="selectClass">
        <el-row>
          <el-col :span="10" style="width: auto;">
            <!-- 揽收时间： -->
            <el-form-item :label="$t('collectionCenter.lanshouTime')">
              <el-date-picker
                v-model="timeValue"
                :clearable="false"
                type="daterange"
                value-format="yyyy-MM-dd"
                :range-separator="$t('collectionCenter.to')"
                :start-placeholder="$t('collectionCenter.StartTime')"
                :end-placeholder="$t('collectionCenter.EndTime')"
                @change="dateChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;">
            <!-- 客户名称： -->
            <el-form-item :label="$t('collectionCenter.CustomerN')">
              <el-select
                v-model="queryForm.params.customerId" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                filterable @focus="getAllCustomer"
              >
                <el-option
                  v-for="(item, index) in customerNameList"
                  :key="index"
                  :label="item.username"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;" class="aClass">
            <!-- 大区： -->
            <el-form-item :label="$t('collectionCenter.region') + '：'">
              <el-select
                v-model="queryForm.params.region" multiple :placeholder="$t('collectionCenter.pleaseChoose')"
                clearable filterable @focus="getAList(1)"
              >
                <el-option
                  v-for="(item, index) in AList1"
                  :key="index"
                  :label="item.value"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;" class="aClass">
            <!-- 区域： -->
            <el-form-item :label="$t('collectionCenter.area') + '：'">
              <el-select
                v-model="queryForm.params.area" multiple :placeholder="$t('collectionCenter.pleaseChoose')"
                clearable filterable @focus="getAList(2)"
              >
                <el-option
                  v-for="(item, index) in AList2"
                  :key="index"
                  :label="item.value"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="width: auto;"><!--所属平台 -->
            <el-form-item :label="$t('collectionCenter.PlatformWhichBelongs')">
              <el-select
                v-model="queryForm.params.platform"
                :placeholder="$t('collectionCenter.pleaseChoose')"
                clearable
              >
                <el-option :value="1" label="shein" /><!-- shein -->
                <el-option :value="2" label="kwai" /><!-- kwai -->
                <el-option :label="$t('collectionCenter.expressDelivery')" :value="3" /><!-- 快递 -->
                <el-option :label="$t('collectionCenter.other')" :value="0" /><!-- 其他 -->
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="24">
          <el-button
            v-permit:remove="'btn:collection-center:KPI-monitoring-reports:search'"
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}</el-button><!-- 查询 -->
          <el-button
            v-permit:remove="'btn:collection-center:KPI-monitoring-reports:reset'"
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}</el-button><!-- 重置 -->
          <el-button
            v-if="active === 'hzFind'"
            v-permit:remove="'btn:collection-center:KPI-monitoring-reports:export'"
            icon="el-icon-upload2"
            size="mini"
            @click="download"
          >{{ $t('collectionCenter.export') }}</el-button><!-- 导出 -->
          <el-button
            v-if="active === 'mxFind'"
            v-permit:remove="'btn:collection-center:KPI-monitoring-reports:export'"
            icon="el-icon-upload2"
            size="mini"
            @click="downloadMX"
          >{{ $t('collectionCenter.export') }}</el-button><!-- 导出 -->
        </el-col>
      </el-row>
      <el-divider />
    </div>
    <el-tabs v-model="active" type="border-card">
      <el-tab-pane :label="$t('collectionCenter.summary')" name="hzFind">
        <el-table
          ref="hzTable"
          :max-height="600"
          border
          :data="tableDate"
        >
          <el-table-column
            type="selection"
            width="55"
          />
          <!-- 序号 -->
          <el-table-column
            :label="$t('collectionCenter.ind')" type="index" width="60"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="scanDate"
            :label="$t('collectionCenter.lanshouT')"
          /><!-- 揽收时间 -->
          <el-table-column
            align="center"
            prop="customerName"
            :label="$t('collectionCenter.CustomerName')"
          /><!-- 客户名称 -->
          <el-table-column
            align="center"
            prop="customerId"
            :label="$t('collectionCenter.clientID')"
          /><!-- 客户编号 -->
          <el-table-column
            align="center"
            prop="platformName"
            :label="$t('collectionCenter.platform')"
          /><!-- 所属平台 -->
          <el-table-column
            align="center"
            prop="region"
            :label="$t('collectionCenter.region')"
          /><!-- 大区 -->
          <el-table-column
            align="center"
            prop="area"
            :label="$t('collectionCenter.area')"
          /><!-- 区域 -->
          <el-table-column
            align="center"
            prop="callCollectCount"
            :label="$t('collectionCenter.doorToLanSCollectionsTotal')"
          ><!-- 上门揽收总揽收量 -->
            <template slot-scope="scope">
              <el-button v-if="scope.row.callCollectCount" @click="toDetail(scope.row, 2)">{{ scope.row.callCollectCount }}</el-button>
              <span v-else>{{ scope.row.callCollectCount }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="callDeliveryCount"
            :label="$t('collectionCenter.doorToDoorCollectionsTotal')"
          ><!-- 上门交件总揽收量 -->
            <template slot-scope="scope">
              <el-button v-if="scope.row.callDeliveryCount" @click="toDetail(scope.row, 1)">{{ scope.row.callDeliveryCount }}</el-button>
              <span v-else>{{ scope.row.callDeliveryCount }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="calculateP90"
            :label="'P90' + $t('collectionCenter.value') + '(h)'"
          /><!-- P90值(h) -->
          <el-table-column
            align="center"
            prop="sitePunctualRate"
            :label="$t('collectionCenter.punctualDepartureRateOutlets')"
          ><!-- 网点准时发车率 -->
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.sitePunctualRate"
                @click="toDetail(scope.row, 3)"
              >{{ scope.row.sitePunctualRate }}</el-button>
              <span v-else>{{ scope.row.sitePunctualRate }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="transferPunctualRate"
            :label="$t('collectionCenter.transitCenterPunctualDepartureRate')"
          ><!-- 转运中心准时发车率 -->
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.transferPunctualRate"
                @click="toDetail(scope.row,4)"
              >{{ scope.row.transferPunctualRate }}</el-button>
              <span v-else>{{ scope.row.transferPunctualRate }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            :label="$t('collectionCenter.OnTimeArrivalRate')"
          ><!-- 运抵准时率 -->
            <el-table-column
              align="center"
              prop="within4hRate"
              label="4h"
            ><!-- 4h -->
              <template slot-scope="scope">
                <el-button v-if="scope.row.within4hRate" @click="toDetail(scope.row, 5)">{{ scope.row.within4hRate }}</el-button>
                <span v-else>{{ scope.row.within4hRate }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="within10hRate"
              label="10h"
            ><!-- 10h -->
              <template slot-scope="scope">
                <el-button v-if="scope.row.within10hRate" @click="toDetail(scope.row, 6)">{{ scope.row.within10hRate }}</el-button>
                <span v-else>{{ scope.row.within10hRate }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="within16hRate"
              label="16h"
            ><!-- 16h -->
              <template slot-scope="scope">
                <el-button v-if="scope.row.within16hRate" @click="toDetail(scope.row, 7)">{{ scope.row.within16hRate }}</el-button>
                <span v-else>{{ scope.row.within16hRate }}</span>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
        <div class="right">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryForm.pageNumber"
            :page-sizes="[10, 20, 50, 100, 500, 1000]"
            :page-size.sync="queryForm.pageSize"
            :total="total"
            background
            style="margin: 10px 0"
            @current-change="getDatePage"
            @size-change="getPageSize"
          />
        </div>
      </el-tab-pane>

      <el-tab-pane v-if="active=='mxFind'" :label="$t('collectionCenter.particulars')" name="mxFind">
        <el-table
          ref="mxTable"
          :max-height="600"
          border
          :data="tableDateMX"
        >
          <el-table-column
            type="selection"
            width="55"
          />
          <el-table-column
            align="center"
            prop="scanNumber"
            :label="$t('collectionCenter.Barcode')"
          /><!-- 条形码 -->
          <el-table-column
            align="center"
            prop="scanTime"
            :label="$t('collectionCenter.lanshouT')"
          /><!-- 揽收时间 -->
          <el-table-column
            align="center"
            prop="collectSiteName"
            :label="$t('collectionCenter.LanShouSite')"
          /><!-- 揽收网点 -->
          <el-table-column
            align="center"
            prop="collectUserName"
            :label="$t('collectionCenter.LanShouPeople')"
          /><!-- 揽收人 -->
          <el-table-column
            align="center"
            prop="signerTime"
            :label="$t('collectionCenter.signT')"
          /><!-- 签收时间 -->
          <el-table-column
            align="center"
            prop="siteLoadingTime"
            :label="$t('collectionCenter.networkLoadingTime')"
          /><!-- 网点装车时间 -->
          <el-table-column
            align="center"
            prop="siteLoadingName"
            :label="$t('collectionCenter.networkLoader')"
          /><!-- 网点装车人 -->
          <el-table-column
            align="center"
            prop="transferCenterUnloadTime"
            :label="$t('collectionCenter.unloadingTransferCenterTime')"
          /><!-- 转运中心卸车时间 -->
          <el-table-column
            align="center"
            prop="transferCenterUnloadName"
            :label="$t('collectionCenter.unloadingTransferCenterP')"
          /><!-- 转运中心卸车人 -->
          <el-table-column
            align="center"
            prop="transferCenterLoadingTime"
            :label="$t('collectionCenter.transferCenterLoadingTime')"
          /><!-- 转运中心装车时间 -->
          <el-table-column
            align="center"
            prop="transferCenterLoadingName"
            :label="$t('collectionCenter.transferCenterLoader')"
          /><!-- 转运中心装车人 -->
        </el-table>
        <div class="right">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryFormMX.pageNumber"
            :page-sizes="[10, 20, 50, 100, 500, 1000]"
            :page-size.sync="queryFormMX.pageSize"
            :total="totalMX"
            background
            style="margin: 10px 0"
            @current-change="getDatePageMX"
            @size-change="getPageSizeMX"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

import {
  queryAllCustomer,
  kpiMonitoringPage,
  kpiMonitoringArea,
  kpiMonitoringDetail,
  kpiMonitoringExportAll,
  kpiMonitoringDetailExportAll
} from '@/api/lanshou';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';

export default {
  name: 'KPIMonitoringReports',
  data() {
    return {
      timeValue: [],
      customerNameList: [], // 客户列表
      AList1: [],
      AList2: [],
      queryForm: {
        pageNumber: 1,
        pageSize: 50,
        params: {
          startTime: '',
          endTime: '',
          customerId: '',
          platform: '',
          region: [],
          area: []
        }
      },
      tableDate: [],
      total: 0,
      active: 'hzFind',
      queryFormMX: {
        pageNumber: 1,
        pageSize: 50,
        params: {
          queryTime: '',
          customerCode: '',
          platform: '',
          zipCode: '',
          type: '',
          region: '',
          area: ''
        }
      },
      totalMX: 0,
      tableDateMX: []
    };
  },
  created() {
    this.active = 'hzFind';
    this.default();
    this.searchClick();
  },
  methods: {
    getAList(type) {
      kpiMonitoringArea(type).then(res => {
        if (res.status === 'OK') {
          if (type === 1) {
            this.AList1 = res.data.map(arr => {
              return {
                value: arr
              };
            });
          }
          if (type === 2) {
            this.AList2 = res.data.map(arr => {
              return {
                value: arr
              };
            });
          }
        }
      }).catch({});
    },
    getAllCustomer() {
      // 查询所有客户信息
      queryAllCustomer().then(res => {
        if (res.status === 'OK') {
          this.customerNameList = [];
          res.data.forEach((list) => {
            this.customerNameList.push({
              id: list.id,
              username: list.username
            });
          });
        }
      });
    },
    dateChange(val) {
      if (val === null) {
        this.timeValue = [];
        this.queryForm.params.startTime = '';
        this.queryForm.params.endTime = '';
        return;
      }
      this.queryForm.params.startTime = val[0];
      this.queryForm.params.endTime = val[1];

      if (val[1]) {
        const endTime = dayjs(val[0]).subtract(-6, 'day').format('YYYY-MM-DD');
        if (val[1] > endTime) {
          this.$message({
            // '单次最多查询一个星期的数据'
            message: this.$t('collectionCenter.chooseOneWeek'),
            type: 'warning'
          });
          const d = new Date();
          const today = dayjs(d).subtract(1, 'day').format('YYYY-MM-DD');
          this.timeValue = [today, today];
          this.queryForm.params.startTime = today;
          this.queryForm.params.endTime = today;
        }
      }
    },
    default() {
      const d = new Date();
      const day = dayjs(d).subtract(1, 'day').format('YYYY-MM-DD');
      this.timeValue = [day, day];
      this.queryForm = {
        pageNumber: 1,
        pageSize: 50,
        params: {
          startTime: day,
          endTime: day,
          customerId: '',
          platform: '',
          region: [],
          area: []
        }
      };
    },
    resetClick() {
      this.default();
    },
    searchClick() {
      this.active = 'hzFind';
      this.queryForm.pageNumber = 1;
      localStorage.setItem('kpiQueryForm', JSON.stringify(this.queryForm));
      this.getDatePage();
    },
    getDatePage() { // 表格page
      const queryForm = JSON.parse(localStorage.getItem('kpiQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      kpiMonitoringPage(queryForm).then(res => {
        if (res.status === 'OK') {
          this.total = res.data.total;
          this.tableDate = res.data.records;
        }
      }).catch({});
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      const query = JSON.parse(localStorage.getItem('kpiQueryForm')) || this.queryForm;
      const queryForm = {
        pageNumber: 1,
        pageSize: this.queryForm.pageSize,
        params: query.params
      };
      kpiMonitoringPage(queryForm).then(res => {
        if (res.status === 'OK') {
          this.total = res.data.total;
          this.tableDate = res.data.records;
        }
      }).catch({});
    },
    download() {
      const query = JSON.parse(localStorage.getItem('kpiQueryForm')) || this.queryForm;
      kpiMonitoringExportAll(query).then(res => {
        if (res.status === 'OK') {
          this.$refs.hzTable.clearSelection();
          goExportList(this);
        }
      }).catch({});
    },
    toDetail(row, type) {
      this.active = 'mxFind';
      this.queryFormMX = {
        pageNumber: 1,
        pageSize: 50,
        params: {
          queryTime: row.scanDate,
          customerCode: row.customerId,
          platform: row.platform,
          zipCode: row.zipCode,
          type,
          region: row.region,
          area: row.area
        }
      };
      kpiMonitoringDetail(this.queryFormMX).then(res => {
        if (res.status === 'OK') {
          this.totalMX = res.data.total;
          this.tableDateMX = res.data.records;
        }
      }).catch(() => {
        this.totalMX = 0;
        this.tableDateMX = [];
      });
    },
    getDatePageMX() {
      kpiMonitoringDetail(this.queryFormMX).then(res => {
        if (res.status === 'OK') {
          this.totalMX = res.data.total;
          this.tableDateMX = res.data.records;
        }
      }).catch(() => {
        this.totalMX = 0;
        this.tableDateMX = [];
      });
    },
    getPageSizeMX() {
      this.queryFormMX.pageNumber = 1;
      kpiMonitoringDetail(this.queryFormMX).then(res => {
        if (res.status === 'OK') {
          this.totalMX = res.data.total;
          this.tableDateMX = res.data.records;
        }
      }).catch(() => {
        this.totalMX = 0;
        this.tableDateMX = [];
      });
    },
    downloadMX() {
      kpiMonitoringDetailExportAll(this.queryFormMX).then(res => {
        if (res.status === 'OK') {
          this.$refs.mxTable.clearSelection();
          goExportList(this);
        }
      });
    }
  }
};
</script>
<style>
  .el-select-dropdown__item{
    max-width: 200px;
  }
</style>
<style lang="scss" scoped>
  .Box{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .selectClass ::v-deep .el-form-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .selectClass ::v-deep .el-form-item__label{
    padding: 0;
    line-height: 16px;
    white-space: nowrap;
    margin-left: 20px;
  }
  .selectClass ::v-deep .el-form-item__content{
    margin-left: 0 !important;
    width: 100%;
  }
  .selectClass ::v-deep .el-select{
    width: 100%;
  }

</style>
