<template>
  <!-- 卸车扫描 -->
  <div class="Box">
    <div>
      <el-steps :active="active" finish-status="success" align-center>
        <el-step :title="$t('collectionCenter.scanningVehicle')" /><!--  扫描车辆  -->
        <el-step :title="$t('collectionCenter.scanPgTrc')" /><!--  扫描大包号/运单号  -->
      </el-steps>
    </div>
    <div v-show="active == 0" class="scanClass">
      <span>{{ $t('collectionCenter.carScan') }}</span><!--  车辆扫描：  -->
      <el-input
        v-if="isScanCar"
        v-model.trim="carMsg"
        :placeholder="$t('collectionCenter.scanQRCodeOrNum')"
        clearable
        @keyup.enter.native="scanCar"
      /><!--  请扫描车辆二维码或输入车辆编号  -->
      <el-tooltip
        v-else effect="dark" :content="$t('userManage.unauthorized')"
        placement="right"
      >
        <el-input v-model.trim="carMsg" :placeholder="$t('collectionCenter.scanQRCodeOrNum')" clearable />
      </el-tooltip>
    </div>
    <!--  车辆信息  -->
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="30%"
      @close="handleClose"
    >
      <div class="dialogClass">
        <!--  车辆编号：  -->
        <span>{{ $t('collectionCenter.carNum') }}{{ carList.id }}</span>
        <!--  车牌号：  -->
        <span>{{ $t('collectionCenter.licensePlateNum') }}{{ carList.vehicleNumber }}</span>
        <!--  运营模式：  -->
        <span>{{ $t('collectionCenter.operationM') }}{{ carList.operationModeName }}</span>
        <!--  车辆类型：  -->
        <span>{{ $t('collectionCenter.carT') }}{{ carList.vehicleTypeName }}</span>
        <!--  驾驶人：  -->
        <span>{{ $t('collectionCenter.carDriver') }}{{ carList.vehicleDriver }}</span>
        <!--  所属网点：  -->
        <span>{{ $t('collectionCenter.belongToSite') }}{{ carList.siteIdName }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <!--  下一步  -->
        <el-button type="primary" @click="next">{{ $t('collectionCenter.next') }}</el-button>
      </span>
    </el-dialog>

    <div v-show="active == 1">
      <div class="scanClass">
        <!--  大包号/运单号扫描：  -->
        <span>{{ $t('collectionCenter.scanPgTrcN') }}</span>
        <el-input
          v-model.trim="bagNumber"
          :placeholder="$t('collectionCenter.PleaseScan')"
          clearable
          @keyup.enter.native="scanBagNumber"
        />
      </div>
    </div>
    <div v-show="active == 1">
      <!-- :disabled="sucTable.length > 0" 有扫描成功的数据时不允许撤回到上一步 -->
      <el-button :disabled="sucTable.length > 0" @click="last">{{ $t('userManage.Previous') }}</el-button><!--  返回上一步  -->
      <!-- 卸车结束 -->
      <el-button type="primary" style="margin-top: 12px;" @click="finish">{{ $t('collectionCenter.endOfUnloading') }}</el-button>
    </div>
    <div v-show="active == 1">
      <!-- 上级转运中心：-->
      <div v-show="superiorTransshipmentCenter" class="titleTop">{{ $t('customerManagements.upTransferName') }}：{{ superiorTransshipmentCenter }}</div>
      <!-- 当前卸车车辆：-->
      <div class="titleTop">{{ $t('collectionCenter.currentUnloadingVehicle') }}：{{ vehicleNumber }}</div>
      <div class="titleTop">
        <!-- 应卸：-->
        <span>
          {{ $t('collectionCenter.shouldUnloaded') }}：<span class="totalClass">{{ loadedVehicle.loadedVehicleBagNum }}</span>{{ $t('collectionCenter.bags') }}
        </span>
        <!-- 实卸：-->
        <span style="margin-left: 50px">
          {{ $t('collectionCenter.actualUnloaded') }}：<span class="totalClass green">{{ actualUnloadedBags }}</span>{{ $t('collectionCenter.bags') }}
        </span>
      </div>
      <div class="titleTop">
        <!-- 应卸车：-->
        <span>
          {{ $t('collectionCenter.shouldUnload') }}：<span class="totalClass">{{ shouldUnloadNum }}</span>{{ $t('basicData.ticket') }}
        </span>
        <!-- 已卸车：-->
        <span style="margin-left: 50px">
          {{ $t('GlobalSetObj.Descarregado') }}：<span class="totalClass green">{{ unloadedNum }}</span>{{ $t('basicData.ticket') }}
        </span>
      </div>
      <!--  已扫描  -->
      <div class="titleTop">{{ $t('collectionCenter.scanned') }} <span class="totalClass">{{ tablePage.length }}</span></div>
      <el-table
        :max-height="600"
        :data="tablePage"
        border
      >
        <!-- <el-table-column
          align="center"
          prop="vehicleTag"
          :label="$t('collectionCenter.carNumber')"
        />车签号 -->
        <el-table-column
          align="center"
          prop="bagNumber"
          :label="$t('collectionCenter.BagTrcNumber')"
        /><!-- 袋号/运单号 -->
        <el-table-column
          align="center"
          prop="scanTime"
          :label="$t('collectionCenter.scanTime')"
        /><!-- 扫描时间 -->
        <!--  -->
        <el-table-column
          :label="$t('collectionCenter.operation')"
          fixed="right"
          width="100"
          align="center"
        ><!-- 操作 -->
          <template slot-scope="scope">
            <el-popconfirm
              :title="$t('collectionCenter.deleteSure')"
              @confirm="scanDelClick(scope.row, scope.$index)"
            ><!-- 确定删除吗 -->
              <el-button slot="reference" type="danger" size="mini">{{ $t('collectionCenter.Delete') }}</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>

    </div>

    <div v-if="signVisible" class="signMask">
      <div class="signVisibleClass">
        <div class="el-icon-close signClose" @click="closeSign" />
        <div class="descriptionsClass">
          <div ref="image">
            <el-descriptions
              title="" :column="1" border
              :content-style="rowClass" :label-style="labelRowClass"
            >
              <el-descriptions-item label="">
                <div class="top1">
                  <!-- 车牌号： -->
                  <span>{{ $t('collectionCenter.licensePlateNum') }}{{ scanSign.vehicleNumber }}</span>
                  <img :src="LOGO" alt="">
                </div>
              </el-descriptions-item>
              <el-descriptions-item label=""><div class="isolateClass" /></el-descriptions-item>
              <el-descriptions-item label="">
                <div class="two">
                  <!-- 卸车地点： -->
                  <div class="dd">{{ $t('collectionCenter.unloadPoint') }}</div>
                  <div class="dd">{{ scanSign.unloadSite }}</div>
                </div>
              </el-descriptions-item>
              <el-descriptions-item label="">
                <div class="two">
                  <!-- 卸车人： -->
                  <div class="dd">{{ $t('collectionCenter.unloader') }}：</div>
                  <div class="dd">{{ scanSign.unloadUser }}</div>
                </div>
              </el-descriptions-item>
              <el-descriptions-item label="">
                <div class="two">
                  <!-- 卸车完成时间： -->
                  <div class="dd">{{ $t('collectionCenter.unloadCompletionTime') }}：</div>
                  <div class="dd">{{ scanSign.signerTime }}</div>
                </div>
              </el-descriptions-item>
              <!-- 包裹信息 -->
              <el-descriptions-item label=""><div class="isolateClass">{{ $t('collectionCenter.infoPackage') }}</div></el-descriptions-item>
              <el-descriptions-item label="">
                <div class="two">
                  <!-- 卸车总数： -->
                  <div class="dd">{{ $t('collectionCenter.totalUnloading') }}</div>
                  <div class="dd">{{ scanSign.bagNumberCount }}{{ $t('collectionCenter.bag') }}（{{ scanSign.realityNumber }}）{{ $t('collectionCenter.ticket') }}</div>
                </div>
              </el-descriptions-item>
              <!-- 签名确认 -->
              <el-descriptions-item label=""><div class="isolateClass">{{ $t('collectionCenter.signatureConfirmation') }}</div></el-descriptions-item>
              <el-descriptions-item label="">
                <div class="two">
                  <div class="dd">
                    <!-- 与装车数据的差异：xx袋（xx票）-->
                    {{ $t('collectionCenter.w1') }}{{ scanSign.loadBagNumber }}
                    {{ $t('collectionCenter.w2') }}{{ scanSign.loadPackageNumber }}
                    {{ $t('collectionCenter.w3') }}
                  </div>
                  <div class="dd textarea">
                    <!-- 备注 -->
                    <span>{{ $t('collectionCenter.Note') }}：</span>
                    <div v-if="showTime" class="remarkShow">
                      {{ scanSign.remark }}
                    </div>
                    <el-input
                      v-else
                      v-model="scanSign.remark"
                      type="textarea"
                      autosize
                      :placeholder="$t('collectionCenter.errRemark')"
                    />
                  </div>
                </div>
              </el-descriptions-item>
              <el-descriptions-item label="">
                <div style="width: 550px;height: 260px;overflow: hidden;">
                  <vue-esign
                    ref="esign" :width="550" :height="260"
                    :line-width="5" line-color="#000" bg-color="#FFF"
                  />
                </div>
              </el-descriptions-item>
            </el-descriptions>
            <div class="statementClass">
              Declaro para todos os fins legais que li e verifiquei a exatidão e veracidade de todas as informações
              contidas neste formulário, e assumo total responsabilidade civil e criminal por quaisquer danos materiais e morais decorrentes de qualquer não conformidade das informações aqui contidas.
            </div>
          </div>
          <div class="signBtn">
            <el-button type="success" class="btn" @click="handleGenerate">{{ $t('collectionCenter.Confirm') }}</el-button><!-- 确认 -->
            <el-button class="btn" @click="resetSign">{{ $t('collectionCenter.empty') }}</el-button><!-- 置空 -->
          </div>
        </div>
      </div>
    </div>

    <!-- 拍照
    <div v-if="active !== 0" class="cameraClass">
      <span>{{ $t('collectionCenter.Upload') + $t('collectionCenter.unloading') + $t('collectionCenter.picture') + '：' }}</span>
      <camera ref="changeImg" :fileList1="fileList1" @uploadImg="getImg"></camera>
    </div>
     -->
    <!-- 隐藏提示音 -->
    <div v-show="false">
      <audio ref="errRef" :src="errorMp3" controls="controls" />
      <audio ref="okRef" :src="okMp3" controls="controls" />
    </div>
  </div>
</template>

<script>
const okMp3 = '/static/scan_success.mp3';
const errorMp3 = '/static/scan_fail.mp3';
import {
  unloadCheckVehicle,
  checkVehicleUnloadedBagNumber,
  collectShouldUnload,
  queryUnloadBagCountAndSiteByBagNumber,
  updateUnloadSignImg
} from '@/api/lanshou';
// import camera from './camera';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import { uploadOos } from '@/utils/ali_oos_util';

export default {
  name: 'UnloadingScan',
  // components: {
  //   camera
  // },
  data() {
    return {
      superiorTransshipmentCenter: '',
      loadedVehicle: {
        loadedVehicleBagNum: 0
      },
      actualUnloadedBags: 0,
      saveSucTable: {},
      sucTable: [],
      signImg: '',
      showTime: false, // 签名截图 备注字段截图bug
      scanSign: {},
      rowClass: {
        'border-width': '2px',
        'border-color': '#000'
      },
      labelRowClass: {
        'border-width': '2px',
        'border-color': '#000'
      },
      LOGO: '/static/anjunLogo.png',
      signVisible: false,
      shouldUnloadNum: 0,
      unloadedNum: 0,
      carBagCache: {}, // 根据车辆保存对应的袋号/运单号————缓存
      vehicleNumber: '',
      unloadedTableList: {}, // 卸车缓存
      tablePage: [],
      bagNumber: '',
      isScanCar: this.$store.getters.button_permissions.includes('btn:collection-center:operation:unloadingScan:scan'),
      fileList1: [],
      active: 0,
      dialogVisible: false,
      carMsg: '', // 车辆扫描
      carList: {}, // 扫描车辆获取的返回值
      vehicleId: '',
      // 后加的提交用到的参数
      operationMode: '',
      vehicleType: '',
      okMp3,
      errorMp3
    };
  },
  watch: {
    tablePage() {
      if (this.tablePage.length <= 0) {
        this.bagNumber = '';
      }
    }
  },
  created() {
    if (localStorage.getItem('unloadedSucTable')) {
      this.saveSucTable = JSON.parse(localStorage.getItem('unloadedSucTable')) || {};
      const user = 'ss' + this.$store.state.user.id;
      if (this.saveSucTable[user]) {
        this.sucTable = this.saveSucTable[user].sucTable;
      } else {
        this.sucTable = [];
      }
    }

    if (localStorage.getItem('unloadedList')) {
      this.unloadedTableList = JSON.parse(localStorage.getItem('unloadedList')) || {};
      const who = 'unloaded' + this.$store.state.user.id;
      if (this.unloadedTableList[who]) {
        this.tablePage = this.unloadedTableList[who].tablePage;
        this.vehicleId = this.unloadedTableList[who].vehicleId;
        this.active = this.unloadedTableList[who].active;
        this.vehicleNumber = this.unloadedTableList[who].vehicleNumber;
        this.operationMode = this.unloadedTableList[who].operationMode;
        this.vehicleType = this.unloadedTableList[who].vehicleType;
      } else {
        this.tablePage = [];
        this.vehicleId = '';
        this.active = 0;
        this.operationMode = '';
        this.vehicleType = '';
      }
      if (this.vehicleId && this.active === 1) {
        collectShouldUnload(this.vehicleId).then(res => {
          if (res.status === 'OK') {
            this.shouldUnloadNum = res.data.loadedVehicleNum;
            this.loadedVehicle = {
              loadedVehicleBagNum: res.data.loadedVehicleBagNum || 0
            };
          }
        });
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    console.log('this.sucTable.length', this.sucTable.length);
    if (this.sucTable.length > 0 && !this.signImg) {
      this.$message({
        message: this.$t('collectionCenter.unsigned'), // '未签名!'
        type: 'warning'
      });
      return false;
    } else {
      next();
    }
  },
  methods: {
    closeSign() {
      this.signVisible = false;
      this.scanSign = {};
    },
    resetSign() {
      this.$refs['esign'].reset(); // 清空画布
    },
    handleGenerate() {
      if (this.scanSign.remark) {
        this.showTime = true;
        setTimeout(() => {
          this.showTime = false;
        }, 2000);
      }
      this.$refs['esign'].generate().then(res => {
        this.canvasImg();
      }).catch((err) => { // 没有签名，点击生成图片时调用
        console.log(err);
        this.$message({
          message: this.$t('collectionCenter.unsigned'), // '未签名!'
          type: 'warning'
        });
      });
    },
    canvasImg() {
      const loading = this.$loading({
        fullscreen: true,
        lock: true,
        spinner: 'el-icon-loading',
        text: this.$i18n.t('orderCenterCont.DesperatelyLoading'), // '拼命加载中',
        background: 'rgba(250, 250, 250, 0.6)'
      });
      const canvas = document.createElement('canvas'); // 截图区
      const canvasBox = this.$refs.image; // 父级
      const width = parseInt(window.getComputedStyle(canvasBox).width);
      const height = parseInt(window.getComputedStyle(canvasBox).height);
      const size = window.devicePixelRatio;
      canvas.width = width * 2 * size;
      canvas.height = height * 2 * size;
      canvas.style.width = width + 'px';
      canvas.style.height = height + 'px';
      const context = canvas.getContext('2d');
      context.scale(2, 2);
      const options = {
        backgroundColor: null,
        canvas: canvas,
        useCORS: true
      };
      html2canvas(canvasBox, options).then((canvas) => {
        // toDataURL 图片格式转成 base64
        const dataURL = canvas.toDataURL('image/png');
        const rSignImg = this.base64ImgtoFile(dataURL, 'file'); // 得到文件格式
        uploadOos(rSignImg, 'sign').then(res => {
          if (res.code === 200) {
            if (res.url) {
              this.showTime = false;
              this.signImg = res.url;
              this.signVisible = false;
              this.scanSign = {};
              this.verifySignData();
              loading.close();
            } else {
              this.signImg = '';
            }
          }
        }).catch({}).finally(() => {
          loading.close();
        }).finally(() => {
          loading.close();
        });
      });
    },
    base64ImgtoFile(dataurl, filename = 'file') {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const suffix = mime.split('/')[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
      });
    },
    scanBagNumber() {
      if (!this.bagNumber) {
        this.$refs.errRef.play();
        return;
      }
      const scanForm = {
        bagNumber: this.bagNumber,
        vehicleId: this.vehicleId
      };
      const d = new Date();
      const bagNumber1 = this.bagNumber;
      checkVehicleUnloadedBagNumber(scanForm).then(res => {
        if (res.status === 'ERROR') {
          this.bagNumber = '';
          this.superiorTransshipmentCenter = '';
          this.$refs.errRef.play();
          this.$message({
            message: res.msg,
            type: 'warning'
          });
          this.tablePage.unshift({
            vehicleTag: res.data.tagNumber,
            bagNumber: bagNumber1,
            scanTime: dayjs(d).format('HH:mm:ss YYYY-MM-DD'),
            unloadOrderIdList: res.data.unloadOrderIdList,
            unloadTicketCount: res.data.unloadTicketCount, // 卸车票数
            unloadBagNumber: res.data.unloadBagNumber, // 卸车袋号
            unloadBagCount: res.data.unloadBagCount // 卸车袋数
          });
        }
        // 扫描成功后遍历表中数据，删除相同项
        if (res.status === 'OK') {
          this.superiorTransshipmentCenter = res.data.upTransferCenterName;
          this.actualUnloadedBags += res.data.unloadBagCount;
          /**
           *  -------------------------------成功数据缓存起来
           */
          this.sucTable.push({
            tagNumber: res.data.tagNumber,
            bagNumber: bagNumber1,
            scanTime: dayjs(d).format('HH:mm:ss YYYY-MM-DD'),
            unloadOrderIdList: res.data.unloadOrderIdList,
            unloadTicketCount: res.data.unloadTicketCount, // 卸车票数
            unloadBagNumber: res.data.unloadBagNumber, // 卸车袋号
            unloadBagCount: res.data.unloadBagCount // 卸车袋数
          });
          this.saveSucTable['ss' + this.$store.state.user.id] = {
            sucTable: this.sucTable
          };
          // 加密
          const a = JSON.stringify(this.saveSucTable);
          localStorage.setItem('unloadedSucTable', a);

          this.unloadedNum += res.data.unloadTicketCount;
          this.bagNumber = '';
          this.$refs.okRef.play();
          this.$message.success(this.$t('collectionCenter.unloadedSuccessfully')); // 卸车成功
          this.tablePage.forEach((arr, index) => {
            if (arr.bagNumber === bagNumber1) {
              this.tablePage.splice(index, 1);
            }
          });
        }
        this.unloadedTableList['unloaded' + this.$store.state.user.id] = {
          tablePage: this.tablePage,
          vehicleId: this.vehicleId,
          active: this.active,
          vehicleNumber: this.vehicleNumber,
          operationMode: this.operationMode,
          vehicleType: this.vehicleType
        };
        // 加密
        const a = JSON.stringify(this.unloadedTableList);
        localStorage.setItem('unloadedList', a);

        // 车辆对应的袋号另外缓存
        this.carBagCache[this.vehicleId] = {
          vehicleId: this.vehicleId,
          tablePage: this.tablePage
        };
        const carBag = JSON.stringify(this.carBagCache);
        localStorage.setItem('carNumberBag', carBag);
      }).catch(() => {
        this.bagNumber = '';
        this.superiorTransshipmentCenter = '';
        this.$refs.errRef.play();
        const d = new Date();
        const today = dayjs(d).format('HH:mm:ss YYYY-MM-DD');
        this.tablePage.unshift({
          vehicleTag: '',
          bagNumber: bagNumber1,
          scanTime: today,
          unloadOrderIdList: '',
          unloadTicketCount: '', // 卸车票数
          unloadBagNumber: '', // 卸车袋号
          unloadBagCount: '' // 卸车袋数
        });
        this.unloadedTableList['unloaded' + this.$store.state.user.id] = {
          tablePage: this.tablePage,
          vehicleId: this.vehicleId,
          active: this.active,
          vehicleNumber: this.vehicleNumber,
          operationMode: this.operationMode,
          vehicleType: this.vehicleType
        };
        // 加密
        const a = JSON.stringify(this.unloadedTableList);
        localStorage.setItem('unloadedList', a);

        // 车辆对应的袋号另外缓存
        this.carBagCache[this.vehicleId] = {
          vehicleId: this.vehicleId,
          tablePage: this.tablePage
        };
        const carBag = JSON.stringify(this.carBagCache);
        localStorage.setItem('carNumberBag', carBag);
      });
    },
    scanDelClick(row, index) {
      this.tablePage.splice(index, 1);
      if (this.tablePage.length <= 0 || row.bagNumber == this.bagNumber) {
        this.bagNumber = '';
      }
      this.unloadedTableList['unloaded' + this.$store.state.user.id] = {
        tablePage: this.tablePage,
        vehicleId: this.vehicleId,
        active: this.active,
        vehicleNumber: this.vehicleNumber,
        operationMode: this.operationMode,
        vehicleType: this.vehicleType
      };
      // 加密
      const a = JSON.stringify(this.unloadedTableList);
      localStorage.setItem('unloadedList', a);

      // 车辆对应的袋号另外缓存
      this.carBagCache[this.vehicleId] = {
        vehicleId: this.vehicleId,
        tablePage: this.tablePage
      };
      const carBag = JSON.stringify(this.carBagCache);
      localStorage.setItem('carNumberBag', carBag);
    },
    // x车图片
    getImg(val) {
      if (val) {
        this.fileList1 = val;
      } else {
        this.fileList1 = [];
        return;
      }
    },
    scanCar() {
      if (!this.carMsg) {
        this.$message({
          message: this.$t('collectionCenter.pleaseEnterOrScan'), // '请输入或扫描'
          type: 'warning'
        });
        this.$refs.errRef.play();
        return;
      }
      unloadCheckVehicle(this.carMsg).then(res => {
        if (res.status === 'OK') {
          this.operationMode = res.data.operationModeName;
          this.vehicleType = res.data.vehicleTypeName;
          this.dialogVisible = true;
          this.carList = res.data;
          this.vehicleId = res.data.id;
          this.vehicleNumber = res.data.vehicleNumber;

          this.unloadedTableList['unloaded' + this.$store.state.user.id] = {
            tablePage: this.tablePage,
            vehicleId: this.vehicleId,
            active: this.active,
            vehicleNumber: this.vehicleNumber,
            operationMode: this.operationMode,
            vehicleType: this.vehicleType
          };
          // 加密
          const a = JSON.stringify(this.unloadedTableList);
          localStorage.setItem('unloadedList', a);
          collectShouldUnload(res.data.id).then(res => {
            if (res.status === 'OK') {
              this.unloadedNum = 0;
              this.actualUnloadedBags = 0;
              this.shouldUnloadNum = res.data.loadedVehicleNum;
              this.loadedVehicle = {
                loadedVehicleBagNum: res.data.loadedVehicleBagNum || 0
              };
            }
          });

          this.$refs.okRef.play();
        } else {
          this.$refs.errRef.play();
        }
      }).catch(() => {
        this.carMsg = '';
        this.$refs.errRef.play();
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    last() {
      this.active--;
      this.carMsg = '';
      this.bagNumber = '';
      this.$nextTick(() => {
        this.unloadedTableList['unloaded' + this.$store.state.user.id] = {
          tablePage: this.tablePage,
          vehicleId: this.vehicleId,
          active: this.active,
          vehicleNumber: this.vehicleNumber,
          operationMode: this.operationMode,
          vehicleType: this.vehicleType
        };
        // 加密
        const a = JSON.stringify(this.unloadedTableList);
        localStorage.setItem('unloadedList', a);
      });
    },
    next() {
      this.dialogVisible = false;

      // 保留每台车辆扫入的缓存袋号信息
      if (this.active === 0 && localStorage.getItem('carNumberBag')) {
        // 拿到扫描袋号后的缓存，根据车辆id赋值对应的袋号
        this.carBagCache = JSON.parse(localStorage.getItem('carNumberBag'));
        const carBagLst = this.carBagCache[this.vehicleId];
        if (carBagLst && (this.vehicleId === carBagLst.vehicleId)) {
          this.tablePage = this.carBagCache[this.vehicleId].tablePage || [];
        } else {
          this.tablePage = [];
        }
      }

      this.active++;
      this.carMsg = '';
      this.bagNumber = '';

      this.$nextTick(() => {
        this.unloadedTableList['unloaded' + this.$store.state.user.id] = {
          tablePage: this.tablePage,
          vehicleId: this.vehicleId,
          active: this.active,
          vehicleNumber: this.vehicleNumber,
          operationMode: this.operationMode,
          vehicleType: this.vehicleType
        };
        // 加密
        const a = JSON.stringify(this.unloadedTableList);
        localStorage.setItem('unloadedList', a);
      });
    },
    finish() {
      if (this.sucTable.length <= 0) {
        this.$message({
          message: this.$t('collectionCenter.toUnload'), // '已卸车数量为0，请先卸车'
          type: 'warning'
        });
        return;
      }
      const bagL = this.sucTable.map(item => {
        return {
          tagNumber: item.tagNumber,
          unloadBagNumber: item.unloadBagNumber,
          unloadTicketCount: item.unloadTicketCount,
          unloadBagCount: item.unloadBagCount
        };
      });
      const bagForm = {
        bagNumberList: bagL,
        driverId: this.$store.state.user.id,
        siteId: this.$store.state.user.siteId,
        vehicleId: this.vehicleId
      };
      queryUnloadBagCountAndSiteByBagNumber(bagForm).then(res => {
        this.scanSign = {
          vehicleNumber: this.vehicleNumber, // 车牌号
          unloadSite: this.$store.state.user.dotName, // 卸车地点
          unloadUser: this.$store.state.user.realName, // 卸车人
          signerTime: res.data.signerTime, // 时间
          realityNumber: res.data.realityNumber,
          bagNumberCount: res.data.bagNumberCount,
          scanNumbers: res.data.scanNumbers, //
          loadBagNumber: res.data.loadBagNumber, // 袋数
          loadPackageNumber: res.data.loadPackageNumber // 票数
        };
        this.signVisible = true;
      }).catch({});
    },
    verifySignData() {
      const unloadBags = this.sucTable.map(item => {
        return {
          orderIds: item.unloadOrderIdList,
          tagNumber: item.tagNumber,
          unloadTicketCount: item.unloadTicketCount,
          unloadBagCount: item.unloadBagCount,
          unloadBagNumber: item.unloadBagNumber
        };
      });
      const signForm = {
        unloadSignImg: this.signImg,
        vehicleNumber: this.vehicleNumber,
        vehicleId: this.vehicleId,
        unloadBags
      };
      updateUnloadSignImg(signForm).then(res => {
        this.signVisible = false;
        this.sucTable = [];
        this.saveSucTable['ss' + this.$store.state.user.id] = {
          sucTable: this.sucTable
        };
        // 加密
        const s = JSON.stringify(this.saveSucTable);
        localStorage.setItem('unloadedSucTable', s);

        this.unloadedTableList['unloaded' + this.$store.state.user.id] = {
          tablePage: [],
          vehicleId: '',
          active: 0,
          vehicleNumber: '',
          operationMode: '',
          vehicleType: ''
        };
        const a = JSON.stringify(this.unloadedTableList);
        localStorage.setItem('unloadedList', a);
        // 车辆对应的袋号另外缓存
        this.carBagCache[this.vehicleId] = {
          vehicleId: '',
          tablePage: []
        };
        const carBag = JSON.stringify(this.carBagCache);
        localStorage.setItem('carNumberBag', carBag);

        this.fileList1 = [];
        this.carMsg = '';
        this.tablePage = [];
        this.vehicleId = '';
        this.vehicleType = '';
        this.vehicleNumber = '';
        this.operationMode = '';
        this.bagNumber = '';
        this.superiorTransshipmentCenter = '';
        setTimeout(() => {
          this.active = 0;
        }, 100);
      });
    }
  }
};
</script>

<style lang="scss" scoped>

  .top1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 17px;
    font-weight: bold;
    padding: 3px;
    box-sizing: border-box;
  }
  .remarkShow{
    width: 80%;
    font-size: 14px;
    font-weight: normal;
    text-align: left;
    border: 1px solid #d4d7df;
    border-radius: 5px;
    padding: 3px;
    box-sizing: border-box;
  }
  .two{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
  }
  .two .dd ::v-deep .el-radio{
    margin-right: 10px;
  }
  .two .dd ::v-deep .el-radio .el-radio__label{
    padding-left: 2px;
  }
  .dd.textarea ::v-deep .el-textarea{
    width: 70%;
  }
  .two .dd{
    width: 50%;
    padding: 10px 0;
    padding-left: 3px;
    font-weight: bold;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .two div.dd:nth-child(1) {
    border-right: 2px solid #000;
    height: 100%;
    position: absolute;
  }
  .two div.dd:nth-child(2) {
    margin-left: 50%;
  }
  .descriptionsClass ::v-deep tbody td{
    padding: 0 !important;
  }
  .descriptionsClass ::v-deep td:nth-child(1) th{
    width: 0;
    background: #222;
  }
  .descriptionsClass ::v-deep th{
    padding: 0 !important;
    border: none !important;
    box-sizing: border-box;
  }
  .descriptionsClass ::v-deep .el-descriptions .is-bordered .el-descriptions-item__cell{
    background: #fff;
    font-size: 17px;
    font-weight: 400;
    color: #000;
    text-align: center;
  }
  .descriptionsClass {
    position: relative;
    width: 554px;
    margin: 50px auto;
    .statementClass{
      position: absolute;
      bottom: 27px;
      left: 4px;
      color: red;
      font-size: 14px;
      //pointer-events: none; //  该元素会不触发事件,而且事件可以穿透该元素
    }
    .cameraClass{
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
    }
    .dialogClass{
      padding: 20px;
      border: 1px solid #b1b3b7;
      border-radius: 10px;
      box-sizing: border-box;
      span{
        font-size: 16px;
        display: block;
        margin: 10px 0;
      }
    }
    .tableClass{
      .titleTop{
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: center;
      }
    }
    .signBtn{
      position: absolute;
      right: 20px;
      bottom: 5px;
      .btn{
        color: #000;
        font-size: 17px;
      }
    }
  }
  .signMask{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.4);
    z-index: 99;
    .isolateClass{
      background-color: #d6d6d6;
      height: 40px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 17px;
      font-weight: bold;
    }
    .signVisibleClass{
      position: absolute;
      bottom:0;
      left:0;
      width:100%;
      height:100%;
      background:#fff;
      padding:20px 0;
      // overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: auto;
    }
    .signClose{
      position: absolute;
      top: 25px;
      right: 40px;
      transform: scale(1.3);
    }
  }
  .titleTop{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 17px;
    .green.totalClass {
      color: #95f204;
    }
    .totalClass{
      font-weight: bold;
      color: #da0014;
      font-size: 30px;
    }
  }
  .cameraClass{
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
  }
  .dialogClass{
    padding: 20px;
    border: 1px solid #b1b3b7;
    border-radius: 10px;
    box-sizing: border-box;
    span{
      font-size: 16px;
      display: block;
      margin: 10px 0;
    }
  }
  .scanClass{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
  }
  .scanClass ::v-deep .el-input{
    width: 30%;
  }
  .scanClass ::v-deep .el-input .el-input__inner{
    height: 45px;
  }
  .Box{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
</style>
