var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      staticClass: "demo-ruleForm",
      attrs: {
        model: _vm.formData,
        "label-width": "0",
        size: "small",
        rules: _vm.rules,
      },
    },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.formData.arr, border: "" } },
        [
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "startDistance",
                label: _vm.$t("basicData.KilometersStartKM") + "*",
                width: "200",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "arr." + scope.$index + ".startDistance",
                            rules: {
                              required: true,
                              trigger: "blur",
                              validator: function (rule, value, callback) {
                                return _vm.startCheck(
                                  rule,
                                  value,
                                  callback,
                                  scope.row
                                )
                              },
                            },
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              precision: 2,
                              min: 0,
                              max: 999999999,
                              controls: false,
                              placeholder: _vm.$t(
                                "basicData.KilometersStartKM"
                              ),
                            },
                            model: {
                              value: scope.row.startDistance,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "startDistance", $$v)
                              },
                              expression: "scope.row.startDistance",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c("div", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("basicData.KilometersStartKM"))),
                  ]),
                  _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "endDistance",
                label: _vm.$t("basicData.MileageEndKM"),
                width: "200",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "arr." + scope.$index + ".endDistance",
                            rules: {
                              required: true,
                              trigger: "blur",
                              validator: function (rule, value, callback) {
                                return _vm.startCheck(
                                  rule,
                                  value,
                                  callback,
                                  scope.row
                                )
                              },
                            },
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              precision: 2,
                              min: 0.01,
                              max: 999999999,
                              controls: false,
                              placeholder: _vm.$t("basicData.MileageEndKM"),
                            },
                            model: {
                              value: scope.row.endDistance,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "endDistance", $$v)
                              },
                              expression: "scope.row.endDistance",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c("div", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("basicData.MileageEndKM"))),
                  ]),
                  _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "vehicleType",
                label: _vm.$t("basicData.DriverType"),
                width: "200",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "arr." + scope.$index + ".vehicleType",
                            rules: _vm.rules.vehicleType,
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t("basicData.DriverType"),
                              },
                              model: {
                                value: scope.row.vehicleType,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "vehicleType", $$v)
                                },
                                expression: "scope.row.vehicleType",
                              },
                            },
                            _vm._l(_vm.DRIVER_TYPE, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c("div", [
                  _c("span", [_vm._v(_vm._s(_vm.$t("basicData.DriverType")))]),
                  _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "standardCargo",
                label: _vm.$t("basicData.DailyTaskCapacityFromBase") + "(>=)*",
                width: "200",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "arr." + scope.$index + ".standardCargo",
                            rules: _vm.rules.standardCargo,
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              precision: 0,
                              min: 1,
                              max: 999999999,
                              controls: false,
                              placeholder: _vm.$t(
                                "basicData.DailyTaskCapacityFromBase"
                              ),
                            },
                            model: {
                              value: scope.row.standardCargo,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "standardCargo", $$v)
                              },
                              expression: "scope.row.standardCargo",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c("div", [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("basicData.DailyTaskCapacityFromBase")) +
                        "(>=)"
                    ),
                  ]),
                  _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                ]),
              ]),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: { label: _vm.$t("basicData.CostCycle"), "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label:
                            _vm.$t("basicData.BasicDeliveryFee") + "(PT005)",
                          "label-width": "200px",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "arr." + scope.$index + ".standardPrice",
                                },
                              },
                              [
                                _c("el-input-number", {
                                  attrs: {
                                    precision: 3,
                                    min: 0,
                                    max: 999999999,
                                    controls: false,
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseInput"
                                    ),
                                  },
                                  model: {
                                    value: scope.row.standardPrice,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "standardPrice", $$v)
                                    },
                                    expression: "scope.row.standardPrice",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "arr." + scope.$index + ".billingMode",
                                  "label-width": "10px",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100px" },
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "GlobalSetObj.pleaseChoose"
                                      ),
                                    },
                                    model: {
                                      value: scope.row.billingMode,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "billingMode", $$v)
                                      },
                                      expression: "scope.row.billingMode",
                                    },
                                  },
                                  _vm._l(_vm.CHARGE_MODE, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label:
                            _vm.$t("basicData.ExceedingTargetIncentiveBonus") +
                            "(PT012)",
                          "label-width": "200px",
                        },
                      },
                      [
                        _c("el-input-number", {
                          attrs: {
                            precision: 3,
                            min: 0,
                            max: 999999999,
                            controls: false,
                            placeholder: _vm.$t(
                              "basicData.ExceedingTargetIncentiveBonus"
                            ),
                          },
                          model: {
                            value: scope.row.overshipmentPrice,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "overshipmentPrice", $$v)
                            },
                            expression: "scope.row.overshipmentPrice",
                          },
                        }),
                        _vm._v(
                          " / " +
                            _vm._s(_vm.$t("collectionCenter.ticket")) +
                            " "
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label:
                            _vm.$t("basicData.AttendanceIncentiveBonus") +
                            "(PT006)",
                          "label-width": "200px",
                        },
                      },
                      [
                        _c("el-input-number", {
                          attrs: {
                            precision: 3,
                            min: 0,
                            controls: false,
                            max: 999999999,
                            placeholder: "出勤激励奖金",
                          },
                          model: {
                            value: scope.row.incentiveBonus,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "incentiveBonus", $$v)
                            },
                            expression: "scope.row.incentiveBonus",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label:
                            _vm.$t("basicData.SingleVolumeBonusOverTarget") +
                            "(PT007)",
                          "label-width": "200px",
                        },
                      },
                      [
                        _c("el-input-number", {
                          attrs: {
                            precision: 3,
                            min: 0,
                            max: 999999999,
                            controls: false,
                            placeholder: _vm.$t(
                              "basicData.SingleVolumeBonusOverTarget"
                            ),
                          },
                          model: {
                            value: scope.row.price,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "price", $$v)
                            },
                            expression: "scope.row.price",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.FuelSubsidy") + "(PT009)",
                          "label-width": "200px",
                        },
                      },
                      [
                        _c("el-input-number", {
                          attrs: {
                            precision: 3,
                            min: 0,
                            max: 999999999,
                            controls: false,
                            placeholder: "$t('basicData.FuelSubsidy')",
                          },
                          model: {
                            value: scope.row.subsidy,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "subsidy", $$v)
                            },
                            expression: "scope.row.subsidy",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label:
                                _vm.$t("basicData.Remission") +
                                "INSS11%(PT010)",
                              "label-width": "200px",
                            },
                          },
                          [
                            _c("el-input-number", {
                              attrs: {
                                precision: 2,
                                min: 0,
                                max: 100,
                                controls: false,
                                placeholder: _vm.$t("basicData.Remission"),
                              },
                              model: {
                                value: scope.row.inssExemption,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "inssExemption", $$v)
                                },
                                expression: "scope.row.inssExemption",
                              },
                            }),
                            _vm._v(
                              "%" + _vm._s(_vm.$t("basicData.TaxBase")) + " "
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "", "label-width": "10px" } },
                          [
                            _c("el-input-number", {
                              attrs: {
                                precision: 2,
                                min: 0,
                                max: 100,
                                controls: false,
                                placeholder: _vm.$t("basicData.Remission"),
                              },
                              model: {
                                value: scope.row.inssTaxBase,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "inssTaxBase", $$v)
                                },
                                expression: "scope.row.inssTaxBase",
                              },
                            }),
                            _vm._v(
                              "%" + _vm._s(_vm.$t("basicData.Remission")) + " "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label:
                                _vm.$t("basicData.Remission") +
                                "Sest Senat 2.5%(PT011)",
                              "label-width": "200px",
                            },
                          },
                          [
                            _c("el-input-number", {
                              attrs: {
                                precision: 2,
                                min: 0,
                                max: 100,
                                controls: false,
                                placeholder: _vm.$t("basicData.Remission"),
                              },
                              model: {
                                value: scope.row.sestSenatExemption,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "sestSenatExemption", $$v)
                                },
                                expression: "scope.row.sestSenatExemption",
                              },
                            }),
                            _vm._v(
                              "%" + _vm._s(_vm.$t("basicData.TaxBase")) + " "
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "", "label-width": "10px" } },
                          [
                            _c("el-input-number", {
                              attrs: {
                                precision: 2,
                                min: 0,
                                max: 100,
                                controls: false,
                                placeholder: _vm.$t("basicData.Remission"),
                              },
                              model: {
                                value: scope.row.sestSenatTaxBase,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "sestSenatTaxBase", $$v)
                                },
                                expression: "scope.row.sestSenatTaxBase",
                              },
                            }),
                            _vm._v(
                              "%" + _vm._s(_vm.$t("basicData.Remission")) + " "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDel(scope.$index)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("basicData.Delete")))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }