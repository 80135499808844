<template>
  <div class="warp">
    <el-card class="box-card searchInputCont">
      <el-form :model="queryForm" label-position="top" size="small">
        <el-row :gutter="20">
          <!--创建时间-->
          <el-col :span="8">
            <el-form-item prop="searchTimeType">
              <template slot="label">
                <div class="selectLabel">
                  <el-select
                    v-model="queryForm.params.timeType"
                    :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  >
                    <!--入仓时间-->
                    <el-option key="1" :label="$t('newOrder.DepositTime')" :value="1" />
                    <!--退仓时间-->
                    <el-option key="2" :label="$t('newOrder.WithdrawalTime')" :value="2" />
                  </el-select>
                </div>
              </template>
              <el-date-picker
                v-model="timeValue"
                :clearable="true"
                :default-time="['00:00:00', '23:59:59']"
                :format="$i18n.locale=='zh'?zhDate:ptDate"
                :placeholder="$t('newOrder.SelectDatetime')"
                range-separator="~"
                style="width: 100%;"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
              />
            </el-form-item>
          </el-col>
          <!--派送供应商-->
          <el-col :span="8">
            <el-form-item prop="preOrNext">
              <template slot="label">
                <div class="selectLabel">
                  <el-select
                    v-model="queryForm.params.preOrNext"
                    :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  >
                    <!--退仓时间-->
                    <el-option key="1" :label="$t('spareNumberPoo.label1')" :value="1" />
                    <!--      预派送供应商        -->
                    <el-option key="2" :label="$t('spareNumberPoo.supplierSite')" :value="2" />
                  </el-select>
                </div>
              </template>
              <el-cascader
                v-model="queryForm.params.siteDataId"
                :options="siteData"
                :props="{ multiple: true, checkStrictly: true }"
                clearable
                filterable
                style="width: 100%;"
              />
            </el-form-item>
          </el-col>
          <!--退仓状态-->
          <el-col :span="8">
            <el-form-item :label="$t('newOrder.WithdrawalStatus')">
              <el-select
                v-model="queryForm.params.state"
                :placeholder="$t('operationCenter.PleaseSelect')"
                clearable
                filterable
                style="width: 100%;"
              >
                <!--全部-->
                <el-option :key="0" :label="$t('GlobalSetObj.all')" :value="null" />
                <!--正在退回-->
                <el-option :key="1" :label="$t('orderCenterCont.Returning')" :value="1" />
                <!--已入库-->
                <el-option :key="2" :label="$t('CenterForSorting.InStorage')" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item class="numberClassSelect">
              <template slot="label">
                <div class="selectLabel">
                  <el-select
                    v-model="selectionType"
                    :placeholder="$t('orderDetails.pleaseChoose')"
                    @change="handleChangeLable"
                  >
                    <!--运单号-->
                    <el-option key="1" :label="$t('newOrder.waybillNumber')" :value="1" />
                    <el-option key="2" :label="$t('GlobalSetObj.bagNumber')" :value="2" />
                  </el-select>
                </div>
              </template>
              <div class="searchOrder">
                <el-input
                  v-model.trim="searchNumber"
                  :placeholder="$t('orderDetails.pleaseInput')"
                  clearable
                  @keyup.enter.native="searchFun(1)"
                />
                <div style="width: 40px;">
                  <el-button class="chaxunBtn" @click="searchFun(1)">
                    <i class="iconfont icon-chaxun" style="font-size: 24px" />
                  </el-button>
                </div>
                <div style="width: 38px;">
                  <el-popover
                    v-model="showNumList"
                    placement="bottom-end"
                    trigger="manual"
                  >
                    <div style="width: 300px;">
                      <el-input
                        v-model="searchNumberStr"
                        :placeholder="$t('newOrder.PreciseSearch')"
                        :rows="10"
                        type="textarea"
                      />
                      <div style="margin-top: 10px;">
                        <!--清空-->
                        <el-button size="small" @click="clearSearchNum()">{{ $t('newOrder.empty') }}</el-button>
                        <div style="float: right">
                          <!--关闭-->
                          <el-button size="small" @click="openNumList">{{ $t('orderExport.close') }}</el-button>
                          <!--搜索-->
                          <el-button
                            plain size="small" type="primary"
                            @click="searchFun(2)"
                          >
                            {{ $t('newOrder.search') }}
                          </el-button>
                        </div>
                      </div>
                    </div>
                    <el-button slot="reference" class="btnIcon" @click="openText">
                      <i class="iconfont icon-liebiao1" style="font-size: 24px" />
                    </el-button>
                  </el-popover>
                </div>
              </div>
            </el-form-item>
          </el-col>
          <!--下一站仓库-->
          <el-col :span="8">
            <el-form-item :label="$t('spareNumberPoo.nextStation')">
              <el-select
                v-model="queryForm.params.nextSiteId"
                :placeholder="$t('operationCenter.PleaseSelect')"
                clearable
                filterable
                style="width: 100%;"
              >
                <el-option
                  v-for="item in TransitCenter" :key="item.id" :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <div style="margin-top: 25px;">
              <el-button
                icon="el-icon-search"
                size="small"
                type="primary"
                @click="searchResources(1)"
              >{{ $t('operationCenter.Procurar') }}
              </el-button>
              <el-button icon="el-icon-refresh-left" size="small" @click="searchResources(2)">
                {{ $t('operationCenter.reset') }}
              </el-button>
            </div>
          </el-col>
          <!--运单号-->
          <!--          <el-col :span="8">-->
          <!--            <el-form-item :label="$t('GlobalSetObj.waybillNumber')">-->
          <!--              <el-input v-model="waybillNumbers" type="textarea" :rows="3" :placeholder="$t('orderCenterCont.MultipleLineBreaks')" style="width: 100%" />-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
          <!--袋号-->
          <!--          <el-col :span="8">-->
          <!--            <el-form-item :label="$t('GlobalSetObj.bagNumber')">-->
          <!--              <el-input v-model="bagNumber" type="textarea" :rows="3" :placeholder="$t('orderCenterCont.MultipleLineBreaks')" style="width: 100%" />-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
        </el-row>
        <el-row v-if="searchNumberList.length > 0">
          <div class="searchNum">
            <el-tooltip class="item" effect="dark" placement="bottom">
              <template slot="content">
                <pre>{{ searchNumberStr }}</pre>
              </template>
              <div class="searchNumWarp">
                <div class="wordBreak">
                  <p>
                    {{ searchNameObj.label }}：
                    {{ searchNumberList.join('，') }}
                  </p>
                </div>
              </div>
            </el-tooltip>
            <div class="clearSearchNum" @click="clearSearchNum()">
              <i class="el-icon-close" />
            </div>
          </div>
        </el-row>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px;">
      <div style="margin-bottom: 10px">
        <el-button size="small" type="primary" @click="changeOutlets">{{
          $t('GlobalSetObj.ModifyPreDeliveryLocation')
        }}
        </el-button>
        <!--更新面单-->
        <!--        <el-button-->
        <!--          v-permit:remove="'btn:logisticsOrders:UpdateTheFaceSheet'"-->
        <!--          size="small"-->
        <!--          type="primary"-->
        <!--          @click="updateFaceSheet()"-->
        <!--        >-->
        <!--          {{ $t('newOrder.UpdateTheFaceSheet') }}-->
        <!--        </el-button>-->
        <el-button
          size="small"
          type="primary"
          @click="downloadTheMenu()"
        >
          {{ $t('newOrder.DownloadFaceSheets') }}
        </el-button>
      </div>
      <el-table
        ref="listTable" :data="tableData" border
        @selection-change="selectionChangeList"
      >
        <el-table-column
          align="center"
          type="selection"
          width="60"
        />
        <el-table-column
          v-for="(item, index) in tableHeaderList"
          :key="index"
          :label="item.label"
          :min-width="item.cloWidth"
          :prop="item.prop"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="item.prop === 'state'">
              <!--正在退回-->
              <span v-if="scope.row[item.prop] === 1">{{ $t('orderCenterCont.Returning') }}</span>
              <!--已入库-->
              <span v-if="scope.row[item.prop] === 2">{{ $t('CenterForSorting.InStorage') }}</span>
            </div>
            <div v-else-if="item.prop === 'waybillStatus'">
              {{ waybillStatus[scope.row[item.prop]].label }}
            </div>
            <div v-else>{{ scope.row[item.prop] }}</div>
          </template>
        </el-table-column>
      </el-table>
      <!--  分页  -->
      <el-pagination
        :current-page="queryForm.pageNumber"
        :page-size="queryForm.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 10px 0;float: right"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
  </div>
</template>

<script>
import { apiProviderPage } from '@/api/finance/basicData';
import { apiTransitWarehousePage, getTransitCenter } from '@/api/logisticsOrders';
import { siteProvider } from '@/api/automaticSorting';
import { cloneDeep } from 'lodash-es';
import dayjs from 'dayjs';

export default {
  name: 'List',
  data() {
    return {
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          siteDataId: [],
          timeType: 2,
          preOrNext: 1,
          startReturnTime: '',
          endReturnTime: '',
          startEntryTime: '',
          endEntryTime: '',
          providerIdList: null,
          state: null,
          nextSiteId: null,
          waybillNumberList: [],
          bagNumberList: [],
          updateUser: null
        }
      },
      waybillNumbers: '',
      bagNumber: '',
      timeValue: [this.getAWeek().startDate + ' 00:00:00', this.getAWeek().endDate + ' 23:59:59'],
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      providerList: [],
      tableData: [],
      total: 0,
      selectionList: [],
      tableHeaderList: [
        // 运单号
        { label: this.$t('newOrder.waybillNumber'), prop: 'waybillNumber', cloWidth: '160' },
        // 袋号
        // { label: this.$t('orderCenterCont.BagNumber'), prop: 'transitBagNumber', cloWidth: '120' },
        // 退仓时间
        { label: this.$t('newOrder.WithdrawalTime'), prop: 'returnTime', cloWidth: '140' },
        // 下一站目的地
        { label: '下一站目的地', prop: 'nextSiteName', cloWidth: '120' },
        // 退仓状态
        { label: this.$t('newOrder.WithdrawalStatus'), prop: 'state', cloWidth: '120' },
        // 运单状态
        { label: this.$t('newOrder.waybillStatus'), prop: 'waybillStatus', cloWidth: '120' },
        // 到件时间
        { label: this.$t('newOrder.DepositTime'), prop: 'entryTime', cloWidth: '140' },
        // 退仓供应商
        { label: this.$t('spareNumberPoo.returnProvider'), prop: 'returnProviderName', cloWidth: '160' },
        // 退仓网点
        { label: this.$t('spareNumberPoo.returnSite'), prop: 'returnStationName', cloWidth: '140' },
        // 预派送供应商
        { label: this.$t('GlobalSetObj.preProvider'), prop: 'preProviderName', cloWidth: '120' },
        // 预派送网点
        { label: this.$t('GlobalSetObj.preEntrega'), prop: 'preDeliverStationName', cloWidth: '120' }
      ],
      selectionType: 1,
      showNumList: false,
      searchNumberStr: '',
      searchNumberList: [],
      searchNumber: '',
      searchNameObj: { id: 1, label: this.$t('newOrder.waybillNumber'), value: 1 },
      // 查询列表数据
      selectLabel: [
        { id: 1, label: this.$t('newOrder.waybillNumber'), value: 1 },
        { id: 2, label: this.$t('GlobalSetObj.bagNumber'), value: 2 }
      ],
      TransitCenter: [],
      siteData: []
    };
  },
  computed: {
    waybillStatus() {
      return {
        1: { label: this.$i18n.t('newOrder.ToBeAssigned'), code: 1 }, // 待分配
        2: { label: this.$i18n.t('newOrder.ToBeCollected'), code: 2 }, // 待揽收
        3: { label: this.$i18n.t('newOrder.Collected'), code: 3 }, // 已揽收
        4: { label: this.$i18n.t('newOrder.CollectTheRevenuePool'), code: 4 }, // 揽收入库
        5: { label: this.$i18n.t('newOrder.CollectTakeOutWarehouse'), code: 5 }, // 揽收出库
        19: { label: this.$i18n.t('GlobalSetObj.moduan'), code: 19 }, // 末端已揽收
        6: { label: this.$i18n.t('newOrder.TransshipmentIntoStorage'), code: 6 }, // 转运入库
        7: { label: this.$i18n.t('newOrder.TransferOutWarehouse'), code: 7 }, // 转运出库
        8: { label: this.$i18n.t('newOrder.DeliveryOutletsIntoStorage'), code: 8 }, // 派送网点入库
        9: { label: this.$i18n.t('newOrder.Delivering'), code: 9 }, // 派送中
        10: { label: this.$i18n.t('newOrder.Signed'), code: 10 }, // 已签收
        11: { label: this.$i18n.t('newOrder.ProblemPiece'), code: 11 }, // 问题件
        12: { label: this.$i18n.t('collectionCenter.unclaimed'), code: 12 }, // 待取件
        13: { label: this.$i18n.t('GlobalSetObj.Canceled'), code: 13 }, // 已取消

        17: { label: this.$i18n.t('GlobalSetObj.DotBack'), code: 17 }, // 网点退回
        18: { label: this.$i18n.t('GlobalSetObj.ReturnToStorage'), code: 18 }, // 退仓入库
        15: { label: this.$i18n.t('GlobalSetObj.Withdrawn'), code: 15 }, // 退件中
        16: { label: this.$i18n.t('GlobalSetObj.TheReturnComplete'), code: 16 }, // 退件完成
        200: { label: '', code: 200 } // 问题件
      };
    }
  },
  created() {
    this.getTransitCenterList();
    this.getSupperSite();
    this.getProviderPage();
    this.getTableData();
  },
  methods: {
    // 打开或者关闭
    openText() {
      this.showNumList = !this.showNumList;
    },
    // 打开\关闭多个单号输入
    openNumList() {
      this.showNumList = !this.showNumList;
      this.searchNumber = '';
    },
    handleChangeLable(val) {
      const index = val - 1;
      this.searchNameObj = this.selectLabel[index];
    },
    // 搜索
    searchFun(type) {
      this.queryForm.pageNumber = 1;
      if (type === 1) {
        this.clearSearchNum();
      } else if (type === 2) {
        this.searchNumberCollation();
        this.showNumList = !this.showNumList;
        this.searchNumber = '';
      }
      this.getTableData();
    },
    // 清除列表搜索
    clearSearchNum() {
      this.searchNumberList = [];
      this.searchNumberStr = '';
    },
    // 查询单号整理
    searchNumberCollation() {
      if (this.searchNumberStr) {
        this.searchNumberList = [];
        const orderList = this.searchNumberStr.split('\n');
        orderList.map(item => {
          const num = item.replace(/^\s/g, '').trim();
          if (num) {
            this.searchNumberList.push(num);
          }
        });
      }
    },
    // 下载面单
    downloadTheMenu() {
      const newArray = cloneDeep(this.selectionList);
      const downloadArr = newArray.map((item) => {
        if (item.labelUrl === '' || item.labelUrl === null) {
          item.labelUrl = item.serviceLableUrl;
        }
        return item.labelUrl;
      });
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$i18n.t('orderCenterCont.PleaseSelectTheWaybill')); // 请选择运单
        return;
      }
      if (this.selectionList.length > 200) {
        this.$message.warning(this.$i18n.t('orderCenterCont.TheNumberOfSelectedPolygonsExceedsTheMaximumLimit200')); // 选中面单数超出最大限制：200
        return;
      }
      this.$emit('downloadTheMenuFee', downloadArr, 'list');
    },
    // 更新面单
    updateFaceSheet() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('orderCenterCont.PleaseSelectTheWaybill'));
        return;
      }
      const orderList = this.selectionList.map(item => {
        return item.waybillNumber;
      });
      this.$emit('updateSheets', orderList, 'list');
    },
    // 选择数据
    selectionChangeList(data) {
      this.selectionList = data;
    },
    // 打开修改预派送网点弹窗
    changeOutlets() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('orderCenterCont.PleaseSelectTheWaybill'));
        return;
      }
      this.$emit('changeOutletsData', this.selectionList, 'list');
    },
    // 更新面单后查询
    getListData(data) {
      this.queryForm.pageNumber = 1;
      this.waybillNumbers = data;
      this.searchNumberStr = data;
      this.selectionType = 1;
      console.log(this.searchNumberStr, '====this.searchNumberStr');
      this.searchNumberCollation();
      this.$nextTick(() => {
        this.getTableData();
      });
    },
    // 搜索
    searchResources(type) {
      if (type === 1) {
        this.queryForm.pageNumber = 1;
        this.getTableData();
      } else {
        this.queryForm = {
          pageSize: 10,
          pageNumber: 1,
          params: {
            siteDataId: [],
            timeType: 2,
            preOrNext: 1,
            startReturnTime: '',
            endReturnTime: '',
            startEntryTime: '',
            endEntryTime: '',
            providerIdList: null,
            state: null,
            nextSiteId: null,
            waybillNumberList: [],
            bagNumberList: [],
            updateUser: null
          }
        };
        this.waybillNumbers = '';
        this.bagNumber = '';
        this.searchNumber = '';
        this.showNumList = false;
        this.searchNumberStr = '';
        this.searchNumberList = [];
        this.selectionType = 1;
        this.timeValue = [this.getAWeek().startDate + ' 00:00:00', this.getAWeek().endDate + ' 23:59:59'];
      }
    },
    // 获取最近一星期时间
    getAWeek() {
      // 默认最近一周时间
      return {
        endDate: dayjs().format('YYYY-MM-DD'),
        startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD')
      };
    },
    // 获取供应商选项
    getProviderPage() {
      const param = {
        pageSize: 9999999,
        pageNumber: 1,
        params: {
          state: 1
        }
      };
      apiProviderPage(param, false).then(res => {
        if (res.status === 'OK') {
          this.providerList = res.data.records;
        }
      });
    },
    // 获取列表数据
    getTableData() {
      const param = {
        pageNumber: this.queryForm.pageNumber,
        pageSize: this.queryForm.pageSize,
        params: {
          startEntryTime: '',
          endEntryTime: '',
          startReturnTime: '',
          endReturnTime: '',
          waybillNumberList: [],
          bagNumberList: [],
          providerIdList: [],
          siteIdList: [],
          updateUser: this.queryForm.params.updateUser,
          state: this.queryForm.params.state,
          nextSiteId: this.queryForm.params.nextSiteId,
          preOrNext: this.queryForm.params.preOrNext
        }
      };
      if (this.queryForm.params.siteDataId.length > 0) {
        this.queryForm.params.siteDataId.forEach(item => {
          if (item.length === 1) {
            param.params.providerIdList.push(item[0]);
          }
          if (item.length === 2) {
            param.params.siteIdList.push(item[1]);
          }
        });
      }
      if (this.timeValue && this.timeValue.length > 0) {
        if (this.queryForm.params.timeType === 1) {
          param.params.startEntryTime = this.timeValue[0];
          param.params.endEntryTime = this.timeValue[1];
          param.params.startReturnTime = '';
          param.params.endReturnTime = '';
        } else {
          param.params.startReturnTime = this.timeValue[0];
          param.params.endReturnTime = this.timeValue[1];
          param.params.startEntryTime = '';
          param.params.endEntryTime = '';
        }
      }
      let list = [];
      if (this.searchNumber) {
        this.searchNumberList = [];
        this.searchNumberStr = '';
        list = [this.searchNumber];
      } else {
        list = this.searchNumberList;
      }
      if (this.selectionType === 1) {
        param.params.waybillNumberList = list;
      } else {
        param.params.bagNumberList = list;
      }

      // if (this.waybillNumbers) {
      //   const num = this.waybillNumbers.split(/([\n\r])/);
      //   num.map(item => {
      //     const reg = /\s/g;
      //     const a = item.replace(reg, '');
      //     const b = a.trim();
      //     if (b) {
      //       param.params.waybillNumberList.push(b);
      //     }
      //   });
      // }
      // if (this.bagNumber) {
      //   const num = this.bagNumber.split(/([\n\r])/);
      //   num.map(item => {
      //     const reg = /\s/g;
      //     const a = item.replace(reg, '');
      //     const b = a.trim();
      //     if (b) {
      //       param.params.bagNumberList.push(b);
      //     }
      //   });
      // }
      console.log(param, '====param');
      apiTransitWarehousePage(param).then(res => {
        if (res.status === 'OK') {
          this.tableData = res.data.records;
          this.total = res.data.total;
          this.selectionList = [];
          this.$refs.listTable.clearSelection();
        }
      });
    },
    // 获取下一站仓库
    getTransitCenterList() {
      this.TransitCenter = [];
      getTransitCenter().then(res => {
        if (res.status === 'OK') {
          console.log(res, '获取下一站仓库');
          res.data.forEach(item => {
            if (item.siteType === 3) {
              this.TransitCenter.push(item);
            }
          });
        }
      });
    },
    // 获取对应的供应商下的网点
    getSupperSite() {
      siteProvider().then(res => {
        this.siteData = [];
        if (res.status === 'OK') {
          res.data.forEach((item) => {
            item.label = item.providerName;
            item.value = item.id;
            item.children = [];
            if (item.siteList) {
              item.siteList.forEach(item => {
                item.label = item.name;
                item.value = item.id;
              });
              item.children = item.siteList;
            }
          });
          this.siteData = [...res.data];
          console.log(this.siteData, 'this.siteDatathis.siteData');
        }
      });
    },
    // 翻页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getTableData();
    }
  }
};
</script>
<style>
.numberClassSelect .el-form-item__label .el-select .el-input__inner {
  border: none;
  padding: 0;
}

.numberClassSelect .el-form-item {
  margin-bottom: 10px;
}
</style>
<style scoped>
.searchOrder {
  display: flex;
}

.searchOrder .el-popover__reference-wrapper .btnIcon {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 32px;
}

.warp >>> .searchOrder .el-input__inner {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.warp >>> .searchOrder .el-input-group__prepend {
  background-color: transparent !important;
}

.warp >>> .searchOrder .el-button--small {
  padding: 2px 6px;
}

.chaxunBtn {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  width: 40px;
  height: 32px;
  padding: 0;
}

.searchNum {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 15px;
  height: 28px;
}

.searchNumWarp {
  height: 28px;
  font-size: 14px;
  border: 1px solid #91B8FA;
  border-right: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 3px 5px 5px 5px;
  background: rgba(145, 184, 250, 0.2);
}

.searchNumWarp p {
  margin: 0;
}

.clearSearchNum {
  height: 28px;
  border: 1px solid #91B8FA;
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: rgba(145, 184, 250, 0.2);
  cursor: pointer;
  padding-right: 5px;
  padding-top: 3px;
}
</style>
