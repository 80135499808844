<template>
  <div>
    <div
      v-permit:remove="'btn:payable:quoteManagement:addEdit'"
      style="margin-bottom: 10px"
    >
      <!--下载模板-->
      <el-button type="primary" size="small" @click="downloadTemplate(1)">{{ $t('basicData.DownloadTheTemplate') }}</el-button>
    </div>
    <div
      v-permit:remove="'btn:payable:quoteManagement:addEdit'"
      style="margin-bottom: 10px"
    >
      <!--批量导入-->
      <upload-excel
        :on-success="importLogistics"
        :but-name="BulkImport"
      />
    </div>
    <div>
      <!--下载报价-->
      <el-button type="primary" size="small" @click="downloadTemplate(2)">{{ $t('basicData.DownloadTheQuote') }}</el-button>
    </div>
    <el-dialog
      :append-to-body="true"
      :title="$t('basicData.tips')"
      :visible.sync="dialogVisible"
      width="400"
    >
      <div>
        <div v-for="(item, ind) in errData" :key="ind" style="color:#f00;margin-bottom: 8px;">{{ item }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">{{ $t('basicData.determine') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import { BUSINESS_TYPE_TWO } from '@/views/finance/utils.js';
const ExcelJs = require('exceljs');

export default {
  name: 'ImportDownload',
  components: {
    'upload-excel': () => import('@/components/UploadExcel/index')
  },
  props: {
    tableData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    tableDataObj: {
      type: Object,
      default: () => {
        return {};
      }
    },
    expenseTypeList: {
      type: Array,
      default: () => {
        return [];
      }
    },
  },
  data() {
    return {
      BulkImport: this.$t('basicData.BulkImport'), // 批量导入
      MultiPartitionImport: this.$t('basicData.MultiPartitionImport'), // 批量导入
      errData: [],
      dialogVisible: false
    };
  },
  methods: {
    // 导出数据处理
    exportData() {
      const collectFeesList = [];
      const collectFeesArr = [];
      if (this.tableData.subregionList.length > 0) {
        for (const k in this.tableDataObj) {
          if (this.tableDataObj[k]) {
            for (const k1 in this.tableDataObj[k]) {
              if (this.tableDataObj[k][k1]) {
                this.tableDataObj[k][k1].map((item) => {
                  collectFeesList.push(item);
                });
              }
            }
          }
        }
      } else {
        for (const k in this.tableDataObj) {
          if (this.tableDataObj[k].length > 0) {
            this.tableDataObj[k].map((item) => {
              collectFeesList.push(item);
            });
          }
        }
      }
      collectFeesList.map(item => {
        let zipcode = '';
        this.tableData.subregionList.map(list => {
          if (item.partitionAffiliateId === list.id) {
            zipcode = list.postalCode;
          }
        });
        const obj = {
          ...this.tableData.formData,
          ...item,
          name: this.tableData.formData.name,
          offerType: this.tableData.formData.offerType === 1
            ? this.$t('basicData.StandardPrice') : this.tableData.formData.offerType === 2
              ? this.$t('basicData.BusinessEntityPrice') : this.$t('basicData.SitePrice'),
          providerInfoName: this.tableData.formData.providerInfoName,
          siteName: this.tableData.formData.siteName,
          partitionName: this.tableData.formData.partitionName,
          zipcode: zipcode
        };
        collectFeesArr.push(obj);
      });
      return {
        collectFeesArr: collectFeesArr
      };
    },
    // 下载模板\下载报价
    downloadTemplate(type) {
      // type: 1=下载模板；2=下载报价详情
      const that = this;
      
      async function ddd() {
        const wb = new ExcelJs.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        const Sheet2 = wb.addWorksheet('Sheet2');
        const Sheet3 = wb.addWorksheet('Sheet3');
        const Sheet1_data = [];
        // 安俊物流
        if (that.tableData.formData.businessType === 1) {
          Sheet1.columns = [
            {
              header: (that.tableData.subregionList.length > 0 ? '*' : '') + that.$t('basicData.PartitionName'), // 分区
              key: 'partitionAffiliateId',
              width: 30
            },
            {
              header: '*' + that.$t('basicData.ExpenseType'), // 费用类型
              key: 'feeTypeCode',
              width: 30
            },
            {
              header: '*' + that.$t('basicData.QuantityStartTicketNotIncluded'), // 货量开始（票）(不包含)
              key: 'startOfGoodsQuantity',
              width: 30
            },
            {
              header: '*' + that.$t('basicData.EndOfVolumeTicketIncluded'), // 货量结束（票）(包含)
              key: 'endOfGoodsQuantity',
              width: 30
            },
            {
              header: '*' + that.$t('basicData.PickUpPrice'), // 揽收价格
              key: 'price',
              width: 30
            }
          ]
        }
        // DSP派送
        if (that.tableData.formData.businessType === 4) {
          Sheet1.columns = [
            {
              header: (that.tableData.subregionList.length > 0 ? '*' : '') + that.$t('basicData.PartitionName'), // 分区
              key: 'partitionAffiliateId',
              width: 30
            },
            {
              header: '*' + that.$t('basicData.ExpenseType'), // 费用类型
              key: 'feeTypeCode',
              width: 30
            },
            {
              header: '*' + that.$t('basicData.WeightStartKG'), // 重量开始KG(不包含)
              key: 'startWeight',
              width: 30
            },
            {
              header: '*' + that.$t('basicData.WeightEndKG'), // 重量结束KG(包含)
              key: 'endWeight',
              width: 30
            },
            {
              header: '*0-31KM' + that.$t('basicData.Price'), // 0-31KM价格
              key: 'innerDistancePrice',
              width: 30
            },
            {
              header: '31-101KM' + that.$t('basicData.Price'), // 31-101KM价格
              key: 'outerDistancePrice',
              width: 30
            },
            {
              header: '>=101KM' + that.$t('basicData.Price'), // >=101KM价格
              key: 'greaterThanDistancePrice',
              width: 30
            },
            {
              header: '0-31KM' + that.$t('basicData.ExcessWeightFareIncrease'), // 0-31KM超出重量加价(每KG)
              key: 'overWeightExtraPrice',
              width: 30
            },
            {
              header: '31-101KM' + that.$t('basicData.ExcessWeightFareIncrease'), // 31-101KM超出重量加价（每KG）
              key: 'overKilometerExtraPrice',
              width: 30
            },
            {
              header: '>=101KM' + that.$t('basicData.ExcessWeightFareIncrease'), // >=101KM超出重量加价（每KG）
              key: 'greaterThanKilometerExtraPrice',
              width: 30
            }
          ]
        }
        // 运输（干/支线）
        if (that.tableData.formData.businessType === 5) {
          Sheet1.columns = [
            {
              header: (that.tableData.subregionList.length > 0 ? '*' : '') + that.$t('basicData.PartitionName'), // 分区
              key: 'partitionAffiliateId',
              width: 30
            },
            {
              header: '*' + that.$t('basicData.ExpenseType'), // 费用类型
              key: 'feeTypeCode',
              width: 30
            },
            {
              header: '*' + that.$t('vehicle.modelLabel'), // 车型
              key: 'vehicleType',
              width: 30
            },
            {
              header: '*' + that.$t('basicData.WeightStartKG'), // 公里数开始（公里）
              key: 'startWeight',
              width: 30
            },
            {
              header: '*' + that.$t('basicData.WeightEndKG'), // 公里数结束（公里）
              key: 'endWeight',
              width: 30
            },
            {
              header: '*' + that.$t('basicData.Price'), // 价格
              key: 'price',
              width: 30
            },
            {
              header: that.$t('123123'), // 保底价（公里）
              key: '',
              width: 30
            }
          ]
        }
        if (type === 2) {
          const collectFeesArr = that.exportData().collectFeesArr;
          collectFeesArr.map((item) => {
            that.expenseTypeList.map(list => {
              if (item.feeTypeCode === list.code) {
                item.feeTypeCode = list.displayName;
              }
              if (item.royaltyChargeType === list.code) {
                item.royaltyChargeType = list.displayName;
              }
            });
            if (item.billingType === 1) {
              item.billingType = that.$t('newOperationCenter.Weight');
            } else {
              item.billingType = that.$t('collectionCenter.ballot');
            }
            if (item.businessType === 1) {
              item.businessName = that.$t('collectionCenter.turnTransport');
            } else if (item.businessType === 2) {
              item.businessName = that.$t('collectionCenter.lanshou');
            } else if (item.businessType === 3) {
              item.businessName = that.$t('collectionCenter.dispatch');
            }
            if (that.tableData.formData.collectType === 1) {
              item.collectName = that.$t('collectionCenter.dropOff');
            } else if (item.collectType === 2) {
              item.collectName = that.$t('collectionCenter.doorPickUp');
            }
            Sheet1_data.push({
              ...item
            });
          });
          Sheet1.addRows(Sheet1_data);
        }
        Sheet1.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
        Sheet1.getRow(1).height = 20;
        // 分区
        Sheet2.columns = [
          {
            header: that.$t('basicData.PartitionName'), // 分区
            key: 'partitionIdentifier',
            width: 20
          },
          {
            header: that.$t('basicData.PartitionName') + '(ID)', // 分区
            key: 'partitionAffiliateId',
            width: 20
          }
        ];
        const Sheet2_data = [];
        that.tableData.subregionList.map((item) => {
          Sheet2_data.push({
            partitionAffiliateId: item.id,
            partitionIdentifier: item.partitionIdentifier + '->' + item.id
          });
        });
        Sheet2.addRows(Sheet2_data);
        // 费用类型
        Sheet3.columns = [
          {
            header: that.$t('basicData.ExpenseType') + '(' + that.$t('ChannelManage.name') + ')', // 费用类型
            key: 'feeTypeName',
            width: 20
          },
          {
            header: that.$t('basicData.ExpenseType') + '(code)', // 费用类型
            key: 'feeTypeCode',
            width: 20
          }
        ];
        const Sheet3_data = [];
        that.expenseTypeList.map((item) => {
          Sheet3_data.push({
            feeTypeCode: item.code,
            feeTypeName: item.displayName + '->' + item.code
          });
        });
        Sheet3.addRows(Sheet3_data);

        new Array(2000).fill(0).forEach((_, idx) => {
          const row = idx + 2;
          // 分区
          Sheet1.getCell(row, 1).dataValidation = {
            type: 'list',
            formulae: [`=Sheet2!$A$2:$A${Sheet2_data.length + 1}`]
          };

          // 费用类型
          Sheet1.getCell(row, 2).dataValidation = {
            type: 'list',
            formulae: [`=Sheet3!$A$2:$A${Sheet3_data.length + 1}`]
          };
        });
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        const date = Dates();
        let saveName;
        if (type === 1) {
          saveName = `${BUSINESS_TYPE_TWO[that.tableData.formData.businessType]?.label}${that.$t('basicData.ImportTemplate')}` + '.xlsx'; // 批量导入报价模板
        } else {
          saveName = BUSINESS_TYPE_TWO[that.tableData.formData.businessType]?.label + date + '.xlsx'; // 揽收费
        }

        saveAs(blob, saveName);
        return;
      }
      ddd();
    },
    /**
     * 模板导入
     * @param arr 
     */
    importLogistics(arr) {
      const header = arr[0].header;
      let required = [];
      let headObj = [];
      // 安俊小屋
      if (this.tableData.formData.businessType === 1) {
        required = [
          '*' + this.$t('basicData.ExpenseType'), // 费用类型
          '*' + this.$t('basicData.QuantityStartTicketNotIncluded'), // 货量开始（票）(不包含)
          '*' + this.$t('basicData.EndOfVolumeTicketIncluded'), // 货量结束（票）(包含)
          '*' + this.$t('basicData.PickUpPrice') // 揽收价格
        ];
        headObj = [
          'partitionAffiliateId', // 分区名称
          'feeTypeCode', // 费用类型
          'startOfGoodsQuantity', // 货量开始（票）(不包含)
          'endOfGoodsQuantity', // 货量结束（票）(包含)
          'price' // 揽收价格
        ];
      };
      // DSP派送
      if (this.tableData.formData.businessType === 4) {
        required = [
          '*' + this.$t('basicData.ExpenseType'), // 费用类型
          '*' + this.$t('basicData.WeightStartKG'), // 重量开始KG(不包含)
          '*' + this.$t('basicData.WeightEndKG'), // 重量结束KG(包含)
          '*0-31KM' + this.$t('basicData.Price') // 0-30KM价格
        ];
        headObj = [
          'partitionAffiliateId', // 分区名称
          'feeTypeCode', // 费用类型
          'startWeight', // 重量开始KG(不包含)
          'endWeight', // 重量结束KG(包含)
          'innerDistancePrice', // 0-30KM价格
          'outerDistancePrice', // 31-101KM价格
          'greaterThanDistancePrice', // >=100KM价格
          'overWeightExtraPrice', // 0-31KM超出重量加价(每KG)
          'overKilometerExtraPrice', // 31-101KM超出重量加价（每KG）
          'greaterThanKilometerExtraPrice' // >=101KM超出重量加价（每KG）
        ];
      };
      // 运输（干/支线）
      if (this.tableData.formData.businessType === 5) {
        required = [
          '*' + this.$t('basicData.ExpenseType'), // 费用类型
          '*' + this.$t('vehicle.modelLabel'), // 车型
          '*' + this.$t('basicData.StartingFromKMExcluding'), // 公里数开始KM(不包含)
          '*' + this.$t('basicData.EndOfKilometersKMInclusive'), // 公里数结束KM(包含)
          '*' + this.$t('basicData.Price') // 价格
        ];
        headObj = [
          'partitionAffiliateId', // 分区名称
          'feeTypeCode', // 费用类型
          'startWeight', // 公里数开始KM(不包含)
          'endWeight', // 公里数结束KM(包含)
          'price', // 价格
          'prices', // 最低价格
        ];
      };
      this.errData = [];
      const allData = [];
      arr[0].results.map((item, ind) => {
        const keyArr = [];
        const data = {};
        header.map(list => {
          data[list] = (item[list] !== '' && item[list] !== null && item[list] !== undefined) ? item[list] : '';
        });
        allData.push(data);
        Object.keys(item).forEach((key) => {
          keyArr.push(key);
        });
        required.map(list => {
          if (!keyArr.includes(list)) {
            const text = this.$t('basicData.row') + (ind + 1) + this.$t('basicData.line') + ',' + list + this.$t('basicData.CannotBeEmpty');
            this.errData.push(text);
          }
        });
      });
      const arrData = [];
      allData.map((item) => {
        const data = {};
        Object.keys(item).forEach((key, i) => {
          // 合并处理逻辑，提取共同操作
          if (['partitionAffiliateId', 'royaltyChargeType'].includes(headObj[i])) {
            data[headObj[i]] = item[key] ? item[key].toString().split('->')[1] : '';
          } else {
            data[headObj[i]] = item[key];
          }
        });
        arrData.push(data);
      });
      // arrData.map((item, ind) => {
      //   if (this.tableData.formData.businessType === 2) {
      //     if (parseInt(item.startOfGoodsQuantity) > parseInt(item.endOfGoodsQuantity)) {
      //       // 包裹开始数量不能大于包裹结束数量
      //       this.errData.push(this.$t('basicData.row') +
      //           (ind + 1) + this.$t('basicData.line') + ',' +
      //           this.$t('basicData.TheStartValueCannotBeGreaterThanTheEndValue')); // 开始值不能大于结束值
      //     }
      //   } else if (this.tableData.formData.businessType === 3) {
      //     if (parseInt(item.startWeight) > parseInt(item.endWeight)) {
      //       // 包裹开始数量不能大于包裹结束数量
      //       this.errData.push(this.$t('basicData.row') +
      //           (ind + 1) + this.$t('basicData.line') + ',' +
      //           this.$t('basicData.TheStartValueCannotBeGreaterThanTheEndValue')); // 开始值不能大于结束值
      //     }
      //   }
      //   if (!this.tableData.subregionList || this.tableData.subregionList.length === 0) {
      //     item.partitionAffiliateId = '';
      //   }
      // });
      if (this.errData.length > 0) {
        this.dialogVisible = true;
      } else {
        this.$emit('importPartition', arrData);
      }
    }
  }
};

function Dates() {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const date = now.getDate();
  return year + '-' + month + '-' + date;
}
</script>

<style scoped>

</style>
