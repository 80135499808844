var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "box-card detailStyle" },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c("span", [
            _vm._v(_vm._s(_vm.$t("collectionCenter.basicInformation"))),
          ]),
        ]
      ),
      _c(
        "el-form",
        {
          attrs: {
            "label-width": "auto",
            "label-position": _vm.$i18n.locale != "zh" ? "top" : "right",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("receivablePayable.TicketNumber") + "：",
                      },
                    },
                    [_c("p", [_vm._v(_vm._s(_vm.addBatchFee.waybillNumber))])]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("receivablePayable.customer") + "：",
                      },
                    },
                    [_c("p", [_vm._v(_vm._s(_vm.addBatchFee.customerAlias))])]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("basicData.PayablePrincipal") + "：",
                      },
                    },
                    [_c("p", [_vm._v(_vm._s(_vm.addBatchFee.providerName))])]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("userManage.site") + "：" } },
                    [_c("p", [_vm._v(_vm._s(_vm.addBatchFee.siteName))])]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.$t("receivablePayable.BillableWeight") + "：",
                      },
                    },
                    [_c("p", [_vm._v(_vm._s(_vm.addBatchFee.chargeWeight))])]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("newOrder.CollectionType") + "：",
                      },
                    },
                    [
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.addBatchFee.collectType === 1
                                ? _vm.$t("collectionCenter.dropOff")
                                : _vm.$t("collectionCenter.doorPickUp")
                            )
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.$t("receivablePayable.TotalFeesPayable") + "：",
                      },
                    },
                    [_c("p", [_vm._v(_vm._s(_vm.addBatchFee.totalFee))])]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("receivablePayable.remark") + "：",
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            height: "100px",
                            "overflow-y": "scroll",
                            width: "100%",
                          },
                        },
                        [_vm._v(_vm._s(_vm.addBatchFee.remark))]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }