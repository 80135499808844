var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "SupplierContainer" },
    [
      _c(
        "el-form",
        {
          ref: "queryForm",
          attrs: {
            inline: false,
            model: _vm.queryForm,
            "label-position": "top",
            size: "default",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.RuleName") } },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t(
                              "GlobalSetObj.pleaseInputContent"
                            ),
                            clearable: "",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.getSearch($event)
                            },
                          },
                          model: {
                            value: _vm.queryForm.ruleName,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "ruleName", $$v)
                            },
                            expression: "queryForm.ruleName",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: { click: _vm.getSearch },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.deviceName"),
                        prop: "deviceId",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            clearable: "",
                            filterable: "",
                          },
                          on: { change: _vm.getGridNumListSearch },
                          model: {
                            value: _vm.queryForm.deviceId,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "deviceId", $$v)
                            },
                            expression: "queryForm.deviceId",
                          },
                        },
                        _vm._l(_vm.deviceList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.deviceName, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.gridNum"),
                        prop: "gridNum",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.queryForm.gridNum,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "gridNum", $$v)
                            },
                            expression: "queryForm.gridNum",
                          },
                        },
                        _vm._l(_vm.gridNumsListSearch, function (grid) {
                          return _c("el-option", {
                            key: grid.id,
                            attrs: {
                              label: grid.gridNum,
                              value: grid.gridNum.toString(),
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 2 } }, [
                _c(
                  "div",
                  { staticStyle: { "margin-top": "27px" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "medium", type: "primary" },
                        on: { click: _vm.getSearch },
                      },
                      [_vm._v(_vm._s(_vm.$t("GlobalSetObj.search")))]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value: "btn:SortingCenter:ruleCode",
                          expression: "'btn:SortingCenter:ruleCode'",
                          arg: "remove",
                        },
                      ],
                      attrs: { label: "分拣套餐" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticStyle: { width: "100%" },
                          on: { change: _vm.moreSortRules },
                          model: {
                            value: _vm.queryForm.ruleCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "ruleCode", $$v)
                            },
                            expression: "queryForm.ruleCode",
                          },
                        },
                        [
                          _c(
                            "el-radio-button",
                            { attrs: { label: "Perus01" } },
                            [_vm._v("规则一")]
                          ),
                          _c(
                            "el-radio-button",
                            { attrs: { label: "Perus02" } },
                            [_vm._v("规则二")]
                          ),
                          _c(
                            "el-radio-button",
                            { attrs: { label: "Perus03" } },
                            [_vm._v("规则三")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "15px" },
          attrs: { icon: "el-icon-plus", size: "small", type: "primary" },
          on: { click: _vm.newAdd },
        },
        [_vm._v(" " + _vm._s(_vm.$t("GlobalSetObj.add")) + " ")]
      ),
      _c("AnjunTableList", {
        ref: "anjunTable",
        attrs: {
          "format-time": _vm.isTormat,
          "has-operation": true,
          "header-list": _vm.tableHeaderList,
          "is-index": false,
          "is-tip": true,
          "op-width": 210,
          "table-data": _vm.tableData,
        },
        on: { "on-edit": _vm.edit, "on-look": _vm.toLook, "on-del": _vm.onDel },
      }),
      _c("el-pagination", {
        staticStyle: { margin: "10px 0", float: "right" },
        attrs: {
          "current-page": _vm.queryForm.number,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [10, 20, 50, 100],
          total: _vm.queryForm.total,
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c(
        "el-drawer",
        {
          ref: "drawer",
          attrs: {
            "append-to-body": true,
            title: _vm.titleName,
            visible: _vm.drawer,
            "custom-class": "demo-drawer",
            direction: "ltr",
            size: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "demo-drawer__content",
              staticStyle: { padding: "0 20px" },
            },
            [
              _c(
                "el-form",
                {
                  key: _vm.drawer,
                  ref: "addForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "auto",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.RuleName"),
                        prop: "ruleName",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: _vm.isSee,
                          placeholder: _vm.$t(
                            "GlobalSetObj.pleaseInputContent"
                          ),
                          clearable: "",
                          maxlength: "50",
                          "show-word-limit": "",
                          type: "text",
                        },
                        model: {
                          value: _vm.ruleForm.ruleName,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "ruleName", $$v)
                          },
                          expression: "ruleForm.ruleName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.RuleType") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "60%" },
                          attrs: {
                            disabled: _vm.isSee || _vm.isBandType,
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                          },
                          on: { change: _vm.handleRuleType },
                          model: {
                            value: _vm.ruleForm.specialRuleFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "specialRuleFlag", $$v)
                            },
                            expression: "ruleForm.specialRuleFlag",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.ordinary"),
                              value: false,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.Especial"),
                              value: true,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.ruleForm.specialRuleFlag
                    ? _c(
                        "div",
                        { staticClass: "teshu" },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "10001",
                              attrs: {
                                label: _vm.$t("GlobalSetObj.SortingCondition"),
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "60%" },
                                  attrs: {
                                    disabled: _vm.isSee,
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseChoose"
                                    ),
                                  },
                                  on: { change: _vm.handlesubRuleType },
                                  model: {
                                    value: _vm.ruleForm.subRuleType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "subRuleType", $$v)
                                    },
                                    expression: "ruleForm.subRuleType",
                                  },
                                },
                                _vm._l(
                                  _vm.SortingconditionList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.label,
                                        value: item.code,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("GlobalSetObj.deviceName"),
                                prop: "deviceId",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "60%" },
                                  attrs: {
                                    disabled: _vm.isSee,
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseChoose"
                                    ),
                                    "collapse-tags": "",
                                    filterable: "",
                                  },
                                  on: { change: _vm.getGridNumList },
                                  model: {
                                    value: _vm.ruleForm.deviceId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "deviceId", $$v)
                                    },
                                    expression: "ruleForm.deviceId",
                                  },
                                },
                                _vm._l(_vm.deviceList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.deviceName,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              key: _vm.ruleForm.specialRuleFlag,
                              attrs: {
                                label: _vm.$t("GlobalSetObj.gridNum"),
                                prop: "gridNum",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "60%" },
                                  attrs: {
                                    disabled: _vm.isSee,
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseChoose"
                                    ),
                                    filterable: "",
                                  },
                                  model: {
                                    value: _vm.ruleForm.gridNum,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "gridNum", $$v)
                                    },
                                    expression: "ruleForm.gridNum",
                                  },
                                },
                                _vm._l(_vm.gridNumsList, function (grid) {
                                  return _c("el-option", {
                                    key: grid.id,
                                    attrs: {
                                      label: grid.gridNum,
                                      value: grid.gridNum.toString(),
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.ruleForm.subRuleType === "1"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("GlobalSetObj.client"),
                                        prop: "customerId",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "60%" },
                                          attrs: {
                                            disabled: _vm.isSee,
                                            placeholder: _vm.$t(
                                              "GlobalSetObj.pleaseChoose"
                                            ),
                                            clearable: "",
                                            filterable: "",
                                          },
                                          on: { change: _vm.changeCustomer },
                                          model: {
                                            value: _vm.ruleForm.customerId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "customerId",
                                                $$v
                                              )
                                            },
                                            expression: "ruleForm.customerId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.usernameList,
                                          function (user) {
                                            return _c("el-option", {
                                              key: user.id,
                                              attrs: {
                                                label: user.username,
                                                value: user.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("GlobalSetObj.system"),
                                        prop: "systemState",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "60%" },
                                          attrs: {
                                            disabled: _vm.isSee,
                                            placeholder: _vm.$t(
                                              "GlobalSetObj.pleaseChoose"
                                            ),
                                          },
                                          model: {
                                            value: _vm.ruleForm.systemState,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "systemState",
                                                $$v
                                              )
                                            },
                                            expression: "ruleForm.systemState",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            key: "1",
                                            attrs: {
                                              label: _vm.$t(
                                                "GlobalSetObj.Acquiesce"
                                              ),
                                              value: 1,
                                            },
                                          }),
                                          _c("el-option", {
                                            key: "2",
                                            attrs: {
                                              label: _vm.$t(
                                                "GlobalSetObj.Collect"
                                              ),
                                              value: 2,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.ruleForm.systemState === 2
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "orderCenterCont.OrderStatus"
                                            ),
                                            prop: "waybillStatus",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "60%" },
                                              attrs: {
                                                disabled: _vm.isSee,
                                                placeholder: _vm.$t(
                                                  "GlobalSetObj.pleaseChoose"
                                                ),
                                                clearable: "",
                                                filterable: "",
                                                multiple: "",
                                              },
                                              on: { change: _vm.selectStatus },
                                              model: {
                                                value:
                                                  _vm.ruleForm.waybillStatus,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.ruleForm,
                                                    "waybillStatus",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "ruleForm.waybillStatus",
                                              },
                                            },
                                            _vm._l(
                                              _vm.waybillStatusList,
                                              function (item, ind) {
                                                return _c("el-option", {
                                                  key: ind,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.code,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.ruleForm.systemState === 2
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "customerManagements.channel01"
                                            ),
                                            prop: "channel",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "60%" },
                                              attrs: {
                                                disabled: _vm.isSee,
                                                placeholder: _vm.$t(
                                                  "GlobalSetObj.pleaseChoose"
                                                ),
                                              },
                                              model: {
                                                value: _vm.ruleForm.channel,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.ruleForm,
                                                    "channel",
                                                    $$v
                                                  )
                                                },
                                                expression: "ruleForm.channel",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                key: "1",
                                                attrs: {
                                                  label:
                                                    _vm.$t("GlobalSetObj.all"),
                                                  value: 0,
                                                },
                                              }),
                                              _c("el-option", {
                                                key: "2",
                                                attrs: {
                                                  label: _vm.$t(
                                                    "GlobalSetObj.postal"
                                                  ),
                                                  value: 1,
                                                },
                                              }),
                                              _c("el-option", {
                                                key: "3",
                                                attrs: {
                                                  label: _vm.$t(
                                                    "GlobalSetObj.ortherChannl"
                                                  ),
                                                  value: 2,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.ruleForm.subRuleType === "8"
                            ? _c(
                                "el-form-item",
                                {
                                  key: "10051",
                                  attrs: {
                                    label: _vm.$t("GlobalSetObj.Outlets"),
                                    prop: "siteId",
                                  },
                                },
                                [
                                  _c("el-cascader", {
                                    staticStyle: { width: "60%" },
                                    attrs: {
                                      options: _vm.siteData,
                                      clearable: "",
                                      filterable: "",
                                    },
                                    on: { change: _vm.hanleSiteId },
                                    model: {
                                      value: _vm.ruleForm.siteId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "siteId", $$v)
                                      },
                                      expression: "ruleForm.siteId",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "putong" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("GlobalSetObj.fuwushang"),
                                prop: "providerIds",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "60%" },
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseChoose"
                                    ),
                                    filterable: "",
                                  },
                                  on: { change: _vm.selectChangeSupplier },
                                  model: {
                                    value: _vm.ruleForm.providerIds,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "providerIds", $$v)
                                    },
                                    expression: "ruleForm.providerIds",
                                  },
                                },
                                _vm._l(
                                  _vm.transferData,
                                  function (user, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: user.providerName,
                                        value: user.id.toString(),
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              key: _vm.ruleForm.specialRuleFlag,
                              attrs: { label: _vm.$t("GlobalSetObj.otherIf") },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "60%" },
                                  attrs: {
                                    disabled: _vm.isSee,
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.ThereIsNo"
                                    ),
                                    "collapse-tags": "",
                                    multiple: "",
                                  },
                                  on: { change: _vm.changeOtherSubRuleType },
                                  model: {
                                    value: _vm.ruleForm.otherSubRuleType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "otherSubRuleType",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.otherSubRuleType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("GlobalSetObj.zipCode"),
                                      value: "1",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("GlobalSetObj.weight"),
                                      value: "2",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("GlobalSetObj.deviceName"),
                                prop: "deviceId",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "60%" },
                                  attrs: {
                                    disabled: _vm.isSee,
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseChoose"
                                    ),
                                    "collapse-tags": "",
                                    filterable: "",
                                  },
                                  on: { change: _vm.getGridNumList },
                                  model: {
                                    value: _vm.ruleForm.deviceId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "deviceId", $$v)
                                    },
                                    expression: "ruleForm.deviceId",
                                  },
                                },
                                _vm._l(_vm.deviceList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.deviceName,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { key: _vm.listKey, staticClass: "conditionList" },
                            [
                              _vm._l(
                                _vm.ruleForm.subRuleList,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "createCondition",
                                    },
                                    [
                                      _vm.ruleForm.subRuleList.length > 1 &&
                                      !_vm.isSee
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "delFillter",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delFillter(index)
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-circle-close",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "GlobalSetObj.gridNum"
                                            ),
                                            prop:
                                              "subRuleList." +
                                              index +
                                              ".gridNums",
                                            rules:
                                              _vm.subRuleListRules.gridNums,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "60%" },
                                              attrs: {
                                                disabled: _vm.isSee,
                                                placeholder: _vm.$t(
                                                  "GlobalSetObj.pleaseChoose"
                                                ),
                                                filterable: "",
                                              },
                                              on: {
                                                focus: function ($event) {
                                                  return _vm.uniqueGridNum(
                                                    _vm.ruleForm.subRuleList,
                                                    index
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.gridNums,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "gridNums",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.gridNums",
                                              },
                                            },
                                            _vm._l(
                                              item.gridList,
                                              function (grid) {
                                                return _c("el-option", {
                                                  key: grid.id,
                                                  attrs: {
                                                    label: grid.gridNum,
                                                    value: grid.gridNum,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.ruleForm.otherSubRuleType.includes(
                                        "1"
                                      )
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  "GlobalSetObj.zipCode"
                                                ),
                                                prop:
                                                  "subRuleList." +
                                                  index +
                                                  ".zipcodeAreaCode",
                                                rules:
                                                  _vm.subRuleListRules
                                                    .zipcodeAreaCode,
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  autosize: {
                                                    minRows: 2,
                                                    maxRows: 4,
                                                  },
                                                  disabled: _vm.isSee,
                                                  placeholder: _vm.$t(
                                                    "GlobalSetObj.pleaseInputContent"
                                                  ),
                                                  maxlength: "2000",
                                                  "show-word-limit": "",
                                                  type: "textarea",
                                                },
                                                model: {
                                                  value: item.zipcodeAreaCode,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "zipcodeAreaCode",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.zipcodeAreaCode",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.ruleForm.otherSubRuleType.includes(
                                        "2"
                                      )
                                        ? _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 10 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "GlobalSetObj.MinWeight"
                                                        ),
                                                        prop:
                                                          "subRuleList." +
                                                          index +
                                                          ".minWeight",
                                                        rules:
                                                          _vm.subRuleListRules
                                                            .minWeight,
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: {
                                                          disabled: _vm.isSee,
                                                          placeholder: _vm.$t(
                                                            "GlobalSetObj.pleaseInput"
                                                          ),
                                                          clearable: "",
                                                          type: "number",
                                                        },
                                                        model: {
                                                          value: item.minWeight,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "minWeight",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.minWeight",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 10 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "GlobalSetObj.MaxWeight"
                                                        ),
                                                        prop:
                                                          "subRuleList." +
                                                          index +
                                                          ".maxWeight",
                                                        rules:
                                                          _vm.subRuleListRules
                                                            .maxWeight,
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: {
                                                          disabled: _vm.isSee,
                                                          placeholder: _vm.$t(
                                                            "GlobalSetObj.pleaseInput"
                                                          ),
                                                          clearable: "",
                                                          type: "number",
                                                        },
                                                        model: {
                                                          value: item.maxWeight,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "maxWeight",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.maxWeight",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }
                              ),
                              _vm.ruleForm.otherSubRuleType.length !== 0 &&
                              !_vm.isSee
                                ? _c(
                                    "div",
                                    { staticClass: "filters" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            icon: "el-icon-plus",
                                            type: "primary",
                                          },
                                          on: { click: _vm.addFillter },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "ChannelManage.CreateCondition"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.titleName === "" + _vm.$t("GlobalSetObj.RuleDetails")
            ? _c(
                "div",
                { staticClass: "demo-drawer__footer" },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-right": "20px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.cancelForm },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("GlobalSetObj.close")) + " ")]
                  ),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "demo-drawer__footer" },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-right": "20px" },
                      on: { click: _vm.cancelForm },
                    },
                    [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.saveForm } },
                    [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Salve")))]
                  ),
                ],
                1
              ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }