<template>
  <div class="app-content">
    <el-card style="margin-bottom: 10px;">
      <!-- 搜索栏 -->
      <el-form ref="form" :model="params" label-width="auto" :label-position="'top'" size="small">
        <el-row :gutter="10">
          <el-col :span="6">
            <!--账单编号/索赔单号-->
            <el-form-item label="" class="selectInner">
              <template slot="label">
                <el-select v-model="type" :placeholder="$t('GlobalSetObj.pleaseChoose')" size="mini" style="width:180px; height: 28px;">
                  <el-option :label="$t('compensationManagement.BillNumber')" value="claimsBillNumber" />
                  <el-option :label="$t('compensationManagement.ClaimNumber')" value="claimsNumber" />
                </el-select>
              </template>
              <el-input
                v-model="params.val1" :placeholder="$t('GlobalSetObj.pleaseInputContent')" clearable
                class="input-with-select"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--支付时间-->
            <el-form-item :label="$t('compensationManagement.TimeOfPayment')">
              <el-date-picker
                v-model="params.patyedTimeList"
                type="datetimerange"
                :start-placeholder="$t('GlobalSetObj.startDate')"
                :end-placeholder="$t('GlobalSetObj.endDate')"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['09:00:00', '18:00:00']"
                style="display: block;width: 100%;"
              />
            </el-form-item>
          </el-col>
          <!--供应商-->
          <!-- <el-col :span="6">
            <el-form-item :label="$t('compensationManagement.ClaimSupplier')">
              <el-select
                v-model="params.customerId"
                filterable
                :placeholder="$t('operationCenter.PleaseSelect')"
                clearable
                style="width: 100%;"
              >
                <el-option
                  v-for="item in providerList"
                  :key="item.id"
                  :label="item.providerBody + '（' + item.providerName + '）'"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col> -->
          <!-- 索赔类型 -->
          <el-col :span="6">
            <el-form-item :label="$t('compensationManagement.ClaimType')">
              <el-select
                v-model="params.claimsType"
                filterable
                :placeholder="$t('operationCenter.PleaseSelect')"
                clearable
                style="width: 100%;"
              >
                <el-option
                  v-for="item in CLAIMS_TYPE"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--状态-->
            <el-form-item :label="$t('compensationManagement.PaymentStatus')">
              <el-select v-model="params.payedStatus" :placeholder="$t('GlobalSetObj.pleaseChoose')">
                <el-option
                  v-for="item in PAYSTATUS_STATUS"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-button
        type="primary"
        size="small"
        icon="el-icon-search"
        @click="onQuery"
      >{{ $t('operationCenter.Procurar') }}</el-button>
      <el-button size="small" icon="el-icon-refresh-left" @click="onReset">
        {{ $t('operationCenter.reset') }}
      </el-button>
    </el-card>

    <el-card>
      <el-table
        :data="tableData"
        :height="580"
        border
        style="width: 100%"
      >
        <!--序号-->
        <el-table-column :label="$t('operationCenter.ind')" width="60px">
          <template slot-scope="scope">
            <span>{{ (pageNumber - 1) * pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!-- 账单编号 -->
        <el-table-column :label="$t('compensationManagement.BillNumber')" prop="claimsBillNumber" width="130" />
        <!-- 索赔单号 -->
        <el-table-column :label="$t('compensationManagement.ClaimNumber')" prop="claimsNumber" width="130" />
        <!-- 索赔类型 -->
        <el-table-column :label="$t('compensationManagement.ClaimType')" prop="claimsType" width="140">
          <template slot-scope="scope">
            <span>{{ CLAIMS_TYPE[scope.row.claimsType] && CLAIMS_TYPE[scope.row.claimsType].name || ''}}</span>
          </template>
        </el-table-column>
        <!-- 索赔供应商 -->
        <!-- <el-table-column :label="$t('compensationManagement.ClaimSupplier')" prop="customerName" width="130" /> -->
        <!--  索赔申请金额-->
        <el-table-column :label="$t('compensationManagement.ClaimAmount')" prop="claimAmount" width="110" />
        <!-- 实际支付金额 -->
        <el-table-column :label="$t('compensationManagement.AmountActuallyPaid')" prop="actualClaimAmount" width="110" />
        <!-- 支付状态 -->
        <el-table-column :label="$t('compensationManagement.PaymentStatus')" prop="name" width="120">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.tag" :key="scope.row.tag.value" :type="scope.row.tag.type">
              {{ scope.row.tag.name }}
            </el-tag>
          </template>
        </el-table-column>
        <!-- 支付时间 -->
        <el-table-column :label="$t('compensationManagement.TimeOfPayment')" prop="payedTime" width="170" />
        <!-- 支付方式 -->
        <el-table-column :label="$t('compensationManagement.PaymentMethod')" prop="payedTypeStr" width="120" />
        <!-- 支付账号 -->
        <el-table-column :label="$t('compensationManagement.PaymentAccountNumber')" prop="payAccount" />
        <!-- 附件 -->
        <el-table-column :label="$t('customerManagements.enclosure')" prop="attachmentUrl">
          <template slot-scope="scope">
            <el-link
              v-for="item of scope.row.attachmentUrl" :key="item.url" type="primary"
              :href="item.url" target="_blank"
            >{{ item.name }}</el-link>
          </template>
        </el-table-column>
        <!-- 备注 -->
        <el-table-column :label="$t('GlobalSetObj.remark')" prop="remarks" :show-overflow-tooltip="true"/>
        <!-- 操作 -->
        <el-table-column
          fixed="right"
          :label="$t('GlobalSetObj.operate')"
          width="160"
        >
          <template slot-scope="scope">
            <!-- 付款 -->
            <!-- <el-button
              v-if="scope.row.payedStatus === PAYSTATUS_STATUS[2].value" size="small" type="success"
              @click="onOpenPayment(scope.row)"
            >{{ $t('compensationManagement.Payment') }}</el-button> -->
            <!-- 账单抵扣 -->
            <el-button
              v-if="scope.row.payedStatus === PAYSTATUS_STATUS[2].value" size="small" type="primary"
              @click="asyncApiClaimBillDeduction(scope.row.id)"
            >{{ $t('compensationManagement.BillCredit') }}</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getCurrentTableData"
          @size-change="getSizeTableData"
        />
      </div>
    </el-card>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash-es';
import { apiDemageClaimBillPage, apiDemageClaimBillDeduction } from '@/api/compensationManagement/bill.js';
import { PAYSTATUS_STATUS, PAYTYPE_LIST } from '../content.js';
import { claimantMixin } from '../claimantMinxin.js';
import dayjs from 'dayjs';

const firstDay = dayjs().startOf('month').format('YYYY-MM-DD') + ' 00:00:00';
const lastDay = dayjs().endOf('month').format('YYYY-MM-DD') + ' 23:59:59';

const PARAM = {
  payedStatus: '', // 状态
  customerId: '', // 供应商ID
  patyedTimeList: [firstDay, lastDay], // 支付时间
  val1: '', // 单号
};
export default {
 
  data() {
    return {
      params: {
        ...PARAM
      },
      type: 'claimsBillNumber', // 账单编号/索赔单号

      tableData: [], // 列表数据
      pageNumber: 1,
      pageSize: 10,
      total: 0, // 总条数

      PAYSTATUS_STATUS: PAYSTATUS_STATUS, // 状态
      PAYTYPE_LIST: PAYTYPE_LIST, // 支付方式
    };
  },

  mixins: [claimantMixin],

  created() {
    
  },

  mounted() {
    this.getTableData();
  },

  // activated() {
  //   console.log('进入')
  // },
  // deactivated() {
  //   console.log('离开')
  // },

  methods: {
    // 搜索
    onQuery() {
      this.searchList();
    },

    // 重置
    onReset() {
      this.params = {
        ...PARAM
      };

      this.searchList();
    },

    searchList() {
      this.pageNumber = 1;
      this.pageSize = 10;
      this.getTableData();
    },

    /**
     * 切换页码
     * @param val 
     */
    getCurrentTableData(val) {
      this.pageNumber = val;
      this.getTableData();
    },

    /**
     * 切换每页条数
     * @param val 
     */
    getSizeTableData(val) {
      this.pageSize = val;
      this.pageNumber = 1;
      this.getTableData();
    },

    // 获取列表数据
    getTableData() {
      const obj = cloneDeep(this.params);
      obj[this.type] = obj.val1;
      delete obj.val1;

      apiDemageClaimBillPage({
        params: removeEmptyProperties(obj),
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      }).then(res => {
        if (res.status === 'OK') {
          const { total, records } = res.data;
          this.total = total;
          
          this.tableData = (records || []).map(item => {
            item.payedTypeStr = this.PAYTYPE_LIST[item.payedType]?.name || '';
            item.tag = this.PAYSTATUS_STATUS[item.payedStatus] || null;
            return item;
          });
        }
      }).catch(err => {
        console.error(err);
      });
    },

    asyncApiClaimBillDeduction(id) {
      this.$confirm(`${this.$t('compensationManagement.ConfirmOperation')}?`, this.$t('GlobalSetObj.tips'), {
        confirmButtonText: this.$t('GlobalSetObj.determine'),
        cancelButtonText: this.$t('GlobalSetObj.cancel'),
        type: 'warning'
      }).then(() => {
        return apiDemageClaimBillDeduction({ id });
      }).then(res => {
        // 操作成功
        this.$message.success(this.$t('GlobalSetObj.TheOperationSucceeded'));
        this.getTableData();
      }).catch(() => {
        console.error('取消操作');
      });
    },
  }
};

function removeEmptyProperties(obj) {
  const newObj = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
      newObj[key] = obj[key];
    }
  }

  return newObj;
}
</script>




<style scoped lang="scss">
@import '../style/index.scss';

</style>
