<template>
  <div class="Box LoadBox">
    <div class="scanClass">
      <!-- 签收： -->
      <span>{{ $t('collectionCenter.scanBtn') }}</span>
      <el-input
        v-if="isReceiveSignBtn"
        v-model.trim="signBagNumber"
        :placeholder="$t('collectionCenter.pleaseEnterOrScan')"
        prefix-icon="el-icon-full-screen"
        clearable
        class="iconBtn"
        @keyup.enter.native="pushTable"
      />
      <el-tooltip v-else effect="dark" :content="$t('userManage.unauthorized')" placement="right">
        <el-input v-model.trim="signBagNumber" :placeholder="$t('collectionCenter.pleaseEnterOrScan')" prefix-icon="el-icon-full-screen" clearable class="iconBtn" />
      </el-tooltip>
    </div>
    <div class="uploadBtn">
      <el-button
        v-if="isReceiveSignBtn"
        type="success"
        @click="signature"
      >{{ $t('collectionCenter.signature') }}</el-button><!-- 签名 -->
    </div>
    <div class="recordClass">
      <span>{{ $t('collectionCenter.scannedNumber') }}</span><!-- 已扫描的数量 -->
      <span class="red">{{ scanPageTotal }}</span>
    </div>
    <div class="tableClass">
      <el-table
        :max-height="600"
        :data="scanPage"
        border
      >
        <el-table-column
          prop="scanNumber"
          align="center"
          :label="$t('collectionCenter.Number')"
        /><!-- 袋号 -->
        <el-table-column
          prop="scanTimeStr"
          align="center"
          :label="$t('collectionCenter.scanTime')"
        /><!-- 扫描时间 -->
        <el-table-column
          :label="$t('collectionCenter.operation')"
          fixed="right"
          width="100"
          align="center"
        ><!-- 操作 -->
          <template slot-scope="scope">
            <el-popconfirm
              :title="$t('collectionCenter.deleteSure')"
              @confirm="scanDelClick(scope.row,scope.$index)"
            ><!-- 确定删除吗？ -->
              <el-button slot="reference" type="danger" size="mini">{{ $t('collectionCenter.Delete') }}</el-button><!-- 删除 -->
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 签名 -->
    <div v-if="signVisible" class="signMask">
      <div class="el-icon-close signClose" @click="signCloseClick" />
      <div class="m-b">
        <div id="printElement" class="sc-wrapper-label">
          <section ref="image" class="sc-border-full">
            <header class="sc-header">
              <p class="sc-pointCollect"> {{ $t('collectionCenter.LanShouSite') }}: {{ scanList.siteName }} </p>
              <div>
                <img src="/static/anjunLogo.png" alt="logo da anjun">
              </div>
            </header>

            <div class="sc-contentSeparator" style="height:30px;" />

            <div class="sc-gridInformations">
              <div class="sc-gridItem">{{ $t('collectionCenter.receiverName') }}: </div>
              <div class="sc-gridItem"> {{ scanList.name }} </div>

              <div class="sc-gridItem">{{ $t('collectionCenter.lanshouT') }}: </div>
              <div class="sc-gridItem"> {{ scanList.time }} </div>
            </div>

            <div class="sc-contentSeparator">
              <h2>{{ $t('collectionCenter.lanshouMsg') }}</h2>
            </div>

            <div class="sc-gridInformations">
              <div class="sc-gridItem">{{ $t('collectionCenter.receivedParcelsTotal') }} </div>
              <div class="sc-gridItem"> {{ scanList.bagSum }} </div>
            </div>

            <div class="sc-contentSeparator">
              <h2>{{ $t('collectionCenter.customerSignature') }}</h2>
            </div>

            <div>
              <div class="page-content">
                <div class="content">
                  <vue-esign ref="esignImg" :width="898" :height="300" :line-width="5" line-color="#000" bg-color="#FFF" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="flex">
        <el-button class="btn" type="danger" @click="handleReset">{{ $t('collectionCenter.empty') }}</el-button><!-- 清空 -->
        <el-button class="btn" type="success" @click="handleGenerate">{{ $t('collectionCenter.submitSignature') }}</el-button><!-- 提交签名 -->
      </div>
    </div>

    <div v-if="isLoading" class="loadingClass" />
    <!-- 隐藏提示音 -->
    <div v-show="false">
      <audio ref="errRef" :src="errorMp3" controls="controls" />
      <audio ref="okRef" :src="okMp3" controls="controls" />
    </div>
  </div>
</template>

<script>
import {
  getFormatServerTime,
  scanInfoPcCollectionScan,
  scanInfoDeletedScan,
  scanInfoQueryScanLog, collectPcSmallSellerSubmit
} from '@/api/lanshou';
import { uploadOos } from '@/utils/ali_oos_util';
import html2canvas from 'html2canvas';
const okMp3 = '/static/scan_success.mp3';
const errorMp3 = '/static/scan_fail.mp3';
export default {
  data() {
    return {
      isReceiveSignBtn: this.$store.getters.button_permissions.includes('btn:collection-center:operation:largeParcelCollection:submit'),
      signBagNumber: '',
      scanPage: [],
      scanPageTotal: 0,
      receiveSignList: {}, // 缓存的数据
      signVisible: false,
      isLoading: false, // 加载动画
      scanList: {},
      receiveSignImg: '', // 提交的签名图片
      brazilTime: '',
      parcelsSum: '',
      rowClass: {
        'border-color': '#a8a9ad'
      },
      labelRowClass: {
        'border-color': '#a8a9ad'
      },
      okMp3,
      errorMp3
    };
  },
  watch: {
    scanPage() {
      this.scanPageTotal = this.scanPage.length;
      if (this.scanPage.length <= 0) {
        this.signBagNumber = '';
      }
    }
  },
  created() {
    this.getScanInfoList();
  },
  methods: {
    pushTable() {
      if (!this.signBagNumber) { return; }
      // 重复过滤
      const isAdd = this.scanPage.some(el => {
        return el.signBagNum === this.signBagNumber;
      });
      if (isAdd && this.scanPage.length > 0) {
        this.$message({
          message: this.$t('collectionCenter.bagExist'), // '当前袋号重复'
          type: 'warning'
        });
        this.$refs.errRef.play();
      } else {
        // 无重复
        const form = {
          bagNumber: this.signBagNumber
        };
        scanInfoPcCollectionScan(form.bagNumber).then(res => {
          if (res.data.status === 'OK' || res.status === 'OK') {
            // const scanTime = new Date();
            // this.scanPage.unshift({
            //   scanTime: this.$dayjs(scanTime).format('HH:mm:ss YYYY-MM-DD'),
            //   id: res.data.data || res.data,
            //   signBagNum: this.signBagNumber
            // });
            // 获取扫描的列表
            this.getScanInfoList();
            this.$refs.okRef.play();
            this.signBagNumber = '';
          } else {
            const msg = res.data.msg || res.msg;
            this.$message.error(msg);
            this.signBagNumber = '';
            this.$refs.errRef.play();
          }
        }).catch((err) => {
          this.signBagNumber = '';
          console.log('err', err);
          this.$refs.errRef.play();
        });
      }
    },
    scanDelClick(row, index) { // 扫件——删除
      scanInfoDeletedScan(row.id).then(res => {
        this.scanPage.splice(index, 1);
        if (row.scanNumber == this.signBagNumber) {
          this.signBagNumber = '';
        }
        // 获取扫描信息列表
        this.getScanInfoList();
      }).catch({});
    },
    // 调用巴西时间
    getBrazilTime() {
      return new Promise((resolve, reject) => {
        getFormatServerTime().then(res => {
          if (res.status === 'OK' && res.data) {
            const bxTime = res.data.split(' ');
            const bxt1 = bxTime[0].split('-');
            this.brazilTime = bxTime[1] + ' ' + bxt1[0] + '-' + bxt1[1] + '-' + bxt1[2];
            resolve();
          }
          reject();
        }).catch(() => {
          reject();
        });
      });
    },
    // 获取包裹数
    getParcelsSum() {
      const bnList = [];
      this.scanPage.forEach(arr => {
        bnList.push(arr.signBagNum);
      });
      scanInfoQueryScanLog().then(res => {
        if (res.status === 'OK') {
          this.parcelsSum = res.data;
        } else {
          this.parcelsSum = '';
        }
        // this.parcelsSum = res.data;
      }).catch({});
      return this.parcelsSum;
    },
    async signature() {
      if (this.scanPage.length <= 0) {
        this.$message({
          message: this.$t('collectionCenter.scanLargePackage'), // '请先扫描袋号'
          type: 'warning'
        });
        return;
      }
      await this.getBrazilTime();
      this.scanList = {
        name: this.$store.state.user.realName,
        code: this.$store.state.user.username,
        parcelsSum: this.parcelsSum,
        bagSum: this.scanPageTotal,
        time: this.brazilTime,
        siteName: this.$store.state.user.dotName
      };
      this.signVisible = true;
    },
    signCloseClick() {
      this.signVisible = false;
      this.parcelsSum = '';
    },
    // 签名——清空
    handleReset() {
      this.$refs['esignImg'].reset(); // 清空画布
    },
    // 签名并提交
    upload() {
      if (this.scanPage.length <= 0) {
        this.$message({
          message: this.$t('collectionCenter.nullSubmit'), // '无数据提交'
          type: 'warning'
        });
      } else {
        const bnList = [];
        this.scanPage.forEach(arr => {
          bnList.push({
            orderId: arr.orderId,
            scanTime: arr.scanTime,
            scanNumber: arr.scanNumber
          });
        });
        const scanSubmit = {
          scanUserId: this.$store.state.user.id,
          siteId: this.$store.state.user.siteId,
          signImg: this.receiveSignImg,
          packageList: bnList
        };
        collectPcSmallSellerSubmit(scanSubmit).then(res => {
          if (res.status === 'OK') {
            this.isLoading = false;
            this.$message.success(this.$t('collectionCenter.submitSuccessfully')); // 提交成功
            this.signVisible = false;
            // 获取扫描信息列表
            this.getScanInfoList();
          }
        }).catch((err) => {
          this.isLoading = false;
          console.error(err);
        });
      }
    },
    handleGenerate() {
      // // 查看截图样式
      // html2canvas(this.$refs.image).then(res => {
      //   const imgUrl = res.toDataURL('image/png');
      //   // console.log('图片临时地址',imgUrl)
      //   const aLink = document.createElement('a');
      //   aLink.href = imgUrl;
      //   aLink.download = new Date().toLocaleString() + '.png';	// 导出文件名，这里以时间命名
      //   document.body.appendChild(aLink);
      //   // 模拟a标签点击事件
      //   aLink.click();
      // });
      // return;

      this.$refs['esignImg'].generate().then(res => {
        this.isLoading = true;
        this.canvasImg();
      }).catch((err) => {
        console.log(err);
        this.$message({
          message: this.$t('collectionCenter.unsigned'), // '未签名!'
          type: 'warning'
        });
      });
    },
    canvasImg() {
      const canvas = document.createElement('canvas'); // 截图区
      const canvasBox = this.$refs.image; // 父级
      const windowSize = window.devicePixelRatio; // 浏览器缩放大小会影响截屏最终展示
      const width = parseInt(window.getComputedStyle(canvasBox).width) * windowSize;
      const height = parseInt(window.getComputedStyle(canvasBox).height) * windowSize;
      canvas.width = width * 2;
      canvas.height = height * 2;
      canvas.style.width = width + 'px';
      canvas.style.height = height + 'px';
      const context = canvas.getContext('2d');
      context.scale(2, 2);
      const options = {
        backgroundColor: '#ffffff',
        canvas: canvas,
        useCORS: true
      };
      html2canvas(canvasBox, options).then((canvas) => {
        // toDataURL 图片格式转成 base64
        const dataURL = canvas.toDataURL('image/png');
        const rSignImg = this.base64ImgtoFile(dataURL, 'file'); // 得到文件格式

        uploadOos(rSignImg, 'Order').then(res => {
          if (res.code === 200) {
            if (res.url) {
              this.receiveSignImg = res.url;
              this.upload();
            } else {
              this.receiveSignImg = '';
            }
          }
        });
      });
    },
    base64ImgtoFile(dataurl, filename = 'file') {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const suffix = mime.split('/')[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
      });
    },
    /**
     * @description: 获取扫描信息列表
     */
    getScanInfoList() {
      scanInfoQueryScanLog().then(res => {
        if (res.status === 'OK') {
          this.scanPage = res.data;
          if (this.scanPage.length <= 0) {
            this.signBagNumber = '';
          }
        } else {
          this.parcelsSum = '';
        }
      }).catch({});
    }
  }
};
</script>

<style lang="scss" scoped>
.descriptionsClass{
  position: relative;
  width: 500px;
  margin: 50px auto;
  max-height: 700px;
  .desTitle{
    color: #000;
    background: #a8a9ad;
    text-align: center;
    padding: 12px 10px;
    font-size: 20px;
    font-weight: 400;
    border: none;
    box-sizing: border-box;
  }
  .labelT{
    word-wrap: break-word;
  }
  .page-content{
    border-top: none;
    box-sizing: border-box;
    background: #fff;
  }
}
.descriptionsClass ::v-deep .el-descriptions-row{
  width: 100%;
  font-size: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.descriptionsClass ::v-deep tbody{
  font-size: 18px !important;
}
.descriptionsClass ::v-deep .el-descriptions-item__cell{
  border-top: none;
  background: none;
  color: #000;
  font-size: 19px;
  font-weight: 400;
  padding: 7px 10px;
}
.descriptionsClass ::v-deep .el-descriptions-item__content{
  text-align: center;
  border-right: none !important;
}
.descriptionsClass ::v-deep .el-descriptions-item__cell.is-bordered-label{
  width: 150px;
  max-width: 150px;
  border-left: none !important;
  border-right: none !important;
}
.LoadBox{
  position: relative;
  width: 100%;
  height: calc(100vh - 85px);
}
.loadingClass{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.3);
  z-index: 999;
}
.loadingClass:before{
  content: "";
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: spin 1s linear infinite;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #62b0ff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.signMask{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background: #ffffff;
  z-index: 99;
  .signVisibleClass{
    overflow: auto;
    width:100%;
    height:100%;
    background:#fff;
    padding:20px 0;
    // overflow: hidden;
  }
  .signClose{
    position: absolute;
    top: 25px;
    right: 40px;
    transform: scale(1.3);
  }
  .page-content{
    margin: 0 auto;
    width: 100%;
    height: auto;
    overflow-y: auto;
    box-sizing: border-box;
    //max-height: 644px;
  }
  .content {
    border: 1px solid #EBEEF5;
    box-sizing: border-box;
    overflow: hidden;
  }
  .flex {
    margin: 10px;
    text-align: center;
    position: absolute;
    //right: -200px;
    right: 10px;
    bottom: 212px;
  }
}
.flex ::v-deep .el-button{
  height: 40px;
  margin: 20px 0;
  display: block;
  padding: 0 20px;
  width: auto;
}
.flex ::v-deep .el-button--danger{
  color: #FFF !important;
  background-color: #F56C6C !important;
  border-color: #F56C6C !important;
}
.signVisibleClass ::v-deep .el-drawer__header{
  margin-bottom: 0 !important;
}
.signVisibleClass ::v-deep .el-drawer__header>:first-child{
  // display: none;
  opacity: 0;
}
.signVisibleClass ::v-deep .el-drawer__open .el-drawer.btt{
  height: 50% !important;
}

.recordClass{
  text-align: center;
  margin: 20px auto;
  font-size: 18px;
  .red{
    font-size: 30px;
    font-weight: bold;
    color: #e0223c;
    margin-left: 6px;
  }
}
.uploadBtn ::v-deep .el-button{
  font-size: 18px;
  padding: 7px 20px;
  line-height: 17px;
}
.uploadBtn{
  margin-top: 40px;
  text-align: center;
}
.scanClass ::v-deep .el-input{
  width: 350px;
}
.scanClass{
  margin: 20px auto;
  .iconBtn::after{
    position: absolute;
    content: "-";
    top: 8px;
    left: 15px;
    transform: scaleX(2.3);
    color: #bbb;
  }
}
.Box{
  padding: 20px;
  box-sizing: border-box;
}
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.sc-wrapper-label {
  max-width: 900px;
  margin: 0px auto;
  box-sizing: border-box;
  display: grid;
  gap: 4rem;
}

.sc-border-full {
  border: 1px solid #000;
  background-color: #ffffff;
}

.m-b-200 {
  margin-bottom: 200px;
}

.sc-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px;
}

.sc-pointCollect {
  font-size: 20px;
  font-weight: bold;
}

.sc-contentSeparator {
  padding: 1rem;
  text-align: center;
  background: #a8a9ad;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.sc-contentSeparator > h2 {
  font-size: 20px;
  font-weight: bold;
}

.sc-gridInformations {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.sc-gridItem {
  padding: 16px;
  font-size: 20px;
}

.sc-gridItem:nth-child(even) {
  border-left: 1px solid #000;
  text-align: center;
}

.sc-gridItem:nth-child(odd) {
  font-weight: bold;
}

.sc-gridItem:nth-child(n+3) {
  border-top: 1px solid #000;
}

.sc-wrapper-table {
  width: 100%;
}

.sc-section-table{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

tbody.sc-section-table > .sc-table-row {
  border-top: 1px solid #000;
}

.sc-table-row {
  display: grid;
  grid-template-columns: 20px 1fr 1fr;
}

.sc-table-cell{
  padding: 4px;
  font-size: 14px;
  text-align: center;
}

.sc-table-cell ~ .sc-table-cell {
  border-left: 1px solid #000;
}

.border-left {
  border-left: 1px solid #000;
}

.m-b {
  margin-bottom: 120px;
  background-color: #ffffff;
}

.sc-button-actions{
  position: fixed;
  bottom: 0px;
  background: #fff;
  width: 100%;
  padding: 1.5rem 0;
  display: flex;
  justify-content: center;
  gap: 1rem;
}
</style>
