import request from '@/utils/request';

// 索赔账单

// 分页
export const apiDemageClaimBillPage = data => {
  return request.post('/fa/demageClaimBill/page', data);
};

// 付款
export const apiDemageClaimBillPayTodo = data => {
  return request.post('/fa/demageClaimBill/payTodo', data);
};

// 账单抵扣
export const apiDemageClaimBillDeduction = data => {
  return request.post('/fa/demageClaimBill/deduction', data);
};
