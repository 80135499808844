var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return [0, 2].includes(_vm.status)
    ? _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "basicForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "120px",
                size: "small",
                "label-position": _vm.$i18n.locale != "zh" ? "top" : "right",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.businessT"),
                            prop: "claimsNode",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "form-bottom",
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                                disabled:
                                  [2].includes(_vm.status) && _vm.id !== "",
                                id: "claimsNode",
                              },
                              on: { change: _vm.handelClaimsNode },
                              model: {
                                value: _vm.ruleForm.claimsNode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "claimsNode", $$v)
                                },
                                expression: "ruleForm.claimsNode",
                              },
                            },
                            _vm._l(_vm.claimNode, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.name, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("compensationManagement.ClaimType"),
                            prop: "claimsType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "operationCenter.PleaseSelect"
                                ),
                                id: "claimsType",
                              },
                              on: { change: _vm.handelClaimType },
                              model: {
                                value: _vm.ruleForm.claimsType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "claimsType", $$v)
                                },
                                expression: "ruleForm.claimsType",
                              },
                            },
                            _vm._l(_vm.CLAIMS_TYPE, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.name, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "compensationManagement.AmountOfClaim"
                            ),
                            prop: "claimAmount",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                              clearable: "",
                              id: "claimAmount",
                            },
                            model: {
                              value: _vm.ruleForm.claimAmount,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "claimAmount", $$v)
                              },
                              expression: "ruleForm.claimAmount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("Resource.applicant") } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "40",
                              "show-word-limit": "",
                              placeholder: _vm.$t(
                                "GlobalSetObj.pleaseInputContent"
                              ),
                              id: "applicant",
                            },
                            model: {
                              value: _vm.ruleForm.applicant,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "applicant",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "ruleForm.applicant",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("Resource.Reviewer") } },
                        [
                          _c("UserSelectCmp", {
                            staticClass: "form-bottom",
                            attrs: {
                              "user-name": _vm.userName,
                              "is-user-name": true,
                            },
                            model: {
                              value: _vm.ruleForm.reviewer,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "reviewer", $$v)
                              },
                              expression: "ruleForm.reviewer",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "compensationManagement.CauseOfClaim"
                            ),
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              maxlength: "2000",
                              "show-word-limit": "",
                              autosize: { minRows: 2, maxRows: 4 },
                              placeholder: _vm.$t(
                                "GlobalSetObj.pleaseInputContent"
                              ),
                              id: "claimReason",
                            },
                            model: {
                              value: _vm.ruleForm.claimReason,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "claimReason", $$v)
                              },
                              expression: "ruleForm.claimReason",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "uploadFile",
                          attrs: {
                            label: _vm.$t("customerManagements.enclosure"),
                            prop: "attachmentUrl",
                          },
                        },
                        [
                          _c("UploadCmp", {
                            attrs: {
                              drag: true,
                              multiple: true,
                              "file-arr": _vm.fileArr,
                              "auto-upload": true,
                              limit: 5,
                            },
                            on: { change: _vm.onFileChange },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "div",
        [
          _c(
            "el-form",
            {
              staticClass: "demo-ruleForm",
              attrs: {
                "label-width": "120px",
                size: "small",
                "label-position": _vm.$i18n.locale != "zh" ? "top" : "right",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("collectionCenter.businessT"),
                          },
                        },
                        [
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  (_vm.claimNode[_vm.ruleForm.claimsNode] &&
                                    _vm.claimNode[_vm.ruleForm.claimsNode]
                                      .name) ||
                                    ""
                                )
                              ),
                            ]),
                          ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("compensationManagement.ClaimType"),
                          },
                        },
                        [
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  (_vm.CLAIMS_TYPE[_vm.ruleForm.claimsType] &&
                                    _vm.CLAIMS_TYPE[_vm.ruleForm.claimsType]
                                      .name) ||
                                    ""
                                )
                              ),
                            ]),
                          ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "compensationManagement.AmountOfClaim"
                            ),
                          },
                        },
                        [
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.ruleForm.claimAmount)),
                            ]),
                          ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("Resource.applicant") } },
                        [
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.ruleForm.applicant)),
                            ]),
                          ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("Resource.Reviewer") } },
                        [
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.ruleForm.reviewerUsername)),
                            ]),
                          ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "compensationManagement.CauseOfClaim"
                            ),
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                display: "inline-block",
                                "max-height": "100px",
                                "overflow-y": "scroll",
                                width: "100%",
                              },
                            },
                            [_vm._v(_vm._s(_vm.ruleForm.claimReason))]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "uploadFile",
                          attrs: {
                            label: _vm.$t("customerManagements.enclosure"),
                          },
                        },
                        [
                          _vm._l(_vm.fileArr, function (item) {
                            return _c(
                              "li",
                              { key: item.url },
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "primary",
                                      target: "_blank",
                                      href: item.url,
                                    },
                                  },
                                  [
                                    _vm._v(_vm._s(item.name)),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-view el-icon--right",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }