var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "searchBox" },
    [
      _c(
        "el-form",
        {
          ref: "searchForm",
          attrs: { inline: false, "label-position": "top" },
          model: {
            value: _vm.queryForm,
            callback: function ($$v) {
              _vm.queryForm = $$v
            },
            expression: "queryForm",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("newOrder.waybillStatus") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.all"),
                            clearable: "",
                            "collapse-tags": "",
                            filterable: "",
                            multiple: "",
                            size: "small",
                          },
                          on: { change: _vm.selectStatus },
                          model: {
                            value: _vm.queryForm.params.waybillStatus,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "waybillStatus",
                                $$v
                              )
                            },
                            expression: "queryForm.params.waybillStatus",
                          },
                        },
                        _vm._l(_vm.waybillStatusList, function (item, ind) {
                          return _c("el-option", {
                            key: ind,
                            attrs: { label: item.label, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("newOrder.CustomerName"),
                        name: "customerId",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t(
                              "spareNumberPoo.Only10AreDisplayed"
                            ),
                            "remote-method": _vm.getCustomerPage,
                            clearable: "",
                            filterable: "",
                            remote: "",
                            "reserve-keyword": "",
                            size: "small",
                          },
                          on: {
                            focus: function ($event) {
                              return _vm.getCustomerPage()
                            },
                          },
                          model: {
                            value: _vm.queryForm.params.customerId,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm.params, "customerId", $$v)
                            },
                            expression: "queryForm.params.customerId",
                          },
                        },
                        _vm._l(_vm.customerList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label:
                                item.customerAlias + "(" + item.username + ")",
                              value: item.id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("orderCenterCont.recipientProvince"),
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("orderDetails.pleaseInput"),
                          clearable: "",
                          size: "small",
                        },
                        model: {
                          value: _vm.queryForm.params.receiveState,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.queryForm.params,
                              "receiveState",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.params.receiveState",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("collectionCenter.LanShouSite") },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            clearable: "",
                            filterable: "",
                            size: "small",
                          },
                          model: {
                            value: _vm.queryForm.params.preCollectStationId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "preCollectStationId",
                                $$v
                              )
                            },
                            expression: "queryForm.params.preCollectStationId",
                          },
                        },
                        _vm._l(_vm.siteList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("GlobalSetObj.preEntrega") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            clearable: "",
                            filterable: "",
                            size: "small",
                          },
                          model: {
                            value: _vm.queryForm.params.preDeliverStationId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "preDeliverStationId",
                                $$v
                              )
                            },
                            expression: "queryForm.params.preDeliverStationId",
                          },
                        },
                        _vm._l(_vm.siteList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("orderCenterCont.deliveryPerson"),
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t(
                              "operationCenter.Only10AreDisplayed"
                            ),
                            "remote-method": _vm.getallDriver,
                            clearable: "",
                            filterable: "",
                            remote: "",
                            size: "small",
                          },
                          on: { focus: _vm.getallDriver },
                          model: {
                            value: _vm.queryForm.params.deliveryManName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "deliveryManName",
                                $$v
                              )
                            },
                            expression: "queryForm.params.deliveryManName",
                          },
                        },
                        _vm._l(_vm.drvierList, function (item) {
                          return _c("el-option", {
                            key: item.expressId,
                            attrs: {
                              label: item.deliveryManName,
                              value: item.deliveryManName,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("placeOrder.cargoType"),
                        size: "small",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.queryForm.params.cargoType,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm.params, "cargoType", $$v)
                            },
                            expression: "queryForm.params.cargoType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.all"),
                              value: "",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("placeOrder.normal01"),
                              value: 0,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("placeOrder.big01"),
                              value: 1,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("placeOrder.packageType"),
                        size: "small",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.queryForm.params.orderPackageType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "orderPackageType",
                                $$v
                              )
                            },
                            expression: "queryForm.params.orderPackageType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("GlobalSetObj.all"),
                              value: 0,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("placeOrder.zhifa"),
                              value: 1,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("orderCenterCont.hedan"),
                              value: 2,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("orderCenterCont.other"),
                              value: 3,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "searchTimeType" } },
                    [
                      _c(
                        "template",
                        { slot: "label" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selectClass",
                              style: _vm.IsWidthTime,
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                                size: "small",
                              },
                              on: { change: _vm.SwitchTimeType },
                              model: {
                                value: _vm.queryForm.params.searchTimeType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "searchTimeType",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.searchTimeType",
                              },
                            },
                            [
                              _c("el-option", {
                                key: "1",
                                attrs: {
                                  label: _vm.$t("newOrder.createTime"),
                                  value: "1",
                                },
                              }),
                              _c("el-option", {
                                key: "5",
                                attrs: {
                                  label: _vm.$t(
                                    "orderCenterCont.customerDeliveryTime"
                                  ),
                                  value: "5",
                                },
                              }),
                              _c("el-option", {
                                key: "3",
                                attrs: {
                                  label: _vm.$t("newOrder.TransitInboundTime"),
                                  value: "3",
                                },
                              }),
                              _c("el-option", {
                                key: "4",
                                attrs: {
                                  label: _vm.$t("newOrder.shipmentTime"),
                                  value: "4",
                                },
                              }),
                              _c("el-option", {
                                key: "7",
                                attrs: {
                                  label: _vm.$t("orderCenterCont.deliveryTime"),
                                  value: "7",
                                },
                              }),
                              _c("el-option", {
                                key: "2",
                                attrs: {
                                  label: _vm.$t("orderCenterCont.SigningTime"),
                                  value: "2",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "default-time": ["00:00:00", "23:59:59"],
                          "end-placeholder": _vm.$t("operationCenter.endDate"),
                          format:
                            _vm.$i18n.locale === "zh" ? _vm.zhDate : _vm.ptDate,
                          "picker-options": _vm.pickerOptions,
                          "start-placeholder": _vm.$t(
                            "operationCenter.startDate"
                          ),
                          "range-separator": "~",
                          size: "small",
                          type: "datetimerange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        on: {
                          change: _vm.handleTime,
                          input: function ($event) {
                            return _vm.changeInput($event)
                          },
                        },
                        model: {
                          value: _vm.timeValue,
                          callback: function ($$v) {
                            _vm.timeValue = $$v
                          },
                          expression: "timeValue",
                        },
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "template",
                        { slot: "label" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selectClass",
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pleaseChoose"
                                ),
                                size: "small",
                              },
                              model: {
                                value:
                                  _vm.queryForm.params
                                    .deliverWarehouseStationType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "deliverWarehouseStationType",
                                    $$v
                                  )
                                },
                                expression:
                                  "queryForm.params.deliverWarehouseStationType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("GlobalSetObj.entrega"),
                                  value: "1",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("GlobalSetObj.infeatSite"),
                                  value: "2",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            clearable: "",
                            filterable: "",
                            size: "small",
                          },
                          model: {
                            value: _vm.queryForm.params.deliverStationId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "deliverStationId",
                                $$v
                              )
                            },
                            expression: "queryForm.params.deliverStationId",
                          },
                        },
                        _vm._l(_vm.siteList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "template",
                        { slot: "label" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selectClass",
                              style: _vm.IsWidth,
                              attrs: {
                                placeholder: _vm.$t(
                                  "orderDetails.pleaseChoose"
                                ),
                                size: "small",
                              },
                              on: { change: _vm.handleChangeLable },
                              model: {
                                value: _vm.queryForm.params.searchNumberType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm.params,
                                    "searchNumberType",
                                    $$v
                                  )
                                },
                                expression: "queryForm.params.searchNumberType",
                              },
                            },
                            [
                              _c("el-option", {
                                key: "1",
                                attrs: {
                                  label: _vm.$t(
                                    "orderCenterCont.anjunWaybillNumber"
                                  ),
                                  value: "1",
                                },
                              }),
                              _c("el-option", {
                                key: "8",
                                attrs: {
                                  label: _vm.$t("GlobalSetObj.ScanNumber"),
                                  value: "8",
                                },
                              }),
                              _c("el-option", {
                                key: "5",
                                attrs: {
                                  label: _vm.$t(
                                    "GlobalSetObj.serviceLogistics"
                                  ),
                                  value: "5",
                                },
                              }),
                              _c("el-option", {
                                key: "2",
                                attrs: {
                                  label: _vm.$t("newOrder.PlatformOrderNumber"),
                                  value: "2",
                                },
                              }),
                              _c("el-option", {
                                key: "4",
                                attrs: {
                                  label: _vm.$t(
                                    "orderCenterCont.TransitBinBagNumber"
                                  ),
                                  value: "4",
                                },
                              }),
                              _c("el-option", {
                                key: "7",
                                attrs: {
                                  label: _vm.$t(
                                    "orderCenterCont.SubpackageNumber"
                                  ),
                                  value: "7",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "100%", "margin-top": "5px" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("newOrder.PreciseSearch1"),
                              rows: 3,
                              size: "small",
                              type: "textarea",
                            },
                            model: {
                              value: _vm.searchNumberStr,
                              callback: function ($$v) {
                                _vm.searchNumberStr = $$v
                              },
                              expression: "searchNumberStr",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("collectionCenter.MerchantNumBer"),
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("orderDetails.pleaseInput"),
                          clearable: "",
                          size: "small",
                        },
                        model: {
                          value: _vm.queryForm.params.merchantCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.queryForm.params,
                              "merchantCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.params.merchantCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("collectionCenter.MerchantN") } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("orderDetails.pleaseInput"),
                          clearable: "",
                          size: "small",
                        },
                        model: {
                          value: _vm.queryForm.params.merchantName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.queryForm.params,
                              "merchantName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.params.merchantName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 8 } }, [
                _c(
                  "div",
                  { staticStyle: { "margin-top": "30px" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          icon: "el-icon-search",
                          size: "small",
                          type: "primary",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getData()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("newOrder.search")) + " ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "20px" },
                        attrs: {
                          icon: "el-icon-delete",
                          size: "small",
                          type: "info",
                        },
                        on: { click: _vm.resetForm },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("orderCenterCont.clearCurrentContent")
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }