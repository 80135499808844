<template>
  <div class="app-container">
    <el-card class="box-card searchInputCon">
      <el-form :model="queryForm" label-position="top" size="small">
        <el-row :gutter="20">
          <!--创建时间-->
          <el-col :span="8">
            <el-form-item :label="$t('GlobalSetObj.CreationTime')">
              <el-date-picker
                v-model="creationTime"
                :default-time="['00:00:00', '23:59:59']"
                :end-placeholder="$t('operationCenter.endDate')"
                :format="$i18n.locale=='zh'?zhDate:ptDate"
                :range-separator="$t('operationCenter.to')"
                :start-placeholder="$t('operationCenter.startDate')"
                style="width: 100%"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
              />
            </el-form-item>
          </el-col>
          <!--下单供应商-->
          <el-col :span="8">
            <el-form-item :label="$t('GlobalSetObj.SupplierWhoPlacedOrder')">
              <el-select
                v-model="queryForm.params.providerId"
                :placeholder="$t('operationCenter.PleaseSelect')"
                clearable
                filterable
                style="width: 100%;"
              >
                <el-option
                  v-for="item in providerList"
                  :key="item.id"
                  :label="item.providerName + '（' + item.code + '）'"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <!--逆向运单号-->
          <el-col :span="8">
            <el-form-item :label="$t('GlobalSetObj.ReverseTrackingNumber')">
              <el-input
                v-model="waybillNumbers"
                :placeholder="$t('orderCenterCont.MultipleLineBreaks')"
                :rows="3"
                style="width: 100%"
                type="textarea"
              />
            </el-form-item>
          </el-col>
          <!--关联正向运单号-->
          <el-col :span="8">
            <el-form-item :label="$t('GlobalSetObj.AssociateForwardTrackingNumber')">
              <el-input
                v-model="forwardWaybillNumbers"
                :placeholder="$t('orderCenterCont.MultipleLineBreaks')"
                :rows="3"
                style="width: 100%"
                type="textarea"
              />
            </el-form-item>
          </el-col>
          <!--关联正向服务商单号-->
          <el-col :span="8">
            <el-form-item :label="$t('GlobalSetObj.AssociateTrackingNumber')">
              <el-input
                v-model="forwardLogisticsNumbers"
                :placeholder="$t('orderCenterCont.MultipleLineBreaks')"
                :rows="3"
                style="width: 100%"
                type="textarea"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <div style="margin-top: 10px;">
          <el-button
            icon="el-icon-search"
            size="small"
            type="primary"
            @click="searchResources(1)"
          >{{ $t('operationCenter.Procurar') }}
          </el-button>
          <el-button icon="el-icon-refresh-left" size="small" @click="searchResources(2)">
            {{ $t('operationCenter.reset') }}
          </el-button>
        </div>
      </el-form>
    </el-card>
    <div style="margin-top: 10px;">
      <div style="margin-bottom: 10px;">
        <el-button
          v-permit:remove="'btn:reverseOrders:home-export'"
          icon="el-icon-upload2"
          size="small"
          @click="exportOrder"
        >{{ $t('newOrder.export') }}
        </el-button>

        <el-button
          v-permit:remove="'btn:reverseOrders:DownloadFaceSheets'"
          size="small"
          @click="downloadTheMenu()"
        >
          {{ $t('newOrder.DownloadFaceSheets') }}
        </el-button>
      </div>
      <el-table
        ref="reverseOrders"
        :data="tableData"
        border
        row-key="id"
        @selection-change="selectionDataChange"
      >
        <el-table-column
          :reserve-selection="true"
          align="center"
          type="selection"
          width="50"
        />
        <el-table-column :label="$t('operationCenter.ind')" align="center" width="60px">
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, ind) in tableColumn"
          :key="ind"
          :label="item.label"
          :min-width="item.width"
          :prop="item.prop"
        >
          <template slot-scope="scope">
            <div v-if="scope.row[item.prop]">
              <div v-if="item.isCopy">
                <el-popover
                  placement="top"
                  popper-class="copy"
                  trigger="hover"
                >
                  <el-button
                    style="color: black;font-size: 12px;padding: 2px"
                    type="text"
                    @click="copy(scope.row[item.prop])"
                  >
                    {{ $t('orderDetails.copy') }}
                  </el-button>
                  <div slot="reference">
                    <div
                      v-if="item.highlight"
                      class="WaybillNumber"
                      @click="checkReverseDetail(scope.row)"
                    >
                      {{ scope.row[item.prop] }}
                    </div>
                    <div v-else>{{ scope.row[item.prop] }}</div>
                  </div>
                </el-popover>
              </div>
              <div v-else>
                <div v-if="item.prop === 'waybillStatus'">
                  {{ waybillStatus[scope.row[item.prop]].label }}
                </div>
                <div v-else>
                  {{ scope.row[item.prop] }}
                </div>
              </div>
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
      </el-table>
      <!--  分页  -->
      <el-pagination
        :current-page="queryForm.pageNumber"
        :page-size="queryForm.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 10px 0;float: right"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <!--运单详情-->
    <el-drawer
      :append-to-body="true"
      :title="$t('orderCenterCont.LogisticsOrders')"
      :visible.sync="showReverseOrder"
      custom-class="drawerClass"
      direction="ltr"
      size="90%"
    >
      <div style="height: calc(100vh - 67px); overflow: auto">
        <waybill-information :key="reverseOrderKey" :query-track-param="reverseOrderDetail" query-type="1" />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { copyData } from '@/utils/copyData';
import { apiReverseOrderWaybill, apiReverseWaybillExport } from '@/api/logisticsOrders';
import waybillInformation from '@/views/orderCenter/logisticsOrders/components/waybillInformation/waybillInformation';
import { batchPdfDownload } from '@/utils/PDF_util';
import { setDateTime2 } from '@/utils/date_util';
import { apiProviderPage } from '@/api/finance/basicData';
import { cloneDeep } from 'lodash-es';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';
import { saveAs } from 'file-saver';

export default {
  name: 'ReverseOrders',
  components: {
    waybillInformation
  },
  data() {
    return {
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          waybillNumbers: [],
          createStartTime: '',
          createEndTime: '',
          providerId: ''
        }
      },
      forwardWaybillNumbers: '',
      forwardLogisticsNumbers: '',
      waybillNumbers: '',
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      creationTime: this.getCurrentDate(),
      providerList: [],
      tableData: [],
      total: 0,
      tableColumn: [
        // 逆向运单号
        {
          label: this.$t('GlobalSetObj.ReverseTrackingNumber'),
          prop: 'waybillNumber',
          width: 200,
          isCopy: true,
          highlight: true
        },
        // 逆向订单创建时间
        { label: this.$t('GlobalSetObj.TheTimeWhenTheReverseOrderWasCreated'), prop: 'createTime', width: 160 },
        // 下单供应商
        { label: this.$t('GlobalSetObj.SupplierWhoPlacedOrder'), prop: 'providerName', width: 180 },
        // 下单网点
        { label: this.$t('GlobalSetObj.OrderOutlets'), prop: 'siteName', width: 180 },
        // 运单状态
        { label: this.$t('newOrder.waybillStatus'), prop: 'waybillStatus', width: 160 },
        // 关联正向运单号
        {
          label: this.$t('GlobalSetObj.AssociateForwardTrackingNumber'),
          prop: 'forwardWaybillNumber',
          width: 180,
          isCopy: true
        },
        // 关联正向服务商单号
        {
          label: this.$t('GlobalSetObj.AssociateTrackingNumber'),
          prop: 'forwardLogisticsNumber',
          width: 180,
          isCopy: true
        },
        // 正向运单创建时间
        { label: this.$t('GlobalSetObj.TheForwardOrderCreationTime'), prop: 'forwardCreateTime', width: 160 },
        // 服务商单号
        { label: this.$t('GlobalSetObj.serviceLogistics'), prop: 'logisticsNumber', width: 180, isCopy: true },
        // 客户订单号
        { label: this.$t('newOrder.orderNumber'), prop: 'customerNumber', width: 180, isCopy: true },
        // 最新轨迹时间
        { label: this.$t('newOrder.LatestTime'), prop: 'lastTrackTime', width: 160 },
        // 揽收袋号
        // { label: this.$t('GlobalSetObj.PickUpBagNumber'), prop: 'bagNumber', width: 180 },
        // 中转仓袋号
        { label: this.$t('orderCenterCont.TransitBinBagNumber'), prop: 'transitBagNumber', width: 180 },
        // 派送路线
        { label: this.$t('GlobalSetObj.DeliveryRoutes'), prop: 'pathNumber', width: 160 },
        // 退件原因
        { label: this.$t('GlobalSetObj.TheReasonForTheReturn'), prop: 'returnReason', width: 230 }
      ],
      showReverseOrder: false,
      reverseOrderKey: new Date().getTime(),
      reverseOrderDetail: null,
      selectedData: [],
      downloadArr: []
    };
  },
  computed: {
    waybillStatus() {
      return {
        1: { label: this.$i18n.t('newOrder.ToBeAssigned'), code: 1 }, // 待分配
        2: { label: this.$i18n.t('newOrder.ToBeCollected'), code: 2 }, // 待揽收
        3: { label: this.$i18n.t('newOrder.Collected'), code: 3 }, // 已揽收
        4: { label: this.$i18n.t('newOrder.CollectTheRevenuePool'), code: 4 }, // 揽收入库
        5: { label: this.$i18n.t('newOrder.CollectTakeOutWarehouse'), code: 5 }, // 揽收出库
        19: { label: this.$i18n.t('GlobalSetObj.moduan'), code: 19 }, // 末端已揽收
        6: { label: this.$i18n.t('newOrder.TransshipmentIntoStorage'), code: 6 }, // 转运入库
        7: { label: this.$i18n.t('newOrder.TransferOutWarehouse'), code: 7 }, // 转运出库
        8: { label: this.$i18n.t('newOrder.DeliveryOutletsIntoStorage'), code: 8 }, // 派送网点入库
        9: { label: this.$i18n.t('newOrder.Delivering'), code: 9 }, // 派送中
        10: { label: this.$i18n.t('newOrder.Signed'), code: 10 }, // 已签收
        11: { label: this.$i18n.t('newOrder.ProblemPiece'), code: 11 }, // 问题件
        12: { label: this.$i18n.t('collectionCenter.unclaimed'), code: 12 }, // 待取件
        13: { label: this.$i18n.t('GlobalSetObj.Canceled'), code: 13 }, // 已取消

        17: { label: this.$i18n.t('GlobalSetObj.DotBack'), code: 17 }, // 网点退回
        18: { label: this.$i18n.t('GlobalSetObj.ReturnToStorage'), code: 18 }, // 退仓入库
        15: { label: this.$i18n.t('GlobalSetObj.Withdrawn'), code: 15 }, // 退件中
        16: { label: this.$i18n.t('GlobalSetObj.TheReturnComplete'), code: 16 }, // 退件完成
        200: { label: '', code: 200 } // 问题件
      };
    }
  },
  created() {
    this.getVendorList('');
    this.getTableDataList();
  },
  methods: {
    // 下载面单
    downloadTheMenu() {
      const downloadArr = this.downloadArr;
      if (this.selectedData.length === 0) {
        this.$message.warning(this.$i18n.t('orderCenterCont.PleaseSelectTheWaybill')); // 请选择运单
        return;
      }
      if (this.selectedData.length > 200) {
        this.$message.warning(this.$i18n.t('orderCenterCont.TheNumberOfSelectedPolygonsExceedsTheMaximumLimit200')); // 选中面单数超出最大限制：200
        return;
      }
      // 手动显示加载中动画
      const loading = this.$loading({
        fullscreen: true,
        lock: true,
        spinner: 'el-icon-loading',
        text: this.$i18n.t('orderCenterCont.DesperatelyLoading'), // '拼命加载中',
        background: 'rgba(250, 250, 250, 0.6)'
      });
      batchPdfDownload(downloadArr).then((res) => {
        const dateType = setDateTime2();
        const saveName = `${dateType}` + this.$i18n.t('orderCenterCont.FaceSheet');
        saveAs(res, saveName);
        // const a = document.createElement('a');
        // a.download = `${dateType}`;
        // a.href = res;
        // a.download = `${dateType}` + this.$i18n.t('orderCenterCont.FaceSheet');
        // a.click();
        // a.remove();
      }).finally(() => {
        loading.close();
      });
    },
    // 列表数据勾选
    selectionDataChange(data) {
      this.selectedData = data;
      const newArray = cloneDeep(data);
      this.downloadArr = newArray.map((item) => {
        if (item.labelUrl === '' || item.labelUrl === null) {
          item.labelUrl = item.serviceLableUrl;
        }
        return item.labelUrl;
      });
    },
    // 查看详情
    checkReverseDetail(row) {
      const detail = cloneDeep(row);
      const obj = {
        id: detail.id,
        waybillNumber: detail.waybillNumber
      };
      this.reverseOrderDetail = obj;
      this.showReverseOrder = true;
      this.reverseOrderKey = new Date().getTime();
      setTimeout(() => {
        this.bus.$emit('todetailBox', obj.id);
      }, 100);
    },
    // 获取当前日期
    getCurrentDate() {
      const date = new Date();
      const brazilTime = date.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
      const today = dayjs(brazilTime).format('YYYY-MM-DD');
      return [today + ' 00:00:00', today + ' 23:59:59'];
    },
    // 获取供应商账号分页列表
    getVendorList(name) {
      const param = {
        pageSize: 99999999,
        pageNumber: 1,
        params: {
          providerId: name
        }
      };
      apiProviderPage(param, false).then(res => {
        if (res.status === 'OK') {
          this.providerList = res.data.records;
        }
      });
    },
    // 点击复制
    copy(data) {
      copyData(data);
    },
    // 搜索
    searchResources(type) {
      if (type === 1) {
        this.queryForm.pageNumber = 1;
        this.selectedData = [];
        this.downloadArr = [];
        this.$refs['reverseOrders'].clearSelection();
        this.getTableDataList();
      } else {
        this.queryForm = {
          pageSize: 10,
          pageNumber: 1,
          params: {
            waybillNumbers: [],
            createStartTime: '',
            createEndTime: '',
            providerId: '',
            forwardWaybillNumbers: [],
            forwardLogisticsNumbers: []
          }
        };
        this.waybillNumbers = '';
        this.forwardWaybillNumbers = '';
        this.forwardLogisticsNumbers = '';
        this.creationTime = this.getCurrentDate();
      }
    },
    // 导出
    exportOrder() {
      if (this.selectedData.length <= 0) {
        // '请勾选要导出的数据'
        this.$message.warning(this.$t('collectionCenter.PleaseTickTheDataExport'));
        return;
      }
      const ids = this.selectedData.map(item => {
        return item.id;
      });
      const param = {
        waybillIdList: ids,
        operator: this.$store.state.user.realName,
        operatorId: this.$store.state.user.id
      };
      apiReverseWaybillExport(param).then(res => {
        if (res.status === 'OK') {
          return goExportList(this);
        }
      }).then(res => {
        if (res) {
          this.showExport = false;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 获取逆向订单列表数据
    getTableDataList() {
      const param = {
        pageSize: this.queryForm.pageSize,
        pageNumber: this.queryForm.pageNumber,
        params: {
          waybillNumbers: [],
          forwardWaybillNumbers: [],
          forwardLogisticsNumbers: [],
          createStartTime: '',
          createEndTime: '',
          providerId: this.queryForm.params.providerId
        }
      };
      if (this.creationTime && this.creationTime.length > 0) {
        param.params.createStartTime = this.creationTime[0];
        param.params.createEndTime = this.creationTime[1];
      } else {
        param.params.createStartTime = '';
        param.params.createEndTime = '';
      }
      if (this.waybillNumbers) {
        const num = this.waybillNumbers.split(/([\n\r])/);
        num.map(item => {
          const reg = /\s/g;
          const a = item.replace(reg, '');
          const b = a.trim();
          if (b) {
            param.params.waybillNumbers.push(b);
          }
        });
      }
      if (this.forwardWaybillNumbers) {
        const num = this.forwardWaybillNumbers.split(/([\n\r])/);
        num.map(item => {
          const reg = /\s/g;
          const a = item.replace(reg, '');
          const b = a.trim();
          if (b) {
            param.params.forwardWaybillNumbers.push(b);
          }
        });
      }
      if (this.forwardLogisticsNumbers) {
        const num = this.forwardLogisticsNumbers.split(/([\n\r])/);
        num.map(item => {
          const reg = /\s/g;
          const a = item.replace(reg, '');
          const b = a.trim();
          if (b) {
            param.params.forwardLogisticsNumbers.push(b);
          }
        });
      }
      apiReverseOrderWaybill(param).then(res => {
        if (res.status === 'OK') {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getTableDataList();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.getTableDataList();
    }
  }
};
</script>

<style scoped>
.searchInputCon .el-form-item--mini.el-form-item,
.searchInputCon .el-form-item--small.el-form-item {
  margin-bottom: 5px;
}

.WaybillNumber {
  color: #409eff;
  cursor: pointer;
  padding: 0;
}

.app-container >>> .drawerClass header {
  border-bottom: 1px solid #ccc !important;
  margin-bottom: 0 !important;
  padding: 20px 20px 20px !important;
}
</style>
