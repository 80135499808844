var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "searchBox" },
        [
          _c("search-box", {
            key: _vm.searchKey,
            ref: "searchBox",
            attrs: { pagination: _vm.queryForm },
            on: { searchFun: _vm.searchFun },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dialog" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "btn:logisticsOrders:importOrderList",
                  expression: "'btn:logisticsOrders:importOrderList'",
                  arg: "remove",
                },
              ],
              attrs: { size: "small", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.importOrders()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("orderCenterCont.ImportOrdersBulk")) + " ")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "btn:logisticsOrders:home-export",
                  expression: "'btn:logisticsOrders:home-export'",
                  arg: "remove",
                },
              ],
              attrs: { size: "small", type: "success" },
              on: { click: _vm.exportOrder },
            },
            [_vm._v(_vm._s(_vm.$t("collectionCenter.export1")) + " ")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "btn:logisticsOrders:home-export",
                  expression: "'btn:logisticsOrders:home-export'",
                  arg: "remove",
                },
              ],
              attrs: {
                icon: "el-icon-upload2",
                size: "small",
                type: "success",
              },
              on: { click: _vm.allExportOrder },
            },
            [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")) + " ")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "btn:logisticsOrders:home-export",
                  expression: "'btn:logisticsOrders:home-export'",
                  arg: "remove",
                },
              ],
              attrs: { size: "small", type: "success" },
              on: { click: _vm.orderNumberExport },
            },
            [_vm._v(_vm._s(_vm.$t("placeOrder.BatchExport")) + " ")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "btn:logisticsOrders:home-export",
                  expression: "'btn:logisticsOrders:home-export'",
                  arg: "remove",
                },
              ],
              attrs: { size: "small", type: "success" },
              on: { click: _vm.exportOrderChilder },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("collectionCenter.exportChilderInfor")) + " "
              ),
            ]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "btn:logisticsOrders:printIATA",
                  expression: "'btn:logisticsOrders:printIATA'",
                  arg: "remove",
                },
              ],
              attrs: { size: "small" },
              on: { click: _vm.printIATA },
            },
            [_vm._v(_vm._s(_vm.$t("orderCenterCont.printIATA")) + " ")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: { click: _vm.orderDistruProvider },
            },
            [_vm._v(_vm._s(_vm.$t("navbar.orderDistruProvider")) + " ")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { float: "right" },
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.operationTableDialog },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("orderCenterCont.customizingDisplayColumns")) +
                  " "
              ),
            ]
          ),
          _c(
            "el-popover",
            { attrs: { placement: "top", trigger: "hover" } },
            [
              _c(
                "div",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value: "btn:logisticsOrders:problemObjLanjie",
                          expression: "'btn:logisticsOrders:problemObjLanjie'",
                          arg: "remove",
                        },
                      ],
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.problemInterceptor },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("customerManagements.problemObjLanjie")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { slot: "reference", size: "small", type: "primary" },
                  slot: "reference",
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("orderCenterCont.moreActions")) + " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "btn:logisticsOrders:distribution-dot",
                  expression: "'btn:logisticsOrders:distribution-dot'",
                  arg: "remove",
                },
              ],
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.dotClick },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("orderCenterCont.RedistributionOfOutlets")) +
                  " "
              ),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.downloadTheMenu()
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("orderCenterCont.downloadExpressLabels")) +
                  " "
              ),
            ]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "btn:logisticsOrders:UpdateTheFaceSheet",
                  expression: "'btn:logisticsOrders:UpdateTheFaceSheet'",
                  arg: "remove",
                },
              ],
              attrs: { size: "small", type: "warning" },
              on: { click: _vm.updateFaceSheet },
            },
            [_vm._v(" " + _vm._s(_vm.$t("newOrder.UpdateNet")) + " ")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "btn:logisticsOrders:cancelOrder",
                  expression: "'btn:logisticsOrders:cancelOrder'",
                  arg: "remove",
                },
              ],
              attrs: { size: "small", type: "warning" },
              on: {
                click: function ($event) {
                  return _vm.cancelOrder()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("collectionCenter.cancelOrder")) + " ")]
          ),
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.changeOutlets } },
            [
              _vm._v(
                _vm._s(_vm.$t("GlobalSetObj.ModifyPreDeliveryLocation")) + " "
              ),
            ]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "btn:logisticsOrders:recipientAddress",
                  expression: "'btn:logisticsOrders:recipientAddress'",
                  arg: "remove",
                },
              ],
              attrs: { size: "small" },
              on: { click: _vm.recipientAddress },
            },
            [_vm._v(" " + _vm._s(_vm.$t("GlobalSetObj.fixaddress")) + " ")]
          ),
        ],
        1
      ),
      _c("DistributionDot", {
        attrs: {
          "anjun-table": _vm.$refs["anjunTable"],
          "dot-list": _vm.dotList,
          netdot: _vm.ShowDistributionDot,
          "selection-list": _vm.selectionList,
        },
        on: { closeDot: _vm.closeDot, getSearchFun: _vm.getSearchFun },
      }),
      _c("AnjunTableList", {
        ref: "anjunTable",
        attrs: {
          "format-time": _vm.isTormat,
          "has-operation": true,
          "header-list": _vm.tableHeaderList,
          hide: false,
          "is-index": true,
          "is-select-btn": true,
          "is-tip": true,
          "table-data": _vm.tableData,
        },
        on: {
          selectTable: _vm.selectTable,
          "on-checkTrajectory": _vm.trajectoryQuery,
          "to-OrderDetail": _vm.toOrderDetail,
          "on-editWaybillRemark": _vm.editWaybillRemark,
        },
      }),
      _c("el-pagination", {
        staticStyle: { margin: "20px 0", "text-align": "right" },
        attrs: {
          "current-page": _vm.queryForm.number,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [10, 50, 100, 200, 400],
          total: _vm.queryForm.total,
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
        },
        on: {
          "update:currentPage": function ($event) {
            return _vm.$set(_vm.queryForm, "number", $event)
          },
          "update:current-page": function ($event) {
            return _vm.$set(_vm.queryForm, "number", $event)
          },
          "update:pageSize": function ($event) {
            return _vm.$set(_vm.queryForm, "pageSize", $event)
          },
          "update:page-size": function ($event) {
            return _vm.$set(_vm.queryForm, "pageSize", $event)
          },
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c(
        "el-drawer",
        {
          attrs: {
            "append-to-body": true,
            title: _vm.$t("orderCenterCont.TrajectoryQuery"),
            visible: _vm.showQueryTrack.visible,
            "custom-class": "drawerClass",
            direction: "ltr",
            size: "80%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.showQueryTrack, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { height: "calc(100vh - 67px)", overflow: "auto" } },
            [
              _c("trackQuery", {
                key: _vm.showQueryTrack.trackKey,
                attrs: {
                  "query-track-param": _vm.queryTrackParam,
                  "query-type": "1",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            "append-to-body": true,
            title: _vm.$t("orderCenterCont.LogisticsOrders"),
            visible: _vm.waybillDescription.visible,
            "custom-class": "drawerClass",
            direction: "ltr",
            size: "90%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.waybillDescription, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { height: "calc(100vh - 67px)", overflow: "auto" } },
            [
              _c("waybill-information", {
                key: _vm.showQueryTrack.trackKey,
                attrs: {
                  "query-track-param": _vm.TrackParam,
                  "query-type": "1",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            visible: _vm.isoperationTable,
            width: "" + (_vm.$i18n.locale === "zh" ? "30%" : "45%"),
          },
          on: {
            "update:visible": function ($event) {
              _vm.isoperationTable = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "tableHeaderForm",
              attrs: { model: _vm.tableHeaderForm, "label-width": "auto" },
            },
            [
              _c("el-form-item", { attrs: { label: "" } }, [
                _c("div", { staticStyle: { "font-size": "18px" } }, [
                  _vm._v(_vm._s(_vm.$t("GlobalSetObj.HideTheColumn"))),
                ]),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.tableHeaderForm.tableHeaderList,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableHeaderForm, "tableHeaderList", $$v)
                        },
                        expression: "tableHeaderForm.tableHeaderList",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { display: "flex", "flex-flow": "wrap" },
                          attrs: { gutter: 0 },
                        },
                        _vm._l(_vm.bindTableColumns, function (item) {
                          return _c(
                            "el-col",
                            { key: item.id, attrs: { span: 8 } },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "140px",
                                    "word-wrap": "break-word",
                                    "word-break": "break-all",
                                  },
                                },
                                [
                                  _c("el-checkbox", {
                                    attrs: { label: item.label },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { float: "left" },
                  on: { click: _vm.resetTable },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.reset")))]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.isoperationTable = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.operationTable },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("newOrder.registrationOfProblemPieces"),
            visible: _vm.showProblemPiece,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showProblemPiece = $event
            },
            close: _vm.problemPieceClose,
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c(
              "div",
              { staticStyle: { "font-weight": "bold", "font-size": "20px" } },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("newOrder.registrationOfProblemPieces")) +
                    " "
                ),
              ]
            ),
          ]),
          _c(
            "div",
            { staticStyle: { padding: "20px" } },
            [
              _c(
                "el-form",
                {
                  key: _vm.showProblemPiece,
                  ref: "problemPiece",
                  attrs: {
                    model: _vm.problemPieceForm,
                    "label-position": "top",
                    "label-width": "auto",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("newOrder.TheCauseOfTheProblem"),
                        rules: {
                          required: true,
                          message: _vm.$t("newOrder.PleaseSelectReasonProblem"),
                          trigger: ["blur", "change"],
                        },
                        prop: "dictId",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("orderDetails.pleaseChoose"),
                            filterable: "",
                          },
                          model: {
                            value: _vm.problemPieceForm.dictId,
                            callback: function ($$v) {
                              _vm.$set(_vm.problemPieceForm, "dictId", $$v)
                            },
                            expression: "problemPieceForm.dictId",
                          },
                        },
                        _vm._l(_vm.problemReasonOption, function (item, ind) {
                          return _c("el-option", {
                            key: ind,
                            attrs: {
                              label: item.dictDescribe,
                              value: item.dictId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("newOrder.problemPieceRemarks"),
                        prop: "problemRemark",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t(
                            "newOrder.PleaseEnterDescriptionPortuguese"
                          ),
                          rows: 4,
                          maxlength: "255",
                          "show-word-limit": "",
                          type: "textarea",
                        },
                        model: {
                          value: _vm.problemPieceForm.problemRemark,
                          callback: function ($$v) {
                            _vm.$set(_vm.problemPieceForm, "problemRemark", $$v)
                          },
                          expression: "problemPieceForm.problemRemark",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("newOrder.waybillNumber"),
                        rules: {
                          required: true,
                          message: _vm.$t("newOrder.PleaseEnterWaybillNumber"),
                          trigger: ["blur", "change"],
                        },
                        prop: "waybillNumbers",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t(
                            "newOrder.EachLineHasOneSingleNumber"
                          ),
                          rows: 8,
                          type: "textarea",
                        },
                        model: {
                          value: _vm.problemPieceForm.waybillNumbers,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.problemPieceForm,
                              "waybillNumbers",
                              $$v
                            )
                          },
                          expression: "problemPieceForm.waybillNumbers",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showProblemPiece = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.saveProblemPiece },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Salve")) + " ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("newOrder.registrationOfProblemPieces"),
            visible: _vm.showProblemPieceTips,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showProblemPieceTips = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c(
              "div",
              { staticStyle: { "font-weight": "bold", "font-size": "20px" } },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("newOrder.registrationOfProblemPieces")) +
                    " "
                ),
              ]
            ),
          ]),
          _c("div", { staticStyle: { padding: "20px" } }, [
            _c("div", [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    background: "#dadada",
                    "border-radius": "20px",
                    height: "10px",
                  },
                },
                [
                  _c("div", {
                    staticStyle: {
                      background: "#42b983",
                      height: "100%",
                      "border-radius": "20px",
                    },
                    style: { width: _vm.widthProgress + "%" },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "text-align": "right",
                    color: "#13adf0",
                    "font-size": "13px",
                    "margin-top": "10px",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.widthProgress < 100
                          ? _vm.$t("newOrder.processing")
                          : _vm.$t("newOrder.ProcessingComplete")
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
            _vm.widthProgress === 100
              ? _c("div", [
                  _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
                    _c("span", [
                      _vm._v(
                        " " + _vm._s(_vm.$t("operationCenter.succeed")) + "："
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#42b983",
                            "margin-right": "30px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.problemPieceReturn.successNumber))]
                      ),
                      _vm._v(
                        " " + _vm._s(_vm.$t("operationCenter.fail")) + "："
                      ),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(_vm._s(_vm.problemPieceReturn.failureNumber)),
                      ]),
                    ]),
                  ]),
                  _vm.problemPieceReturn.list &&
                  _vm.problemPieceReturn.list.length > 0
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            border: "1px solid #ccc",
                            padding: "10px",
                            "max-height": "170px",
                            "overflow-y": "auto",
                          },
                        },
                        _vm._l(
                          _vm.problemPieceReturn.list,
                          function (item, ind) {
                            return _c(
                              "div",
                              {
                                key: ind,
                                staticStyle: { "margin-bottom": "10px" },
                              },
                              [_vm._v(" " + _vm._s(item) + " ")]
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showProblemPieceTips = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.close")) + " ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("orderCenterCont.ImportOrdersBulk"),
            visible: _vm.showImportOrder,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImportOrder = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("import-orders", {
                key: _vm.showImportOrder,
                attrs: { "customer-list": _vm.customerList },
                on: { importOrder: _vm.importOrder },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("orderExport.hint"),
            visible: _vm.showFaceSheets,
            width: "450px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showFaceSheets = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c("div", [
            _c("p", [
              _vm._v(
                _vm._s(_vm.$t("GlobalSetObj.TheOperationSucceeded")) + "!"
              ),
            ]),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-between",
                },
              },
              [
                _c("div", [
                  _vm._v(
                    _vm._s(_vm.$t("GlobalSetObj.all")) +
                      ": " +
                      _vm._s(_vm.totalFaceSheets)
                  ),
                ]),
                _c("div", [
                  _vm._v(
                    _vm._s(_vm.$t("newOrder.TheUpdateWasSuccessful")) +
                      ": " +
                      _vm._s(_vm.successful)
                  ),
                ]),
                _c("div", [
                  _vm._v(
                    _vm._s(_vm.$t("newOrder.TheUpdateFailed")) +
                      ": " +
                      _vm._s(_vm.failOrder)
                  ),
                ]),
              ]
            ),
            _vm.errList.length
              ? _c("div", [
                  _c("div", { staticStyle: { margin: "30px 0 10px 0" } }, [
                    _vm._v(_vm._s(_vm.$t("newOrder.TheUpdateFailed")) + "："),
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        border: "1px solid #ccc",
                        "border-radius": "3px",
                        padding: "10px",
                        "max-height": "260px",
                        overflow: "auto",
                      },
                    },
                    _vm._l(_vm.errList, function (item, ind) {
                      return _c(
                        "div",
                        { key: ind, staticStyle: { margin: "5px 0" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(item.waybillNumber) +
                              "：" +
                              _vm._s(item.failReason) +
                              " "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticStyle: { "margin-top": "40px", "text-align": "center" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.printedSheet },
                  },
                  [_vm._v(_vm._s(_vm.$t("GlobalSetObj.PrintedSheet")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.checkedOrder()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("orderCenterCont.ViewDetails")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.showFaceSheets = false
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("orderCenterCont.ShutDown")) + " ")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("clearOrder", {
        ref: "clearOrder",
        attrs: {
          "customer-list": _vm.customerList,
          "selection-list": _vm.selectionList,
        },
        on: { getSearchFun: _vm.getSearchFun },
      }),
      _c("orderNumberExport", { ref: "OrderExport" }),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("GlobalSetObj.ModifyPreDeliveryLocation"),
            visible: _vm.showChangeOutlets,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showChangeOutlets = $event
            },
          },
        },
        [
          _c("modifyPreDeliveryLocation", {
            key: _vm.showChangeOutlets,
            attrs: {
              "selection-list": _vm.selectionList,
              "site-id-list": _vm.siteIdList,
            },
            on: { changeOutletsDataClose: _vm.changeOutletsDataClose },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("GlobalSetObj.remark"),
            visible: _vm.showOrderNote,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showOrderNote = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "remarkBox" },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("collectionCenter.pleaseInput"),
                  maxlength: "200",
                  "show-word-limit": "",
                  type: "textarea",
                },
                model: {
                  value: _vm.orForm.waybillRemark,
                  callback: function ($$v) {
                    _vm.$set(_vm.orForm, "waybillRemark", $$v)
                  },
                  expression: "orForm.waybillRemark",
                },
              }),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.orSubmit } },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "show-close": true,
            title: _vm.$t("GlobalSetObj.fixaddress"),
            visible: _vm.fixAddress,
            top: "10vh",
            width: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.fixAddress = $event
            },
            close: _vm.clearForm,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "receviceRuleForm",
              staticClass: "demo-receviceRuleForm",
              attrs: {
                model: _vm.receviceRuleForm,
                inline: "",
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 50 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: _vm.$t("newOrder.Street"),
                            prop: "receiveStreet",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "newOrder.ConfirmStreetNumber"
                              ),
                              clearable: "",
                              maxlength: "50",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.receviceRuleForm.receiveStreet,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.receviceRuleForm,
                                  "receiveStreet",
                                  $$v
                                )
                              },
                              expression: "receviceRuleForm.receiveStreet",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: _vm.$t("placeOrder.HouseNumber"),
                            rules: _vm.checkStr5,
                            prop: "receiveHouseNumber",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("placeOrder.pleaseInput"),
                              clearable: "",
                              maxlength: "6",
                            },
                            model: {
                              value: _vm.receviceRuleForm.receiveHouseNumber,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.receviceRuleForm,
                                  "receiveHouseNumber",
                                  $$v
                                )
                              },
                              expression: "receviceRuleForm.receiveHouseNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: _vm.$t("placeOrder.Area"),
                            rules: _vm.addressComplement,
                            prop: "receiveArea",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("placeOrder.pleaseInput"),
                              clearable: "",
                              maxlength: "32",
                            },
                            model: {
                              value: _vm.receviceRuleForm.receiveArea,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.receviceRuleForm,
                                  "receiveArea",
                                  $$v
                                )
                              },
                              expression: "receviceRuleForm.receiveArea",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 50 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: _vm.$t("placeOrder.addressComplement"),
                            rules: _vm.addressComplement,
                            prop: "receiveComplement",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("placeOrder.pleaseInput"),
                              clearable: "",
                              maxlength: "32",
                            },
                            model: {
                              value: _vm.receviceRuleForm.receiveComplement,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.receviceRuleForm,
                                  "receiveComplement",
                                  $$v
                                )
                              },
                              expression: "receviceRuleForm.receiveComplement",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: _vm.$t("placeOrder.city"),
                            rules: _vm.cityComplement,
                            prop: "receiveCity",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("placeOrder.pleaseInput"),
                              clearable: "",
                              maxlength: "32",
                            },
                            model: {
                              value: _vm.receviceRuleForm.receiveCity,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.receviceRuleForm,
                                  "receiveCity",
                                  $$v
                                )
                              },
                              expression: "receviceRuleForm.receiveCity",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: _vm.$t("placeOrder.State"),
                            rules: {
                              required: true,
                              message: _vm.$t("placeOrder.pleaseInput"),
                              trigger: ["blur", "change"],
                            },
                            prop: "receiveState",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t("placeOrder.pleaseInput"),
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.receviceRuleForm.receiveState,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.receviceRuleForm,
                                    "receiveState",
                                    $$v
                                  )
                                },
                                expression: "receviceRuleForm.receiveState",
                              },
                            },
                            _vm._l(_vm.senderStateList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.label, value: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: _vm.$t("placeOrder.zipcode"),
                            rules: _vm.isZipCode,
                            prop: "receiveZipcode",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("placeOrder.pleaseInput"),
                              clearable: "",
                            },
                            model: {
                              value: _vm.receviceRuleForm.receiveZipcode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.receviceRuleForm,
                                  "receiveZipcode",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "receviceRuleForm.receiveZipcode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: _vm.$t("placeOrder.detailedAddress"),
                            rules: _vm.isReceiveAddress,
                            prop: "receiveAddress",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("placeOrder.pleaseInput"),
                              clearable: "",
                            },
                            model: {
                              value: _vm.receviceRuleForm.receiveAddress,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.receviceRuleForm,
                                  "receiveAddress",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "receviceRuleForm.receiveAddress",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.fixAddress = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.comfireFixAddress },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.determine")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("GlobalSetObj.tips"),
            visible: _vm.tipDialog,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.tipDialog = $event
            },
          },
        },
        [
          _c(
            "el-row",
            {
              staticStyle: { "font-size": "16px !important" },
              attrs: { gutter: 10 },
            },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _vm._v(_vm._s(_vm.$t("GlobalSetObj.fixTotal")) + ":"),
                _c("span", { staticClass: "tipclass" }, [
                  _vm._v(_vm._s(_vm.tipDialogData.updateTotal)),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _vm._v(
                  _vm._s(_vm.$t("GlobalSetObj.ModificationSuccessful")) + ":"
                ),
                _c("span", { staticClass: "tipclass" }, [
                  _vm._v(_vm._s(_vm.tipDialogData.successTotal)),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _vm._v(_vm._s(_vm.$t("GlobalSetObj.failTotal")) + ":"),
                _c(
                  "span",
                  { staticClass: "tipclass", staticStyle: { color: "red" } },
                  [_vm._v(_vm._s(_vm.tipDialogData.failTotal))]
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                "margin-top": "20px",
                "font-size": "16px",
                "line-height": "30px",
                height: "200px",
                "overflow-y": "auto",
              },
            },
            [
              _vm.tipDialogData.failTotal
                ? _c(
                    "div",
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.$t("GlobalSetObj.notFix")) + ":"),
                      ]),
                      _vm._l(
                        _vm.tipDialogData.errorList,
                        function (item, index) {
                          return _c("div", { key: index }, [
                            _c("span", [_vm._v(_vm._s(index + 1) + ".")]),
                            _c("span", [_vm._v(_vm._s(item.waybillNumber))]),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "10px" } },
                              [_vm._v(_vm._s(item.errorMessage))]
                            ),
                          ])
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", margin: "15px 0" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.tipDialog = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.determine")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { display: "none" } },
        [_c("pdf", { ref: "pdfPrint", attrs: { src: _vm.viewUrl } })],
        1
      ),
      _c("div", { staticStyle: { display: "none" } }, [
        _c(
          "table",
          {
            staticStyle: { "text-align": "center", width: "100%" },
            attrs: { id: "printpreDeliverNameList" },
          },
          _vm._l(_vm.preDeliverNameList, function (item, index) {
            return _c(
              "tr",
              { key: index, staticStyle: { "text-align": "center" } },
              [
                _c(
                  "td",
                  {
                    staticStyle: {
                      "text-align": "center",
                      "font-size": "30px",
                      "line-height": "40px",
                    },
                  },
                  [_vm._v(_vm._s(item))]
                ),
              ]
            )
          }),
          0
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.$t("customerManagements.problemObjLanjie"),
            visible: _vm.tipDialog1,
            width: "620px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.tipDialog1 = $event
            },
          },
        },
        [
          _c(
            "el-row",
            {
              staticStyle: { "font-size": "16px !important" },
              attrs: { gutter: 10 },
            },
            [
              _c("el-col", { attrs: { span: 4 } }, [
                _vm._v(_vm._s(_vm.$t("GlobalSetObj.all")) + ":"),
                _c("span", { staticClass: "tipclass" }, [
                  _vm._v(_vm._s(_vm.problemObj.totalCount)),
                ]),
              ]),
              _c("el-col", { attrs: { span: 10 } }, [
                _vm._v(
                  _vm._s(_vm.$t("customerManagements.ReleaseSuccess")) + ":"
                ),
                _c("span", { staticClass: "tipclass" }, [
                  _vm._v(_vm._s(_vm.problemObj.successCount)),
                ]),
              ]),
              _c("el-col", { attrs: { span: 10 } }, [
                _vm._v(_vm._s(_vm.$t("customerManagements.ReleaseFail")) + ":"),
                _c(
                  "span",
                  { staticClass: "tipclass", staticStyle: { color: "red" } },
                  [_vm._v(_vm._s(_vm.problemObj.failCount))]
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                "margin-top": "20px",
                "font-size": "16px",
                "line-height": "30px",
                height: "200px",
                "overflow-y": "auto",
              },
            },
            [
              _vm.problemObj.failCount
                ? _c(
                    "div",
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.$t("GlobalSetObj.ReasonForFailure")) + ":"
                        ),
                      ]),
                      _vm._l(
                        _vm.problemObj.failReasonList,
                        function (item, index) {
                          return _c("div", { key: index }, [
                            _c("span", [_vm._v(_vm._s(index + 1) + ".")]),
                            _c("span", [_vm._v(_vm._s(item.waybillNumber))]),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "10px" } },
                              [_vm._v(_vm._s(item.reason))]
                            ),
                          ])
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", margin: "15px 0" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.tipDialog1 = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.determine")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }