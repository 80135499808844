<template>
  <div>
    <el-button size="small" @click="open">
      {{ $t('receivablePayable.RecalculateThePrice') }}
    </el-button>

    <!-- 选择弹出窗 -->
    <el-dialog
      :title="title"
      :visible.sync="isShow"
      width="600px"
    >
      <el-form
        ref="ruleForm" :model="ruleForm" :rules="rules" size="small"
        class="demo-ruleForm" label-position="top"
      >
        <el-form-item :label="$t('basicData.BusinessTime')" prop="time">
          <el-date-picker
            v-model="ruleForm.time"
            style="width: 100%"
            type="daterange"
            :picker-options="pickerOptions"
            range-separator="-"
            :start-placeholder="$t('operationCenter.startDate')"
            :end-placeholder="$t('operationCenter.endDate')"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>

        <el-form-item :label="$t('basicData.Outlets')" prop="siteIds">
          <el-select
            v-model="ruleForm.siteIds"
            :placeholder="$t('operationCenter.PleaseSelect')"
            style="width: 100%;"
            filterable
            multiple
            remote
            :multiple-limit="10"
            :remote-method="getSitePageList"
            :loading="isLoading"
          >
            <el-option
              v-for="item in outletsList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="isShow = false">{{ $t('basicData.Cancel') }}</el-button>
        <el-button size="small" type="primary" @click="submitForm('ruleForm')">{{ $t('basicData.determine') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { setDisabledDate } from '@/views/finance/basicData/PriceInquiryLog/utils.js';
import { apiCommonSite } from '@/api/logisticsOrders';
import dayjs from 'dayjs';
import i18n from '@/lang';

const str1 = i18n.t('basicData.上门揽收(第三方)重算(上限10w单)'); // 第三方收款
const str2 = i18n.t('basicData.揽收安骏小屋重算(上限10w单)'); // 安俊
const str3 = i18n.t('receivablePayable.SendAJDRecalculation'); // AJD
const str4 = i18n.t('basicData.DSPDispatch'); // DSP

const parentQuery = {
  siteIds: [], // 网点集合
  startTime: '', // 开始时间
  endTime: '' // 结束时间
};

const PARAM = {
  pageNumber: 1,
  pageSize: 10,
  params: {
    // siteStatus: undefined,
    // ajSiteType: 1, // 网点类型 1不为小屋,2不为网点,3不为转运中心,4不为总部
    // businessModel: 2, // 经营模式(1为直营,2为加盟)
    name: ''
  }
};

// 设置默认时间
const firstDay = dayjs(Date.now() - 6 * 24 * 60 * 60 * 1000).format('YYYY-MM-DD');
const lastDay = dayjs().format('YYYY-MM-DD');
export default {
  name: '',

  props: {
    'payableType': {
      type: Number,
      default: ''
    }
  },

  data() {
    return {
      title: '',
      isShow: false,
      ruleForm: {
        siteIds: [], // 网点集合
        time: []
      },
      isLoading: false,
      outletsList: [], // 网点集合

      choiceDate0: '',

      rules: {
        time: [
          { required: true, message: this.$t('operationCenter.PleaseSelect'), trigger: 'change' }
        ],
        siteIds: [
          { required: true, message: this.$t('operationCenter.PleaseSelect'), trigger: 'change' }
        ]
      },

      // 时间跨度限制
      pickerOptions: {
        // 设置不能选择的日期
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate0 = minDate.getTime();
          if (maxDate) {
            this.choiceDate0 = '';
          }
        },
        disabledDate: (time) => {
          // 最大7天.
          return setDisabledDate(time, this.choiceDate0, 6);
        }
      },

      isRecalculate: false // 重新计算弹窗
    };
  },

  watch: {
    isShow(val) {
      if (!val) {
        this.$refs.ruleForm.clearValidate()
      }
    }
  },
  methods: {
    // 获取网点
    getSitePageList(name = '') {
      PARAM.params.name = name;
      this.isLoading = true;
      this.outletsList = [];
      apiCommonSite(PARAM, false).then(res => {
        const { records } = res.data;
        this.outletsList = (records || []).map(item => {
          const { id, name, siteType } = item;
          const obj = {
            value: id,
            label: name,
            siteType
          };
          return Object.freeze(obj);
        });
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.isLoading = false;
      });
    },

    // 打开
    open() {
      this.isShow = true;
      this.getSitePageList();
      
      // 初始化数据
      this.ruleForm.siteIds = [];
      this.ruleForm.time = [firstDay, lastDay];

      this.setTile();
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate();
      });
    },

    setTile() {
      this.title = (() => {
        switch (this.payableType) {
          case 1:
            return str2;
          case 2:
            return str1;
          case 3:
            return str3;
          default:
            return str4;
        }
      })();
    },

    // 设置数据格式
    setData() {
      const { siteIds, time } = this.ruleForm;

      return {
        siteIds,
        startDate: time[0] + ' 00:00:00',
        endDate: time[1] + ' 23:59:59'
      };
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = this.setData();
          this.$emit('submit', data);
          this.isShow = false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
