var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Box" },
    [
      _c("div", { staticClass: "topTitle t1" }, [
        _vm._v(_vm._s(_vm.$t("collectionCenter.BagManage"))),
      ]),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.totalTableData,
            "empty-text": _vm.$t("collectionCenter.nullMsg"),
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "type", label: _vm.$t("collectionCenter.Type") },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "sum",
              label: _vm.$t("collectionCenter.numberPackages"),
            },
          }),
          _vm.isSealingBags
            ? _c("el-table-column", {
                attrs: { width: "80", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "bagClass",
                              on: {
                                click: function ($event) {
                                  return _vm.openSeal(scope.row, scope.$index)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("singleScan.closeBag")))]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  524482117
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "btw bor-mar" },
        [
          _c("div", { staticClass: "topTitle t2" }, [
            _vm._v(_vm._s(_vm.$t("collectionCenter.packageCollection"))),
          ]),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "btn:collection-center:single-scan:signature",
                  expression: "'btn:collection-center:single-scan:signature'",
                  arg: "remove",
                },
              ],
              attrs: { type: "success" },
              on: { click: _vm.openSign },
            },
            [_vm._v(_vm._s(_vm.$t("collectionCenter.confirmPackage")))]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "scanBox" }, [
        _c(
          "div",
          { staticClass: "text-left tl1" },
          [
            _c("span", [
              _vm._v(_vm._s(_vm.$t("collectionCenter.orderNumber"))),
            ]),
            _c("el-input", {
              attrs: {
                clearable: "",
                placeholder: _vm.$t("collectionCenter.PleaseScan"),
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.addTable($event)
                },
              },
              model: {
                value: _vm.packageNumber,
                callback: function ($$v) {
                  _vm.packageNumber = $$v
                },
                expression: "packageNumber",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "text-left tl2" },
          [
            _c("span", [_vm._v(_vm._s(_vm.$t("collectionCenter.scanCount")))]),
            _c("el-input", {
              attrs: { disabled: true },
              model: {
                value: _vm.tableData.length,
                callback: function ($$v) {
                  _vm.$set(_vm.tableData, "length", $$v)
                },
                expression: "tableData.length",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "empty-text": _vm.$t("collectionCenter.nullMsg"),
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "num",
              label: _vm.$t("singleScan.numberPackage"),
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "type",
              label: _vm.$t("singleScan.bag"),
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "merchantName",
              label: _vm.$t("collectionCenter.CommercialOwner"),
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "date",
              label: _vm.$t("collectionCenter.scanTime"),
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: { width: "80", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-popconfirm",
                      {
                        attrs: { title: _vm.$t("basicData.AreYouSureDelete") },
                        on: {
                          confirm: function ($event) {
                            return _vm.deleteRow(scope.row, scope.$index)
                          },
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            slot: "reference",
                            type: "danger",
                            icon: "el-icon-delete",
                            circle: "",
                          },
                          slot: "reference",
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.signVisible
        ? _c("div", { staticClass: "signMask" }, [
            _c("div", { staticClass: "signVisibleClass" }, [
              _c("div", {
                staticClass: "el-icon-close signClose",
                on: { click: _vm.signCloseClick },
              }),
              _c("div", { staticClass: "descriptionsClass" }, [
                _c(
                  "div",
                  {
                    ref: "image",
                    staticStyle: { border: "1px solid #a8a9ad" },
                  },
                  [
                    _c("div", { staticClass: "logo-box" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("collectionCenter.LSSite")) +
                            _vm._s(_vm.scanList.signDot)
                        ),
                      ]),
                      _c("div", { staticClass: "logo-img" }, [
                        _c("img", { attrs: { src: _vm.LOGO, alt: "" } }),
                      ]),
                    ]),
                    _c("div", { staticClass: "desTitle" }),
                    _c(
                      "el-descriptions",
                      {
                        staticClass: "margin-top",
                        attrs: {
                          column: 1,
                          border: "",
                          "content-style": _vm.rowClass,
                          "label-style": _vm.labelRowClass,
                        },
                      },
                      [
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "text-align": "center",
                                    display: "block",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("collectionCenter.LanShouPerson")
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" " + _vm._s(_vm.scanList.signUser) + " "),
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "text-align": "center",
                                    display: "block",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("collectionCenter.lanshouTime")
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" " + _vm._s(_vm.scanList.signTime) + " "),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "desTitle" }, [
                      _vm._v(_vm._s(_vm.$t("collectionCenter.lanshouMsg"))),
                    ]),
                    _c(
                      "el-descriptions",
                      {
                        staticClass: "margin-top",
                        attrs: {
                          column: 1,
                          border: "",
                          "content-style": _vm.rowClass,
                          "label-style": _vm.labelRowClass,
                        },
                      },
                      [
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "text-align": "center",
                                    display: "block",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("collectionCenter.merchant")
                                    ) + "："
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.scanList.merchantCode) +
                                " / " +
                                _vm._s(_vm.scanList.merchantName) +
                                " "
                            ),
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "text-align": "center",
                                    display: "block",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("collectionCenter.LSSum"))
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" " + _vm._s(_vm.scanList.signSum) + " "),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "desTitle" }, [
                      _vm._v(
                        _vm._s(_vm.$t("collectionCenter.customerSignature"))
                      ),
                    ]),
                    _c("div", { staticClass: "page-content" }, [
                      _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _c("vue-esign", {
                            ref: "esignImg",
                            attrs: {
                              width: 500,
                              height: 300,
                              "line-width": 5,
                              "line-color": "#000",
                              "bg-color": "#FFF",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { type: "danger" },
                        on: { click: _vm.handleReset },
                      },
                      [_vm._v(_vm._s(_vm.$t("collectionCenter.empty")))]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { type: "success" },
                        on: { click: _vm.handleGenerate },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("collectionCenter.submitSignature"))
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.isLoading ? _c("div", { staticClass: "loadingClass" }) : _vm._e(),
      _vm.sealingVisible
        ? _c(
            "div",
            { staticClass: "sealingClass" },
            [
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "",
                    visible: _vm.sealingVisible,
                    width: "30%",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.sealingVisible = $event
                    },
                    close: _vm.handleClose,
                  },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("GlobalSetObj.bagNumber")) + "："),
                  ]),
                  _c("el-input", {
                    attrs: {
                      minlength: "6",
                      maxlength: "8",
                      "show-word-limit": "",
                      clearable: "",
                      placeholder: _vm.$t("GlobalSetObj.scannerOrinput"),
                    },
                    model: {
                      value: _vm.bagNumber,
                      callback: function ($$v) {
                        _vm.bagNumber = $$v
                      },
                      expression: "bagNumber",
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c("el-button", { on: { click: _vm.handleClose } }, [
                        _vm._v(_vm._s(_vm.$t("collectionCenter.cancel"))),
                      ]),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.sealingBags },
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.confirm")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
        },
        [
          _c("audio", {
            ref: "errRef",
            attrs: { src: _vm.errorMp3, controls: "controls" },
          }),
          _c("audio", {
            ref: "okRef",
            attrs: { src: _vm.okMp3, controls: "controls" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }