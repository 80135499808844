<template>
  <div>
    <el-table
      :data="tableData"
      :height="isHeight"
      border
      style="width: 100%"
    >
      <el-table-column
        :label="$t('newOrder.SerialNumber')"
        align="center"
        type="index"
        width="70"
      />
      <template v-for="item in tableHeaderList">
        <el-table-column :key="item.id" :label="item.label" :min-width="item.cloWidth" :prop="item.prop">
          <template slot-scope="scope">
            <!--Minuta-->
            <div>{{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '--' }}</div>
          </template>
        </el-table-column>
      </template>
      <el-table-column
        :label="$t('newOrder.operation')"
        align="center"
        fixed="right"
        width="100"
      >
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.operation === 0"
            size="mini"
            type="text"
            @click="clickInvoiceInfo"
          >
            <i class="el-icon-edit-outline hoverClass" style="font-size: 25px;" />
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('newOrder.UpdateInvoiceInformation')"
      :visible.sync="showUpdateInvoice"
      width="400px"
      @close="updateInvoiceData"
    >
      <div>
        <el-form
          :key="showUpdateInvoice"
          ref="updateInvoiceForm"
          :model="updateInvoiceForm"
          :rules="rules"
        >
          <!--开票日期-->
          <el-form-item :label="$t('placeOrder.DateOfInvoicing')" prop="invoiceIssueDate">
            <el-date-picker
              v-model="updateInvoiceForm.invoiceIssueDate"
              :format="$i18n.locale=='zh'?zhDate:ptDate"
              :picker-options="pickerOptions"
              :placeholder="$t('newOrder.SelectDatetime')"
              :value-format="$i18n.locale=='zh'?zhDate:ptDate"
              clearable
              style="width: 100%;"
              type="date"
            />
          </el-form-item>
          <!--发票号-->
          <el-form-item :label="$t('newOrder.InvoiceNumber')" prop="invoiceNumber">
            <el-input
              v-model="updateInvoiceForm.invoiceNumber"
              :placeholder="$t('orderDetails.pleaseInput')"
              clearable
              style="width: 100%;"
            />
          </el-form-item>
          <!--开票金额-->
          <el-form-item :label="`${$t('placeOrder.InvoiceAmount')}`" prop="invoiceMoney">
            <el-input
              v-model="updateInvoiceForm.invoiceMoney"
              :placeholder="$t('orderDetails.pleaseInput')"
              clearable
              style="width: 100%;"
            />
          </el-form-item>
          <!--发票密钥-->
          <el-form-item :label="$t('newOrder.InvoiceKey')" prop="invoiceAccessKey">
            <el-input
              v-model="updateInvoiceForm.invoiceAccessKey"
              :placeholder="$t('orderDetails.pleaseInput')"
              clearable
              rows="4"
              style="width: 100%;"
              type="textarea"
            />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showUpdateInvoice = false">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button type="primary" @click="saveUpdateInvoice()">{{ $t('GlobalSetObj.Salve') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getOrderInfo, rebuildMinutaApi, updateInvoice } from '@/api/logisticsOrders';
import { InvoiceAmounts, invoiceNumberCheck, invoiceSeriakey } from '@/utils/validate';
import { saveAs } from 'file-saver';

export default {
  name: 'ChildOrderInvoiceInfo',
  props: {
    waybillInvoiceVOList: {
      type: Array,
      required: true,
      default: () => []
    },
    queryTrackParam: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      tableData: [],
      invoiceData: {
        tradeTypeName: '',
        invoiceIssueDate: '',
        invoiceSerialNumber: '',
        taxCode: '',
        invoiceNumber: '',
        invoiceAccessKey: '',
        invoiceGenerationStatusName: ''
      },
      showUpdateInvoice: false,
      updateInvoiceForm: {
        waybillNumber: '',
        invoiceIssueDate: '',
        invoiceAccessKey: '',
        invoiceNumber: '',
        invoiceMoney: ''// 开票金额
      },
      zhDate: 'yyyy-MM-dd',
      ptDate: 'dd-MM-yyyy',
      pickerOptions: {
        disabledDate(time) {
          const curDate = new Date().getTime();
          const three = 180 * 24 * 3600 * 1000;
          const threeMonths = curDate - three;
          return (time.getTime() > Date.now() - 8.64e6 || time.getTime() < threeMonths);
        }
      },
      rules: {
        invoiceNumber: [
          {
            required: true,
            message: this.$t('placeOrder.PleaseEnter6To16DigitInvoiceNumber'),
            trigger: ['blur', 'change']
          },
          { validator: invoiceNumberCheck, trigger: ['blur', 'change'] }
        ],
        invoiceAccessKey: [
          {
            required: true,
            message: this.$t('placeOrder.PleaseEnter1To64DigitInvoiceNumber'),
            trigger: ['blur', 'change']
          },
          { validator: invoiceSeriakey, trigger: ['blur', 'change'] }
        ],
        // 开票金额校验
        invoiceMoney: [
          { required: true, message: this.$t('placeOrder.pleaseInput'), trigger: ['blur', 'change'] },
          { validator: InvoiceAmounts, trigger: ['blur', 'change'] }
        ]
      },
      keyInvoice: new Date().getTime(),
      tableHeaderList: [
        // 子包号
        { id: 1, label: this.$t('orderCenterCont.SubpackageNumber'), prop: 'subPacketNumber', cloWidth: '210' },
        // 订单类型
        { id: 2, label: this.$t('newOrder.OrderType'), prop: 'orderType', cloWidth: '130' },
        // 包裹价值(BRL)
        { id: 3, label: this.$t('GlobalSetObj.packageVlaue'), prop: 'packetValue', cloWidth: '180' },
        // 开票金额(BRL)
        { id: 4, label: this.$t('placeOrder.InvoiceAmount') + '(BRL)', prop: 'invoicedAmount', cloWidth: '160' },
        // 发票号
        { id: 5, label: this.$t('newOrder.InvoiceNumber'), prop: 'invoiceNumber', cloWidth: '160' },
        // 发票密钥
        { id: 6, label: this.$t('newOrder.InvoiceKey'), prop: 'invoiceKey', cloWidth: '200' },
        // 发票序列号
        { id: 7, label: this.$t('newOrder.TheInvoiceSerialNumber'), prop: 'invoiceSerial', cloWidth: '180' },
        // 税码
        { id: 8, label: this.$t('newOrder.TaxCode'), prop: 'taxCode', cloWidth: '160' },
        // 生成时间
        { id: 9, label: this.$t('newOrder.BuildTime'), prop: 'generationTime', cloWidth: '180' },
        // 州注册号
        { id: 10, label: this.$t('GlobalSetObj.NationalRegistrationNumber'), prop: 'ienumber', cloWidth: '160' },
        // 物流发票
        { id: 12, label: this.$t('newOrder.LogisticsInvoices'), prop: 'waybillCte', cloWidth: '160' }
      ],
      isHeight: ''
    };
  },
  created() {
    const date = new Date();
    const yy = date.getFullYear();
    const mm = date.getMonth() + 1;
    const dd = date.getDate();
    const dateName = yy + '-' + (mm < 10 ? '0' + mm : mm) + '-' + (dd < 10 ? '0' + dd : dd);
    this.updateInvoiceForm.invoiceIssueDate = dateName;
    this.waybillInvoiceVOList.forEach(item => {
      item.operatorIcon = true;
    });
    this.tableData = this.waybillInvoiceVOList;
    if (this.tableData.length > 5) {
      this.isHeight = '300';
    } else {
      this.isHeight = '';
    }
    this.getOrderDetail();
  },
  methods: {
    // 获取订单详情
    getOrderDetail() {
      getOrderInfo(this.queryTrackParam.id).then((res) => {
        if (res.status === 'OK') {
          this.tableInfomation = res.data;
          this.tableData = res.data.waybillInvoiceVOList;
          if (this.tableData.length > 5) {
            this.isHeight = '300';
          } else {
            this.isHeight = '';
          }
          console.log(this.tableData, 'this.tableDatagetTime');
          this.keyInvoice = new Date().getTime();
        }
      });
    },
    // 清空更新发票参数
    updateInvoiceData() {
      const date = new Date();
      const yy = date.getFullYear();
      const mm = date.getMonth() + 1;
      const dd = date.getDate();
      const dateName = yy + '-' + (mm < 10 ? '0' + mm : mm) + '-' + (dd < 10 ? '0' + dd : dd);
      this.updateInvoiceForm.invoiceIssueDate = dateName;
      this.updateInvoiceForm = {
        waybillNumber: '',
        invoiceIssueDate: dateName,
        invoiceAccessKey: '',
        invoiceNumber: '',
        invoiceMoney: ''// 发票金额
      };
    },
    // 保存更新发票
    saveUpdateInvoice() {
      this.$refs.updateInvoiceForm.validate((valid) => {
        if (valid) {
          const param = {
            waybillNumber: this.queryTrackParam.waybillNumber,
            invoiceIssueDate: this.updateInvoiceForm.invoiceIssueDate,
            invoiceAccessKey: this.updateInvoiceForm.invoiceAccessKey,
            invoiceNumber: this.updateInvoiceForm.invoiceNumber,
            invoiceMoney: this.updateInvoiceForm.invoiceMoney
          };
          updateInvoice(param).then(res => {
            if (res.status === 'OK') {
              this.$message.success(this.$t('newOrder.OperationSuccessful')); // '操作成功
              this.getOrderDetail();
              this.showUpdateInvoice = false;
            }
          });
        }
      });
    },
    // 点击编辑
    clickInvoiceInfo() {
      this.showUpdateInvoice = true;
    }
  }
};
</script>

<style scoped>
.WaybillNumber {
  color: #409eff;
  cursor: pointer;
  padding: 0;
}

.cursorPoniter {
  cursor: pointer;
}
</style>
