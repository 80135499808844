// @ts-check

import { BUSINESS_TYPE_ARR } from './searchForm/utils';

// 详情添加费用 下拉菜单控制
export const { getOneBusinessTypeList, getBatchBusinessTypeList } = (() => {
  /** @typedef {0|1|2} TBusinessType */

  // 业务类型
  // const BUSINESS_TYPE_ARR = {
  //   1: {
  //     label: i18n.t('collectionCenter.lanshou'), // 揽收
  //     value: 1
  //   },
  //   0: {
  //     label: i18n.t('collectionCenter.expressDelivery'), // 快递
  //     value: 0
  //   },
  //   2: {
  //     label: i18n.t('collectionCenter.dispatch'), // 派送 含尾程揽收
  //     value: 2
  //   }
  // };

  // 添加单个 根据不同状态获取业务类型列表
  /**
   *
   * @param {TBusinessType} businessType  快递类型
   * @param {0|1} billingMethod  报价方式 0:1段 1:2段
   * @returns {{label:string, value: number}[]} 业务类型可选项
   */
  const getOneBusinessTypeList = (businessType, billingMethod) => {
    const arr = [];
    // 快递类型判断
    if (businessType === BUSINESS_TYPE_ARR[0].value) {
      // 报价方式是 1段，下拉菜单只能选择快递
      if (billingMethod === 0) {
        arr.push(BUSINESS_TYPE_ARR[0]);
        return arr;
      }

      // 报价方式是 1段，下拉菜单只能选择派送
      if (billingMethod === 1) {
        arr.push(BUSINESS_TYPE_ARR[2]);
        return arr;
      }
      console.error('报价方式错误');

      return arr;
    }

    // 揽收，下拉菜单只能选择揽收
    if (businessType === BUSINESS_TYPE_ARR[1].value) {
      arr.push(BUSINESS_TYPE_ARR[1]);
      return arr;
    }

    // 派送，下拉菜单只能选择派送
    if (businessType === BUSINESS_TYPE_ARR[2].value) {
      arr.push(BUSINESS_TYPE_ARR[2]);
      return arr;
    }

    console.error('业务类型错误', businessType, billingMethod);

    return arr;
  };

  /**
   * 批量添加费用
   * @param {TBusinessType} businessType 搜索条件 快递类型
   * @returns {{label:string, value: number}[]} 业务类型可选项
   */
  const getBatchBusinessTypeList = (businessType) => {
    const arr = [];
    // 如果是快递类型，则显示 派送、快递
    if (businessType === BUSINESS_TYPE_ARR[0].value) {
      arr.push(BUSINESS_TYPE_ARR[2], BUSINESS_TYPE_ARR[0]);
      return arr;
    }

    // 如果是揽收类型，则显示 》揽收
    if (businessType === BUSINESS_TYPE_ARR[1].value) {
      arr.push(BUSINESS_TYPE_ARR[1]);
      return arr;
    }
    return arr;
  };

  return {
    getOneBusinessTypeList,
    getBatchBusinessTypeList
  };
})();
