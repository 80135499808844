import i18n from '@/lang/index.js';

// 索赔状态
export const STATUS = {
  0: {
    value: 0,
    type: 'primary',
    name: i18n.t('ClaimsManagement.ToBeSubmitted') // 待提交
  },
  1: {
    value: 1,
    type: 'warning',
    name: i18n.t('receivablePayable.ToBeReviewed') // 待审核
  },
  2: {
    value: 2,
    type: 'warning',
    name: i18n.t('ClaimsManagement.Rejected') // 已驳回
  },
  3: {
    value: 3,
    type: 'warning',
    name: i18n.t('ClaimsManagement.Due') // 待付款
  },
  4: {
    value: 4,
    type: 'success',
    name: i18n.t('collectionCenter.completed') // 已完成
  },
  5: {
    value: 5,
    type: 'danger',
    name: i18n.t('GlobalSetObj.Closed') // 已关闭
  },
  // 6: {
  //   value: 6,
  //   type: 'info',
  //   name: i18n.t('GlobalSetObj.edit') // 编辑
  // }
};

// 业务类型
export const claimNode = {
  // 第三方揽收
  4: {
    value: 4,
    name: i18n.t('compensationManagement.ThirdPartyCollection')
  },
  // AJD派送
  5: {
    value: 5,
    name: i18n.t('compensationManagement.AJDDispatch')
  },
  // DSP派送
  6: {
    value: 6,
    name: i18n.t('compensationManagement.DSPDispatch')
  },
  // 运输(干/支线)
  7: {
    value: 7,
    name: i18n.t('compensationManagement.Transportation_trunk_branch')
  }
};

// 支付状态
export const PAYSTATUS_STATUS = {
  1: {
    type: 'success',
    name: i18n.t('GlobalSetObj.paid'),
    value: 1
  },
  2: {
    type: 'warning',
    name: i18n.t('compensationManagement.Due'),
    value: 2
  },
  3: {
    type: 'danger',
    name: i18n.t('GlobalSetObj.Closed'),
    value: 3
  },
  // 4: {
  //   type: 'primary',
  //   name: i18n.t('compensationManagement.BillCredits'),
  //   value: 4
  // }
};

// 付款方式
export const PAYTYPE_LIST = {
  // 现金
  1: {
    name: i18n.t('basicData.cash'),
    value: 1
  },

  // 转账 
  2: {
    name: i18n.t('basicData.transferable'),
    value: 2
  },

  // 网咯
  3: {
    name: i18n.t('basicData.online'),
    value: 3
  },
  // 冲销
  4: {
    name: i18n.t('basicData.Elimination'),
    value: 4
  },

  // 其他
  5: {
    name: i18n.t('basicData.other'),
    value: 5
  }
};

// 索赔类型
export const CLAIMS_TYPE = {
  1: {
    name: i18n.t('compensationManagement.TimeoutNotClosedLoop'),
    value: 1
  },
  2: {
    name: i18n.t('compensationManagement.FalseSigningForDelivery'),
    value: 2
  },
  3: {
    name: i18n.t('compensationManagement.TimeDelay'),
    value: 3
  },
}
