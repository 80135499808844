var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "basicTable" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          [0, 2].includes(_vm.status)
            ? [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "small",
                      disabled: !_vm.isAddDisabled,
                    },
                    on: {
                      click: function ($event) {
                        _vm.isAdd = true
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("GlobalSetObj.append")))]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "info",
                      size: "small",
                      disabled: !_vm.isAddDisabled,
                    },
                    on: {
                      click: function ($event) {
                        _vm.isImport = true
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("CenterForSorting.Import")))]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "danger",
                      size: "small",
                      disabled: !_vm.multipleSelection.length,
                    },
                    on: { click: _vm.onRemove },
                  },
                  [_vm._v(_vm._s(_vm.$t("ClaimsManagement.Remove")))]
                ),
              ]
            : [
                _c(
                  "el-button",
                  {
                    attrs: { type: "info", size: "small" },
                    on: { click: _vm.onExport },
                  },
                  [_vm._v(_vm._s(_vm.$t("collectionCenter.export")))]
                ),
              ],
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [_c("StatisticiansTipsCmp", { attrs: { arr: _vm.arr } })],
            1
          ),
        ],
        2
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, "max-height": "350", border: "" },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: _vm.$t("operationCenter.ind"),
              width: "60px",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("ChannelManage.oddNumbers"),
              prop: "barCode",
              width: "180",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.customerNum"),
              prop: "customerNumber",
              width: "180",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("orderCenterCont.DeclaredValue"),
              prop: "totalDeclaredValue",
              width: "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("ClaimsManagement.CurrentTrajectory"),
              prop: "trajectory",
              width: "160",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("ClaimsManagement.TrackTime"),
              prop: "trajectoryTime",
              width: "160",
            },
          }),
          !_vm.isColumn
            ? [
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("GlobalSetObj.infeatSite"),
                    prop: "deliverWarehouseStationName",
                    width: "160",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("GlobalSetObj.entrega"),
                    prop: "deliverStationName",
                    width: "160",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("orderCenterCont.deliveryPerson"),
                    prop: "dispatchName",
                    width: "160",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("orderCenterCont.deliveryTime"),
                    prop: "deliveredTime",
                    width: "160",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("GlobalSetObj.SigningTime"),
                    width: "160",
                    prop: "signTime",
                  },
                }),
              ]
            : [
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("collectionCenter.LanShouPeople"),
                    prop: "dispatchName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("collectionCenter.lanshouT"),
                    prop: "deliveredTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("collectionCenter.siteName"),
                    prop: "deliverStationName",
                  },
                }),
              ],
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("orderCenterCont.AddOrder"),
            visible: _vm.isAdd,
            width: "500px",
            "append-to-body": "",
            "close-on-click-modal": false,
            "before-close": _vm.onClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isAdd = $event
            },
          },
        },
        [
          _c("el-input", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: {
              type: "textarea",
              placeholder: _vm.$t("trajectory.multipleOrders"),
              autosize: { minRows: 5, maxRows: 10 },
            },
            model: {
              value: _vm.orderList,
              callback: function ($$v) {
                _vm.orderList = $$v
              },
              expression: "orderList",
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.onClose } },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.add },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.determine")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("CenterForSorting.Import"),
            visible: _vm.isImport,
            width: "500px",
            "append-to-body": "",
            "close-on-click-modal": false,
            "before-close": _vm.onClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isImport = $event
            },
          },
        },
        [
          _c("ImportExcelCmp", {
            attrs: {
              "import-tmp-url":
                "https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/upload-file/workOrder/%E7%B4%A2%E8%B5%94%E6%A8%A1%E6%9D%BF-202410241030-20241025T104256.xlsx?q-sign-algorithm=sha1&q-ak=AKID2vc8UcWIfqskPtEKDtMfookXDD1e6JP6&q-sign-time=1729824176;2045184176&q-key-time=1729824176;2045184176&q-header-list=host&q-url-param-list=&q-signature=263596cbd8621e8468e0302c265fa0254729354d",
              "key-and-val-obj": _vm.excelKeyAndValue,
            },
            on: { change: _vm.onImportClick },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }