// 索赔

export const compensationManagement = {

  LinkedOrder: {
    zh: '关联订单',
    en: 'Linked order',
    pu: 'Ordem de associação'
  },
  ClaimSettlementProcess: {
    zh: '索赔流程',
    en: '',
    pu: 'Processo do Pedido de Indenização'
  },
  ApplicantsContactInformation: {
    zh: '申请人联系方式',
    en: "Applicant's contact information",
    pu: 'Contato para candidatos'
  },
  ClaimsNode: {
    zh: '索赔节点',
    en: 'Claims node',
    pu: 'Nó de reclamações'
  },
  AmountOfClaim: {
    zh: '索赔金额',
    en: '',
    pu: 'Valor do Pedido de Indenização'
  },
  CauseOfClaim: {
    zh: '索赔原因',
    en: '',
    pu: 'Motivo do Pedido de Indenização'
  },
  ClaimNumber: {
    zh: '索赔单号',
    en: '',
    pu: 'Número do Pedido de Indenização'
  },
  ApplyForClaimAmount: {
    zh: '申请索赔金额',
    en: '',
    pu: 'Valor solicitado do Pedido de Indenização'
  },
  ActualClaimAmount: {
    zh: '实际索赔金额',
    en: '',
    pu: 'Valor real do Pedido de Indenização'
  },
  ClaimApplicationImportTemplate: {
    zh: '索赔申请导入模板',
    en: 'Claim application import template',
    pu: 'Modelo de importação de solicitação de reclamação'
  },
  Drawee: {
    zh: '付款人',
    en: 'drawee',
    pu: 'Pagador'
  },
  ClaimApplication: {
    zh: '索赔申请',
    en: 'Claim application',
    pu: 'Pedido de reclamação'
  },
  ClaimStatus: {
    zh: '索赔状态',
    en: '',
    pu: 'Status do Pedido de Indenização'
  },
  ApplicationTime: {
    zh: '申请时间',
    en: 'Application time',
    pu: 'Tempo de aplicação'
  },
  CurrentTrajectory: {
    zh: '当前轨迹',
    en: 'Current trajectory',
    pu: 'A trajetória atual'
  },
  TrackTime: {
    zh: '轨迹时间',
    en: 'Track time',
    pu: 'Tempo da trilha'
  },
  SubmitPaymentRequest: {
    zh: '提交付款申请',
    en: 'Submit payment request',
    pu: 'Apresentação do pedido de pagamento'
  },
  Pass: {
    zh: '通过',
    en: 'pass',
    pu: 'Através de'
  },
  TurnDown: {
    zh: '驳回',
    en: 'Turn down',
    pu: 'Recusado.'
  },
  SubmitForReview: {
    zh: '提交审核',
    en: 'Submit for review',
    pu: 'Submeter uma avaliação'
  },
  LastUpdater: {
    zh: '最后更新人',
    en: 'Last updater',
    pu: 'Última atualização homem'
  },
  LastUpdateTime: {
    zh: '最后更新时间',
    en: 'Last update time',
    pu: 'Hora da última atualização'
  },
  DeclarationValueThreshold: {
    zh: '申报价值阈值',
    en: 'Declaration value threshold',
    pu: 'Limiar de valor declarado'
  },
  TimeOfPayment: {
    zh: '支付时间',
    en: 'Time of payment',
    pu: 'Tempo de pagamento'
  },
  PaymentMethod: {
    zh: '支付方式',
    en: 'Payment method',
    pu: 'Formas de pagamento'
  },
  PaymentAccountNumber: {
    zh: '支付账号',
    en: 'Payment account number',
    pu: 'Número da conta'
  },
  Payment: {
    zh: '付款',
    en: 'payment',
    pu: 'pagamentos'
  },
  BillCredit: {
    zh: '账单抵扣',
    en: 'Bill credit',
    pu: 'Dedução de contas'
  },
  BillNumber: {
    zh: '账单编号',
    en: 'Bill number',
    pu: 'Número da fatura'
  },
  ApplyForClaim: {
    zh: '申请索赔',
    en: '',
    pu: 'Solicitar Pedido de Indenização'
  },
  ClaimDetails: {
    zh: '索赔详情',
    en: '',
    pu: '12131'
  },
  DefaultAuditor: {
    zh: '默认审核人',
    en: 'Default auditor',
    pu: 'Moderador padrão por'
  },
  PaymentStatus: {
    zh: '支付状态',
    en: 'Payment status',
    pu: 'Estado do pagamento'
  },
  AmountActuallyPaid: {
    zh: '实际支付金额',
    en: 'Amount actually paid',
    pu: 'Montante efectivamente pago'
  },
  ClaimAmount: {
    zh: '索赔申请金额',
    en: 'Claim amount',
    pu: 'Montante do pedido de indemnização'
  },
  ClaimsClient: {
    zh: '索赔供应商',
    en: 'Claims client',
    pu: 'Reclamações de clientes'
  },
  Rejected: {
    zh: '已驳回',
    en: 'rejected',
    pu: 'Foi rejeitado'
  },
  Due: {
    zh: '待付款',
    en: 'due',
    pu: 'A ser pago'
  },
  Examine: {
    zh: '审核',
    en: 'examine',
    pu: 'auditoria'
  },
  ReturnCompleted: {
    zh: '退件完成',
    en: 'Return completed',
    pu: 'Devolução de peças concluída'
  },
  ToBeSubmitted: {
    zh: '待提交',
    en: 'To be Submitted',
    pu: 'Para ser submetido'
  },
  Remove: {
    zh: '移除',
    en: 'Remove',
    pu: 'remover'
  },
  TotalNumberOfVotes: {
    zh: '总票数',
    en: 'Total number of votes',
    pu: 'Total de votos'
  },
  TotalDeclaredValue: {
    zh: '总申报价值',
    en: 'Total declared value',
    pu: 'Valor declarado total'
  },
  SubmitPayment: {
    zh: '提交付款',
    en: 'Submit payment',
    pu: 'Enviar um pagamento'
  },
  订单不是当前供应商的订单: {
    zh: '订单不是当前供应商的订单',
    en: 'Order is not the current supplier order',
    pu: 'O pedido não é o pedido do fornecedor atual'
  },
  pleaseEnterANumberWithAMaximumOf3DecimalPlaces: {
    zh: '请输入数字，最多保留3位小数',
    en: '',
    pu: 'Por favor, insira um número, com no máximo 3 casas decimais'
  },
  ThirdPartyCollection: {
    zh: '第三方揽收',
    en: '',
    pu: 'Coleta por treceiro'
  },
  AJDDispatch: {
    zh: 'AJD派送',
    en: '',
    pu: 'Entrega por AJD'
  },
  DSPDispatch: {
    zh: 'DSP派送',
    en: '',
    pu: 'Entrega por DSP'
  },
  Transportation_trunk_branch: {
    zh: '运输(干/支线)',
    en: '',
    pu: 'Transporte (Rota Primária/Secundária )'
  },
  RelatedOrdersCannotBeEmpty: {
    zh: '关联订单不可为空',
    en: '',
    pu: 'Pedido associado não pode estar vazio'
  },
  PleaseEnterTheReasonForRejection: {
    zh: '请输入驳回原因',
    en: '',
    pu: 'Por favor, insira o motivo da rejeição'
  },
  ConfirmOperation: {
    zh: '确认操作',
    en: '',
    pu: 'Confirmar operação'
  },
  BillCredits: {
    zh: '账单抵扣中',
    en: '',
    pu: 'Dedução de fatura em andamento'
  },
  ClaimSupplier: {
    zh: '索赔供应商',
    en: '',
    pu: 'Fornecedor de reivindicação'
  },
  Maximum500Orders: {
    zh: '最大输入 500 单',
    en: 'Maximum input of 500 orders',
    pu: 'Máximo de 500 entradas'
  },
  WrongTrackingNumber: {
    zh: '错误单号',
    en: 'Wrong tracking number',
    pu: 'Número do pacote incorreto'
  },
  ClaimType: {
    zh: '索赔类型',
    en: '',
    pu: 'Tipo de reivindicação'
  },
  TimeoutNotClosedLoop: {
    zh: '超时未闭环',
    en: '',
    pu: 'Tempo limite sem fechamento de ciclo'
  },
  FalseSigningForDelivery: {
    zh: '虚假签收',
    en: '',
    pu: 'Assinatura falsa'
  },
  TimeDelay: {
    zh: '时效延误',
    en: '',
    pu: 'Atraso no prazo'
  },
};
