<template>
  <div class="detailContainer">
    <el-timeline>
      <el-timeline-item v-for="(item, index) in tableData" :key="index" :timestamp="item.createTime" placement="top">
        <el-card>
          <el-form :model="item" label-width="auto" class="operationData">
            <!--操作ip-->
            <el-form-item
              prop="ip"
              :label="$t('basicData.OperationalIP') + '：'"
            >
              <div>{{ item.ip }}</div>
            </el-form-item>
            <!--操作人姓名-->
            <el-form-item
              prop="operatorName"
              :label="$t('basicData.OperatorName') + '：'"
            >
              <div>{{ item.operatorName }}</div>
            </el-form-item>
            <!--操作时间-->
            <el-form-item
              prop="createTime"
              :label="$t('basicData.OperationTime') + '：'"
            >
              <div>{{ item.createTime }}</div>
            </el-form-item>
            <!--操作内容-->
            <el-form-item
              prop="operationContent"
              :label="$t('basicData.ActionContent') + '：'"
            >
              <div v-for="(list, ind) in item.operationContent.split('\n')" :key="ind">
                {{ list }}
                <!--下载分区-->
                <span
                  v-if="item.extra && ind === (item.operationContent.split('\n').length - 1)"
                  style="color: #409eff;cursor: pointer;"
                  @click="downloadQuote(item.extra)"
                >{{ $t('basicData.DownloadThePartition') }}</span>
              </div>
            </el-form-item>
          </el-form>
        </el-card>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { apiListByPartitionId } from '@/api/finance/partition';
import { saveAs } from 'file-saver';
const ExcelJs = require('exceljs');

export default {
  name: 'OperationLog',
  props: {
    editData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      tableData: [],
      createUser: []
    };
  },
  created() {
    this.createUser = getUserIdAndNameObj();
    this.getLogTable();
  },
  methods: {
    // 下载分区
    downloadQuote(data) {
      const dataArr = JSON.parse(data);
      const that = this;
      async function ddd() {
        const wb = new ExcelJs.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        const Sheet1_data = [];
        Sheet1.columns = [
          {
            header: that.$t('basicData.PartitionName'), // 分区名称
            key: 'partitionIdentifier',
            width: 40
          },
          {
            header: that.$t('basicData.zipCode'), // 邮编
            key: 'postalCode',
            width: 40
          }
        ];
        Sheet1.mergeCells('A2:B2');
        Sheet1.getCell('A2').value = that.$i18n.t('basicData.PartitionImportRules'); // 注意：分区名称（必填）；邮编（必填，邮编号码段连接符“~”，比如：1001~2001，代表1001到2001之间全部1000个邮编号码）
        Sheet1.getCell('A2').font = {
          color: { argb: 'FF0000' }
        };
        Sheet1.getCell('A2').alignment = { wrapText: true };
        Sheet1.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
        Sheet1.getRow(1).height = 20;
        Sheet1.getRow(2).height = 40;
        dataArr.map((item) => {
          Sheet1_data.push({
            partitionIdentifier: item.partitionIdentifier,
            postalCode: item.postalCode
          });
        });
        Sheet1.addRows(Sheet1_data);
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        const saveName = that.$t('basicData.PartitionDetails') + '.xlsx'; // 分区详情

        saveAs(blob, saveName);

        return;
      }
      ddd();
    },
    // 获取操作日志
    getLogTable() {
      const dateData = (property, bol) => {
        return function(a, b) {
          const value1 = a[property];
          const value2 = b[property];
          if (bol) {
            // 升序
            return Date.parse(value1) - Date.parse(value2);
          } else {
            // 降序
            return Date.parse(value2) - Date.parse(value1);
          }
        };
      };
      apiListByPartitionId(this.editData.id).then(res => {
        if (res.status === 'OK') {
          res.data.map(item => {
            item.createTime = item.createTime.replace(/T|Z/g, ' ');
          });
          res.data.sort(dateData('createTime', false));
          this.tableData = res.data;
        }
      });
    }
  }
};
</script>

<style scoped>
.detailContainer {
  height: calc(100vh - 40px);
  overflow-y: auto;
  padding: 20px;
}
.el-timeline{
  padding: 0;
}
.el-form-item{
  margin-bottom: 0;
}
.detailContainer >>> .operationData .el-form-item__label,
.detailContainer >>> .operationData .el-form-item__content{
  line-height: 26px!important;
}
pre {
  white-space: pre-wrap;      /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /*Opera 7 */
  word-wrap:break-word;       /* Internet Explorer 5.5+ */
  margin: 0;
  font-family: inherit;
  max-width: 600px;
}
</style>
