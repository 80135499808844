// @ts-check
import { cloneDeep } from 'lodash-es';
import i18n from '@/lang';

export const ROW_TMP = {
  startDistance: 0, // 公里数开始
  endDistance: 0, // 公里数结束
  vehicleType: '', // 司机类型
  standardCargo: 0, // 起底日任务量
  standardPrice: 0, // 保底薪
  billingMode: 0, // 计费方式
  overshipmentPrice: 0, // 超出计费/单价
  incentiveBonus: 0, // 出勤激励
  price: 0, // 超目标单量
  subsidy: 0, // 燃油补贴
  inssExemption: 0, // INSS减免比例
  inssTaxBase: 0, // INSS税基
  sestSenatExemption: 0, // Sest Senat减免比例
  sestSenatTaxBase: 0 // Sest Senat税基
};

// 司机类型
export const DRIVER_TYPE = Object.freeze([
  {
    // 自行车快递员(AJD01)
    label: `${i18n.t('basicData.MensageiroDeBicicleta')}(AJD01)`,
    value: 'AJD01'
  },
  {
    // 公司配车快递员(AJD02)
    label: `${i18n.t('basicData.CompanyCarDeliveryMan')}(AJD02)`,
    value: 'AJD02'
  },
  {
    // 租赁公司车辆给第三方司机(D01)
    label: `${i18n.t('basicData.LeasingCompanyVehiclesThirdpartyDrivers')}(D01)`,
    value: 'D01'
  },
  {
    // 摩托车租赁给第三方骑手(D02)
    label: `${i18n.t('basicData.MotorbikeLeasingThirdpartyRiders')}(D02)`,
    value: 'D02'
  },
  {
    // 第三方骑手(D03)
    label: `${i18n.t('basicData.ThirdpartyRiders')}(D03)`,
    value: 'D03'
  },
  {
    // 第三方自带车派送司机(D04)
    label: `${i18n.t('basicData.ThirdPartyVringyourownvehicleDeliveryDrivers')}(D04)`,
    value: 'D04'
  }
]);

// 计费模式
export const CHARGE_MODE = Object.freeze({
  0: {
    label: i18n.t('collectionCenter.ticket'), // 票
    value: 0
  },
  1: {
    label: i18n.t('orderCenterCont.days'), // 天
    value: 1
  },
  2: {
    label: i18n.t('receivablePayable.cycle'), // 周期
    value: 2
  }
});

/**
 * 区间是否重叠
 * @param {any[]} arr
 * @returns {boolean} 如果返回true 代表没有重叠
 */
const isOverLap = (arr = []) => {
  if (arr.length === 1) {
    return true;
  }
  const minArr = arr.map(item => {
    return item.startDistance;
  });

  const maxArr = arr.map(item => {
    return item.endDistance;
  });

  // 存在重叠
  if (Math.max(...minArr) < Math.min(...maxArr)) {
    return false;
  }
  return true;
};

/**
 * 同一司机类型 区间不能重叠
 *    意思就是同一个分区下的区间 自行车快递员不能同时存在 1-5、2-6 这种区间，
 * @param {any[]} arr
 * @returns {boolean} 如果返回true 代表没有重叠
 */
export const validateDriverType = (arr = []) => {
  // 只有一个的时候不校验
  if (arr.length === 1) {
    return true;
  }

  const list = cloneDeep(arr);

  const map = new Map();

  list.forEach(item => {
    const { vehicleType } = item;

    // 存在相同的分区
    if (map.has(vehicleType)) {
      const list = map.get(vehicleType) || [];
      list.push(item);

      map.set(vehicleType, list);
    } else {
      map.set(vehicleType, [item]);
    }
  });

  let isBoolean = true;

  // @ts-ignore
  for (const [, val] of map) {
    // if(val.length)
    if (!Array.isArray(val)) {
      continue;
    }
    const isBool = isOverLap(val);

    if (!isBool) {
      // 存在重叠
      isBoolean = false;
      break;
    }
  }
  return isBoolean;
};

/**
 * 校验价格详情
 * @param {any[]} arr
 * @returns {boolean}
 */
export const validatePrice = (arr) => {
  const isBool = arr.every(item => {
    // 校验 起始/结束公里数
    {
      const { startDistance, endDistance } = item;
      // 校验 起始/结束公里数

      if (startDistance === undefined) {
        return false;
      }
      if (endDistance === undefined) {
        return false;
      }

      // 校验 必须大于起始KM
      if (endDistance <= startDistance) {
        return false;
      }
    }

    // 司机类型
    {
      const { vehicleType } = item;
      if (!vehicleType) {
        return false;
      }
    }

    // 起底日任务量
    {
      const { standardCargo } = item;
      if (!standardCargo) {
        return false;
      }
    }

    // 基础派送费
    {
      const { standardPrice, overshipmentPrice } = item;

      if (!standardPrice && !overshipmentPrice) {
        return false;
      }
    }

    return true;
  });

  if (!isBool) {
    return false;
  }

  // 区间不能重叠
  {
    const isBool = validateDriverType(arr);
    if (!isBool) {
      return false;
    }
  }

  // 区间不能重叠 暂时不做校验
  // if (arr.length > 1) {
  //   const minArr = arr.map(item => {
  //     return item.startDistance;
  //   });

  //   const maxArr = arr.map(item => {
  //     return item.endDistance;
  //   });

  //   if (Math.max(...minArr) < Math.min(...maxArr)) {
  //     return false;
  //   }
  // }
  return true;
};

