var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Box" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          model: {
            value: _vm.activePage,
            callback: function ($$v) {
              _vm.activePage = $$v
            },
            expression: "activePage",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: { label: _vm.$t("collectionCenter.summary"), name: "hz" },
            },
            [
              _c(
                "el-form",
                { staticClass: "selectClass" },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: { "flex-wrap": "wrap" },
                      attrs: { type: "flex", justify: "start" },
                    },
                    [
                      _c(
                        "el-col",
                        { staticStyle: { width: "auto" }, attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label:
                                  _vm.$t("collectionCenter.auditTime") + "：",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                ref: "dateRef",
                                attrs: {
                                  clearable: false,
                                  type: "datetimerange",
                                  "default-time": ["00:00:00", "23:59:59"],
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  "range-separator": _vm.$t(
                                    "collectionCenter.to"
                                  ),
                                  "start-placeholder": _vm.$t(
                                    "collectionCenter.StartTime"
                                  ),
                                  "end-placeholder": _vm.$t(
                                    "collectionCenter.EndTime"
                                  ),
                                },
                                on: { change: _vm.dateChange },
                                model: {
                                  value: _vm.queryDate,
                                  callback: function ($$v) {
                                    _vm.queryDate = $$v
                                  },
                                  expression: "queryDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticStyle: { width: "auto" }, attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label:
                                  _vm.$t("collectionCenter.auditNetwork") +
                                  "：",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: _vm.$t(
                                      "GlobalSetObj.pleaseChoose"
                                    ),
                                    remote: "",
                                    "reserve-keyword": "",
                                    "remote-method": _vm.getSiteList,
                                  },
                                  model: {
                                    value: _vm.queryForm.params.auditSiteId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm.params,
                                        "auditSiteId",
                                        $$v
                                      )
                                    },
                                    expression: "queryForm.params.auditSiteId",
                                  },
                                },
                                _vm._l(_vm.siteIdList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticStyle: { width: "auto" }, attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label:
                                  _vm.$t("collectionCenter.auditor") + "：",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    placeholder: _vm.$t(
                                      "operationCenter.Only10AreDisplayed"
                                    ),
                                    remote: "",
                                    "reserve-keyword": "",
                                    "remote-method": _vm.getScanUserId,
                                  },
                                  model: {
                                    value: _vm.queryForm.params.createUser,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm.params,
                                        "createUser",
                                        $$v
                                      )
                                    },
                                    expression: "queryForm.params.createUser",
                                  },
                                },
                                _vm._l(_vm.customerList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.realName,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticStyle: { width: "auto" }, attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label:
                                  _vm.$t("collectionCenter.auditResult") + "：",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "collectionCenter.pleaseChoose"
                                    ),
                                    filterable: "",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.queryForm.params.auditStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryForm.params,
                                        "auditStatus",
                                        $$v
                                      )
                                    },
                                    expression: "queryForm.params.auditStatus",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("collectionCenter.tudo"),
                                      value: null,
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("userManage.normal"),
                                      value: 0,
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t(
                                        "newOperationCenter.abnormal"
                                      ),
                                      value: 1,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticStyle: { width: "auto" }, attrs: { span: 8 } },
                        [
                          _c("el-form-item", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "start",
                                  "align-items": "start",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "autoWidth",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "collectionCenter.pleaseChoose"
                                      ),
                                      filterable: "",
                                    },
                                    on: { change: _vm.changeNum },
                                    model: {
                                      value: _vm.orderIndex,
                                      callback: function ($$v) {
                                        _vm.orderIndex = $$v
                                      },
                                      expression: "orderIndex",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "prefix" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            (
                                              _vm.orderList.find(function (
                                                item
                                              ) {
                                                return (
                                                  item.value === _vm.orderIndex
                                                )
                                              }) || {}
                                            ).label
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _vm._l(
                                      _vm.orderList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: _vm.$t(
                                      "collectionCenter.BatchQuery"
                                    ),
                                    type: "textarea",
                                    rows: 4,
                                  },
                                  on: { blur: _vm.inputNumber },
                                  model: {
                                    value: _vm.chooseNumber,
                                    callback: function ($$v) {
                                      _vm.chooseNumber = $$v
                                    },
                                    expression: "chooseNumber",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permit",
                              rawName: "v-permit:remove",
                              value: "btn:collection-center:packet-scan:find",
                              expression:
                                "'btn:collection-center:packet-scan:find'",
                              arg: "remove",
                            },
                          ],
                          attrs: {
                            type: "primary",
                            size: "mini",
                            icon: "el-icon-search",
                          },
                          on: { click: _vm.searchClick },
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.search")))]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permit",
                              rawName: "v-permit:remove",
                              value: "btn:collection-center:packet-scan:reset",
                              expression:
                                "'btn:collection-center:packet-scan:reset'",
                              arg: "remove",
                            },
                          ],
                          attrs: { icon: "el-icon-refresh", size: "mini" },
                          on: { click: _vm.resetClick },
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.reset")))]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permit",
                              rawName: "v-permit:remove",
                              value:
                                "btn:collection-center:packet-scan:exportAll",
                              expression:
                                "'btn:collection-center:packet-scan:exportAll'",
                              arg: "remove",
                            },
                          ],
                          class: _vm.isClickHZ ? "onlyClickOnce10" : "",
                          attrs: {
                            icon: "el-icon-upload2",
                            size: "mini",
                            type: "primary",
                          },
                          on: { click: _vm.downloadAll },
                        },
                        [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-divider"),
              _c(
                "el-table",
                {
                  ref: "tableRef",
                  attrs: { "max-height": 600, border: "", data: _vm.tableDate },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("collectionCenter.ind"),
                      type: "index",
                      width: "60",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  (_vm.queryForm.pageNumber - 1) *
                                    _vm.queryForm.pageSize +
                                    scope.$index +
                                    1
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "batchNumber",
                      label: _vm.$t("collectionCenter.auditBatchNumber"),
                      align: "center",
                      width: "110",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "number",
                      label: _vm.$t("collectionCenter.auditedBagOrBill"),
                      align: "center",
                      width: "120",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "auditTotalNum",
                      label: _vm.$t("collectionCenter.shouldAuditedTotal"),
                      align: "center",
                      width: "110",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.viewDetails(
                                      scope.row,
                                      1,
                                      scope.row.auditTotalNum
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.auditTotalNum))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "actualAuditNum",
                      label: _vm.$t("collectionCenter.physicalAuditTotal"),
                      align: "center",
                      width: "130",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.viewDetails(
                                      scope.row,
                                      2,
                                      scope.row.actualAuditNum
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.actualAuditNum))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "differenceNum",
                      label: _vm.$t("collectionCenter.discrepancyTotal"),
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.viewDetails(
                                      scope.row,
                                      3,
                                      scope.row.differenceNum
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.differenceNum))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: _vm.$t("collectionCenter.auditTime"),
                      align: "center",
                      width: "110",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "auditSiteName",
                      label: _vm.$t("collectionCenter.auditNetwork"),
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createUserName",
                      label: _vm.$t("collectionCenter.auditor"),
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "auditStatus",
                      label: _vm.$t("collectionCenter.auditResult"),
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.auditStatus == 0
                                    ? _vm.$t("userManage.normal")
                                    : scope.row.auditStatus == 1
                                    ? _vm.$t("newOperationCenter.abnormal")
                                    : ""
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "result",
                      label: _vm.$t("collectionCenter.processingResult"),
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-tooltip",
                              { attrs: { placement: "top", effect: "dark" } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "300px",
                                      "word-wrap": "break-word",
                                    },
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [_vm._v(_vm._s(scope.row.result))]
                                ),
                                _c("div", { staticClass: "paramsStyle" }, [
                                  _vm._v(_vm._s(scope.row.result)),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "auditImg",
                      label: _vm.$t("collectionCenter.auditImg"),
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  title: _vm.$t("collectionCenter.auditImg"),
                                  content: "",
                                  placement: "left-start",
                                  trigger: "hover",
                                  width: "400",
                                },
                              },
                              [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: scope.row.auditImg,
                                      expression: "scope.row.auditImg",
                                    },
                                  ],
                                  staticStyle: { width: "50px" },
                                  attrs: {
                                    slot: "reference",
                                    src: scope.row.auditImg,
                                    alt: "",
                                  },
                                  slot: "reference",
                                }),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !scope.row.auditImg,
                                        expression: "!scope.row.auditImg",
                                      },
                                    ],
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("collectionCenter.Null"))
                                    ),
                                  ]
                                ),
                                scope.row.auditImg
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%",
                                      },
                                      attrs: {
                                        src: scope.row.auditImg,
                                        alt: "",
                                      },
                                    })
                                  : _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "collectionCenter.NoContentYet"
                                          )
                                        )
                                      ),
                                    ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "remark",
                      label: _vm.$t("collectionCenter.Note"),
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-tooltip",
                              { attrs: { effect: "dark", placement: "top" } },
                              [
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [_vm._v(_vm._s(scope.row.remark))]
                                ),
                                _c("div", { staticClass: "paramsStyle" }, [
                                  _vm._v(_vm._s(scope.row.remark)),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "updateUserName",
                      label: _vm.$t("collectionCenter.ModifiedBy"),
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "updateTime",
                      label: _vm.$t("collectionCenter.ModificationTime"),
                      align: "center",
                    },
                  }),
                  _vm.isEditBtn
                    ? _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("collectionCenter.operation"),
                          fixed: "right",
                          width: "100",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        cursor: "pointer",
                                        color: "#26b3f1",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.edit(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("collectionCenter.modify")
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          598805098
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c("el-pagination", {
                    staticStyle: { margin: "10px 0" },
                    attrs: {
                      layout: "total, sizes, prev, pager, next, jumper",
                      "current-page": _vm.queryForm.pageNumber,
                      "page-sizes": [10, 20, 50, 100, 500, 1000],
                      "page-size": _vm.queryForm.pageSize,
                      total: _vm.total,
                      background: "",
                    },
                    on: {
                      "update:currentPage": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageNumber", $event)
                      },
                      "update:current-page": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageNumber", $event)
                      },
                      "update:pageSize": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageSize", $event)
                      },
                      "update:page-size": function ($event) {
                        return _vm.$set(_vm.queryForm, "pageSize", $event)
                      },
                      "current-change": _vm.getPageNum,
                      "size-change": _vm.getPageSize,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.activePage == "mx"
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("collectionCenter.particulars"),
                    name: "mx",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value:
                            "btn:collection-center:packet-scan:exportAll-mx",
                          expression:
                            "'btn:collection-center:packet-scan:exportAll-mx'",
                          arg: "remove",
                        },
                      ],
                      class: _vm.isClickMX ? "onlyClickOnce10" : "",
                      attrs: {
                        icon: "el-icon-upload2",
                        size: "mini",
                        type: "primary",
                      },
                      on: { click: _vm.downloadAllMX },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.allExport")))]
                  ),
                  _c("el-divider"),
                  _c(
                    "div",
                    { staticClass: "overflowClass" },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "tableRef",
                          staticStyle: { width: "100%" },
                          attrs: {
                            "max-height": 600,
                            border: "",
                            data: _vm.tableDateMX,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("collectionCenter.ind"),
                              type: "index",
                              width: "60",
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            (_vm.queryFormMX.pageNumber - 1) *
                                              _vm.queryFormMX.pageSize +
                                              scope.$index +
                                              1
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2346069176
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "scanNumber",
                              label: _vm.$t(
                                "collectionCenter.pocketParcelNumber"
                              ),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "actualScanNumber",
                              label: _vm.$t(
                                "collectionCenter.actualAuditScanNumber"
                              ),
                              align: "center",
                              width: "140",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "collectStatusName",
                              label: _vm.$t("collectionCenter.OrderStatus"),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "auditScanTime",
                              label: _vm.$t(
                                "collectionCenter.checkScanningTime"
                              ),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "collectSiteName",
                              label: _vm.$t("collectionCenter.collectionDot"),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "collectUserName",
                              label: _vm.$t("collectionCenter.LanShouPeople"),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "collectTime",
                              label: _vm.$t("collectionCenter.lanshouT"),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "vehicleNumber",
                              label: _vm.$t(
                                "collectionCenter.licensePlateNumber"
                              ),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "loadUserName",
                              label: _vm.$t("collectionCenter.loader"),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "loadSiteName",
                              label: _vm.$t(
                                "customerManagements.loadSiteIdList"
                              ),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "loadTime",
                              label: _vm.$t("collectionCenter.loadTime"),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "unloadUserName",
                              label: _vm.$t("collectionCenter.unloader"),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "unloadTime",
                              label: _vm.$t("collectionCenter.unloadTime"),
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "unloadSiteName",
                              label: _vm.$t("collectionCenter.unloadDot"),
                              align: "center",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-pagination", {
                        staticStyle: { margin: "10px 0" },
                        attrs: {
                          "current-page": _vm.queryFormMX.pageNumber,
                          "page-size": _vm.queryFormMX.pageSize,
                          "page-sizes": [10, 20, 50, 100, 500, 1000],
                          total: _vm.totalMX,
                          background: "",
                          layout: "total, sizes, prev, pager, next, jumper",
                        },
                        on: {
                          "update:currentPage": function ($event) {
                            return _vm.$set(
                              _vm.queryFormMX,
                              "pageNumber",
                              $event
                            )
                          },
                          "update:current-page": function ($event) {
                            return _vm.$set(
                              _vm.queryFormMX,
                              "pageNumber",
                              $event
                            )
                          },
                          "update:pageSize": function ($event) {
                            return _vm.$set(_vm.queryFormMX, "pageSize", $event)
                          },
                          "update:page-size": function ($event) {
                            return _vm.$set(_vm.queryFormMX, "pageSize", $event)
                          },
                          "current-change": _vm.getPageNumMX,
                          "size-change": _vm.getPageSizeMX,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.remarkVisible, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.remarkVisible = $event
            },
            close: _vm.remarkClose,
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("collectionCenter.Note")) + "：")]),
          _c("el-input", {
            attrs: {
              clearable: "",
              placeholder: _vm.$t("collectionCenter.pleaseInput"),
            },
            model: {
              value: _vm.editRemark.remark,
              callback: function ($$v) {
                _vm.$set(_vm.editRemark, "remark", $$v)
              },
              expression: "editRemark.remark",
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.remarkClose } }, [
                _vm._v(_vm._s(_vm.$t("collectionCenter.cancel"))),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.remarkSubmit } },
                [_vm._v(_vm._s(_vm.$t("collectionCenter.confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }