// 轨迹管理

const trajectory = {
  Country: {
    zh: '国家',
    en: 'Country',
    pu: 'País'
  },
  APICarrier: {
    zh: 'API运输商',
    en: 'API carrier',
    pu: 'Operadora de API'
  },
  RecordingStartTime: {
    zh: '录单开始时间',
    en: 'Recording start time',
    pu: 'Hora de início da gravação'
  },
  RecordingEndTime: {
    zh: '录单结束时间',
    en: 'Recording end time',
    pu: 'Hora de término da gravação'
  },
  channel: {
    zh: '渠道',
    en: 'channel',
    pu: 'canal'
  },
  NoOfAWB: {
    zh: '航空提单号',
    en: 'No. of AWB',
    pu: 'No. do AWB'
  },
  NoOfBigBag: {
    zh: '大包号',
    en: 'No. of big bag',
    pu: 'Número de saca'
  },
  ListOfOrderNo: {
    zh: '订单号列表',
    en: 'List of order No.',
    pu: 'Lista de No. de pedidos'
  },
  TrackingNoList: {
    zh: '追踪号列表',
    en: 'Tracking No. List',
    pu: 'Lista de No. de Rastreamento'
  },
  ListOfreferenceNo: {
    zh: '参考号列表',
    en: 'List of reference No.',
    pu: 'Lista de No. de referência'
  },
  ExportTrackingInformation: {
    zh: '导出轨迹信息',
    en: 'Export tracking information',
    pu: 'Exportar informações de rastreamento'
  },
  downloadAWB: {
    zh: '提单绑定模板下载',
    en: 'download AWB ',
    pu: 'Download do AWB '
  },
  UploadTheAWB: {
    zh: '提单绑定导入',
    en: 'Upload the AWB',
    pu: 'Upload do AWB'
  },
  UpdateTrackingInfoManually: {
    zh: '手动更新轨迹',
    en: 'Update tracking info manually',
    pu: 'Atualizar o status manualmente'
  },
  ChangeCarrier: {
    zh: '更换运输商',
    en: 'Change carrier',
    pu: 'Mudar de operadora'
  },

  orderNo: {
    zh: '订单号',
    en: 'order No.',
    pu: 'Número do pedido'
  },
  ReferenceNo: {
    zh: '参考号',
    en: 'Reference No.',
    pu: 'Nº de referência'
  },
  TrackingNo: {
    zh: '追踪号',
    en: 'Tracking No.',
    pu: 'No. de rastreio'
  },
  channelName: {
    zh: '渠道名称',
    en: 'channel name',
    pu: 'nome do canal'
  },
  postalRoute: {
    zh: '邮路',
    en: 'postal route',
    pu: 'rota postal'
  },
  TimeofArrivalInDestinationCountry: {
    zh: '抵达目的国时间',
    en: 'Time of arrival in destination country',
    pu: 'País de chegada'
  },
  Arrivaltime: {
    zh: '到仓时间',
    en: 'Arrival time',
    pu: 'Hora de chegada'
  },
  Departuretime: {
    zh: '离仓时间',
    en: 'Departure time',
    pu: 'Tempo de saída'
  },
  Signingtime: {
    zh: '签收时间',
    en: 'Signing time',
    pu: 'Hora de assinatura'
  },

  multipleOrders: {
    zh: '请输入订单号，多个订单换行输入',
    en: 'Please enter the order No., for multiple orders enter every order No. in a new line',
    pu: 'Insira o No. do pedido, vários pedidos são inseridos em uma nova linha'
  },
  delivering: {
    zh: '运输途中',
    en: 'delivering',
    pu: 'em transito'
  },
  awaitingForPickUp: {
    zh: '到达待取',
    en: 'awaiting for pick up',
    pu: 'aguardando a retirar'
  },
  deliveryFailed: {
    zh: '投递失败',
    en: 'delivery failed',
    pu: 'falha na entrega'
  },
  DeliveredSuccessfully: {
    zh: '成功签收',
    en: 'Delivered successfully',
    pu: 'entregue com sucesso'
  },
  mayBeAbnormal: {
    zh: '可能异常',
    en: 'may be abnormal',
    pu: 'pode ser anormal'
  },
  NumberOfQueries: {
    zh: '查询数量',
    en: 'Number of queries',
    pu: 'Número de consultas'
  },

  SearchByNumber: {
    zh: '按号码查询',
    en: 'Search by number',
    pu: 'Pesquisar por número'
  },
  OrderNumberInquiry: {
    zh: '订单号查询',
    en: 'Order number inquiry',
    pu: 'Inquérito do número de pedidos'
  },
  LogisticsSlipNumberInquiry: {
    zh: '物流单号查询',
    en: 'Logistics slip number inquiry',
    pu: 'Inquérito de número de deslizamento logístico'
  },
  TranslatedAsChinese: {
    zh: '是否翻译为中文',
    en: 'Whether it is translated as Chinese',
    pu: 'Se é traduzido como chinês'
  },
  QueryByRange: {
    zh: '按范围查询',
    en: 'Query by range',
    pu: 'Consulta por intervalo'
  },
  dateAndChannelCountry: {
    zh: '注意：请选择日期和对应客户渠道或国家',
    en: 'Note: Please select a date and corresponding customer channel or country',
    pu: 'Note: Please select a date and corresponding customer channel or country'
  },
  QueryTime: {
    zh: '查询时间',
    en: 'Query time',
    pu: 'Tempo de consulta'
  },
  total: {
    zh: '总数',
    en: 'total',
    pu: 'total'
  },
  NoDataWasQueried: {
    zh: '未查询到数据',
    en: 'No data was queried',
    pu: 'Nenhum dado foi consultado'
  },
  within180Days: {
    zh: '起始日期只能在180天内',
    en: 'The start date can only be within 180 days',
    pu: 'A data de início só pode ser dentro de 180 dias'
  },
  DatesCannotBeEmpty: {
    zh: '日期不能为空',
    en: 'Dates cannot be empty',
    pu: 'As datas não podem estar vazias.'
  },
  customerChannelOrCountry: {
    zh: '范围查询必须选择对应客户渠道或国家',
    en: 'The scope query must select the corresponding customer channel or country',
    pu: 'A consulta de escopo deve selecionar o canal ou país correspondente'
  },
  UpTo500Queries: {
    zh: '每次最多查询500条',
    en: 'Up to 500 queries',
    pu: 'Até 500 consultas'
  },
  PleaseEnterCriteria: {
    zh: '请输入查询条件',
    en: 'Please enter your query criteria',
    pu: 'Por favor, insira seus critérios de consulta'
  },
  ThereMustBeOneEntryThatIsNotEmpty: {
    zh: '提单号，订单号，参考号，物流单号，必须有一项不为空',
    en: 'Air bill of lading number,order No.,Reference No.,Logistics Single Number,There must be one entry that is not empty',
    pu: 'No. do AWB,número do pedido,Nº de referência,Número único da logística,Deve haver uma entrada que não esteja vazia.'
  },
  notMeetRequirements: {
    zh: '上传格式不符合要求!',
    en: 'The upload format does not meet the requirements!',
    pu: 'O formato de upload não atende aos requisitos!'
  },
  imageSizeExceeded: {
    zh: '上传图片大小不能超过',
    en: 'The uploaded image size cannot be exceeded',
    pu: 'O tamanho da imagem carregado não pode ser excedido'
  },
  PleaseEnterComment: {
    zh: '请输入评论内容',
    en: 'Please enter a comment',
    pu: 'Por favor, insira um comentário'
  },
  empty: {
    zh: '清空',
    en: 'empty',
    pu: 'vazio'
  },
  AirBillOfLadingNumber: {
    zh: '航空提单号',
    en: 'Air bill of lading number',
    pu: 'AWB'
  },
  TotalWeight: {
    zh: '总重',
    en: 'Total weight',
    pu: 'Peso total'
  },
  largePacketNumber: {
    zh: '大包数',
    en: 'large packet number',
    pu: 'Número de saca'
  },
  numberSmallPackets: {
    zh: '小包数',
    en: 'The number of small packets',
    pu: 'O número de pequenos pacotes'
  },
  PleaseSelect: {
    zh: '请选择',
    en: 'Please select',
    pu: 'Favor selecionar'
  },
  TheTypeService: {
    zh: '服务类型',
    en: 'The type of service',
    pu: 'O tipo de serviço'
  },
  customer: {
    zh: '客户',
    en: 'customer',
    pu: 'cliente'
  },
  DestinationAirport: {
    zh: '目的机场',
    en: 'Destination airport',
    pu: 'Aeroporto de Destino'
  },
  ArrivalTime: {
    zh: '到达时间',
    en: 'Arrival time',
    pu: 'Hora da chegada'
  },
  PostOfficeReceptionTime: {
    zh: '邮局接收时间',
    en: 'Post office reception time',
    pu: 'Horário de recepção dos Correios'
  },
  PublishTime: {
    zh: '发布时间',
    en: 'Publish time',
    pu: 'Tempo de publicação'
  },
  Statistics: {
    zh: '统计数据',
    en: 'Statistics',
    pu: 'Estatística'
  },
  state: {
    zh: '状态',
    en: 'state',
    pu: 'status'
  },
  DepartureAirport: {
    zh: '起飞机场',
    en: 'Departure airport',
    pu: 'Aeroporto de Partida'
  },
  DepartureTime: {
    zh: '起飞时间',
    en: 'Departure time',
    pu: 'Horário de partida'
  },
  TraceInformation: {
    zh: '跟踪信息',
    en: 'Trace information',
    pu: 'Informações de rastreamento'
  },
  Save: {
    zh: '保存',
    en: 'Save',
    pu: 'Salvar'
  },
  SaveSuccessfully: {
    zh: '保存成功',
    en: 'Save successfully',
    pu: 'Salvar com sucesso'
  },
  TheFormatIsIncorrect: {
    zh: '格式不正确',
    en: 'The format is incorrect',
    pu: 'O formato está incorreto'
  },
  MustBeLessThan: {
    zh: '必须小于',
    en: 'Must be less than',
    pu: 'Deve ser menor a'
  },
  CannotBeEmpty: {
    zh: '不能为空',
    en: 'Cannot be empty',
    pu: 'Não pode estar vazio'
  },
  SubmittedOn: {
    zh: '提交于',
    en: 'Submitted on',
    pu: 'Enviado em'
  },
  NoContentYet: {
    zh: '暂无内容',
    en: 'No content yet',
    pu: 'Nenhum conteúdo ainda'
  },
  ForecastTime: {
    zh: '预报时间',
    en: 'Forecast time',
    pu: 'Tempo de previsão'
  },
  to: {
    zh: '至',
    en: 'to',
    pu: 'Para'
  },
  startDate: {
    zh: '开始日期',
    en: 'Start date',
    pu: 'Data de início'
  },
  endDate: {
    zh: '结束日期',
    en: 'End date',
    pu: 'Data final'
  },
  BillLadingNumber: {
    zh: '提单号',
    en: 'Bill of lading number',
    pu: 'Bill de número de embarque'
  },
  BillLadingNumberCannotEmpty: {
    zh: '提单号不能为空',
    en: 'The bill of lading number cannot be empty',
    pu: 'A conta do número de embarque não pode estar vazia'
  },
  Procurar: {
    zh: '搜索',
    en: 'Search',
    pu: 'Pesquisar'
  },
  reset: {
    zh: '重置',
    en: 'reset',
    pu: 'Limpar'
  },
  add: {
    zh: '添加',
    en: 'add',
    pu: 'adicionar'
  },
  ShippingDocuments: {
    zh: '随货文件',
    en: 'Shipping documents',
    pu: 'Documentos de envio'
  },
  TimeOccurrence: {
    zh: '发生时间',
    en: 'Time of occurrence',
    pu: 'Tempo de ocorrência'
  },
  PostOfficeAccepted: {
    zh: '邮局已接收',
    en: 'Post office has received',
    pu: 'Correios receberam'
  },
  operate: {
    zh: '操作',
    en: 'operate',
    pu: 'Operar'
  },
  View: {
    zh: '查看',
    en: 'View',
    pu: 'consultar'
  },
  modify: {
    zh: '修改',
    en: 'modify',
    pu: 'modificar'
  },
  MoreActions: {
    zh: '更多操作',
    en: 'More actions',
    pu: 'Mais ações'
  },
  Cancel: {
    zh: '取消',
    en: 'Cancel',
    pu: 'Cancelar'
  },
  submit: {
    zh: '提交',
    en: 'submit',
    pu: 'Enviar'
  },
  all: {
    zh: '全部',
    en: 'all',
    pu: 'Todos'
  },
  AirlineNumber: {
    zh: '航空号',
    en: 'Airline number',
    pu: 'Número da companhia aérea'
  },
  NumberPackages: {
    zh: '包裹数',
    en: 'Number of packages',
    pu: 'Número de pacotes'
  },
  LatestTracks: {
    zh: '最新轨迹',
    en: 'Latest tracks',
    pu: 'Últimas faixas'
  },
  Time: {
    zh: '时间',
    en: 'Time',
    pu: 'Hora'
  },
  locus: {
    zh: '轨迹',
    en: 'locus',
    pu: 'Locus'
  },
  description: {
    zh: '描述',
    en: 'description',
    pu: 'descrição'
  },
  AirCargoTracking: {
    zh: '航空货物追踪',
    en: 'Air cargo tracking',
    pu: 'Rastreamento de carga aérea'
  },
  ind: {
    zh: '序号',
    en: 'index',
    pu: 'Número de série.'
  },
  TrackingCode: {
    zh: '跟踪代码',
    en: 'Tracking code',
    pu: 'Código de rastreamento'
  },
  TrajectoryQuery: {
    zh: '轨迹查询',
    en: 'Trajectory query',
    pu: 'Consulta de trajetória'
  },
  trackStatus: {
    zh: '轨迹状态',
    em: 'track status',
    pu: 'Status rastreamento'
  },
  TrajectoryStatisticsReport: {
    zh: '轨迹统计报表',
    en: 'Trajectory statistics report',
    pu: 'estatística de rastreamento'
  },
  AirlineBillLadingManagement: {
    zh: '航空提单管理',
    en: 'Airline bill of lading management',
    pu: 'Gestão de AWB'
  },
  TrajectoriesAreArtificiallyMade: {
    zh: '轨迹人工制作',
    en: 'Trajectories are artificially made',
    pu: 'rastreamento Manual'
  },
  period: {
    zh: '时期',
    en: 'period',
    pu: 'período'
  },
  PostedOn: {
    zh: '发布于',
    en: 'Posted on',
    pu: 'Postado em'
  },
  limitation: {
    zh: '时效',
    en: 'limitation',
    pu: 'limitação'
  },
  TransitTime: {
    zh: '运输时间',
    en: 'Transit time',
    pu: 'Tempo de trânsito'
  },
  Delivered: {
    zh: '已交付',
    en: 'Delivered',
    pu: 'Entregue'
  },
  consignee: {
    zh: '收货人',
    en: 'consignee',
    pu: 'consignatário'
  },
  mailbox: {
    zh: '邮箱',
    en: 'mailbox',
    pu: 'caixa de correio'
  },
  Phone: {
    zh: '电话',
    en: 'Phone',
    pu: 'Telefone'
  },
  TaxID: {
    zh: '税号',
    en: 'Tax ID',
    pu: 'ID fiscal'
  },
  ProofIdentity: {
    zh: '身份证明',
    en: 'Proof of identity',
    pu: 'Prova de identidade'
  },
  CargoControl: {
    zh: '货物控制',
    en: 'Cargo control',
    pu: 'Controle de carga'
  },
  AnjunBagNumber: {
    zh: '安骏袋号',
    en: 'Anjun bag number',
    pu: 'Número da bolsa Anjun'
  },
  ExportFile: {
    zh: '导出文件',
    en: 'Export the file',
    pu: 'Exporte o arquivo'
  },
  ShutDown: {
    zh: '关闭',
    en: 'Shut down',
    pu: 'Desligar'
  },
  Last15Days: {
    zh: '最近15天',
    en: 'Last 15 days',
    pu: 'Últimos 15 dias.'
  },
  Last60Days: {
    zh: '最近60天',
    en: 'Last 60 days',
    pu: 'Últimos 60 dias.'
  },
  Last180Days: {
    zh: '最近180天',
    en: 'Last 180 days',
    pu: 'Últimos 180 dias.'
  },
  exportMoreThan100000Items: {
    zh: '一次导出不能超过100000条',
    en: 'You cannot export more than 100,000 items at a time',
    pu: 'Você não pode exportar mais de 100.000 itens por vez'
  },
  SelectDate: {
    zh: '选择日期',
    en: 'Select a date',
    pu: 'Selecione uma data'
  },
  TimeToLeaveTheWarehouse: {
    zh: '离开机场邮政仓库时间',
    en: 'Departure time from the airport post office',
    pu: 'Liberada pelo GTCAI'
  },
  DeliveryTimeToTheWarehouse: {
    zh: '送进机场邮政仓库时间',
    en: 'Delivery time to the airport postal depot',
    pu: 'Entregue ao GTCAI'
  },
  ArrivalTimeGRU: {
    zh: '到达时间(到达GRU机场)',
    en: 'Arrival time (arrival at GRU airport)',
    pu: 'Chegada em GRU'
  },
  MultipleLineBreaks: {
    zh: '多个请换行输入',
    en: 'Multiple line breaks are entered',
    pu: 'Várias quebras de linha são inseridas'
  },
  queryUpTo50Pieces: {
    zh: '一次最多查询50条数据',
    en: 'You can query up to 50 pieces of data at a time',
    pu: 'Você pode consultar até 50 dados por vez'
  },
  CustomerSubject: {
    zh: '客户主体',
    en: 'Customer subject',
    pu: 'Assunto do cliente'
  },
  ThereIsDataThatFailedToImport: {
    zh: '存在导入失败的数据',
    en: 'There is data that failed to import',
    pu: 'Há dados que falharam em importar'
  },
  UpdateShipmentStatus: {
    zh: '更新货运状态',
    en: 'Update the shipment status',
    pu: 'Atualize o status da remessa'
  },
  InAirFreight: {
    zh: '空运中',
    en: 'In air freight',
    pu: 'No frete aéreo'
  },
  ArrivalAtTheDestinationAirport: {
    zh: '到达目的机场',
    en: 'Arrival at the destination airport',
    pu: 'Chegada ao aeroporto de destino'
  },
  packageHasBeenDeliveredToPostOffice: {
    zh: '包裹已交付给邮局-GRU',
    en: 'The package has been delivered to post office-GRU',
    pu: 'O pacote foi entregue aos Correios-GRU'
  },
  postOffice: {
    zh: '邮局',
    en: 'post office',
    pu: 'correio'
  },
  parcel: {
    zh: '包裹',
    en: 'parcel',
    pu: 'pacote'
  },
  AWBInformation: {
    zh: 'AWB信息',
    en: 'AWB information',
    pu: 'Informações da AWB'
  },
  FlightStatus: {
    zh: '航班状态',
    en: 'Flight status',
    pu: 'Status de voo'
  },
  address: {
    zh: '地址',
    en: 'address',
    pu: 'endereço'
  },
  flight: {
    zh: '航班',
    en: 'flight',
    pu: 'voo'
  },
  metrics: {
    zh: '指标',
    en: 'metrics',
    pu: 'Métricas'
  },
  recipient: {
    zh: '收件人',
    en: 'recipient',
    pu: 'Destinatário'
  },
  WaitForCEINTRead: {
    zh: '等待CEINT读取',
    en: 'Wait for the CEINT to read',
    pu: 'Aguarde a leitura do CEINT'
  },
  BeTaxed: {
    zh: '被征税',
    en: 'Be taxed',
    pu: 'Seja tributado'
  },
  DeliveryComplete: {
    zh: '派送完成',
    en: 'Delivery complete',
    pu: 'Entrega completa'
  },
  copy: {
    zh: '复制',
    en: 'copy',
    pu: 'Cópia'
  },
  ClearCache17: {
    zh: '17清除缓存',
    en: '17 Clear cache',
    pu: '17 Cache claro'
  },
  ManuallyPullEmmisOrders: {
    zh: '手动拉取钮门订单',
    en: 'Manually pull Emmis orders',
    pu: 'Puxe manualmente ordens da Emmis'
  },
  NoOfAWBReplacement: {
    zh: '提单号替换',
    en: 'replacement No. of AWB',
    pu: 'substituição No. do AWB'
  },
  checkUpTo20: {
    zh: '一次最多查询20个提单号',
    en: 'You can check up to 20 No. of AWB at a time',
    pu: 'Você pode verificar até 20 No. do AWB de cada vez'
  },
  checkUpTo100: {
    zh: '一次最多查询100个提单号',
    en: 'You can check up to 100 No. of AWB at a time',
    pu: 'Você pode verificar até 100 No. do AWB de cada vez'
  },
  BulkExport: {
    zh: '批量导出',
    en: 'Bulk export',
    pu: 'Exportação a granel'
  },
  QueryByTrajectoryStatus: {
    zh: '按轨迹状态查询',
    en: 'Query by trajectory status',
    pu: 'Consulta por status de trajetória'
  },
  TheNumberTrackStatusPackages: {
    zh: '轨迹状态包裹数',
    en: 'The number of track status packages',
    pu: 'O número de pacotes de status da faixa'
  },
  trajectoryStatusRefers: {
    zh: '轨迹状态参照包裹数',
    en: 'The trajectory status refers to the number of packages',
    pu: 'O status da trajetória refere-se ao número de pacotes'
  },
  Proportion: {
    zh: '占比',
    en: 'Proportion',
    pu: 'Proporção'
  },
  PercentageOfTaxed: {
    zh: '被征税比例',
    en: 'Percentage of taxed',
    pu: 'Percentual de tributados'
  },
  DeliveryCompletionRatio: {
    zh: '派送完成比例',
    en: 'Delivery completion ratio',
    pu: 'Relação de conclusão de entrega'
  }

};
export { trajectory };
