<template>
  <div class="app-container" style="height: calc(100vh - 66px);overflow: hidden auto;">
    <el-form label-width="auto">
      <el-descriptions :colon="false" :column="5" direction="horizontal">
        <!--网点名称-->
        <el-descriptions-item :label="$t('basicData.siteName') + '：'">
          <span>{{ detailData.name }}</span>
        </el-descriptions-item>
        <!--应付主体-->
        <el-descriptions-item :label="$t('basicData.PayablePrincipal') + '：'">
          <span>{{ detailData.providerBody }}</span>
        </el-descriptions-item>
        <!--应付合计-->
        <el-descriptions-item :label="$t('receivablePayable.TotalAmountToBePaid') + '：'">
          <span>{{ detailData.payableUnsettledAmount }}</span>
        </el-descriptions-item>

        <!-- 已付合计 -->
        <el-descriptions-item :label="$t('receivablePayable.TotalAmountPaid') + '：'">
          <span>{{ detailData.payedAmount }}</span>
        </el-descriptions-item>
        <!-- 未付合计 -->
        <el-descriptions-item :label="$t('receivablePayable.TotalAmountOutstanding') + '：'">
          <span>{{ detailData.unpavedAmount }}</span>
        </el-descriptions-item>
      </el-descriptions>

      <el-form-item :label="$t('basicData.TradingHours') + '：'" style="margin-top: 20px;">
        <el-date-picker
          v-model="queryDate"
          type="datetimerange"
          size="small"
          :picker-options="pickerOptions"
          :format="$i18n.locale=='zh'?zhDate:ptDate"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          style="width: 500px"
          :placeholder="$t('basicData.SelectADatetime')"
          @change="getTableDetail()"
        />
        <!--导出账户流水-->
        <el-button
          type="primary"
          size="small"
          style="float: right;"
          @click="exportAccountInformation"
        >{{ $t('basicData.ExportAccountRollover') }}
        </el-button>
      </el-form-item>
    </el-form>
    <div class="pending">
      <el-table
        ref="elTable"
        :key="tableKey"
        :data="tableData"
        border
        row-key="idCopy"
        :tree-props="{ children: 'siteMeets', hasChildren: true }"
        @header-dragend="headerWidthChange"
      >
        <!--交易日期-->
        <el-table-column
          prop="acceptTime"
          :label="$t('basicData.TradingHours')"
          align="left"
          width="240"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.acceptTime }}</span>
          </template>
        </el-table-column>

        <!--核付时间-->
        <el-table-column
          prop="acceptOperateTime"
          :label="$t('receivablePayable.TimeOfPayment')"
          align="left"
          width="160"
          :show-overflow-tooltip="true"
        />

        <!--交款单号-->
        <el-table-column
          prop="payableNumber"
          :label="$t('basicData.PaymentSlipNumber')"
          align="left"
          width="240"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.isParent" class="batchNumber" @click="showDetalhes(scope.row)">{{ scope.row.payableNumber }}</span>
            <span v-else>{{ scope.row.payableNumber }}</span>
          </template>
        </el-table-column>
        <!--交款金额-->
        <el-table-column
          prop="actualFeeBrl"
          :label="$t('basicData.PaymentAmount')"
          align="left"
          width="240"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.actualFeeBrl }}</span>
          </template>
        </el-table-column>
        <!--票单金额-->
        <el-table-column
          prop="billFee"
          :label="$t('basicData.TicketAmount'
          )"
          align="left"
          width="240"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.billFee }}</span>
          </template>
        </el-table-column>
        <!--付款账户-->
        <el-table-column
          prop="accountAlias"
          :label="$t('basicData.PaymentAccount')"
          align="left"
          width="240"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.accountAlias }}</span>
          </template>
        </el-table-column>
        <!--付款方式-->
        <el-table-column
          prop="payableMethod"
          :label="$t('receivablePayable.ReceiptStatus')"
          align="center"
          width="200"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>
              <!--现金-->
              <span v-if="scope.row.payableMethod === 'cash'">{{ $t('basicData.cash') }}</span>
              <!--转账-->
              <span v-if="scope.row.payableMethod === 'transfer'">{{ $t('basicData.transferable') }}</span>
              <!--网络-->
              <span v-if="scope.row.payableMethod === 'network'">{{ $t('basicData.online') }}</span>
              <!--冲销-->
              <span v-if="scope.row.payableMethod === 'sterilisation'">{{ $t('basicData.Elimination') }}</span>
              <!--其他-->
              <span v-if="scope.row.payableMethod === 'other'">{{ $t('basicData.other') }}</span>
            </div>
          </template>
        </el-table-column>
        <!--附件-->
        <el-table-column
          prop="attachmentUrl"
          :label="$t('basicData.annex')"
          align="center"
          width="200"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>
              <!-- <el-button
                v-if="scope.row.attachmentUrl"
                type="text"
                @click="showImg(scope.row.attachmentUrl)"
              >{{ $t('basicData.View') }}</el-button> -->
              <el-link
                v-if="scope.row.attachmentUrl" type="success" :href="scope.row.attachmentUrl"
                target="_blank"
              >{{ $t('basicData.View') }}</el-link>
            </div>
          </template>
        </el-table-column>
        <!--备注-->
        <el-table-column
          prop="remark"
          :label="$t('receivablePayable.remark')"
          align="left"
          width="200"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.isParent" class="remarkStyle" v-html="scope.row.remark" />
            <div v-else class="remarkStyle" v-html="scope.row.remark" />
          </template>
        </el-table-column>
        <!--创建人-->
        <el-table-column
          prop="createUser"
          :label="$t('basicData.CreatePeople')"
          align="center"
          width="200"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.createUserName }}</div>
          </template>
        </el-table-column>
        <!--创建时间-->
        <el-table-column
          prop="createTime"
          :label="$t('basicData.CreationTime')"
          align="center"
          width="200"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.createTime }}</div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getCurrentPage"
          @size-change="getSizePage"
        />
      </div>
    </div>
    <!--付款明细-->
    <el-drawer
      :title="$t('basicData.PaymentDetails')"
      :append-to-body="true"
      :visible.sync="showDetailList"
      direction="ltr"
      size="90%"
    >
      <div>
        <batch-number-detail
          :key="detailKeyList"
          :batch-number-data="batchNumberData"
        />
      </div>
    </el-drawer>
    <div class="imgs">
      <el-image-viewer v-if="imageViewerObj.showViewer" :on-close="closeViewer" :url-list="imageViewerObj.imgArr" />
    </div>
  </div>
</template>

<script>
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import { apiExportOfBillFlow, apiGetDetailPage } from '@/api/finance/basicData';
import { cloneDeep } from 'lodash-es';
import dayjs from 'dayjs';
import { goExportList } from '@/utils/goExportList.js';

export default {
  name: 'CheckDetail',
  components: {
    ElImageViewer,
    'batch-number-detail': () => import('./batchNumberDetail')
  },
  props: {
    detailData: {
      type: Object,
      default: () => {
        return {
          id: 0,
          requestSource: 0, //  /请求来源 1:应付-揽收账户 2:应付-派送账户
          collectionPayableAccountCondition: {}, // 应付-揽收账户查询条件
          deliveryPayableAccountCondition: {}, // 应付-派送账户查询条件
          // collectionPayableAccountId: 0, // 应付-揽收账户id
          // deliveryPayableAccountId: 0, // 应付-派送账户id
          name: '', // 网点名称
          providerBody: '', // 应付主体.
          payableUnsettledAmount: 0, // 应付合计
          payedAmount: 0, // 已付合计
          unpavedAmount: 0// 未付合计
        };
      }
    }
  },
  data() {
    return {
      queryDate: [this.getAWeek().startDate + ' 00:00:00', this.getAWeek().endDate + ' 23:59:59'],
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      choiceDate0: '',
      pickerOptions: {
        // 设置不能选择的日期
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate0 = minDate.getTime();
          if (maxDate) {
            this.choiceDate0 = '';
          }
        },
        disabledDate: (time) => {
          const choiceDateTime = new Date(this.choiceDate0).getTime();
          const minTime = new Date(choiceDateTime).setDate(
            new Date(choiceDateTime).getDate() - 365
          );
          const maxTime = new Date(choiceDateTime).setDate(
            new Date(choiceDateTime).getDate() + 365
          );
          const min = minTime;
          const newDate =
            new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000;
          const max = newDate < maxTime ? newDate : maxTime;
          // 如果已经选中一个日期 则 返回 该日期前后一个月时间可选
          if (this.choiceDate0) {
            return time.getTime() < min || time.getTime() > max;
          }
          // 若一个日期也没选中 则 返回 当前日期以前日期可选
          return time.getTime() > newDate;
        }
      },
      tableKey: new Date().getTime(),
      tableData: [],
      total: 0,
      createUser: [],
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          isAccept: 1,
          acceptStartTime: '',
          acceptEndTime: '',
          isSiteDetailsRequest: true,
          siteId: 0
          // collectionPayableAccountId: 0, // 应付-揽收账户id
          // deliveryPayableAccountId: 0 // 应付-派送账户id
        }
      },
      imageViewerObj: {
        imgArr: [],
        showViewer: false
      },
      showDetailList: false,
      detailKeyList: new Date().getTime(),
      batchNumberData: null
    };
  },

  watch: {
    detailData: {
      handler(newVal, oldVal) {
        // if (newVal.id !== oldVal.id) {
        this.getTableDetail();
        // }
      },
      deep: true
    }
  },

  created() {
    this.createUser = getUserIdAndNameObj();
    this.getTableDetail();
  },
  methods: {
    exportAccountInformation() {
      // const exportTips = () => {
      //   const h = this.$createElement;
      //   this.$msgbox({
      //     // '提示'
      //     title: this.$t('collectionCenter.tips'),
      //     message: h('p', null, [
      //       // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
      //       h('span', this.$t('collectionCenter.ExportSuccessful')),
      //       // '请勿重复导出！'
      //       h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
      //     ]),
      //     showCancelButton: true,
      //     // '立即前往'
      //     confirmButtonText: this.$t('collectionCenter.Immediately'),
      //     // '关闭'
      //     cancelButtonText: this.$t('collectionCenter.close')
      //   }).then(action => {
      //     if (action === 'confirm') {
      //       this.$emit('clearDetail');
      //       this.$router.push({ path: '/resource/exportList' });
      //     }
      //   }).catch(() => {});
      // };

      const { id, requestSource, collectionPayableAccountCondition, deliveryPayableAccountCondition } = this.detailData;
      const { queryDate } = this;

      const param = {
        startTime: '',
        endTime: '',
        ids: id || undefined,
        requestSource: requestSource || undefined, // 派送/揽收应付账户专用字段
        collectionPayableAccountCondition: collectionPayableAccountCondition || undefined, // 派送/揽收应付账户专用字段
        deliveryPayableAccountCondition: deliveryPayableAccountCondition || undefined, // 派送/揽收应付账户专用字段
        isSiteDetailsRequest: true
      };
      if (queryDate && queryDate.length > 0) {
        param.startTime = queryDate[0];
        param.endTime = queryDate[1];
      }
      apiExportOfBillFlow(param).then(res => {
        if (res.status === 'OK') {
          // exportTips();
          return goExportList(this);
        }
      }).then(res => {
        if (res) {
          this.$emit('clearDetail');
        }
      }).catch(err => {
        console.error(err);
      });
    },
    // 打开详情
    showDetalhes(row) {
      this.batchNumberData = row;
      this.showDetailList = true;
      this.detailKeyList = new Date().getTime();
    },
    // 图片点击放大
    showImg(url) {
      this.$set(this.imageViewerObj, 'showViewer', true);
      this.$set(this.imageViewerObj, 'imgArr', url.split(','));
    },
    // 关闭查看图片
    closeViewer() {
      this.$set(this.imageViewerObj, 'showViewer', false);
    },
    // 默认最近一年时间
    getAWeek() {
      return {
        endDate: dayjs().format('YYYY-MM-DD'),
        startDate: dayjs().subtract(365, 'day').format('YYYY-MM-DD')
      };
    },
    // 表头宽度变化
    headerWidthChange() {
      this.$nextTick(() => {
        this.$refs.elTable.doLayout();
      });
    },

    getQuery() {
      const param = cloneDeep(this.queryForm);
      const { queryDate } = this;

      const { id, requestSource, collectionPayableAccountCondition, deliveryPayableAccountCondition } = this.detailData;

      param.params.siteId = id || undefined;

      // 派送/揽收应付账户专用字段
      param.params.requestSource = requestSource || undefined;
      param.params.collectionPayableAccountCondition = collectionPayableAccountCondition || undefined;
      param.params.deliveryPayableAccountCondition = deliveryPayableAccountCondition || undefined;

      if (queryDate && queryDate.length > 1) {
        param.params.acceptStartTime = queryDate[0];
        param.params.acceptEndTime = queryDate[1];
      }

      return param;
    },

    // 查询详情列表
    getTableDetail() {
      const randomString = (e, t) => {
        // e选取字符长度; t字符串
        e = e || 4;
        const a = t.length;
        let n = '';
        for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
        return n;
      };
      function repeat(receiptNumber, random) {
        const randomStr = randomString(4, receiptNumber);
        if (!random.includes(randomStr)) {
          random.push(randomStr);
          return randomStr;
        } else {
          repeat(receiptNumber, random);
        }
      }
      function recurrence(table) {
        const randomArr = [];
        for (let i = 0; i < table.length; i++) {
          const randomId = repeat(table[i].payableNumber.split('-')[1], randomArr);
          if (table[i].acceptTime) {
            table[i].acceptTime = table[i].acceptTime.replace('T', ' ');
          }
          if (table[i].isParent) {
            table[i]['idCopy'] = table[i].id;
          } else {
            table[i]['idCopy'] = table[i].id + randomId;
          }
          if (table[i].siteMeets && table[i].siteMeets.length > 0) {
            recurrence(table[i].siteMeets);
          }
        }
        return table;
      }

      const param = this.getQuery();

      // const param = cloneDeep(this.queryForm);

      // const { id, collectionPayableAccountId, deliveryPayableAccountId } = this.detailData;

      // param.params.siteId = id || undefined;
      // param.params.collectionPayableAccountId = collectionPayableAccountId || undefined;
      // param.params.deliveryPayableAccountId = deliveryPayableAccountId || undefined;
      // if (this.queryDate && this.queryDate.length > 1) {
      //   param.params.acceptStartTime = this.queryDate[0];
      //   param.params.acceptEndTime = this.queryDate[1];
      // }
      apiGetDetailPage(param).then(res => {
        if (res.status === 'OK') {
          this.tableData = recurrence(res.data.records);
          this.total = res.data.total;
        }
      });
    },
    // 翻页
    getCurrentPage(val) {
      this.queryForm.pageNumber = val;
      this.getTableDetail();
    },
    // 每页显示数量切换
    getSizePage(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getTableDetail();
    }
  }
};
</script>

<style scoped>
.app-container {
  padding:  0 30px;
}
.batchNumber {
  color: #007efd;
  cursor: pointer;
}
</style>
