var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "expressOrder" },
    [
      _c(
        "div",
        { staticClass: "orderInfo" },
        [
          _c(
            "div",
            { staticClass: "basicInformation", attrs: { id: "box1" } },
            [
              _c(
                "div",
                { staticClass: "title center" },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "font-weight": "bold",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("newOrder.BasicInformation")))]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.orderDialog = true
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("placeOrder.copyOrder")))]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "customerContent" }, [
                _c(
                  "div",
                  { staticClass: "selectContent" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "baseRuleForm",
                        staticClass: "demo-baseRuleForm",
                        attrs: {
                          model: _vm.baseRuleForm,
                          inline: "",
                          "label-position": "top",
                        },
                      },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 50 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      label: _vm.$t("placeOrder.client"),
                                      rules: {
                                        required: true,
                                        message: _vm.$t(
                                          "placeOrder.pleaseChoose"
                                        ),
                                        trigger: ["blur", "change"],
                                      },
                                      prop: "customeId",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "placeOrder.pleaseChoose"
                                          ),
                                          clearable: "",
                                          filterable: "",
                                        },
                                        on: { change: _vm.selectCustomeId },
                                        model: {
                                          value: _vm.baseRuleForm.customeId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.baseRuleForm,
                                              "customeId",
                                              $$v
                                            )
                                          },
                                          expression: "baseRuleForm.customeId",
                                        },
                                      },
                                      _vm._l(_vm.customerList, function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.username,
                                            value: item.id,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { prop: "merchantNumber" },
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "label" },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "placeOrder.CommercialOwner"
                                              )
                                            )
                                          ),
                                        ]),
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "QuestionMark",
                                            attrs: { placement: "top" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("placeOrder.collate")
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "QuestionMark",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          disabled: _vm.isInput,
                                          "filter-method": _vm.eventEnter,
                                          placeholder: _vm.$t(
                                            "placeOrder.pleaseChoose"
                                          ),
                                          clearable: "",
                                          filterable: "",
                                        },
                                        on: {
                                          change: _vm.handlemerchantCode,
                                          focus: _vm.focuseventEnter,
                                        },
                                        model: {
                                          value:
                                            _vm.baseRuleForm.merchantNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.baseRuleForm,
                                              "merchantNumber",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "baseRuleForm.merchantNumber",
                                        },
                                      },
                                      _vm._l(
                                        _vm.commercialList,
                                        function (item) {
                                          return _c(
                                            "el-option",
                                            {
                                              key: item.id,
                                              attrs: {
                                                label: item.merchantName,
                                                value: item.merchantNumber,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    float: "left",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.merchantName || "--"
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "20px",
                                                    "margin-right": "5px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.merchantNumber ||
                                                        "--"
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      label: _vm.$t("newOrder.CollectionType"),
                                      rules: {
                                        required: true,
                                        message: _vm.$t(
                                          "placeOrder.pleaseChoose"
                                        ),
                                        trigger: ["blur", "change"],
                                      },
                                      prop: "collectType",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "placeOrder.pleaseChoose"
                                          ),
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.baseRuleForm.collectType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.baseRuleForm,
                                              "collectType",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "baseRuleForm.collectType",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: _vm.$t(
                                              "collectionCenter.dropOff"
                                            ),
                                            value: "1",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: _vm.$t(
                                              "collectionCenter.doorPickUp"
                                            ),
                                            value: "2",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      label: _vm.$t(
                                        "GlobalSetObj.serviceTypes"
                                      ),
                                      prop: "region",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "placeOrder.pleaseChoose"
                                          ),
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.baseRuleForm.expressType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.baseRuleForm,
                                              "expressType",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "baseRuleForm.expressType",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: _vm.$t("GlobalSetObj.Canal"),
                                            value: "2",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: _vm.$t(
                                              "GlobalSetObj.postal"
                                            ),
                                            value: "3",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      label: _vm.$t("newOrder.orderNumber"),
                                      rules: {
                                        min: 2,
                                        max: 50,
                                        message: _vm.$t(
                                          "GlobalSetObj.to50characters"
                                        ),
                                        trigger: "blur",
                                      },
                                      prop: "customerNumber",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled: _vm.isInput,
                                        placeholder: _vm.$t(
                                          "placeOrder.pleaseInput"
                                        ),
                                        clearable: "",
                                        maxlength: "50",
                                        type: "text",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.getCustomerNumber(
                                            _vm.baseRuleForm.customerNumber
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.baseRuleForm.customerNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.baseRuleForm,
                                            "customerNumber",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression:
                                          "baseRuleForm.customerNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      label: _vm.$t("placeOrder.packageType"),
                                      rules: {
                                        required: true,
                                        message: _vm.$t(
                                          "placeOrder.pleaseChoose"
                                        ),
                                        trigger: ["blur", "change"],
                                      },
                                      prop: "orderPackageType",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "placeOrder.pleaseChoose"
                                          ),
                                          clearable: "",
                                        },
                                        model: {
                                          value:
                                            _vm.baseRuleForm.orderPackageType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.baseRuleForm,
                                              "orderPackageType",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "baseRuleForm.orderPackageType",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: _vm.$t("placeOrder.zhifa"),
                                            value: "1",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      label: _vm.$t("placeOrder.goodType"),
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "placeOrder.pleaseChoose"
                                          ),
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.baseRuleForm.cargoType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.baseRuleForm,
                                              "cargoType",
                                              $$v
                                            )
                                          },
                                          expression: "baseRuleForm.cargoType",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: _vm.$t("placeOrder.Normal"),
                                            value: 0,
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: _vm.$t("placeOrder.big01"),
                                            value: 1,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "basicInformation", attrs: { id: "box2" } },
            [
              _c(
                "div",
                { staticClass: "title center" },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "font-weight": "bold",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("placeOrder.senderName")))]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.customerAddress(_vm.$t("placeOrder.senderName"))
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("placeOrder.senderAddress")) + " ")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "customerContent" }, [
                _c(
                  "div",
                  { staticClass: "selectContent" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "senderInfoForm",
                        staticClass: "demo-baseRuleForm",
                        attrs: {
                          model: _vm.senderInfoParams,
                          inline: "",
                          "label-position": "top",
                        },
                      },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 50 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "80%" },
                                    attrs: {
                                      label: _vm.$t("placeOrder.name"),
                                      rules: _vm.checkStr50,
                                      prop: "senderName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "80%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "placeOrder.pleaseInput"
                                        ),
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.senderInfoParams.senderName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.senderInfoParams,
                                            "senderName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "senderInfoParams.senderName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "80%" },
                                    attrs: {
                                      label: _vm.$t("GlobalSetObj.MobilePhone"),
                                      rules: _vm.isPhone,
                                      prop: "senderMobile",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "80%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "placeOrder.pleaseInput"
                                        ),
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.senderInfoParams.senderMobile,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.senderInfoParams,
                                            "senderMobile",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression:
                                          "senderInfoParams.senderMobile",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "80%" },
                                    attrs: {
                                      label: _vm.$t("placeOrder.Tax"),
                                      rules: _vm.isTax12,
                                      prop: "senderTax",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "80%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "newOrder.PleaseEnter12Or14DigitTaxNumber"
                                        ),
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.senderInfoParams.senderTax,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.senderInfoParams,
                                            "senderTax",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression:
                                          "senderInfoParams.senderTax",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { gutter: 50 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "80%" },
                                    attrs: {
                                      label: _vm.$t(
                                        "GlobalSetObj.NationalRegistrationNumber"
                                      ),
                                      rules: _vm.IeNumber,
                                      prop: "senderIeNumber",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "80%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "placeOrder.pleaseInput"
                                        ),
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.senderInfoParams.senderIeNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.senderInfoParams,
                                            "senderIeNumber",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression:
                                          "senderInfoParams.senderIeNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "80%" },
                                    attrs: {
                                      label: _vm.$t("placeOrder.zipcode"),
                                      rules: _vm.isZipCode,
                                      prop: "senderZipcode",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "80%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "placeOrder.pleaseInput"
                                        ),
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.senderInfoParams.senderZipcode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.senderInfoParams,
                                            "senderZipcode",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression:
                                          "senderInfoParams.senderZipcode",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticStyle: { display: "flex" },
                                attrs: { span: 8 },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "30%" },
                                    attrs: {
                                      label: _vm.$t("placeOrder.State"),
                                      rules: [
                                        {
                                          required: true,
                                          message: _vm.$t(
                                            "placeOrder.pleaseChoose"
                                          ),
                                          trigger: ["blur", "change"],
                                        },
                                      ],
                                      prop: "senderState",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "formTable",
                                        staticStyle: { width: "90%" },
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "placeOrder.pleaseChoose"
                                          ),
                                          filterable: "",
                                        },
                                        model: {
                                          value:
                                            _vm.senderInfoParams.senderState,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.senderInfoParams,
                                              "senderState",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "senderInfoParams.senderState",
                                        },
                                      },
                                      _vm._l(
                                        _vm.senderStateList,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.label,
                                              value: item.label,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "70%" },
                                    attrs: {
                                      label: _vm.$t("placeOrder.city"),
                                      rules: _vm.cityComplement,
                                      prop: "senderCity",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "50%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "placeOrder.pleaseInput"
                                        ),
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.senderInfoParams.senderCity,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.senderInfoParams,
                                            "senderCity",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "senderInfoParams.senderCity",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isShowSenderMoreInfo,
                                expression: "isShowSenderMoreInfo",
                              },
                            ],
                            attrs: { gutter: 50 },
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "80%" },
                                    attrs: {
                                      label: _vm.$t("placeOrder.Area"),
                                      rules: _vm.addressComplement,
                                      prop: "senderArea",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "80%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "placeOrder.pleaseInput"
                                        ),
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.senderInfoParams.senderArea,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.senderInfoParams,
                                            "senderArea",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "senderInfoParams.senderArea",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "80%" },
                                    attrs: {
                                      label: _vm.$t("newOrder.Street"),
                                      prop: "senderStreet",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "80%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "newOrder.ConfirmStreetNumber"
                                        ),
                                        clearable: "",
                                        maxlength: "50",
                                        "show-word-limit": "",
                                      },
                                      model: {
                                        value:
                                          _vm.senderInfoParams.senderStreet,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.senderInfoParams,
                                            "senderStreet",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "senderInfoParams.senderStreet",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "80%" },
                                    attrs: {
                                      label: _vm.$t("placeOrder.HouseNumber"),
                                      rules: _vm.checkStr5,
                                      prop: "senderHouseNumber",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "80%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "placeOrder.pleaseInput"
                                        ),
                                        clearable: "",
                                        maxlength: "6",
                                      },
                                      model: {
                                        value:
                                          _vm.senderInfoParams
                                            .senderHouseNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.senderInfoParams,
                                            "senderHouseNumber",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression:
                                          "senderInfoParams.senderHouseNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "80%" },
                                    attrs: {
                                      label: _vm.$t(
                                        "placeOrder.addressComplement"
                                      ),
                                      rules: _vm.addressComplement,
                                      prop: "senderComplement",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "80%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "placeOrder.pleaseInput"
                                        ),
                                        clearable: "",
                                        maxlength: "50",
                                      },
                                      model: {
                                        value:
                                          _vm.senderInfoParams.senderComplement,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.senderInfoParams,
                                            "senderComplement",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "senderInfoParams.senderComplement",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "80%" },
                                    attrs: {
                                      label: _vm.$t("placeOrder.email"),
                                      rules: _vm.checkMail,
                                      prop: "senderMail",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "70%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "placeOrder.pleaseInput"
                                        ),
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.senderInfoParams.senderMail,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.senderInfoParams,
                                            "senderMail",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "senderInfoParams.senderMail",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "80%" },
                                    attrs: {
                                      label: _vm.$t("GlobalSetObj.userPhone"),
                                      prop: "senderPhone",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "70%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "placeOrder.pleaseInput"
                                        ),
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.senderInfoParams.senderPhone,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.senderInfoParams,
                                            "senderPhone",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "senderInfoParams.senderPhone",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "moreInfo",
                                        on: { click: _vm.showSenderMore },
                                      },
                                      [
                                        _vm._v(
                                          " 《" +
                                            _vm._s(_vm.$t("placeOrder.fold"))
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        !_vm.isShowSenderMoreInfo
                          ? _c(
                              "el-row",
                              { attrs: { gutter: 50 } },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { "margin-bottom": "10px" },
                                    attrs: { span: 24 },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "moreInfo",
                                        on: { click: _vm.showSenderMore },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("placeOrder.moreInformation")
                                          ) + " 》"
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-row",
                          {
                            staticClass: "verticallyCentered",
                            attrs: { gutter: 20 },
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 18 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      label: _vm.$t(
                                        "placeOrder.detailedAddress"
                                      ),
                                      rules: [
                                        {
                                          required: true,
                                          message: _vm.$t(
                                            "placeOrder.pleaseInput"
                                          ),
                                          trigger: ["blur", "change"],
                                        },
                                        {
                                          min: 2,
                                          max: 200,
                                          message: _vm.$t(
                                            "newOrder.TdetailedAddressCharacter"
                                          ),
                                          trigger: "blur",
                                        },
                                      ],
                                      prop: "senderAddress",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "newOrder.StreetHouseNumber"
                                        ),
                                        maxlength: "200",
                                        "show-word-limit": "",
                                        type: "text",
                                      },
                                      model: {
                                        value:
                                          _vm.senderInfoParams.senderAddress,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.senderInfoParams,
                                            "senderAddress",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "senderInfoParams.senderAddress",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type:
                                        "" +
                                        (_vm.isInput === false
                                          ? "primary"
                                          : "info"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.SynchronousCustomer(
                                          _vm.$t("placeOrder.senderName")
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("placeOrder.SynchronizeClient")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "basicInformation", attrs: { id: "box3" } },
            [
              _c(
                "div",
                { staticClass: "title center" },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "font-weight": "bold",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("placeOrder.receiveName")))]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.customerAddress(_vm.$t("placeOrder.receiveName"))
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("placeOrder.clientAddress")) + " ")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "customerContent" }, [
                _c(
                  "div",
                  { staticClass: "selectContent" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "receiverInfoForm",
                        staticClass: "demo-baseRuleForm",
                        attrs: {
                          model: _vm.receiverInfoParams,
                          inline: "",
                          "label-position": "top",
                        },
                      },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 50 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "80%" },
                                    attrs: {
                                      label: _vm.$t("placeOrder.name"),
                                      rules: _vm.checkStr50,
                                      prop: "receiveName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "80%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "placeOrder.pleaseInput"
                                        ),
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.receiverInfoParams.receiveName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.receiverInfoParams,
                                            "receiveName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "receiverInfoParams.receiveName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "80%" },
                                    attrs: {
                                      label: _vm.$t("GlobalSetObj.MobilePhone"),
                                      rules: _vm.isPhone,
                                      prop: "receiveMobile",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "80%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "placeOrder.pleaseInput"
                                        ),
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.receiverInfoParams.receiveMobile,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.receiverInfoParams,
                                            "receiveMobile",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "receiverInfoParams.receiveMobile",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "80%" },
                                    attrs: {
                                      label: _vm.$t("placeOrder.Tax"),
                                      rules: _vm.isTax12,
                                      prop: "receiveTax",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "80%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "newOrder.PleaseEnter12Or14DigitTaxNumber"
                                        ),
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.receiverInfoParams.receiveTax,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.receiverInfoParams,
                                            "receiveTax",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression:
                                          "receiverInfoParams.receiveTax",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { gutter: 50 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "80%" },
                                    attrs: {
                                      label: _vm.$t(
                                        "GlobalSetObj.NationalRegistrationNumber"
                                      ),
                                      rules: _vm.IeNumber1,
                                      prop: "receiveIeNumber",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "80%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "placeOrder.pleaseInput"
                                        ),
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.receiverInfoParams
                                            .receiveIeNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.receiverInfoParams,
                                            "receiveIeNumber",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression:
                                          "receiverInfoParams.receiveIeNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "80%" },
                                    attrs: {
                                      label: _vm.$t("placeOrder.zipcode"),
                                      rules: _vm.isZipCode,
                                      prop: "receiveZipcode",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "80%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "placeOrder.pleaseInput"
                                        ),
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.receiverInfoParams.receiveZipcode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.receiverInfoParams,
                                            "receiveZipcode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "receiverInfoParams.receiveZipcode",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticStyle: { display: "flex" },
                                attrs: { span: 8 },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "30%" },
                                    attrs: {
                                      label: _vm.$t("placeOrder.State"),
                                      rules: [
                                        {
                                          required: true,
                                          message: _vm.$t(
                                            "placeOrder.pleaseChoose"
                                          ),
                                          trigger: ["blur", "change"],
                                        },
                                      ],
                                      prop: "receiveState",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "90%" },
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "placeOrder.pleaseChoose"
                                          ),
                                          clearable: "",
                                          filterable: "",
                                        },
                                        model: {
                                          value:
                                            _vm.receiverInfoParams.receiveState,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.receiverInfoParams,
                                              "receiveState",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "receiverInfoParams.receiveState",
                                        },
                                      },
                                      _vm._l(
                                        _vm.senderStateList,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.label,
                                              value: item.label,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "70%" },
                                    attrs: {
                                      label: _vm.$t("placeOrder.city"),
                                      rules: _vm.cityComplement,
                                      prop: "receiveCity",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "50%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "placeOrder.pleaseInput"
                                        ),
                                        clearable: "",
                                        maxlength: "31",
                                      },
                                      model: {
                                        value:
                                          _vm.receiverInfoParams.receiveCity,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.receiverInfoParams,
                                            "receiveCity",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "receiverInfoParams.receiveCity",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isShowRecipientMoreInfo,
                                expression: "isShowRecipientMoreInfo",
                              },
                            ],
                            attrs: { gutter: 50 },
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "80%" },
                                    attrs: {
                                      label: _vm.$t("placeOrder.Area"),
                                      rules: _vm.addressComplement,
                                      prop: "receiveArea",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "80%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "placeOrder.pleaseInput"
                                        ),
                                        clearable: "",
                                        maxlength: "31",
                                      },
                                      model: {
                                        value:
                                          _vm.receiverInfoParams.receiveArea,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.receiverInfoParams,
                                            "receiveArea",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "receiverInfoParams.receiveArea",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "80%" },
                                    attrs: {
                                      label: _vm.$t("newOrder.Street"),
                                      prop: "receiveStreet",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "80%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "newOrder.ConfirmStreetNumber"
                                        ),
                                        clearable: "",
                                        maxlength: "50",
                                        "show-word-limit": "",
                                      },
                                      model: {
                                        value:
                                          _vm.receiverInfoParams.receiveStreet,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.receiverInfoParams,
                                            "receiveStreet",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "receiverInfoParams.receiveStreet",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "80%" },
                                    attrs: {
                                      label: _vm.$t("placeOrder.HouseNumber"),
                                      rules: _vm.checkStr5,
                                      prop: "receiveHouseNumber",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "80%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "placeOrder.pleaseInput"
                                        ),
                                        clearable: "",
                                        maxlength: "6",
                                      },
                                      model: {
                                        value:
                                          _vm.receiverInfoParams
                                            .receiveHouseNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.receiverInfoParams,
                                            "receiveHouseNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "receiverInfoParams.receiveHouseNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "80%" },
                                    attrs: {
                                      label: _vm.$t(
                                        "placeOrder.addressComplement"
                                      ),
                                      rules: _vm.addressComplement,
                                      prop: "receiveComplement",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "80%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "placeOrder.pleaseInput"
                                        ),
                                        clearable: "",
                                        maxlength: "35",
                                      },
                                      model: {
                                        value:
                                          _vm.receiverInfoParams
                                            .receiveComplement,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.receiverInfoParams,
                                            "receiveComplement",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "receiverInfoParams.receiveComplement",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "80%" },
                                    attrs: {
                                      label: _vm.$t("placeOrder.email"),
                                      rules: _vm.checkMail,
                                      prop: "receiveMail",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "70%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "placeOrder.pleaseInput"
                                        ),
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.receiverInfoParams.receiveMail,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.receiverInfoParams,
                                            "receiveMail",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "receiverInfoParams.receiveMail",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "80%" },
                                    attrs: {
                                      label: _vm.$t("GlobalSetObj.userPhone"),
                                      prop: "receivePhone",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "70%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "placeOrder.pleaseInput"
                                        ),
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.receiverInfoParams.receivePhone,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.receiverInfoParams,
                                            "receivePhone",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "receiverInfoParams.receivePhone",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "moreInfo",
                                        on: { click: _vm.showRecipientMore },
                                      },
                                      [
                                        _vm._v(
                                          " 《" +
                                            _vm._s(_vm.$t("placeOrder.fold"))
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        !_vm.isShowRecipientMoreInfo
                          ? _c(
                              "el-row",
                              { attrs: { gutter: 50 } },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { "margin-bottom": "10px" },
                                    attrs: { span: 24 },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "moreInfo",
                                        on: { click: _vm.showRecipientMore },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("placeOrder.moreInformation")
                                          ) + " 》"
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-row",
                          {
                            staticClass: "verticallyCentered",
                            attrs: { gutter: 20 },
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 18 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      label: _vm.$t(
                                        "placeOrder.detailedAddress"
                                      ),
                                      rules: [
                                        {
                                          required: true,
                                          message: _vm.$t(
                                            "placeOrder.pleaseInput"
                                          ),
                                          trigger: ["blur", "change"],
                                        },
                                        {
                                          min: 2,
                                          max: 200,
                                          message: _vm.$t(
                                            "newOrder.TdetailedAddressCharacter"
                                          ),
                                          trigger: "blur",
                                        },
                                      ],
                                      prop: "receiveAddress",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "newOrder.StreetHouseNumber"
                                        ),
                                        clearable: "",
                                        maxlength: "200",
                                        "show-word-limit": "",
                                        type: "text",
                                      },
                                      model: {
                                        value:
                                          _vm.receiverInfoParams.receiveAddress,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.receiverInfoParams,
                                            "receiveAddress",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "receiverInfoParams.receiveAddress",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type:
                                        "" +
                                        (_vm.isInput === false
                                          ? "primary"
                                          : "info"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.SynchronousCustomer(
                                          _vm.$t("placeOrder.receiveName")
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("placeOrder.SynchronizeClient")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "basicInformation", attrs: { id: "box4" } },
            [
              _c(
                "div",
                { staticClass: "title center" },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "font-weight": "bold",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("placeOrder.infoPackage")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-plus" },
                      on: { click: _vm.addPackage },
                    },
                    [_vm._v(_vm._s(_vm.$t("placeOrder.addPackage")))]
                  ),
                ],
                1
              ),
              _c(
                "el-form",
                {
                  ref: "packageRuleForm",
                  staticClass: "demo-baseRuleForm",
                  attrs: {
                    model: _vm.orderPackageListInfo,
                    inline: "",
                    "label-position": "top",
                  },
                },
                _vm._l(
                  _vm.orderPackageListInfo.orderWaybillList,
                  function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "customerContent",
                        staticStyle: { "margin-top": "10px" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "selectContent" },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 30 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 4 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "placeOrder.packageType"
                                          ),
                                          prop:
                                            "orderWaybillList." +
                                            index +
                                            ".waybillType",
                                          rules: [
                                            {
                                              required: true,
                                              message: _vm.$t(
                                                "placeOrder.pleaseChoose"
                                              ),
                                              trigger: "change",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder: _vm.$t(
                                                "placeOrder.pleaseChoose"
                                              ),
                                              clearable: "",
                                            },
                                            model: {
                                              value: item.waybillType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "waybillType",
                                                  $$v
                                                )
                                              },
                                              expression: "item.waybillType",
                                            },
                                          },
                                          _vm._l(
                                            item.packageType,
                                            function (packageType) {
                                              return _c("el-option", {
                                                key: packageType.code,
                                                attrs: {
                                                  label: packageType.code,
                                                  value: packageType.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 10 } },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label:
                                                    _vm.$t("placeOrder.long"),
                                                  prop:
                                                    "orderWaybillList." +
                                                    index +
                                                    ".length",
                                                  rules: _vm.checkFloatNumber,
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    placeholder: _vm.$t(
                                                      "placeOrder.pleaseInput"
                                                    ),
                                                    clearable: "",
                                                    type: "number",
                                                  },
                                                  model: {
                                                    value: item.length,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "length",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.length",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label:
                                                    _vm.$t("placeOrder.width"),
                                                  prop:
                                                    "orderWaybillList." +
                                                    index +
                                                    ".width",
                                                  rules: _vm.checkFloatNumber,
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    placeholder: _vm.$t(
                                                      "placeOrder.pleaseInput"
                                                    ),
                                                    clearable: "",
                                                    type: "number",
                                                  },
                                                  model: {
                                                    value: item.width,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "width",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.width",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label:
                                                    _vm.$t("placeOrder.height"),
                                                  prop:
                                                    "orderWaybillList." +
                                                    index +
                                                    ".height",
                                                  rules: _vm.checkFloatNumber,
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    placeholder: _vm.$t(
                                                      "placeOrder.pleaseInput"
                                                    ),
                                                    clearable: "",
                                                    type: "number",
                                                  },
                                                  model: {
                                                    value: item.height,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "height",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.height",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "verticallyCentered",
                                    attrs: { span: 5 },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "placeOrder.parcelWeight"
                                          ),
                                          prop:
                                            "orderWaybillList." +
                                            index +
                                            ".weight",
                                          rules: _vm.checkFloatNumber,
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "placeOrder.pleaseInput"
                                            ),
                                            clearable: "",
                                            type: "number",
                                          },
                                          model: {
                                            value: item.weight,
                                            callback: function ($$v) {
                                              _vm.$set(item, "weight", $$v)
                                            },
                                            expression: "item.weight",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                index !== 0
                                  ? _c(
                                      "el-col",
                                      {
                                        staticClass: "delPackge",
                                        attrs: { span: 1 },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "danger" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.delPackage(index)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("placeOrder.Delete")
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              { attrs: { gutter: 20 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 5 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { display: "block" },
                                        attrs: {
                                          label: _vm.$t(
                                            "placeOrder.DateOfInvoicing"
                                          ),
                                          prop:
                                            "orderWaybillList." +
                                            index +
                                            ".invoiceIssueDate",
                                          rules: [
                                            {
                                              required: true,
                                              message: _vm.$t(
                                                "placeOrder.pleaseChoose"
                                              ),
                                              trigger: "change",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("el-date-picker", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            clearable: false,
                                            format:
                                              _vm.$i18n.locale == "zh"
                                                ? _vm.zhDate
                                                : _vm.ptDate,
                                            "picker-options": _vm.pickerOptions,
                                            placeholder: _vm.$t(
                                              "newOrder.SelectDatetime"
                                            ),
                                            "value-format":
                                              _vm.$i18n.locale == "zh"
                                                ? _vm.zhDate
                                                : _vm.ptDate,
                                            type: "date",
                                          },
                                          model: {
                                            value: item.invoiceIssueDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "invoiceIssueDate",
                                                $$v
                                              )
                                            },
                                            expression: "item.invoiceIssueDate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 4 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          label: _vm.$t(
                                            "placeOrder.InvoiceAmount"
                                          ),
                                          prop:
                                            "orderWaybillList." +
                                            index +
                                            ".invoiceMoney",
                                          rules: _vm.InvoiceAmountRule,
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "placeOrder.pleaseInput"
                                            ),
                                            clearable: "",
                                            type: "number",
                                          },
                                          model: {
                                            value: item.invoiceMoney,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "invoiceMoney",
                                                $$v
                                              )
                                            },
                                            expression: "item.invoiceMoney",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { display: "block" },
                                        attrs: {
                                          label: _vm.$t(
                                            "placeOrder.InvoiceNumber"
                                          ),
                                          prop:
                                            "orderWaybillList." +
                                            index +
                                            ".invoiceNumber",
                                          rules: _vm.invoiceNumber,
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "placeOrder.PleaseEnter6To16DigitInvoiceNumber"
                                            ),
                                            clearable: "",
                                          },
                                          model: {
                                            value: item.invoiceNumber,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "invoiceNumber",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "item.invoiceNumber",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { display: "block" },
                                        attrs: {
                                          label: _vm.$t(
                                            "GlobalSetObj.TheInvoiceSerialNumber"
                                          ),
                                          prop:
                                            "orderWaybillList." +
                                            index +
                                            ".invoiceSerialNumber",
                                          rules: _vm.iceSerialIndex,
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "placeOrder.PleaseEnter1To8invoiceSerialIndex"
                                            ),
                                            clearable: "",
                                          },
                                          model: {
                                            value: item.invoiceSerialNumber,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "invoiceSerialNumber",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression:
                                              "item.invoiceSerialNumber",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { display: "block" },
                                        attrs: {
                                          label: _vm.$t(
                                            "placeOrder.scanOrderNumber"
                                          ),
                                          prop:
                                            "orderWaybillList." +
                                            index +
                                            ".scanOrderNumber",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "placeOrder.pleaseInput"
                                            ),
                                            clearable: "",
                                            maxlength: "80",
                                          },
                                          model: {
                                            value: item.scanOrderNumber,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "scanOrderNumber",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "item.scanOrderNumber",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { display: "block" },
                                        attrs: {
                                          label: _vm.$t(
                                            "placeOrder.InvoiceKey"
                                          ),
                                          prop:
                                            "orderWaybillList." +
                                            index +
                                            ".invoiceAccessKey",
                                          rules: _vm.invoiceKey,
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "placeOrder.pleaseInput"
                                            ),
                                            clearable: "",
                                            rows: "2",
                                            type: "textarea",
                                          },
                                          model: {
                                            value: item.invoiceAccessKey,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "invoiceAccessKey",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "item.invoiceAccessKey",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c("el-col", {
                                  staticStyle: {
                                    "border-bottom": "1px dashed #cccccc",
                                    "margin-bottom": "10px",
                                  },
                                  attrs: { span: 24 },
                                }),
                              ],
                              1
                            ),
                            _vm._l(
                              item.orderItemList,
                              function (itemGood, goodIndex) {
                                return _c(
                                  "el-row",
                                  { key: goodIndex, attrs: { gutter: 30 } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 7 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              label:
                                                _vm.$t("placeOrder.wpName"),
                                              prop:
                                                "orderWaybillList." +
                                                index +
                                                ".orderItemList." +
                                                goodIndex +
                                                ".name",
                                              rules: [
                                                {
                                                  required: true,
                                                  message: _vm.$t(
                                                    "placeOrder.pleaseInput"
                                                  ),
                                                  trigger: "change",
                                                },
                                              ],
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: _vm.$t(
                                                  "placeOrder.pleaseInput"
                                                ),
                                                clearable: "",
                                                maxlength: "128",
                                                "show-word-limit": "",
                                              },
                                              model: {
                                                value: itemGood.name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    itemGood,
                                                    "name",
                                                    $$v
                                                  )
                                                },
                                                expression: "itemGood.name",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 7 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              label: _vm.$t(
                                                "placeOrder.declaredValue"
                                              ),
                                              prop:
                                                "orderWaybillList." +
                                                index +
                                                ".orderItemList." +
                                                goodIndex +
                                                ".declaredValue",
                                              rules: _vm.checkFloatNumber1,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-input",
                                              {
                                                staticClass:
                                                  "input-with-select",
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "placeOrder.pleaseInput"
                                                  ),
                                                  clearable: "",
                                                  type: "number",
                                                },
                                                model: {
                                                  value: itemGood.declaredValue,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      itemGood,
                                                      "declaredValue",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "itemGood.declaredValue",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    attrs: { slot: "prefix" },
                                                    slot: "prefix",
                                                  },
                                                  [_vm._v("R$")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 7 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              label: _vm.$t(
                                                "placeOrder.quantity"
                                              ),
                                              prop:
                                                "orderWaybillList." +
                                                index +
                                                ".orderItemList." +
                                                goodIndex +
                                                ".quantity",
                                              rules: _vm.isNumber,
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: _vm.$t(
                                                  "placeOrder.pleaseInput"
                                                ),
                                                clearable: "",
                                                type: "number",
                                              },
                                              model: {
                                                value: itemGood.quantity,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    itemGood,
                                                    "quantity",
                                                    $$v
                                                  )
                                                },
                                                expression: "itemGood.quantity",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    item.orderItemList.length > 1
                                      ? _c(
                                          "el-col",
                                          {
                                            staticClass: "delPackge",
                                            attrs: { span: 1 },
                                          },
                                          [
                                            goodIndex !== 0
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "danger",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.delGoods(
                                                              index,
                                                              goodIndex
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "placeOrder.Delete"
                                                            )
                                                          ) + " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "verticallyCentered",
                                    attrs: { span: 24 },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          cursor: "pointer",
                                          color: "#409eff",
                                          "font-size": "16px",
                                          "font-weight": "bold",
                                          "margin-bottom": "10px",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addGoods(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-plus",
                                          staticStyle: {
                                            "font-size": "16px",
                                            "font-weight": "bold",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "5px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "orderCenterCont.AddProducts"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ]
                    )
                  }
                ),
                0
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              staticStyle: { display: "flex", "justify-content": "end" },
              attrs: { gutter: 15 },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c("el-button", { on: { click: _vm.empty } }, [
                    _vm._v(_vm._s(_vm.$t("newOrder.empty"))),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { "margin-left": "20px" }, attrs: { span: 2 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.placeAnOrder },
                    },
                    [_vm._v(_vm._s(_vm.$t("placeOrder.test")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "orderInfoList" },
        _vm._l(_vm.orderInfoList, function (item, index) {
          return _c(
            "div",
            { key: item.id },
            [
              _c(
                "el-link",
                {
                  attrs: {
                    type: "" + (_vm.ulLi === index + 1 ? "primary" : ""),
                    underline: false,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.scrollTop(item.id)
                    },
                  },
                },
                [_vm._v(_vm._s(item.title) + " ")]
              ),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "show-close": false,
            visible: _vm.countdownDialog,
            width: _vm.$i18n.locale == "zh" ? "20%" : "30%",
            top: "30vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.countdownDialog = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center",
                },
              },
              [
                _c("div", { staticClass: "countdown" }, [
                  _c("span", [_vm._v(_vm._s(_vm.countTime))]),
                ]),
                _c("span", { staticStyle: { "font-size": "28px" } }, [
                  _vm._v("s"),
                ]),
              ]
            ),
            _c(
              "div",
              { staticStyle: { "font-size": "18px", "margin-top": "15px" } },
              [_vm._v(_vm._s(_vm.$t("placeOrder.enterAutomatically")))]
            ),
          ]),
          _c(
            "div",
            {
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.countdownDialog = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("placeOrder.returnOrderList")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.continuePlaceOrders },
                },
                [_vm._v(_vm._s(_vm.$t("placeOrder.continueOrders")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "show-close": true,
            visible: _vm.orderDialog,
            top: "20vh",
            width: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.orderDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "div",
                {
                  staticStyle: {
                    color: "#303133",
                    "font-size": "20px",
                    "font-weight": "bold",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("placeOrder.PleaseInputNumber")))]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "18px",
                    "margin-top": "15px",
                    "margin-bottom": "15px",
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "demo-ruleForm",
                      attrs: { model: _vm.order },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "60%" },
                          attrs: {
                            rules: {
                              required: true,
                              message: this.$t("placeOrder.pleaseInput"),
                              trigger: ["blur", "change"],
                            },
                            prop: "WaybillNumber",
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "GlobalSetObj.pedidoCliente"
                                ),
                                clearable: "",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  $event.preventDefault()
                                  return _vm.getSearch($event)
                                },
                              },
                              model: {
                                value: _vm.order.WaybillNumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.order, "WaybillNumber", $$v)
                                },
                                expression: "order.WaybillNumber",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  type: "primary",
                                },
                                on: { click: _vm.getSearch },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    "header-cell-style": { background: "#d9d9d9" },
                    border: "",
                  },
                  on: { "row-click": _vm.confirmOrderDialog },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("GlobalSetObj.client"),
                      prop: "customerName",
                      width: "180",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("placeOrder.destinationZone"),
                      prop: "receiveAddress",
                      width: "220",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.$t("placeOrder.State")) +
                                  ": " +
                                  _vm._s(
                                    scope.row.receiverInfo.receiveState || "- -"
                                  )
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.$t("newOrder.City")) +
                                  " " +
                                  _vm._s(
                                    scope.row.receiverInfo.receiveCity || "- -"
                                  )
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.$t("orderDetails.Area")) +
                                  ": " +
                                  _vm._s(
                                    scope.row.receiverInfo.receiveArea || "- -"
                                  )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("placeOrder.destinationAddress"),
                      prop: "receiveAddress",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.receiverInfo.receiveAddress || "- -"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.orderDialog = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.Cancelar")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmOrderDialog(0)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "close-on-click-modal": false,
            "show-close": true,
            title:
              "" + _vm.addressTitle + _vm.$t("customerManagements.address"),
            visible: _vm.addressDialog,
            top: "10vh",
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addressDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "div",
                {
                  staticStyle: { "font-size": "18px", "margin-bottom": "15px" },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("GlobalSetObj.pleaseChoose")) +
                      _vm._s(_vm.addressTitle) +
                      _vm._s(_vm.$t("customerManagements.address")) +
                      " "
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "addressList" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px" },
                      attrs: {
                        placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                      },
                      model: {
                        value: _vm.addressForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.addressForm, "type", $$v)
                        },
                        expression: "addressForm.type",
                      },
                    },
                    [
                      _vm.addressTitle === "" + _vm.$t("placeOrder.senderName")
                        ? _c("el-option", {
                            attrs: {
                              label: _vm.addressTitle,
                              value: _vm.addressTitle,
                            },
                          })
                        : _c("el-option", {
                            attrs: {
                              label: _vm.addressTitle,
                              value: _vm.addressTitle,
                            },
                          }),
                      _c("el-option", {
                        attrs: {
                          label: _vm.$t("GlobalSetObj.zipCode"),
                          value: "2",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { width: "60%", "margin-top": "15px" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                        clearable: "",
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.getSearchAddress($event)
                        },
                      },
                      model: {
                        value: _vm.addressForm.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.addressForm, "name", $$v)
                        },
                        expression: "addressForm.name",
                      },
                    },
                    [
                      _c("el-button", {
                        staticStyle: {
                          "background-color": "#409eff",
                          color: "black",
                        },
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: { click: _vm.getSearchAddress },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "addressClass" }, [
                _vm.customerAddressList.length > 0
                  ? _c(
                      "div",
                      _vm._l(_vm.customerAddressList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass: "addressItem",
                            on: {
                              click: function ($event) {
                                return _vm.selectAddress(item, _vm.addressTitle)
                              },
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "span",
                                { staticStyle: { color: "#000000" } },
                                [_vm._v(_vm._s(item.name || "--"))]
                              ),
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "20px" } },
                                [_vm._v(_vm._s(item.phone || "--"))]
                              ),
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "20px" } },
                                [_vm._v(_vm._s(item.cpf || "--"))]
                              ),
                            ]),
                            _c("div", [
                              _c("span", {}, [
                                _vm._v(_vm._s(item.zipcode || "--")),
                              ]),
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "20px" } },
                                [_vm._v(_vm._s(item.state || "--"))]
                              ),
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "20px" } },
                                [_vm._v(_vm._s(item.city || "--"))]
                              ),
                            ]),
                            _c("div", [
                              _c("span", {}, [
                                _vm._v(_vm._s(item.area || "--")),
                              ]),
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "20px" } },
                                [_vm._v(_vm._s(item.addressDetail || "--"))]
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _c(
                      "div",
                      { staticClass: "nodataImage" },
                      [_c("svg-icon", { attrs: { "icon-class": "noData" } })],
                      1
                    ),
              ]),
              _c("el-pagination", {
                staticStyle: { margin: "10px 0", float: "right" },
                attrs: {
                  "current-page": _vm.queryForm.pageNumber,
                  "page-size": _vm.queryForm.pageSize,
                  "page-sizes": [5, 10, 20],
                  total: _vm.queryForm.total,
                  background: "",
                  layout: "total, sizes, prev, pager, next, jumper",
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
          _c("div", {
            staticClass: "dialog-footer",
            attrs: { slot: "footer" },
            slot: "footer",
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }