<template>
  <div class="SupplierContainer">
    <el-form
      ref="queryForm"
      :inline="false"
      :model="queryForm"
      label-position="top"
      size="default"
      @submit.native.prevent
    >
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item :label="$t('GlobalSetObj.RuleName')">
            <el-input
              v-model="queryForm.ruleName"
              :placeholder="$t('GlobalSetObj.pleaseInputContent')"
              clearable
              style="width: 100%;"
              @keyup.enter.native="getSearch"
            >
              <el-button slot="append" icon="el-icon-search" @click="getSearch" />
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <!--            设备名称-->
          <el-form-item :label="$t('GlobalSetObj.deviceName')" prop="deviceId">
            <el-select
              v-model="queryForm.deviceId"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width: 100%;"
              @change="getGridNumListSearch"
            >
              <el-option
                v-for="item in deviceList"
                :key="item.id"
                :label="item.deviceName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item :label="$t('GlobalSetObj.gridNum')" prop="gridNum">
            <el-select
              v-model="queryForm.gridNum"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              style="width: 100%;"
            >
              <el-option
                v-for="grid in gridNumsListSearch"
                :key="grid.id"
                :label="grid.gridNum"
                :value="grid.gridNum.toString()"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <!--  搜索    -->
          <div style="margin-top: 27px">
            <el-button size="medium" type="primary" @click="getSearch">{{ $t("GlobalSetObj.search") }}</el-button>
          </div>
        </el-col>
        <el-col :span="7">
          <el-form-item v-permit:remove="'btn:SortingCenter:ruleCode'" label="分拣套餐">
            <el-radio-group v-model="queryForm.ruleCode" style="width: 100%;" @change="moreSortRules">
              <el-radio-button label="Perus01">规则一</el-radio-button>
              <el-radio-button label="Perus02">规则二</el-radio-button>
              <el-radio-button label="Perus03">规则三</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- 新增 -->
    <el-button
      icon="el-icon-plus" size="small" style="margin-bottom: 15px"
      type="primary" @click="newAdd"
    >
      {{ $t('GlobalSetObj.add') }}
    </el-button>
    <!--  表格数据  -->
    <AnjunTableList
      ref="anjunTable"
      :format-time="isTormat"
      :has-operation="true"
      :header-list="tableHeaderList"
      :is-index="false"
      :is-tip="true"
      :op-width="210"
      :table-data="tableData"
      @on-edit="edit"
      @on-look="toLook"
      @on-del="onDel"
    />
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.number"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!--添加系统服务商-->
    <el-drawer
      ref="drawer"
      :append-to-body="true"
      :title="titleName"
      :visible.sync="drawer"
      custom-class="demo-drawer"
      direction="ltr"
      size="50%"
    >
      <div class="demo-drawer__content" style="padding:0 20px">
        <el-form
          :key="drawer" ref="addForm" :model="ruleForm"
          :rules="rules" class="demo-ruleForm" label-width="auto"
        >
          <el-form-item :label="$t('GlobalSetObj.RuleName')" prop="ruleName">
            <el-input
              v-model="ruleForm.ruleName"
              :disabled="isSee"
              :placeholder="$t('GlobalSetObj.pleaseInputContent')"
              clearable
              maxlength="50"
              show-word-limit
              type="text"
            />
          </el-form-item>
          <el-form-item :label="$t('GlobalSetObj.RuleType')">
            <el-select
              v-model="ruleForm.specialRuleFlag"
              :disabled="isSee || isBandType"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              style="width: 60%"
              @change="handleRuleType"
            >
              <el-option :label="$t('GlobalSetObj.ordinary')" :value="false" />
              <el-option :label="$t('GlobalSetObj.Especial')" :value="true" />
            </el-select>
          </el-form-item>
          <div v-if="ruleForm.specialRuleFlag" class="teshu">
            <el-form-item key="10001" :label="$t('GlobalSetObj.SortingCondition')">
              <el-select
                v-model="ruleForm.subRuleType"
                :disabled="isSee"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                style="width: 60%"
                @change="handlesubRuleType"
              >
                <el-option
                  v-for="(item,index) in SortingconditionList"
                  :key="index"
                  :label="item.label"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
            <!--            设备名称-->
            <el-form-item :label="$t('GlobalSetObj.deviceName')" prop="deviceId">
              <el-select
                v-model="ruleForm.deviceId"
                :disabled="isSee"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                collapse-tags
                filterable
                style="width: 60%"
                @change="getGridNumList"
              >
                <el-option
                  v-for="item in deviceList"
                  :key="item.id"
                  :label="item.deviceName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item :key="ruleForm.specialRuleFlag" :label="$t('GlobalSetObj.gridNum')" prop="gridNum">
              <el-select
                v-model="ruleForm.gridNum"
                :disabled="isSee"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                filterable
                style="width: 60%"
              >
                <el-option
                  v-for="grid in gridNumsList"
                  :key="grid.id"
                  :label="grid.gridNum"
                  :value="grid.gridNum.toString()"
                />
              </el-select>
            </el-form-item>
            <!--    客户        -->
            <div v-if="ruleForm.subRuleType === '1'">
              <el-form-item
                :label="$t('GlobalSetObj.client')"
                prop="customerId"
              >
                <el-select
                  v-model="ruleForm.customerId"
                  :disabled="isSee"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  clearable
                  filterable
                  style="width: 60%"
                  @change="changeCustomer"
                >
                  <el-option
                    v-for="(user) in usernameList"
                    :key="user.id"
                    :label="user.username"
                    :value="user.id"
                  />
                </el-select>
              </el-form-item>
              <!--    系统        -->
              <el-form-item
                :label="$t('GlobalSetObj.system')"
                prop="systemState"
              >
                <el-select
                  v-model="ruleForm.systemState"
                  :disabled="isSee"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  style="width: 60%"
                >
                  <el-option key="1" :label="$t('GlobalSetObj.Acquiesce')" :value="1" />
                  <el-option key="2" :label="$t('GlobalSetObj.Collect')" :value="2" />
                </el-select>
              </el-form-item>
              <!--    订单状态        -->
              <el-form-item
                v-if="ruleForm.systemState === 2"
                :label="$t('orderCenterCont.OrderStatus')"
                prop="waybillStatus"
              >
                <el-select
                  v-model="ruleForm.waybillStatus"
                  :disabled="isSee"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  clearable
                  filterable
                  multiple
                  style="width: 60%"
                  @change="selectStatus"
                >
                  <el-option
                    v-for="(item, ind) in waybillStatusList"
                    :key="ind"
                    :label="item.label"
                    :value="item.code"
                  />
                </el-select>
              </el-form-item>
              <!--    渠道        -->
              <el-form-item
                v-if="ruleForm.systemState === 2"
                :label="$t('customerManagements.channel01')"
                prop="channel"
              >
                <el-select
                  v-model="ruleForm.channel"
                  :disabled="isSee"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  style="width: 60%"
                >
                  <el-option key="1" :label="$t('GlobalSetObj.all')" :value="0" />
                  <el-option key="2" :label="$t('GlobalSetObj.postal')" :value="1" />
                  <el-option key="3" :label="$t('GlobalSetObj.ortherChannl')" :value="2" />
                </el-select>
              </el-form-item>
            </div>
            <!--     网点       -->
            <el-form-item
              v-if="ruleForm.subRuleType === '8'"
              key="10051"
              :label="$t('GlobalSetObj.Outlets')"
              prop="siteId"
            >
              <el-cascader
                v-model="ruleForm.siteId"
                :options="siteData"
                clearable
                filterable
                style="width: 60%"
                @change="hanleSiteId"
              />
            </el-form-item>
          </div>
          <div v-else class="putong">
            <!--服务商-->
            <el-form-item :label="$t('GlobalSetObj.fuwushang')" prop="providerIds">
              <el-select
                v-model="ruleForm.providerIds"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                filterable
                style="width: 60%"
                @change="selectChangeSupplier"
              >
                <el-option
                  v-for="(user,index) in transferData"
                  :key="index"
                  :label="user.providerName"
                  :value="user.id.toString()"
                />
              </el-select>
            </el-form-item>
            <el-form-item :key="ruleForm.specialRuleFlag" :label="$t('GlobalSetObj.otherIf')">
              <el-select
                v-model="ruleForm.otherSubRuleType"
                :disabled="isSee"
                :placeholder="$t('GlobalSetObj.ThereIsNo')"
                collapse-tags
                multiple
                style="width: 60%"
                @change="changeOtherSubRuleType"
              >
                <el-option :label="$t('GlobalSetObj.zipCode')" value="1" />
                <el-option :label="$t('GlobalSetObj.weight')" value="2" />
              </el-select>
            </el-form-item>
            <!--            设备名称-->
            <el-form-item :label="$t('GlobalSetObj.deviceName')" prop="deviceId">
              <el-select
                v-model="ruleForm.deviceId"
                :disabled="isSee"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                collapse-tags
                filterable
                style="width: 60%"
                @change="getGridNumList"
              >
                <el-option
                  v-for="item in deviceList"
                  :key="item.id"
                  :label="item.deviceName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <div :key="listKey" class="conditionList">
              <div v-for="(item,index) in ruleForm.subRuleList" :key="index" class="createCondition">
                <span
                  v-if="ruleForm.subRuleList.length > 1 && !isSee"
                  class="delFillter"
                  @click="delFillter(index)"
                ><i class="el-icon-circle-close" /></span>
                <el-form-item
                  :label="$t('GlobalSetObj.gridNum')"
                  :prop="`subRuleList.${index}.gridNums`"
                  :rules="subRuleListRules.gridNums"
                >
                  <el-select
                    v-model="item.gridNums"
                    :disabled="isSee"
                    :placeholder="$t('GlobalSetObj.pleaseChoose')"
                    filterable
                    style="width: 60%"
                    @focus="uniqueGridNum(ruleForm.subRuleList,index)"
                  >
                    <el-option
                      v-for="grid in item.gridList"
                      :key="grid.id"
                      :label="grid.gridNum"
                      :value="grid.gridNum"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item
                  v-if="ruleForm.otherSubRuleType.includes('1')"
                  :label="$t('GlobalSetObj.zipCode')"
                  :prop="`subRuleList.${index}.zipcodeAreaCode`"
                  :rules="subRuleListRules.zipcodeAreaCode"
                >
                  <el-input
                    v-model="item.zipcodeAreaCode"
                    :autosize="{ minRows: 2, maxRows: 4}"
                    :disabled="isSee"
                    :placeholder="$t('GlobalSetObj.pleaseInputContent')"
                    maxlength="2000"
                    show-word-limit
                    type="textarea"
                  />
                </el-form-item>
                <el-row v-if="ruleForm.otherSubRuleType.includes('2')">
                  <el-col :span="10">
                    <el-form-item
                      :label="$t('GlobalSetObj.MinWeight')"
                      :prop="`subRuleList.${index}.minWeight`"
                      :rules="subRuleListRules.minWeight"
                    >
                      <el-input
                        v-model="item.minWeight"
                        :disabled="isSee"
                        :placeholder="$t('GlobalSetObj.pleaseInput')"
                        clearable
                        type="number"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item
                      :label="$t('GlobalSetObj.MaxWeight')"
                      :prop="`subRuleList.${index}.maxWeight`"
                      :rules="subRuleListRules.maxWeight"
                    >
                      <el-input
                        v-model="item.maxWeight"
                        :disabled="isSee"
                        :placeholder="$t('GlobalSetObj.pleaseInput')"
                        clearable
                        type="number"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div v-if="ruleForm.otherSubRuleType.length !== 0 && !isSee" class="filters">
                <el-button icon="el-icon-plus" type="primary" @click="addFillter">
                  {{ $t('ChannelManage.CreateCondition') }}
                </el-button>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <div v-if="titleName === `${$t('GlobalSetObj.RuleDetails')}`" class="demo-drawer__footer">
        <el-button style="margin-right: 20px" type="primary" @click="cancelForm">
          {{ $t('GlobalSetObj.close') }}
        </el-button>
      </div>
      <div v-else class="demo-drawer__footer">
        <el-button style="margin-right: 20px" @click="cancelForm">{{ $t('GlobalSetObj.Cancelar') }}</el-button>
        <el-button type="primary" @click="saveForm">{{ $t('GlobalSetObj.Salve') }}</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import AnjunTableList from '@/components/anjun-tableList';
import {
  addSorterGridRule,
  apiRulePage,
  deleteSorterGridRule,
  deviceManagementList,
  editSorterGridRule,
  getAllcustomer,
  getMoreRule,
  getsorterGridRuleInfo,
  siteProvider,
  sorterGridRuleSelectGrid,
  sorterGridRuleSelectGrid01
} from '@/api/automaticSorting';
import { getUserName } from '@/utils/asyncTools';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { supplierMainPage } from '@/api/SupplierMain';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'SupplierMain',
  components: {
    AnjunTableList
  },
  data() {
    return {
      name1: this.$t('GlobalSetObj.unselected'),
      name2: this.$t('GlobalSetObj.selected'),
      // 渠道弹框
      channelDialog: false,
      // 穿梭框数据
      transferData: [],
      // 是否格式化成巴西时间
      isTormat: false,
      queryForm: {
        pageSize: 10,
        number: 1,
        total: 0,
        ruleName: '',
        ruleCode: '',
        deviceId: '',
        gridNum: ''
      },
      tableHeaderList: [
        // 编号
        { id: 1, label: this.$t('GlobalSetObj.serialNumber'), prop: 'id', cloWidth: '100' },
        // 规则名称
        { id: 3, label: this.$t('GlobalSetObj.RuleName'), prop: 'ruleName', cloWidth: '300' },
        // 规则类型
        { id: 7, label: this.$t('GlobalSetObj.RuleType'), prop: 'specialRuleFlag', cloWidth: '180' },
        // 服务商
        { id: 99, label: this.$t('GlobalSetObj.fuwushang'), prop: 'providerNames', cloWidth: '180' },
        // 分拣条件
        { id: 11, label: this.$t('GlobalSetObj.SortingIf'), prop: 'sortationConditions', cloWidth: '180' },
        // 客户
        { id: 13, label: this.$t('GlobalSetObj.client'), prop: 'customerName', cloWidth: '180' },
        // 供应商 > 网点
        { id: 12, label: this.$t('GlobalSetObj.provideSiteName'), prop: 'provideSiteName', cloWidth: '200' },
        // 格口
        { id: 2, label: this.$t('GlobalSetObj.gridNum'), prop: 'gridNums', cloWidth: '180' },
        // 设备名称
        { id: 14, label: this.$t('GlobalSetObj.deviceName'), prop: 'deviceName', cloWidth: '180' },
        // 创建人
        { id: 4, label: this.$t('GlobalSetObj.CreatePeople'), prop: 'CreateUser', cloWidth: '180' },
        // 创建时间
        { id: 6, label: this.$t('GlobalSetObj.CreationTime'), prop: 'createTime', cloWidth: '180' },
        // 编辑人
        { id: 8, label: this.$t('GlobalSetObj.editor'), prop: 'updateUser', cloWidth: '180' },
        // 更新时间
        { id: 9, label: this.$t('GlobalSetObj.UpdateTime'), prop: 'updateTime', cloWidth: '180' }
      ],
      tableData: [],
      drawer: false,
      titleName: this.$t('GlobalSetObj.newRule'),
      ruleForm: {
        ruleName: '', // 规则名称
        specialRuleFlag: false, // 规则类型
        otherSubRuleType: [], // 其他分区条件
        gridNum: '', // 格口
        providerIds: '', // 渠道
        providerNames: '', // 渠道
        subRuleType: '1', // 分拣条件
        customerId: '', // 客户,
        systemState: null, // 系统,
        waybillStatus: [], // 状态
        channel: 0, // 渠道
        siteId: [], // 网点
        providerId: '', // 网点名称,
        deviceId: '', // 设备ID
        subRuleList: [
          {
            id: 0,
            gridNums: '',
            zipcodeAreaCode: '', // 邮编
            minWeight: '', // 最小重量
            maxWeight: '' // 最大重量
          }
        ]
      },

      rules: {
        ruleName: [
          { required: true, message: this.$t('GlobalSetObj.inputRuleName'), trigger: 'blur' },
          { min: 2, max: 50, message: this.$t('ChannelManage.inputStr'), trigger: 'blur' }
        ],
        providerIds: [
          { required: true, message: this.$t('GlobalSetObj.PleaseSelecioneCanal'), trigger: ['blur', 'change'] }
        ],
        gridNum: [
          { required: true, message: this.$t('GlobalSetObj.selectGrid'), trigger: ['blur', 'change'] }
        ],
        customerId: [
          { required: true, message: this.$t('placeOrder.pleaseChooseClient'), trigger: ['change'] }
        ],
        systemState: [
          { required: true, message: this.$t('placeOrder.pleaseChoose'), trigger: ['change'] }
        ],
        channel: [
          { required: true, message: this.$t('placeOrder.pleaseChoose'), trigger: ['change'] }
        ],
        siteId: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: ['change'] }
        ],
        waybillStatus: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: ['change'] }
        ],
        deviceId: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: ['change'] }
        ]
      },
      subRuleListRules: {
        gridNums: [{ required: true, message: this.$t('GlobalSetObj.selectGrid'), trigger: ['blur', 'change'] }],
        zipcodeAreaCode: [
          { required: true, message: this.$t('orderCenterCont.inputEmail'), trigger: ['blur', 'change'] }
          // { validator: checkWeight, trigger: ['blur'] }
        ],
        minWeight: [
          { required: true, message: this.$t('GlobalSetObj.inputMinWeight'), trigger: ['blur', 'change'] }
        ],
        maxWeight: [
          { required: true, message: this.$t('GlobalSetObj.inputMaxWeight'), trigger: ['blur', 'change'] }
          // { validator: checkWeight, trigger: ['blur', 'change'] }
        ]
      },
      SortingconditionList: [
        { label: this.$t('GlobalSetObj.client'), code: '1' },
        { label: this.$t('GlobalSetObj.Outlets'), code: '8' },
        { label: this.$t('GlobalSetObj.NoOrderFound'), code: '2' },
        { label: this.$t('collectionCenter.ProblemPiece'), code: '3' },
        { label: this.$t('GlobalSetObj.exceedWeight'), code: '4' },
        { label: this.$t('GlobalSetObj.ExceedOrderVariance'), code: '5' },
        { label: this.$t('GlobalSetObj.ParcelUnweighed'), code: '7' },
        { label: this.$t('orderCenterCont.other'), code: '6' },
        { label: this.$t('orderCenterCont.returnPackage'), code: '9' }
      ], // 分拣条件列表
      createFillters: 1, // 创建筛选条件
      isSee: false,
      editId: null,
      ChannelIds: [],
      gridNumsList: [], // 所有格口列表
      ZipCodeList: [], // 所有邮编列表
      usernameList: [], // 所有客户
      isBandType: false,
      uniqueZipcodeList: [],
      userIdAndNameObj: '',
      createTime: null,
      createUser: null,
      supperSitePage: {
        pageSize: 10, // 每页大小
        pageNumber: 1 // 页数
      },
      listKey: new Date().getTime(),
      waybillStatusList: [
        { label: this.$i18n.t('newOrder.all'), code: -1 }, // 全部
        { label: this.$i18n.t('newOrder.ToBeAssigned'), code: 1 }, // 待分配
        { label: this.$i18n.t('newOrder.ToBeCollected'), code: 2 }, // 待揽收
        { label: this.$i18n.t('newOrder.Collected'), code: 3 }, // 已揽收
        { label: this.$i18n.t('newOrder.CollectTheRevenuePool'), code: 4 }, // 揽收入库
        { label: this.$i18n.t('newOrder.CollectTakeOutWarehouse'), code: 5 }, // 揽收出库
        { label: this.$i18n.t('GlobalSetObj.moduan'), code: 19 }, // 末端已揽收
        { label: this.$i18n.t('newOrder.TransshipmentIntoStorage'), code: 6 }, // 转运入库
        { label: this.$i18n.t('newOrder.TransferOutWarehouse'), code: 7 }, // 转运出库
        { label: this.$i18n.t('newOrder.DeliveryOutletsIntoStorage'), code: 8 }, // 派送网点入库
        { label: this.$i18n.t('newOrder.Delivering'), code: 9 }, // 派送中
        { label: this.$i18n.t('newOrder.Signed'), code: 10 }, // 已签收
        { label: this.$i18n.t('newOrder.ProblemPiece'), code: 11 }, // 问题件
        { label: this.$i18n.t('collectionCenter.unclaimed'), code: 12 }, // 待取件
        { label: this.$i18n.t('GlobalSetObj.Canceled'), code: 13 }, // 已取消
        // { label: this.$i18n.t('GlobalSetObj.deliveryFailure'), code: 14 }, // 投递失败
        { label: this.$i18n.t('GlobalSetObj.DotBack'), code: 17 }, // 网点退回
        { label: this.$i18n.t('GlobalSetObj.ReturnToStorage'), code: 18 }, // 退仓入库
        { label: this.$i18n.t('GlobalSetObj.Withdrawn'), code: 15 }, // 退件中
        { label: this.$i18n.t('GlobalSetObj.TheReturnComplete'), code: 16 } // 退件完成
      ],
      deviceList: [], // 设备列表
      editGridNums: '', // 存储编辑获取到的格口数据(只在查询格口列表中使用)
      gridNumsListSearch: [] // 搜索框格口
    };
  },
  watch: {
    drawer: {
      handler: function(val) {
        this.ruleForm = {
          ruleName: '', // 规则名称
          specialRuleFlag: false, // 规则类型
          otherSubRuleType: [], // 其他分区条件
          gridNum: '', // 格口
          providerIds: '', // 渠道
          providerNames: '', // 渠道
          subRuleType: '1', // 分拣条件
          customerId: '', // 客户,
          systemState: null, // 系统,
          waybillStatus: [], // 状态
          channel: 0, // 渠道
          siteId: [], // 网点
          providerId: '', // 供应商id
          deviceId: '', // 设备名称
          subRuleList: [
            {
              id: 0,
              gridRuleId: '',
              gridNums: '',
              zipcodeAreaCode: '', // 邮编
              minWeight: '', // 最小重量
              maxWeight: '' // 最大重量
            }
          ]
        };
      },
      immediate: true,
      deep: true,
      siteData: []// 网点数据
    },
    channelDialog: {
      handler: function(val) {
      },
      immediate: true
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.getSearch();
    this.getAllData();
    this.getSupperSite();
    this.userIdAndNameObj = getUserIdAndNameObj();
    // 获取设备列表
    deviceManagementList()
      .then((res) => {
        if (res.status === 'OK') {
          this.deviceList = res.data;
        }
        console.log(res, '设备列表');
      });
  },
  methods: {
    // 供应商选项变化
    selectChangeSupplier() {
      const selectedSupplier = {
        id: [],
        name: []
      };
      this.transferData.forEach(item => {
        if (String(item.id) == this.ruleForm.providerIds) {
          selectedSupplier.id.push(item.id);
          selectedSupplier.name.push(item.providerName);
        }
      });
      console.log(selectedSupplier.name, '====selectedSupplier.name');
      this.ruleForm.providerNames = selectedSupplier.name.join(',');
      console.log(this.ruleForm.providerNames, '====this.ruleForm.providerNames');
    },
    // 校验
    uniqueGridNum(list, index) {
      const gridNumsList = [];
      const all = [];
      const dataGrid = [];
      const newGridNumsList = cloneDeep(this.gridNumsList);
      this.ruleForm.subRuleList.map(item => {
        newGridNumsList.map(list => {
          all.push(list.gridNum.toString());
          if (item.gridNums.toString() === list.gridNum.toString()) {
            gridNumsList.push(item.gridNums.toString());
          }
        });
      });

      function findAndRemoveDuplicates(arr1, arr2) {
        return arr1.filter(item => arr2.includes(item));
      }

      const mergeArr = findAndRemoveDuplicates(all, gridNumsList);
      newGridNumsList.map(item => {
        if (mergeArr.includes(item.gridNum.toString()) === false) {
          dataGrid.push(item);
        }
      });
      this.$set(this.ruleForm.subRuleList[index], 'gridList', dataGrid);
    },
    uniqueZipcodeAreaCode(list, index) {
      const zipCodeAll = [...new Set(list.map(item => item.zipcodeAreaCode))];
      let arr = [];
      if (zipCodeAll.length === 1 && zipCodeAll[0] === '') {
        return 1;
      }
      zipCodeAll.map(item => {
        arr = arr.concat(item.split(','));
      });
      const reg1 = /^[0-9]{8}$/;
      const reg2 = /^[0-9]{8}~[0-9]{8}$/;
      let flag = true;
      for (let i = 0; i < arr.length; i++) {
        let str = arr[i].replace(/(^\s*)|(\s*$)/g, '');
        str = str.replace(/,$/g, '');
        if (reg1.test(str) || reg2.test(str)) {
          console.log('符合邮编格式');
        } else {
          this.$message.error(this.$t('GlobalSetObj.Englishcomma'));
          flag = false;
          return 0;
        }
      }
      if (flag) {
        const uniqueArray = Array.from(new Set(arr));
        if (uniqueArray.length < arr.length) {
          this.$message.error(this.$t('GlobalSetObj.PostcodeDuplication'));
          return 0;
        }
        this.uniqueZipcodeList = uniqueArray;
      }
      return 1;
    },
    // 切换规则类型
    // 添加
    newAdd() {
      this.isSee = false;
      this.drawer = true;
      this.isBandType = false;
      this.titleName = this.$t('GlobalSetObj.newRule');
      this.editGridNums = '';
      this.editId = '';
      this.getAllData();
    },
    // 切换分拣条件
    handlesubRuleType(val) {
      console.log(val, '切换分拣条件');
      this.ruleForm.siteId = [];
      this.ruleForm.providerId = '';
      this.ruleForm.customerId = '';
      if (val === '1') {
        this.ruleForm.systemState = 1;
      } else {
        this.ruleForm.systemState = null;
      }
    },
    // 切换网点
    hanleSiteId(val) {
      console.log(val, 'valval');
      // return;
      if (val.length >= 2) {
        this.ruleForm.siteId = val;
      }
    },
    // 获取对应的供应商下的网点
    getSupperSite() {
      siteProvider().then(res => {
        this.siteData = [];
        if (res.status === 'OK') {
          res.data.forEach((item) => {
            item.label = item.providerName;
            item.value = item.id;
            item.children = [];
            if (item.siteList) {
              item.siteList.forEach(item => {
                item.label = item.name;
                item.value = item.id;
              });
              item.children = item.siteList;
            }
          });
          // this.siteData = res.data.records;
          this.siteData = [...res.data];
          // this.$nextTick(() => {
          //   this.siteData = res.data.records;
          // });
          console.log(this.siteData, 'this.siteDatathis.siteData');
        }
      });
    },
    // 获取所有请求数据列表
    getAllData() {
      // 获取网点
      this.getSupperSite();
      // 获取供应商列表
      const parma = {
        pageSize: 9999999,
        pageNumber: 1,
        params: {}
      };
      supplierMainPage(parma).then(res => {
        if (res.status === 'OK') {
          this.transferData = res.data.records;
        }
      });
      // 所有渠道
      // getAllProviderChannel().then(res => {
      //   if (res.status === 'OK') {
      //     this.transferData = res.data;
      //   }
      // });
      // 所有格口
      // getAllRuleId().then(res => {
      //   if (res.status === 'OK') {
      //     this.gridNumsList = res.data;
      //   }
      // });
      const customerPageDate = {
        pageNumber: 1,
        pageSize: 10000,
        params: {
          company: '',
          source: '',
          username: ''
        }
      };
      this.usernameList = [];
      // 查询所有客户
      getAllcustomer(customerPageDate).then(res => {
        if (res.status === 'OK') {
          res.data.records.map(arr => {
            this.usernameList.push({
              id: arr.id,
              username: arr.username
            });
          });
          console.log(res, '查询所有客户');
          // this.usernameList = res.data;
        }
      });
    },
    // 点击其他分拣条件
    changeOtherSubRuleType(val) {
      console.log(val, '点击其他分拣条件');
      this.ruleForm.otherSubRuleType = val;
      console.log(this.ruleForm.otherSubRuleType);
      if (this.ruleForm.otherSubRuleType.length === 1) {
        if (!this.ruleForm.otherSubRuleType.includes('1')) {
          this.ruleForm.subRuleList.forEach((item) => {
            item.zipcodeAreaCode = '';
          });
        }
        if (!this.ruleForm.otherSubRuleType.includes('2')) {
          this.ruleForm.subRuleList.forEach((item) => {
            item.minWeight = '';
            item.maxWeight = '';
          });
        }
      }
      if (this.ruleForm.otherSubRuleType.length === 0) {
        this.ruleForm.subRuleList = [{
          id: 0,
          gridRuleId: '',
          gridNums: '',
          gridList: this.gridNumsList,
          zipcodeAreaCode: '', // 邮编
          minWeight: '', // 最小重量
          maxWeight: '' // 最大重量
        }];
      }
    },
    // 点击创建条件
    addFillter() {
      let i = 0;
      const obj = {
        id: i++,
        gridRuleId: '',
        gridNums: '',
        gridList: [],
        zipcodeAreaCode: '', // 邮编
        minWeight: '', // 最小重量
        maxWeight: '' // 最大重量
      };
      this.ruleForm.subRuleList.push(obj);
    },
    // 点击删减条件
    delFillter(index) {
      if (this.ruleForm.subRuleList.length > 1) {
        this.ruleForm.subRuleList.splice(index, 1);
      } else {
        this.ruleForm.otherSubRuleType = [];
        this.ruleForm.subRuleList = [
          {
            id: 0,
            gridRuleId: '',
            gridNums: '',
            gridList: this.gridNumsList,
            zipcodeAreaCode: '', // 邮编
            minWeight: '', // 最小重量
            maxWeight: '' // 最大重量
          }
        ];
      }
    },
    // 多套餐规则
    async moreSortRules(val) {
      console.log(val, '多套餐规则132313');
      const result = await getMoreRule(this.queryForm.ruleCode);
      if (result.status === 'OK') {
        console.log(result, '多套餐规则');
      }
    },
    // 查询
    async getSearch() {
      const data = {
        params: {}
      };
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.number;
      data.params.ruleName = this.queryForm.ruleName;
      data.params.deviceId = this.queryForm.deviceId;
      data.params.gridNum = this.queryForm.gridNum;
      const result = await apiRulePage(data);
      if (result.status === 'OK') {
        this.queryForm.total = result.data.total;
        this.tableData = result.data.records;
        this.tableData.map(item => {
          item['edit'] = true;
          item['del'] = true;
          item['look'] = true;
          item.providerName = item.providerName ? item.providerName : '- -';
          item.siteName = item.siteName ? item.siteName : '- -';
          item.CreateUser = getUserName(item.createUser, this.userIdAndNameObj);
          item.updateUser = getUserName(item.updateUser, this.userIdAndNameObj);
        });
      }
    },

    // 编辑
    edit(row) {
      console.log(row, 'rowrowrowrowrowrowrowrowrow');
      this.isSee = false;
      this.isBandType = true;
      this.drawer = true;
      this.titleName = this.$t('GlobalSetObj.EditingRules');
      this.$refs['addForm']?.resetFields();
      this.editId = row.id;
      this.createUser = row.createUser;
      this.createTime = row.createTime;
      this.editGridNums = '';
      // this.getAllData();
      getsorterGridRuleInfo(row.id).then(res => {
        if (res.status === 'OK') {
          this.ruleForm.ruleName = res.data.ruleName; // 规则名称
          this.ruleForm.specialRuleFlag = res.data.specialRuleFlag; // 规则类型
          if (res.data.otherSubRuleType === '') {
            this.ruleForm.otherSubRuleType = [];
          } else {
            this.ruleForm.otherSubRuleType = res.data.otherSubRuleType.split(','); // 其他分区条件
          }
          this.ruleForm.providerNames = res.data.providerNames;
          this.ruleForm.providerIds = res.data.providerIds || '';
          // const ids = res.data.providerIds || "";
          // const idList = [];
          // ids.forEach(item => {
          //   idList.push(Number(item));
          // });
          // this.transferValue = idList;
          this.ruleForm.gridNum = res.data.gridNums;
          console.log(this.ruleForm.gridNum, 'this.ruleForm.gridNum', res.data.gridNums);
          // 存储格口列表
          this.editGridNums = res.data.gridNums;
          this.ruleForm.subRuleType = res.data.subRuleType; // 分拣条件
          this.ruleForm.customerId = res.data.customerId; // 客户id
          this.ruleForm.systemState = res.data.systemState; // 系统
          this.ruleForm.channel = res.data.channel; // 渠道
          if (res.data.waybillStatus) { // 渠道
            this.ruleForm.waybillStatus = res.data.waybillStatus.split(',').map(numStr => parseInt(numStr));
          }
          this.ruleForm.siteId = [res.data.providerId, res.data.siteId]; // 网点
          this.ruleForm.deviceId = res.data.deviceId; // 设备名称
          // 获取设备下的设备格口
          this.getGridNumList();
          if (res.data.providerIds) {
            this.ruleForm.providerIds = res.data.providerIds || '';
          } else if (res.data.providerIds) {
            this.ruleForm.providerIds = res.data.providerIds || '';
          }
          // res.data.subRuleList.forEach(item => {
          //   item.gridNums = item.gridNums.split(',');
          // });
          this.ruleForm.subRuleList = res.data.subRuleList;
        }
      });
    },
    // 查看
    toLook(row) {
      this.titleName = this.$t('GlobalSetObj.RuleDetails');
      this.drawer = true;
      this.isSee = true;
      this.$refs['addForm']?.resetFields();
      getsorterGridRuleInfo(row.id).then(res => {
        if (res.status === 'OK') {
          this.ruleForm.ruleName = res.data.ruleName; // 规则名称
          this.ruleForm.specialRuleFlag = res.data.specialRuleFlag; // 规则类型
          if (res.data.otherSubRuleType === '') {
            this.ruleForm.otherSubRuleType = [];
          } else {
            this.ruleForm.otherSubRuleType = res.data.otherSubRuleType.split(','); // 其他分区条件
          }
          this.ruleForm.providerNames = res.data.providerNames;
          this.ruleForm.gridNum = res.data.gridNums;
          this.ruleForm.providerIds = res.data.providerIds || '';
          this.ruleForm.subRuleType = res.data.subRuleType; // 分拣条件
          this.ruleForm.customerId = res.data.customerId; // 客户id
          this.ruleForm.systemState = res.data.systemState; // 系统
          this.ruleForm.channel = res.data.channel; // 渠道
          if (res.data.waybillStatus) { // 渠道
            this.ruleForm.waybillStatus = res.data.waybillStatus.split(',').map(numStr => parseInt(numStr));
          }
          this.ruleForm.siteId = [res.data.providerId, res.data.siteId]; // 网点
          this.ruleForm.deviceId = res.data.deviceId;
          // 获取设备下的设备格口
          this.getGridNumList();
          if (res.data.providerIds) {
            this.ruleForm.providerIds = res.data.providerIds || '';
          } else if (res.data.providerIds) {
            this.ruleForm.providerIds = res.data.providerIds || '';
          }
          // res.data.subRuleList.forEach(item => {
          //   item.gridNums = item.gridNums.split(',');
          // });
          this.ruleForm.subRuleList = res.data.subRuleList;
          console.log(this.ruleForm, '===this.ruleForm');
        }
      });
    },
    // 删除
    onDel(row) {
      deleteSorterGridRule(row.id).then((res) => {
        if (res.status === 'OK') {
          this.$message.success(this.$t('GlobalSetObj.DeleteSuccessful'));
          this.getSearch();
        }
      });
    },
    // 取消
    cancelForm() {
      this.drawer = false;
    },
    // 提交保存
    saveForm() {
      this.$refs['addForm'].validate((valid) => {
        if (valid) {
          const WeightList = [];
          this.ruleForm.subRuleList.map(item => {
            const obj = {};
            if (Number(item.minWeight) === 0 && Number(item.maxWeight) === 0) {
              console.log(WeightList, 'WeightList');
            } else {
              obj.minWeight = Number(item.minWeight);
              obj.maxWeight = Number(item.maxWeight);
              WeightList.push(obj);
            }
          });
          console.log(WeightList, 'WeightListWeightList');
          for (let i = 0; i < WeightList.length; i++) {
            try {
              if (WeightList[i].maxWeight < WeightList[i].minWeight) {
                this.$message.error(this.$t('GlobalSetObj.CorrectMaxWeight'));
                return -1;
              }
              if (WeightList[i + 1].minWeight - WeightList[i].minWeight >= 0 && WeightList[i].maxWeight - WeightList[i + 1].minWeight >= 0) {
                this.$message.error(this.$t('GlobalSetObj.weightRangesIntersect'));
                return -1;
              }
              if (WeightList[i].minWeight - WeightList[i + 1].minWeight >= 0 && WeightList[i + 1].maxWeight - WeightList[i].minWeight >= 0) {
                this.$message.error(this.$t('GlobalSetObj.weightRangesIntersect'));
                return -1;
              }
            } catch (e) {
              console.log(e);
            }
          }
          const a = this.uniqueZipcodeAreaCode(this.ruleForm.subRuleList);
          if (a !== 1) {
            return -1;
          }
          console.log(this.ruleForm, '===this.ruleForm');
          const data = {};
          data.customerId = this.ruleForm.customerId;
          data.systemState = this.ruleForm.systemState; // 系统
          if (this.ruleForm.systemState === 2) {
            data.waybillStatus = this.ruleForm.waybillStatus.join(',');
            data.channel = this.ruleForm.channel;// 渠道
          }
          data.siteId = this.ruleForm.siteId[1];
          data.providerId = this.ruleForm.siteId[0];
          data.providerIds = this.ruleForm.providerIds;
          data.providerNames = this.ruleForm.providerNames;
          data.gridNums = this.ruleForm.gridNum;
          data.ruleName = this.ruleForm.ruleName;
          data.specialRuleFlag = this.ruleForm.specialRuleFlag;
          data.deviceId = this.ruleForm.deviceId;
          data.deviceName = this.deviceList.find(item => item.id === this.ruleForm.deviceId).deviceName;
          data.deviceNumber = this.deviceList.find(item => item.id === this.ruleForm.deviceId).deviceNumber;
          // 这里不能直接修改原对象的gridNums，如果报错会导致原对象的gridNums也被修改
          data.subRuleList = this.ruleForm.subRuleList.map(item => {
            return { ...item, gridNums: item.gridNums, zipcodeAreaCodes: item.zipcodeAreaCode.split(',') };
          });
          if (this.ruleForm.specialRuleFlag) {
            data.subRuleType = this.ruleForm.subRuleType;
          }
          const otherSubRuleType = this.ruleForm.otherSubRuleType.slice(0);
          const str = otherSubRuleType.join(',');
          if (str === '2,1') {
            data.otherSubRuleType = '1,2';
          } else {
            data.otherSubRuleType = str;
          }
          // data.otherSubRuleType = otherSubRuleType.join(',');
          console.log(data, 'datadatadata');
          // return;
          if (this.titleName === this.$t('GlobalSetObj.newRule')) {
            addSorterGridRule(data).then(res => {
              if (res.status === 'OK') {
                this.$message.success(this.$t('GlobalSetObj.SaveSuccessfully'));
                this.drawer = false;
                this.getSearch();
              } else {
                // this.drawer = false;
              }
            }).catch(e => {
              // this.drawer = false;
            });
          } else {
            data.id = this.editId;
            data.createUser = this.createUser;
            data.createTime = this.createTime;
            editSorterGridRule(data).then(res => {
              if (res.status === 'OK') {
                this.$message.success(this.$t('GlobalSetObj.ModificationSuccessful'));
                this.drawer = false;
                this.getSearch();
              } else {
                // this.drawer = false;
              }
            }).catch(e => {
              // this.drawer = false;
            });
          }
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.number = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.number = val;
      this.getSearch();
    },
    // 切换规则类型
    handleRuleType(val) {
      console.log(val, 'this.ruleForm.systemState = 1;this.ruleForm.systemState = 1;');
      if (val) {
        this.ruleForm.systemState = 1;
      } else {
        this.ruleForm.systemState = null;
      }
    },
    changeCustomer() {
      this.ruleForm.waybillStatus = [-1];// 状态
      this.ruleForm.channel = 0;
    },
    /**
     * @description 选择设备后获取设备下的格口
     * @param {String} deviceId 设备change事件，如果是change事件调用，清空格口
     */
    getGridNumList(deviceId) {
      console.log(deviceId, 'deviceId');
      const deviceName = this.deviceList.find(item => item.id === this.ruleForm.deviceId).deviceName;
      this.gridNumsList = [];
      if (deviceId) {
        // 切换设备后清空格口
        if (this.ruleForm.subRuleList && this.ruleForm.subRuleList.length) {
          this.ruleForm.subRuleList[0].gridNums = '';
        }
        this.ruleForm.gridNum = '';
      }
      const data = {
        deviceName,
        deviceId: this.ruleForm.deviceId
      };
      if (this.editId) {
        data.id = this.editId;
      }
      if (this.editGridNums) {
        data.gridNums = this.editGridNums;
      }
      sorterGridRuleSelectGrid(data)
        .then((res) => {
          console.log(res);
          if (res.status === 'OK') {
            this.gridNumsList = res.data;
            this.ruleForm.subRuleList[0].gridList = this.gridNumsList;
            this.listKey = new Date().getTime();
          }
        });
    },
    getGridNumListSearch(deviceId) {
      console.log(deviceId, 'deviceId');
      this.gridNumsListSearch = [];
      this.queryForm.gridNum = '';
      if (deviceId) {
        const deviceName = this.deviceList.find(item => item.id === this.queryForm.deviceId).deviceName;
        const data = {
          deviceName,
          deviceId: this.queryForm.deviceId
        };
        sorterGridRuleSelectGrid01(data).then((res) => {
          if (res.status === 'OK') {
            this.gridNumsListSearch = res.data;
          }
        });
      }
    },
    selectStatus(val) {
      console.log(val, 'STATUS');
      if (val.length > 1 && val.includes(-1)) {
        if (val[0] !== -1) {
          if (val.includes(-1)) {
            this.ruleForm.waybillStatus = [-1];
          } else {
            this.ruleForm.waybillStatus = val;
          }
        } else {
          this.ruleForm.waybillStatus = val.filter(state => state !== -1);
        }
      } else {
        if (val.includes(-1)) {
          this.ruleForm.waybillStatus = [-1];
        } else {
          this.ruleForm.waybillStatus = val;
        }
      }
    }
  }
};
</script>

<style scoped>
.SupplierContainer {
  padding: 15px;
}

.seacher {
  width: 50%;
  margin-top: 20px;
  margin-bottom: 20px;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type='number'] {
  -moz-appearance: textfield !important;
}

.demo-drawer {
  position: relative;
}

.demo-drawer__content {
  height: calc(100vh - 130px);
  overflow: auto;
}

.demo-drawer__footer {
  width: 100%;
  border-top: 1px solid #cccccc;
  position: absolute;
  display: flex;
  justify-content: right;
  padding: 10px;
  right: 10px;
  bottom: 0;
  background-color: white;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.SupplierContainer >>> .el-transfer-panel {
  width: 300px;
}

.createCondition {
  position: relative;
  background-color: #f1f3f4;
  padding-top: 15px;
  padding-right: 20px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.delFillter {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.filters {
  display: flex;
  width: 90%;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  margin: 30px 0 15px 50px;
}
</style>
