// @ts-check

import i18n from '@/lang';

export const TABLE_HEADER = [

  // 应收主体
  { prop: 'providerName', label: i18n.t('basicData.businessPrincipal'), width: '', fixed: 'left', highlight: false, disabled: false
  },
  // 网点
  { prop: 'siteName', label: i18n.t('basicData.Outlets'), width: '', fixed: 'left', highlight: false, disabled: false
  },

  // 收件人州
  { prop: 'receiveState', label: i18n.t('newOrder.RecipientState'), width: '', fixed: 'left', highlight: false, disabled: false },

  // 发件人州
  { prop: 'senderState', label: i18n.t('newOrder.SenderState'), width: '', fixed: 'left', highlight: false, disabled: false },

  // 订单号
  { prop: 'orderNumber', label: i18n.t('basicData.OrderNumber'), width: '', fixed: 'left', highlight: false, disabled: false
  },
  // 所属分区
  { prop: 'partitionName', label: i18n.t('basicData.PartitionName'), width: 160, fixed: 'left', highlight: false, disabled: false },

  // 收件人城市
  { prop: 'receiveCity', label: i18n.t('collectionCenter.receiveCity'), width: 160, fixed: 'left', highlight: false, disabled: false },
  // 收件人邮编
  { prop: 'receiveZipcode', label: i18n.t('collectionCenter.Recipientzc'), width: 160, fixed: 'left', highlight: false, disabled: false },
  // 业务单号
  { prop: 'waybillNumber', label: i18n.t('receivablePayable.TicketNumber'), width: '', fixed: 'left', highlight: false, disabled: false },
  // 客户单号
  { prop: 'customerNumber', label: i18n.t('receivablePayable.CustomerTrackingNumber'), width: '', fixed: 'left', highlight: false, disabled: false },
  // 订单状态
  { prop: 'status', label: i18n.t('collectionCenter.OrderStatus'), width: '', fixed: 'left', highlight: false, disabled: false },
  // 客户
  { prop: 'customerAlias', label: i18n.t('receivablePayable.customer'), width: 110, fixed: 'left', highlight: false, disabled: false },
  // 客户出仓时间
  { prop: 'customerSendTime', label: i18n.t('newOrder.clientExitTime'), width: 160, fixed: 'left', highlight: false, disabled: false },
  // 商户
  { prop: 'merchantName', label: i18n.t('collectionCenter.CommercialOwner'), width: 110, fixed: 'left', highlight: false, disabled: false },
  // 支付方式
  { prop: 'payType', label: i18n.t('ClaimsManagement.PaymentMethod'), width: 110, fixed: 'left', highlight: false, disabled: false },
  // 计费重量
  { prop: 'chargeWeight', label: i18n.t('receivablePayable.BillableWeight'), width: 110, fixed: 'left', highlight: true, disabled: false },
  // 应收总费用
  { prop: 'totalFee', label: i18n.t('basicData.TotalFeesReceivable'), width: 110, fixed: 'left', highlight: false, disabled: false },
  // 业务类型
  { prop: 'businessType', label: i18n.t('collectionCenter.businessT'), width: 180, fixed: 'left', highlight: false, disabled: false },
  // 揽收类别
  { prop: 'collectionCategory', label: i18n.t('basicData.PickupCategory'), width: 110, fixed: 'left', highlight: false, disabled: false },
  // 包裹类型
  { prop: 'orderPackageType', label: i18n.t('placeOrder.packageType'), width: 110, fixed: 'left', highlight: false, disabled: false },
  // 来源
  { prop: 'feeSource', label: i18n.t('receivablePayable.source'), width: 110, fixed: 'left', highlight: false, disabled: false }
];

// 业务类型
export const BUSINESS_TYPE = Object.freeze({
  0: {
    label: i18n.t('collectionCenter.expressDelivery'), // 快递
    value: 0
  },
  1: {
    label: i18n.t('collectionCenter.lanshou'), // 揽收
    value: 1
  },
  2: {
    label: i18n.t('collectionCenter.dispatch'), // 派送
    value: 2
  }

});
