// 订单——巴西系统

const newOrder = {
  TimeLanshou: {
    zh: '揽收时长(H)',
    en: '',
    pu: 'Duração da coleta(H)'
  },
  orderNote: {
    zh: '订单备注',
    en: '',
    pu: 'Observações sobre o pedido'
  },
  ordersNumber: {
    zh: '下单数量',
    en: '',
    pu: 'Quantidade de pedido criado'
  },
  ordersDay: {
    zh: '订单日报',
    en: '',
    pu: 'Relatório diário de pedido'
  },
  collectionQuantity: {
    zh: '揽收数量',
    en: '',
    pu: 'Quantidade de coletado'
  },
  daicollectionQuantity: {
    zh: '待揽收数量',
    en: '',
    pu: 'Quantidade dos a ser coletados'
  },
  zhuanyunrukQuantity: {
    zh: '转运入库数量',
    en: '',
    pu: 'Quantidade de entrada no armazém de transferência'
  },
  zhuanyunchukQuantity: {
    zh: '转运出库数量',
    en: '',
    pu: 'Quantidade de saída no armazém de transferência'
  },
  zhuanyunrukuQuantity: {
    zh: '派送网点入库数量',
    en: '',
    pu: 'Quantidade de entreda no ponto de entrega'
  },
  qianshouQuantity: {
    zh: '签收数量',
    en: '',
    pu: 'Quantidade de entregado'
  },
  wentijianQuantity: {
    zh: '问题件数量',
    en: '',
    pu: 'Quantidade de anormal'
  },
  deliveryMisses: {
    zh: '投递失败数量',
    en: '',
    pu: 'Quantidade de falhado ao entregar'
  },
  cancelMisses: {
    zh: '已取消数量',
    en: '',
    pu: 'Quantidade de cancelado'
  },
  returnQuantity: {
    zh: '退件数量',
    en: '',
    pu: 'Quantidade de devolvido'
  },
  returnCompleteQuantity: {
    zh: '退件完成数量',
    en: '',
    pu: 'Quantidade de concluído a devolução'
  },
  failQuantity: {
    zh: '下单失败数量',
    en: '',
    pu: 'Quantidade de falhado ao gerar'
  },
  recent7days: {
    zh: '近7天客户订单走势',
    en: '',
    pu: 'Tendência de pedido de clientes nos recentes 7 dias'
  },
  today: {
    zh: '今日',
    en: '',
    pu: 'Hoje'
  },
  thisWeek: {
    zh: '本周',
    en: '',
    pu: 'Esta semana'
  },
  thisMonth: {
    zh: '本月',
    en: '',
    pu: 'Este mês'
  },
  ComparedToyesterday: {
    zh: '较昨日',
    en: '',
    pu: 'Comparando com ontém'
  },
  ComparedToweek: {
    zh: '较上周',
    en: '',
    pu: 'Comparando com a semana passada'
  },
  ComparedTomonth: {
    zh: '较上月',
    en: '',
    pu: 'Comparando com a mês passada'
  },
  lastCycle: {
    zh: '较上一周期',
    en: '',
    pu: 'Comparado com o período anterior'
  },
  CustomerOrders: {
    zh: '客户下单情况',
    en: '',
    pu: 'Situação de Pedido do Cliente'
  },
  FailedOrder: {
    zh: '下单失败',
    en: '',
    pu: 'Falha ao gerar de pedido'
  },
  ConfiguringCustomers: {
    zh: '配置客户',
    en: '',
    pu: 'Configuração de cliente'
  },
  Total: {
    zh: '总计',
    en: '',
    pu: 'Total'
  },
  orderPush: {
    zh: '订单推送',
    en: '',
    pu: 'Push do pedido'
  },
  otherCustomer: {
    zh: '其他客户',
    en: '',
    pu: 'Outros clientes'
  },
  MonthlyWaybills: {
    zh: '月度运单情况',
    en: '',
    pu: 'Status de pedido de rasterio mensal'
  },
  Month: {
    zh: '月',
    en: '',
    pu: 'Mês'
  },
  Year: {
    zh: '年',
    en: '',
    pu: 'Ano'
  },
  selectCustomer: {
    zh: '选择客户',
    en: '',
    pu: 'Seleciona cliente'
  },
  Ranking: {
    zh: '排名',
    en: '',
    pu: 'Ranking'
  },
  successOrder: {
    zh: '推送成功订单数',
    en: '',
    pu: 'Volume de pedido empurrado com sucesso'
  },
  failOrder: {
    zh: '推送失败订单数',
    en: '',
    pu: 'Volume de pedido falhado ao empurrar'
  },
  ComparedTime: {
    zh: '较上一周期',
    en: '',
    pu: 'Comparando com o período anterior'
  },
  kanbanBoard: {
    zh: '运单看板',
    en: '',
    pu: 'Dashboard de pedido'
  },
  UpdateTheFaceSheet: {
    zh: '更新面单',
    en: 'Update the face sheet',
    pu: 'Renova a etiqueta'
  },
  UpdateNet: {
    zh: '更新网点',
    en: '',
    pu: 'Upload ponto'
  },
  TheUpdateWasSuccessful: {
    zh: '更新成功',
    en: 'The update was successful',
    pu: 'Renova com sucesso'
  },
  TheUpdateFailed: {
    zh: '更新失败',
    en: 'The update failed',
    pu: 'Falha ao renovar'
  },
  FaceSheetStatus: {
    zh: '面单状态',
    en: 'Face sheet status',
    pu: 'status de etiqueta '
  },
  senderZipCode: {
    zh: '发件人邮编',
    en: 'Recipient Name',
    pu: 'Nome do destinatário'
  },
  RecipientName: {
    zh: '收件人姓名',
    en: 'Recipient Name',
    pu: 'Nome do destinatário'
  },
  RecipientState: {
    zh: '收件人州',
    en: 'Recipient State',
    pu: 'Estado do destinatário'
  },
  LostType: {
    zh: '丢失类型',
    en: 'Lost type',
    pu: 'Tipo de perda'
  },
  ReceivingAddress: {
    zh: '收件人地址',
    en: 'Receiving Address',
    pu: 'Endereço do destinatário'
  },
  Recipientphone: {
    zh: '收件人手机',
    en: 'Recipient\'s cell phone',
    pu: 'Nº de celular do destinatário'
  },
  RecipientID: {
    zh: '收件人证件号码:',
    en: 'Recipient ID number',
    pu: 'Número de identificação do destinatário:'
  },
  RecipientCompany: {
    zh: '收件人公司',
    en: 'Recipient Company',
    pu: 'Empresa destinatária'
  },
  Recipientphonenumber: {
    zh: '收件人电话',
    en: 'Recipient phone number',
    pu: 'Nº de telefone do destinatário'
  },
  RecipientCity: {
    zh: '收件人城市',
    en: 'Recipient City',
    pu: 'Cidade do destinatário'
  },
  RecipientEmail: {
    zh: '收件人邮箱',
    en: 'Recipient Email',
    pu: 'E-mail do destinatário'
  },
  RecipientDistrict: {
    zh: '收件人区',
    en: 'Recipient District',
    pu: 'Distrito do destinatário'
  },
  doorNumber: {
    zh: '收件人门牌号',
    en: 'Recipient\'s door number',
    pu: 'Número da porta do destinatário'
  },
  RecipientType: {
    zh: '收件人证件类型:',
    en: 'Recipient document type:',
    pu: 'Tipo de documento do destinatário:'
  },
  RecipientCPF: {
    zh: '收件人税号',
    en: 'Recipient CPF',
    pu: 'CPF do destinatário'
  },
  SenderName: {
    zh: '发件人姓名',
    en: 'Sender\'s name',
    pu: 'Nome do remetente'
  },
  SenderPhone: {
    zh: '发件人电话',
    en: 'Sender\'s phone number',
    pu: 'Telefone do remetente'
  },
  SenderState: {
    zh: '发件人州',
    en: 'Sender State',
    pu: 'Estado do remetente'
  },
  SenderCity: {
    zh: '发件人城市',
    en: 'Sender City',
    pu: 'Cidade do remetente'
  },
  SenderEmail: {
    zh: '发件人邮箱',
    en: 'Sender Email',
    pu: 'E-mail do remetente'
  },
  SendingAddress: {
    zh: '发件人地址',
    en: 'Sending Address',
    pu: 'Endereço do remetente'
  },
  SendAddress: {
    zh: '发件人地址',
    en: 'Sending Address',
    pu: 'Endereço do remetente'
  },
  senderPhone: {
    zh: '发件人手机',
    en: 'Sender\'s cell phone',
    pu: 'Número de celular do remetente'
  },
  SenderType: {
    zh: '发件人证件类型:',
    en: 'Sender document type:',
    pu: 'Tipo de identificação do remetente:'
  },
  SenderId: {
    zh: '发件人证件号码:',
    en: 'Sender ID number:',
    pu: 'Número de identificação do remetente:'
  },
  SenderDistrict: {
    zh: '发件人区',
    en: 'Sender District',
    pu: 'Distrito do remetente'
  },
  SenderCompany: {
    zh: '发件人公司',
    en: 'Sender Company',
    pu: 'Empresa do remetente'
  },
  SenderNumber: {
    zh: '发件人门牌号',
    en: 'Sender\'s door number:',
    pu: 'Número da Porta do Remetente'
  },
  SenderCPF: {
    zh: '发件人税号',
    en: 'Sender CPF',
    pu: 'CPF do remetente'
  },
  LatestTime: {
    zh: '最新轨迹时间',
    en: 'Latest track time:',
    pu: 'Tempo de tracking mais recente'
  },
  DeclaredWeight: {
    zh: '申报重量(kg)',
    en: 'Declared weight(kg)',
    pu: 'Peso declarado(kg)'
  },
  RecipientZipCode: {
    zh: '收件人邮编',
    en: 'Recipient Zip Code',
    pu: 'CEP do destinatário'
  },
  SenderZipCode: {
    zh: '发件人邮编',
    en: 'Sender Zip Code',
    pu: 'CEP do destinatário'
  },
  TrackingNumber: {
    zh: '物流单号:',
    en: 'Tracking Number:',
    pu: 'Tracking número:'
  },
  CustomerName: {
    zh: '客户名称',
    en: 'Customer Name',
    pu: 'Nome do cliente'
  },
  CollectionType: {
    zh: '揽收类型',
    en: 'Collection Type',
    pu: 'Tipo de coleta'
  },
  TypeService: {
    zh: '服务类型',
    en: 'Type of Service',
    pu: 'Tipo de serviço'
  },
  DepotWeighing: {
    zh: '网点称重',
    en: 'Depot weighing',
    pu: 'Pesagem em ponto'
  },
  TransferWeighing: {
    zh: '转运中心称重(kg)',
    en: 'Transfer center weighing(kg)',
    pu: 'Pesagem(kg) em Centro de Distribuição'
  },
  TransferWeighing1: {
    zh: '转运中心称重(kg)',
    en: 'Transfer center weighing (kg)',
    pu: 'Pesagem(kg) em Centro de Distribuição'
  },
  DeclaredValue: {
    zh: '申报价值',
    en: 'save:',
    pu: 'Valor declarado'
  },
  PreCollectionDepot: {
    zh: '预派揽收网点',
    en: 'Pre-dispatch collection depot',
    pu: 'Pontos de coleta pré-despachados'
  },
  transitCenter: {
    zh: '中转仓',
    en: '',
    pu: 'Armazém de trânsito'
  },
  ActualCollection: {
    zh: '实际揽收网点',
    en: 'Actual collection depot',
    pu: 'Pontos de coleta reais'
  },
  length: {
    zh: '中转长(cm)',
    en: 'length(cm) in transfer center',
    pu: 'Comprimento no Centro de Distribuição(cm)'
  },
  width: {
    zh: '中转宽(cm)',
    en: 'width(cm) in transfer center',
    pu: 'Largura no Centro de Distribuição(cm)'
  },
  height: {
    zh: '中转高(cm)',
    en: 'height(cm) in transfer center',
    pu: 'Altura no Centro de Distribuição (cm)'
  },
  TransitPhotos: {
    zh: '中转照片',
    en: 'Transit photos',
    pu: 'Fotos de trânsito'
  },
  TypesProblem: {
    zh: '问题件类型:',
    en: 'Types of problem parcels:',
    pu: 'Tipo de pacote anormal:'
  },
  Sender: {
    zh: '发件人:',
    en: 'Types of problem parcels:',
    pu: 'Remetente:'
  },
  Recipient: {
    zh: '收件人:',
    en: 'Types of problem parcels:',
    pu: 'Destinatário:'
  },
  CPF: {
    zh: '税号:',
    en: 'CPF:',
    pu: 'CPF/CNPJ:'
  },
  TheTaxIDCannotBeEmpty: {
    zh: '税号不能为空',
    en: 'The tax ID cannot be empty',
    pu: 'O ID fiscal não pode estar vazio'
  },
  PleaseEnter12Or14DigitTaxNumber: {
    zh: '请输入11或14位税号',
    en: 'Please enter a 11- or 14-digit tax number',
    pu: 'Introduza um número fiscal de 11 ou 14 dígitos'
  },
  Name: {
    zh: '姓名:',
    en: 'Name:',
    pu: 'Nome:'
  },
  PhoneNumber: {
    zh: '电话:',
    en: 'Phone Number:',
    pu: 'Número de telefone:'
  },
  Email: {
    zh: '邮箱:',
    en: 'Email:',
    pu: 'Email:'
  },
  ZipCode: {
    zh: '邮编:',
    en: 'Zip code:',
    pu: 'CEP:'
  },
  State: {
    zh: '州:',
    en: 'State:',
    pu: 'Estado:'
  },
  City: {
    zh: '市:',
    en: 'City:',
    pu: 'Município:'
  },
  Street: {
    zh: '街道',
    en: 'City',
    pu: 'Rua'
  },
  Street1: {
    zh: '街道',
    en: 'City',
    pu: 'Rua'
  },
  Neighborhood: {
    zh: '邻里:',
    en: 'Neighborhood:',
    pu: 'Vizinho:'
  },
  District: {
    zh: '区:',
    en: 'District:',
    pu: 'Bairro:'
  },
  DoorNumber: {
    zh: '门牌号:',
    en: 'Door number:',
    pu: 'Número:'
  },
  Additional: {
    zh: '补充:',
    en: 'Adicional:',
    pu: 'Adicional:'
  },
  SerialNumber: {
    zh: '序号',
    en: 'Serial number',
    pu: 'No.'
  },
  Quantity: {
    zh: '数量',
    en: 'Quantity',
    pu: 'Quantidade'
  },
  Unit: {
    zh: '单位',
    en: 'Unit',
    pu: 'Unidade'
  },
  Declaration: {
    zh: '申报',
    en: 'Declaration',
    pu: 'Declaração'
  },
  Brand: {
    zh: '品牌',
    en: 'Brand',
    pu: 'Marca'
  },
  Size: {
    zh: '尺寸',
    en: 'Size',
    pu: 'Tamanho'
  },
  Parameters: {
    zh: '参数',
    en: 'Parameters',
    pu: 'Parâmetros'
  },
  PleaseSelect: {
    zh: '请选择先勾选要分配的物流订单',
    en: 'Please select the logistics order to be assigned first',
    pu: 'Favor selecionar o pedido logístico a ser distribuído primeiro'
  },
  OrderType: {
    zh: '订单类型',
    en: 'Order type',
    pu: 'Tipo de pedido'
  },
  BuildTime: {
    zh: '生成时间',
    en: 'Build time',
    pu: 'Tempo de compilação'
  },
  GeneratingState: {
    zh: '生成状态',
    en: '',
    pu: 'Estado gerado'
  },
  forwardDirection: {
    zh: '正向',
    en: '',
    pu: 'Avançar'
  },
  reverse: {
    zh: '逆向',
    en: '',
    pu: 'Direção reversa'
  },
  TheInvoiceSerialNumber: {
    zh: '发票序列号',
    en: 'The invoice serial number',
    pu: 'O número de série da fatura'
  },
  TaxCode: {
    zh: '税码',
    en: 'Tax code',
    pu: 'Código tributário'
  },
  InvoiceNumber: {
    zh: '发票号',
    en: 'Invoice number',
    pu: 'Número da fatura'
  },
  InvoiceKey: {
    zh: '发票密钥',
    en: 'Invoice key',
    pu: 'Chave da fatura'
  },
  LogisticsInvoices: {
    zh: '物流发票',
    en: 'Logistics invoices',
    pu: 'Faturas logísticas'
  },
  UpdateInvoiceInformation: {
    zh: '更新发票信息',
    en: 'Update invoice information',
    pu: 'Atualizar informações da fatura'
  },
  OperationSuccessful: {
    zh: '操作成功',
    en: 'Operation successful',
    pu: 'Operação bem sucedida'
  },
  designation: {
    zh: '名称',
    en: 'Name',
    pu: 'Nome'
  },
  save: {
    zh: '保 存',
    en: 'save',
    pu: 'Salvar'
  },
  collect: {
    zh: '将选中运单分配给以下指定揽收网点进行揽收。',
    en: 'The selected waybill will be assigned to the following designated collection depot for collection.',
    pu: 'A waybill selecionada será designada para os seguintes pontos de coleta designados para coleta.'
  },
  DistributionDot: {
    zh: '分配揽收网点',
    en: 'Allocate depot',
    pu: 'Despacha o ponto de entrega'
  },
  DownloadFaceSheets: {
    zh: '下载面单',
    en: 'Download face sheets',
    pu: 'Exportar etiquetas'
  },
  selectDot: {
    zh: '请选择分配网点',
    en: 'Please select the depot',
    pu: 'Favor selecionar um ponto atribuído'
  },
  unallocated: {
    zh: '非待分配订单将直接过滤, 每次选择超过100条数据，只会取前100条。',
    en: 'Orders that are not to be assigned will be filtered directly, and only the first 100 items will be taken if more than 100 items are selected at a time.',
    pu: 'Os pedidos a não serem atribuídos serão filtradas diretamente, e somente os primeiros 100 serão tomados se mais de 100 forem selecionadas de cada vez.'
  },
  haveBeenSigned: {
    zh: '已签收',
    en: '',
    pu: 'Foi assinado'
  },
  InTransit: {
    zh: '运输中',
    en: '',
    pu: 'Em trânsito'
  },
  order: {
    zh: '订单',
    en: 'order',
    pu: 'Pedido'
  },
  orderList: {
    zh: '订单列表',
    en: 'orderList',
    pu: 'Lista dos Pedidos'
  },
  all: {
    zh: '全部',
    en: 'all',
    pu: 'Tudo'
  },
  stayLanShou: {
    zh: '待揽收',
    en: 'stayLanShou',
    pu: 'Para ser Coletado'
  },
  sendGoods: {
    zh: '待发货',
    en: 'sendGoods',
    pu: 'Pendente de entrega'
  },
  forGoods: {
    zh: '待收货',
    en: 'forGoods',
    pu: 'Pendente do recebimento'
  },
  completed: {
    zh: '已完成',
    en: 'completed',
    pu: 'Concluído'
  },
  abnormal: {
    zh: '异常',
    en: 'abnormal',
    pu: 'Anormal'
  },
  bounce: {
    zh: '退件',
    en: 'bounce',
    pu: 'Devolução'
  },
  enterOrder: {
    zh: '输入订单号',
    en: 'enterOrder',
    pu: 'Digitar número do pedido'
  },
  enterWaybill: {
    zh: '输入运单号',
    en: '',
    pu: 'Digite o número do waybill'
  },
  search: {
    zh: '搜索',
    en: 'search',
    pu: 'Consultar'
  },
  FilterByCriteria: {
    zh: '按条件筛选',
    en: 'Filter by criteria',
    pu: 'Filtrar por critérios'
  },
  notChoose: {
    zh: '未选',
    en: 'notChoose',
    pu: 'Não selecionado'
  },
  selected: {
    zh: '已选',
    en: 'selected',
    pu: 'Selecionado'
  }, // 已选 6 条
  strip: {
    zh: '条',
    en: 'strip',
    pu: 'Artigo'
  },
  export: {
    zh: '导出',
    en: 'export',
    pu: 'Exportar'
  },
  cancel: {
    zh: '取消',
    en: 'cancel',
    pu: 'Cancelar'
  },
  bulkImport: {
    zh: '批量导入',
    en: 'bulkImport',
    pu: 'Importar em lotes'
  },
  manualOrder: {
    zh: '手动下单',
    en: 'manualOrder',
    pu: 'Fazer pedido manualmente'
  },
  orderNumber: {
    zh: '客户订单号',
    en: 'Customer Order Number',
    pu: 'Número do pedido do cliente'
  },
  waybillNumber: {
    zh: '运单号',
    en: 'Waybill number',
    pu: 'Número do Waybill'
  },
  receiverName: {
    zh: '收件人:',
    en: 'Recipient:',
    pu: 'Destinatário:'
  },
  quantity: {
    zh: '件数',
    en: 'quantity',
    pu: 'Número de pacotes'
  },
  receiveCity: {
    zh: '收件人城市:',
    en: 'receiveCity:',
    pu: 'Cidade do destinatário:'
  },
  customerNumber: {
    zh: '客户单号:',
    en: 'customerNumber:',
    pu: 'Número do pedido do cliente:'
  },
  channel: {
    zh: '渠道',
    en: 'channel',
    pu: 'Canal'
  },
  orderStatus: {
    zh: '订单状态:',
    en: 'orderStatus:',
    pu: 'Status do pedido:'
  },
  waybillStatus: {
    zh: '运单状态',
    en: 'Waybill status',
    pu: 'Status do Waybill'
  },
  changeOrderStatus: {
    zh: '换单状态',
    en: 'Change Order status',
    pu: 'Alterar o status do pedido'
  },
  shipped: {
    zh: '已发货',
    en: 'shipped',
    pu: 'Enviado'
  },
  createTime: {
    zh: '创建时间',
    en: 'Creation time',
    pu: 'Tempo de criação'
  },
  orderCreateTime: {
    zh: '订单创建时间',
    en: 'Creation time',
    pu: 'Tempo de criação de pedido'
  },
  clientExitTime: {
    zh: '客户出仓时间',
    en: '',
    pu: 'Hora de Saída da Encomenda'
  },
  lanshouT: {
    zh: '揽收时间',
    en: '',
    pu: 'Tempo de coleta'
  },
  lanshouT0: {
    zh: '末端揽收时间',
    en: '',
    pu: 'Tempo de coleta de LM'
  },
  WithdrawalTime: {
    zh: '退仓发件时间',
    en: 'Withdrawal time',
    pu: 'Tempo de devolução ao armazém'
  },
  DepositTime: {
    zh: '到件时间',
    en: 'Deposit time',
    pu: 'Tempo de entrada no armazém'
  },
  supplierAndSite: {
    zh: '派送供应商 / 网点',
    en: '',
    pu: 'Fornecedor / Ponto de entrega'
  },
  TransitInboundTime: {
    zh: '转运入库时间',
    en: 'Transit inbound time',
    pu: 'Tempo de entrada em trânsito'
  },
  shipmentTime: {
    zh: '转运出库时间',
    en: '',
    pu: 'Tempo de saída em trânsito'
  },
  StorageTimeOutlets: {
    zh: '网点入库时间',
    en: '',
    pu: 'Tempo de inbound no ponto'
  },
  DeliveryProvider: {
    zh: '派送供应商',
    en: '',
    pu: 'Fornecedor de entrega'
  },
  WithdrawalStatus: {
    zh: '退仓状态',
    en: 'Withdrawal status',
    pu: 'Estado de devolução ao armazém'
  },
  TimeReceipt: {
    zh: '收件时长(H)',
    en: '',
    pu: 'Comprimento do recibo (H)'
  },
  DurationTransit: {
    zh: '中转时长(H)',
    en: '',
    pu: 'Duração do trânsito(H)'
  },
  warehouseOperationTime: {
    zh: '中转仓作业时长(H)',
    en: '',
    pu: 'Duração de tratamento no armazém de transferência(H)'
  },
  TransportationTime: {
    zh: '运输时长(H)',
    en: '',
    pu: 'Duração de transferência(H)'
  },
  TransportationTime01: {
    zh: '支线运输时长(H)',
    en: '',
    pu: 'Horas de transporte do alimentador(H)'
  },
  DurationSigning: {
    zh: '签收时长(H)',
    en: '',
    pu: 'Duração de recebimento(H)'
  },
  SelectDate: {
    zh: '选择日期时间',
    en: 'Select date and time',
    pu: 'Selecione data hora'
  },
  inputPrompt: {
    zh: '请输入内容',
    en: '',
    pu: 'Por favor digite conteúdo'
  },
  PrecisionSearch: {
    zh: '精确搜索，一行一项，最多支持200行',
    en: 'Precision search, one line, supports a maximum of 200 lines',
    pu: 'A busca exata, um artigo por linha, apoia 200 linhas no máximo'
  },
  operation: {
    zh: '操作',
    en: 'operation',
    pu: 'Operar'
  },
  inAll: { // 共 9 条
    zh: '共',
    en: 'inAll',
    pu: 'No total'
  },
  articlePage: { // 100 条/页
    zh: '条/页',
    en: 'articlePage',
    pu: 'Artigo/Página'
  },
  togo: { // 前往 2 页
    zh: '前往',
    en: 'togo',
    pu: 'Ir para'
  },
  page: { // 前往 2 页
    zh: '页',
    en: 'page',
    pu: 'Página'
  },
  test: {
    zh: '验证失败，请重新登录。',
    en: 'Authentication failed, please log in again.',
    pu: 'A verificação falhou, favor fazer o login novamente'
  },
  chooseWaybillPlease: {
    zh: '请选择需要导出的运单',
    en: 'Please select the waybill to be exported',
    pu: 'Favor selecionar o waybill a ser exportado'
  },
  SelectDatetime: {
    zh: '选择日期时间',
    en: 'Select a datetime',
    pu: 'Selecione uma data/hora'
  },
  reset: {
    zh: '重置',
    en: 'reset',
    pu: 'Repor'
  },
  AssignOutlets: {
    zh: '分配网点',
    en: 'Assign outlets',
    pu: 'Atribuir pontos de venda'
  },
  PlatformOrderNumber: {
    zh: '客户订单号',
    en: 'Customer order number',
    pu: 'Número da ordem do cliente'
  },
  PreciseSearch: {
    zh: '精确搜索，一行一项，最多支持200行',
    en: 'Exact search, one row at a time, supports up to 200 rows',
    pu: 'A pesquisa exata, uma linha de cada vez, suporta até 200 linhas'
  },
  PreciseSearch1: {
    zh: '精确搜索，一行一项，最多支持2000行',
    en: 'Exact search, one row at a time, supports up to 2000 rows',
    pu: 'A pesquisa exata, uma linha de cada vez, suporta até 2000 linhas'
  },
  PreciseSearch2: {
    zh: '支持多条换行搜索',
    en: '',
    pu: 'Suporta pesquisas de vários avanços de linha'
  },
  empty: {
    zh: '清 空',
    en: 'empty',
    pu: 'vazio'
  },
  ToBeAssigned: {
    zh: '待分配',
    en: 'To be assigned',
    pu: 'Pedido Gerado'
  },
  ToBeCollected: {
    zh: '待揽收',
    en: 'To be collected',
    pu: 'Para ser Coletado'
  },
  Collected: {
    zh: '已揽收',
    en: 'Collected',
    pu: 'Coletado no Cliente'
  },
  CollectTheRevenuePool: {
    zh: '揽收入库',
    en: '',
    pu: 'Recebido no Centro de Consolidação'
  },
  CollectTakeOutWarehouse: {
    zh: '揽收出库',
    en: '',
    pu: 'Expedido no Centro de Consolidação'
  },
  TransshipmentIntoStorage: {
    zh: '转运入库',
    en: 'Transshipment into storage',
    pu: 'Recebido no Centro de Distribuição'
  },
  TransferOutWarehouse: {
    zh: '转运出库',
    en: 'Transfer out of the warehouse',
    pu: 'Expedido no Centro de Distribuição'
  },
  DeliveryOutletsIntoStorage: {
    zh: '派送网点入库',
    en: 'Delivery outlets into storage',
    pu: 'Recebido na base de last mile'
  },
  Delivering: {
    zh: '派送中',
    en: 'Delivering',
    pu: 'Em rota de entrega'
  },
  Signed: {
    zh: '已签收',
    en: 'Signed',
    pu: 'Pedido entregue'
  },
  ProblemPiece: {
    zh: '问题件',
    en: 'Problem piece',
    pu: 'Aguardando tratativa'
  },
  registrationOfProblemPieces: {
    zh: '问题件登记',
    en: 'Registration of problem pieces',
    pu: 'Registro de peças problemáticas'
  },
  TheCauseOfTheProblem: {
    zh: '问题原因',
    en: 'The cause of the problem',
    pu: 'A causa do problema'
  },
  PleaseSelectReasonProblem: {
    zh: '请选择问题件原因',
    en: 'Please select the reason for the problem',
    pu: 'Selecione o motivo do problema'
  },
  PleaseEnterWaybillNumber: {
    zh: '请输入运单号',
    en: 'Please enter the Waybill number',
    pu: 'Por favor, insira Número do Waybill'
  },
  processing: {
    zh: '处理中',
    en: 'processing',
    pu: 'processamento'
  },
  ProcessingComplete: {
    zh: '处理完成',
    en: 'Processing complete',
    pu: 'Processamento concluído'
  },
  processingFailure: {
    zh: '处理失败',
    en: 'processing failure',
    pu: 'falha no processamento'
  },
  PleaseEnterDescriptionPortuguese: {
    zh: '请输入葡语描述',
    en: 'Please enter a description in Portuguese',
    pu: 'Por favor, insira uma descrição em português'
  },
  EachLineHasOneSingleNumber: {
    zh: '每行一个单号，一次最多支持标记100条',
    en: 'Each line has one single number, and up to 100 tags can be marked at a time',
    pu: 'Cada linha tem um único número, e até 100 tags podem ser marcadas por vez'
  },
  problemPieceRemarks: {
    zh: '问题件备注',
    en: 'Problem piece remarks',
    pu: 'Observações sobre o problema'
  },
  BasicInformationWaybill: {
    zh: '运单基本信息',
    en: 'Basic information of the waybill',
    pu: 'Informações básicas da carta de porte'
  },
  InvoiceInformation: {
    zh: '发票信息',
    en: 'Invoice information',
    pu: 'Informações da fatura'
  },
  taxNumberTip: {
    zh: '请输入税号，以备抽检',
    en: '',
    pu: 'Digite seu código fiscal para verificações aleatórias'
  },
  ConfirmStreetNumber: {
    zh: '请精确到街道号',
    en: '',
    pu: 'Seja preciso com relação ao número da rua'
  },
  TdetailedAddressCharacter: {
    zh: '请输入2~200位的字符的详细地址',
    en: '',
    pu: 'Digite os detalhes do endereço com 2 a 200 caractéres'
  },
  StreetHouseNumber: {
    zh: '请精确到街道和门牌号',
    en: '',
    pu: 'Seja preciso em relação à rua e ao número da porta'
  },
  BasicInformation: {
    zh: '基础信息',
    en: '',
    pu: 'Informações básicas'
  },
  RouteNumber: {
    zh: '路线号',
    en: 'Route number',
    pu: 'Número de rota'
  },
  NetRollCall: {
    zh: '网点名',
    en: 'Net roll call',
    pu: 'Chamada líquida'
  },
  CancellationTime: {
    zh: '取消时间',
    en: 'Cancellation time',
    pu: 'Tempo de cancelamento'
  },
  pickUpTime: {
    zh: '揽收时间',
    en: '',
    pu: 'Horário de coleta'
  },
  pickUpDate: {
    zh: '揽收日期',
    en: '',
    pu: 'Data de coleta'
  },
  crossBorder: {
    zh: '跨境',
    en: '',
    pu: 'Transfronteiriço'
  },
  pickUpDay: {
    zh: '揽收星期',
    en: '',
    pu: 'Dia de coleta'
  },
  orderVolume: {
    zh: '下单量',
    en: '',
    pu: 'Qtd. de criados'
  },
  pickUpVolume: {
    zh: '揽收量',
    en: '',
    pu: 'Qtd. de coletados'
  },
  D0deliveryVolume: {
    zh: 'D+0签收量',
    en: '',
    pu: 'Qtd. de entregados no D+0'
  },
  D0DeliveryRate: {
    zh: 'D+0签收率',
    en: '',
    pu: 'Taxa de entregados no D+0'
  },
  D1deliveryVolume: {
    zh: 'D+1签收量',
    en: '',
    pu: 'Qtd. de entregados no D+1'
  },
  D1DeliveryRate: {
    zh: 'D+1签收率',
    en: '',
    pu: 'Taxa de entregados no D+1'
  },
  D2deliveryVolume: {
    zh: 'D+2签收量',
    en: '',
    pu: 'Qtd. de entregados no D+2'
  },
  D2DeliveryRate: {
    zh: 'D+2签收率',
    en: '',
    pu: 'Taxa de entregados no D+2'
  },
  D3deliveryVolume: {
    zh: 'D+3签收量',
    en: '',
    pu: 'Qtd. de entregados no D+3'
  },
  D3DeliveryRate: {
    zh: 'D+3签收率',
    en: '',
    pu: 'Taxa de entregados no D+3'
  },
  D4deliveryVolume: {
    zh: 'D+4签收量',
    en: '',
    pu: 'Qtd. de entregados no D+4'
  },
  D4DeliveryRate: {
    zh: 'D+4签收率',
    en: '',
    pu: 'Taxa de entregados no D+4'
  },
  D5deliveryVolume: {
    zh: 'D+5签收量',
    en: '',
    pu: 'Qtd. de entregados no D+5'
  },
  D5DeliveryRate: {
    zh: 'D+5签收率',
    en: '',
    pu: 'Taxa de entregados no D+5'
  },
  D6deliveryVolume: {
    zh: 'D+6签收量',
    en: '',
    pu: 'Qtd. de entregados no D+6'
  },
  D6DeliveryRate: {
    zh: 'D+6签收率',
    en: '',
    pu: 'Taxa de entregados no D+6'
  },
  D7deliveryVolume: {
    zh: 'D+7签收量',
    en: '',
    pu: 'Qtd. de entregados no D+7'
  },
  D7DeliveryRate: {
    zh: 'D+7签收率',
    en: '',
    pu: 'Taxa de entregados no D+7'
  },
  D8deliveryVolume: {
    zh: 'D+8签收量',
    en: '',
    pu: 'Qtd. de entregados no D+8'
  },
  D8DeliveryRate: {
    zh: 'D+8签收率',
    en: '',
    pu: 'Taxa de entregados no D+8'
  },
  deliverySuccessRate: {
    zh: '妥投率',
    en: '',
    pu: 'Taxa de entregados'
  },
  undeliveredCount: {
    zh: '未妥投票数',
    en: '',
    pu: 'Qtd. de não entregados'
  },
  monday: {
    zh: '周一',
    en: '',
    pu: 'Segunda-feira'
  },
  tuesday: {
    zh: '周二',
    en: '',
    pu: 'Terça-feira'
  },
  wednesday: {
    zh: '周三',
    en: '',
    pu: 'Quarta-feira'
  },
  thursday: {
    zh: '周四',
    en: '',
    pu: 'Quinta-feira'
  },
  friday: {
    zh: '周五',
    en: '',
    pu: 'Sexta-feira'
  },
  saturday: {
    zh: '周六',
    en: '',
    pu: 'Sábado'
  },
  sunday: {
    zh: '周日',
    en: '',
    pu: 'Domingo'
  },
  transitWarehouseWeek: {
    zh: '转运入库星期',
    en: '',
    pu: 'Dia de entrada de Transfência'
  },
  searchMax1000: {
    zh: '单次查询最多2000条，已截取前2000条',
    en: '',
    pu: 'Máximo de 2.000 entradas para uma única consulta, as primeiras 2.000 entradas foram interceptadas'
  },
  timeIsNotNull: {
    zh: '开始时间或结束时间不能为空',
    en: '',
    pu: 'O tempo de começo ou final não pode estar vazio'
  },
  deliveryDistance: {
    zh: '派送距离(KM)',
    en: '',
    pu: 'Distância (km) de entrega'
  },
  Formal: {
    zh: '正式',
    en: '',
    pu: 'Formal'
  },
  test1: {
    zh: '测试',
    en: '',
    pu: 'Teste'
  },
  OperationMode: {
    zh: '派送模式',
    en: '',
    pu: 'Modo de entrega'
  },
  TotalOperationsToday: {
    zh: '今日总操作数据',
    en: 'Total operations data of today',
    pu: 'Dados totais de operações de hoje'
  },
  OrderQuantity: {
    zh: '下单票数',
    en: 'Order quantity',
    pu: 'Qtd. de Pedidos Realizados'
  },
  FinalCollectionQuantity: {
    zh: '末端揽收票数',
    en: 'Final collection quantity',
    pu: 'Qtd. de coleta final'
  },
  ReceivedAtStationQuantity: {
    zh: '派送网点入库票数',
    en: 'Received at delivery point quantity',
    pu: 'Qtd. de pacotes recebidos no ponto'
  },
  DeliveredQuantity: {
    zh: '派件票数',
    en: 'Delivered quantity',
    pu: 'Qtd. de pacotes entregues'
  },
  SignedQuantity: {
    zh: '签收票数',
    en: 'Signed quantity',
    pu: 'Qtd. de pacotes assinados'
  },
  ProblematicParcelQuantity: {
    zh: '问题件票数',
    en: 'Problematic parcel quantity',
    pu: 'Qtd. de pacotes com problemas'
  },
  CancelledQuantity: {
    zh: '已取消票数',
    en: 'Cancelled quantity',
    pu: 'Qtd. de pacotes cancelados'
  },
  ReturnedByStationQuantity: {
    zh: '网点退回票数',
    en: 'Returned by delivery point quantity',
    pu: 'Qtd. de pacotes devolvidos pelo ponto de entrega'
  },
  ReverseDeliveryQuantity: {
    zh: '逆向派件票数',
    en: 'Reverse delivery quantity',
    pu: 'Qtd. de pacotes de entrega reversa'
  },
  ReturnedAndSignedQuantity: {
    zh: '退件签收票数',
    en: 'Returned and signed quantity',
    pu: 'Qtd. de pacotes devolvidos assinados'
  },
  OrderFailureQuantity: {
    zh: '下单失败票数',
    en: 'Order failure quantity',
    pu: 'Qtd. de pedidos falhados'
  },
  SortingCenterOperationData: {
    zh: '分拨操作数据',
    en: 'Sorting center operation data',
    pu: 'Dados de operação do centro de triagem'
  },
  CustomerOrderStatus: {
    zh: '客户下单情况',
    en: 'Customer order status',
    pu: 'Situação de pedidos de clientes'
  },
  ConfigureCustomer: {
    zh: '配置客户',
    en: 'Configure customer',
    pu: 'Configurar cliente'
  },
  CustomerOrderTrendLast7Days: {
    zh: '近7天客户订单走势',
    en: 'Customer order trend in the last 7 days',
    pu: 'Tendência de pedidos de clientes nos últimos 7 dias'
  },
  ProblematicParcelOperationData: {
    zh: '问题件操作数据',
    en: 'Problematic parcel operation data',
    pu: 'Dados de operação de pacotes com problemas'
  },
  ComparedToYesterday: {
    zh: '较昨日',
    en: 'Compared to yesterday',
    pu: 'Comparado ao dia anterior'
  },
  LostParcels: {
    zh: '包裹丢失',
    en: 'Lost parcels',
    pu: 'Pacotes perdidos'
  },
  DamagedParcels: {
    zh: '包裹破损',
    en: 'Damaged parcels',
    pu: 'Pacotes danificados'
  },
  StolenParcels: {
    zh: '包裹被抢',
    en: 'Stolen parcels',
    pu: 'Pacotes roubados'
  },
  Others: {
    zh: '其它',
    en: 'Others',
    pu: 'Outros'
  },
  ConfirmLost: {
    zh: '确认丢失',
    en: 'Confirm lost',
    pu: 'Confirmação de Perda'
  },
  AllDamaged: {
    zh: '全部破损',
    en: 'All damaged',
    pu: 'Todos danificados'
  },
  AllStolen: {
    zh: '全部被盗',
    en: 'All stolen',
    pu: 'Todos roubados'
  },
  DeliveryAddressOutOfRange: {
    zh: '收件地址超出派送范围',
    en: 'Delivery address is out of coverage area',
    pu: 'O endereço de entrega está fora da área de cobertura'
  }
};

// 订单详情
const orderDetails = {
  ReplicationSucceeded: {
    zh: '复制成功',
    en: 'Replication succeeded',
    pu: 'Replicação bem sucedida'
  },
  orderMsg: {
    zh: '订单详情',
    en: '',
    pu: 'Detalhe do pedido'
  },
  waybillMsg: {
    zh: '运单详情',
    en: 'Waybill details',
    pu: 'Descrição de waybill'
  },
  copy: {
    zh: '复制',
    en: '',
    pu: 'Cópia'
  },
  desRem: {
    zh: '收/发件人',
    en: '',
    pu: 'Destinatário/Remetente'
  },
  wpMsg: {
    zh: '物品信息',
    en: '',
    pu: 'Descrição do objeto'
  },
  TrajectoryInformation: {
    zh: '轨迹信息',
    en: 'Trajectory information',
    pu: 'Informações de trajetória'
  },
  pleaseInput: {
    zh: '请输入',
    en: 'Please enter the content',
    pu: 'por favor digitir'
  },
  senderName: {
    zh: '发件人',
    en: '',
    pu: 'Remetente'
  },
  receiveName: {
    zh: '收件人',
    en: 'recipient',
    pu: 'Destinatário'
  },
  name: {
    zh: '名字',
    en: '',
    pu: 'Nome'
  },
  email: {
    zh: '邮箱',
    en: 'mailbox',
    pu: 'Email'
  },
  phone: {
    zh: '电话',
    en: 'Phone',
    pu: 'Telefone'
  },
  zipcode: {
    zh: '邮编',
    en: 'Zip',
    pu: 'CEP'
  },
  pleaseChoose: {
    zh: '请选择',
    en: 'Please select',
    pu: 'Favor selecionar'
  },
  State: {
    zh: '州',
    en: 'state',
    pu: 'Estado'
  },
  City: {
    zh: '城市',
    en: 'city',
    pu: 'Cidade'
  },
  Area: {
    zh: '区',
    en: 'Area',
    pu: 'Bairro'
  },
  Street: {
    zh: '具体街道（精确到门牌号）',
    en: '',
    pu: 'Logradouro específico(exato ao número da placa de porta)'
  },
  // eslint-disable-next-line no-dupe-keys
  print: {
    zh: '打印',
    en: 'print',
    pu: 'Imprimir'
  },
  cancel: {
    zh: '取消',
    en: 'cancel',
    pu: 'Cancelar'
  },
  edit: {
    zh: '编辑',
    en: 'Edit',
    pu: 'editar'
  }
};

// 订单导出
const orderExport = {
  test: {
    zh: '验证失败，请重新登录。',
    en: '',
    pu: 'A verificação falhou, favor fazer o login novamente'
  },
  hint: {
    zh: '提示',
    en: 'tips',
    pu: 'Dica'
  },
  ExportSuccessful: {
    zh: '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，',
    en: 'If the export is successful, the data will be generated within 1~10 minutes, please download it from the resource export list in system management.',
    pu: 'A exportação foi bem sucedida, os dados serão gerados em 1 a 10 minutos, favor descarregá-los da lista de exportação de recursos no gerenciamento do sistema,'
  },
  importSuccessfulPleaseDownload: {
    zh: '导入成功，数据将会在1~10分钟内生成表格，请到资源导出列表中下载',
    en: 'The import is successful, the data will be generated within 1~10 minutes, please download it from the resource export list',
    pu: 'A importação é bem sucedida, os dados serão gerados dentro de 1 a 10 minutos, por favor, baixe-os da lista de exportação de recursos'
  },
  notRepeat: {
    zh: '请勿重复导出！',
    en: 'Do not repeat the export！',
    pu: 'Não repita a exportação!'
  },
  close: {
    zh: '关闭',
    en: 'Shut down',
    pu: 'Fechar'
  },
  Immediately: {
    zh: '立即前往',
    en: 'Go now',
    pu: 'Ir para'
  }
};

// 导入订单
const orderImport = {
  client: {
    zh: '客户',
    en: 'client',
    pu: 'Cliente'
  },
  pleaseChoose: {
    zh: '请选择',
    en: 'Please select',
    pu: 'Favor selecionar'
  },
  uploadOnlyXlsx: {
    zh: '上传文件只能是xlsx格式',
    en: '',
    pu: 'O arquivo carregado só pode ser feito em formato xlsx'
  },
  uploadNot2MB: {
    zh: '上传文件大小不能超过 2MB',
    en: '',
    pu: 'O tamanho do arquivo carregado não pode exceder 2MB'
  },
  orderImport: {
    zh: '批量导入',
    en: '',
    pu: 'Importar em lotes'
  },
  demoUpload: {
    zh: '运单模版下载',
    en: '',
    pu: 'Download módulo do waybill'
  },
  cldrUpload: {
    zh: '点击或拖拽文件到此处上传',
    en: '',
    pu: 'Clicar ou arrastar arquivo para upload aqui'
  },
  onlyXlsx: {
    zh: '只能上传xlsx文件',
    en: '',
    pu: 'Somente arquivos xlsx podem ser upload'
  },
  orderUpload: {
    zh: '运单上传',
    en: '',
    pu: 'carregar o waybill'
  },
  Upload: {
    zh: '上传',
    en: '',
    pu: 'Upload'
  }
};

// 手动下单
const placeOrder = {
  SelectChannel: {
    zh: '选择渠道',
    en: 'Select a channel',
    pu: 'Selecione um canal'
  },
  client: {
    zh: '客户',
    en: 'client',
    pu: 'Cliente'
  },
  pleaseChooseClient: {
    zh: '请选择客户',
    en: '',
    pu: 'Por favor selecione cliente'
  },
  upLoadHint: {
    zh: '请上传pdf文件, 上传文件大小不能超过2MB',
    en: '',
    pu: 'Favor carregar um arquivo pdf, o tamanho do arquivo não deve exceder 2MB'
  },
  orderImport: {
    zh: '批量导入',
    en: '',
    pu: 'Importar em lotes'
  },
  test: {
    zh: '下 单',
    en: '',
    pu: 'Fazer pedido'
  },
  pleaseInput: {
    zh: '请输入',
    en: 'Please enter the content',
    pu: 'por favor digitir'
  },
  PleaseEnter6To16DigitInvoiceNumber: {
    zh: '请输入5~16位发票号',
    en: 'Please enter the 5~16 digit invoice number',
    pu: 'Por favor, insira o número da fatura de 5 ~ 16 dígitos'
  },
  PleaseEnter1To8TheInvoiceSerialNumber: {
    zh: '请输入1~8位发票序列号',
    en: 'Please enter the 1~8 digit invoice number',
    pu: 'Por favor, insira o número da fatura de 1 ~ 8 dígitos'
  },
  PleaseEnter1To64DigitInvoiceNumber: {
    zh: '请输入44位发票密钥',
    en: 'Please enter 44 digits invoice key',
    pu: 'Por favor, insira 44 dígitos chave de fatura'
  },
  PleaseEnter1To8invoiceSerialIndex: {
    zh: '请输入1~8位数字序列号',
    en: '',
    pu: 'Insira o número de série de 1 a 8 dígitos'
  },
  IENumber: {
    zh: '州注册号为1~17位数值',
    en: '',
    pu: 'O número de registro nacional é um valor de 1 a 17 dígitos'
  },
  pleaseChoose: {
    zh: '请选择',
    en: 'Please select',
    pu: 'Favor selecionar'
  },
  zhifa: {
    zh: '直单',
    en: '',
    pu: 'Expedição direta'
  },
  CoPacking: {
    zh: '合包',
    en: 'Co-packing',
    pu: 'Embalagem'
  },
  infoDesRem: {
    zh: '收/发件人信息',
    en: '',
    pu: 'Informação do destinatário/remetente'
  },
  senderName: {
    zh: '发件人',
    en: '',
    pu: 'Remetente'
  },
  senderAddress: {
    zh: '客户发件地址',
    en: 'client sender address',
    pu: 'endereço do remetente do cliente'
  },
  fold: {
    zh: '收起',
    en: 'fold',
    pu: 'dobrar'
  },
  moreInformation: {
    zh: '更多信息',
    en: 'more information',
    pu: 'mais informação'
  },
  detailedAddress: {
    zh: '详细地址',
    en: 'detailed address',
    pu: 'Endereço detalhado'
  },
  SynchronizeClient: {
    zh: '同步客户',
    en: 'Synchronize to client',
    pu: 'Sincronizar com o cliente'
  },
  clientAddress: {
    zh: '客户收件地址',
    en: 'client consignee address',
    pu: 'endereço do cliente consignatário'
  },
  parcelWeight: {
    zh: '包裹重量(KG)',
    en: 'parcel weight(KG)',
    pu: 'peso da parcela (KG)'
  },
  enterAutomatically: {
    zh: '自动进入订单列表',
    en: 'enter order list automatically',
    pu: 'entrar na lista de pedidos automaticamente'
  },
  returnOrderList: {
    zh: '返回订单列表',
    en: 'return to order list',
    pu: 'retornar à lista de pedidos'
  },
  continueOrders: {
    zh: '继续下单',
    en: 'continue creating orders',
    pu: 'continuar criando pedidos'
  },
  PleaseInputNumber: {
    zh: '请输入希望共用信息的运单号',
    en: 'Please input tracking number whose information you wish to share',
    pu: 'Insira o número de rastreamento cujas informações você deseja compartilhar'
  },
  destinationZone: {
    zh: '收件地区',
    en: 'destination zone',
    pu: 'zona de destino'
  },
  destinationAddress: {
    zh: '收件详细地址',
    en: 'detailed destination address',
    pu: 'endereço de destino detalhado'
  },
  baseData: {
    zh: '基础数据',
    en: 'base data',
    pu: 'dado básico'
  },
  selectClient: {
    zh: '请先选择客户',
    en: 'Please select client first',
    pu: 'Selecione o cliente primeiro'
  },
  beyondRange: {
    zh: '超出范围(0~99999999.999]',
    en: 'beyond range(0~99999999.999)',
    pu: 'além do intervalo (0~9999999999.999)'
  },
  beyondRangeNumber: {
    zh: '超出范围(0~99999999]',
    en: 'beyond range(0~99999999)',
    pu: 'além do intervalo (0~9999999999)'
  },
  taxFormatIncorrect: {
    zh: '税号需要包含11位或14位数字',
    en: '',
    pu: 'Número de id fiscal precisa conter 11 ou 14 dígitos'
  },
  taxIncorrect: {
    zh: '税号错误,请输入11~20字符串(包含数字或符号.-/)',
    en: '',
    pu: 'Número de id fiscal errado, por favor insira string de 11~20 (incluindo números ou símbolos.-/)'
  },
  automaticPrinting1: {
    zh: '请点击下载',
    en: '',
    pu: 'Clique para fazer o download'
  },
  automaticPrinting2: {
    zh: '插件',
    en: '',
    pu: 'aqui'
  },
  automaticPrinting3: {
    zh: ',绑定打印机自动打印',
    en: '',
    pu: ',Impressoras integradas para impressão automática'
  },
  // automaticPrinting: {
  //   zh: '1、下载安装自动打印插件, 点击此处下载安装文件; 电脑绑定打印面单的打印机',
  //   en: 'Download and install the automatic printing plug-in, click here to download the installation file; and bind computer with printer for printing labels',
  //   pu: 'Faça o download e instale o plug-in de impressão automática, clique aqui para fazer o download do arquivo de instalação; e conecte o computador com a impressora para imprimir etiquetas.'
  // },
  goToPrintPage: {
    zh: '2、进入换单打印页, 扫描初始物流面单, 系统将自动打印需要替换的新面单',
    en: '',
    pu: 'Entre na página de impressão da ordem de modificação, digitalize a etiqueta inicial da logística e o sistema imprimirá automaticamente a nova etiqueta a ser substituída'
  },
  reduceAbnormalities: {
    zh: '3、为减少异常, 扫描前请先切换成[EN]输入法',
    en: 'Please switch to [EN] input method before scanning to reduce abnormalities',
    pu: 'Alterne para o método de entrada [EN] antes de digitalizar para reduzir as anormalidades'
  },
  copyOrder: {
    zh: '复制订单',
    en: 'copy order',
    pu: 'copiar pedidos'
  },

  receiveName: {
    zh: '收件人',
    en: 'recipient',
    pu: 'Destinatário'
  },
  name: {
    zh: '名字',
    en: '',
    pu: 'Nome'
  },
  email: {
    zh: '邮箱',
    en: 'mailbox',
    pu: 'Email'
  },
  phone: {
    zh: '电话',
    en: 'Phone',
    pu: 'Telefone'
  },
  zipcode: {
    zh: '邮编',
    en: 'Zip',
    pu: 'CEP'
  },
  State: {
    zh: '州',
    en: 'state',
    pu: 'Estado'
  },
  city: {
    zh: '城市',
    en: '',
    pu: 'Cidade'
  },
  Area: {
    zh: '区',
    en: 'Area',
    pu: 'Bairro'
  },
  Street: {
    zh: '街道(精确到街道号)',
    en: '',
    pu: 'Logradouro (exato ao número do logradouro)'
  },
  Neighborhood: {
    zh: '邻里',
    en: '',
    pu: 'Vizinho'
  },
  Tax: {
    zh: '税号',
    en: '',
    pu: 'CPF'
  },
  Complement: {
    zh: '补充',
    en: '',
    pu: 'Suplementar'
  },
  HouseNumber: {
    zh: '门牌号',
    en: 'HouseNumber',
    pu: 'Número'
  },
  cancel: {
    zh: '取消',
    en: 'cancel',
    pu: 'Cancelar'
  },
  next: {
    zh: '下一步',
    en: 'Next',
    pu: 'Próximo passo'
  },
  back: {
    zh: '返回',
    en: 'back',
    pu: 'Voltar'
  },
  // ------------------------------------,
  infoPackage: {
    zh: '包裹信息',
    en: '',
    pu: 'Informação do pacote'
  },
  customerNumber: {
    zh: '客户单号',
    en: '',
    pu: 'Número do pedido do cliente'
  },
  package: {
    zh: '包裹', // 包裹1 包裹2 包裹3........
    en: '',
    pu: 'Pacote'
  },
  addPackage: {
    zh: '添加包裹',
    en: '',
    pu: 'Adicionar pacote'
  },
  long: {
    zh: '长(cm)',
    en: '',
    pu: 'Comprimento(cm)'
  },
  width: {
    zh: '宽(cm)',
    en: '',
    pu: 'Largura(cm)'
  },
  height: {
    zh: '高(cm)',
    en: '',
    pu: 'Altura(cm)'
  },
  weight: {
    zh: '重量(KG)',
    en: '',
    pu: 'Peso(KG)'
  },
  packageType: {
    zh: '包裹类型',
    en: '',
    pu: 'Tipo do pacote'
  },
  cargoType: {
    zh: '货物类型',
    en: '',
    pu: 'Tipo de mercadorias'
  },
  FaceSheet: {
    zh: '面单',
    en: 'Face sheet',
    pu: 'Folha facial'
  },
  DateOfInvoicing: {
    zh: '开票日期',
    en: 'Date of invoicing',
    pu: 'Data de faturamento'
  },
  InvoiceNumber: {
    zh: '发票号',
    en: 'Invoice number',
    pu: 'Número da fatura'
  },
  InvoiceKey: {
    zh: '发票密钥',
    en: 'Invoice key',
    pu: 'Chave da fatura'
  },
  scanOrderNumber: {
    zh: '扫描单号',
    en: '',
    pu: 'Código de barra'
  },
  wp: {
    zh: '物品', // 物品1 物品2 物品3......
    en: '',
    pu: 'Objeto'
  },
  wpName: {
    zh: '物品名称',
    en: '',
    pu: 'Nome do objeto'
  },
  declaredValue: {
    zh: '物品单价(R$/$)',
    en: '',
    pu: 'Preço unitário(R$/$)'
  },
  quantity: {
    zh: '数量', // 物品的数量
    en: '',
    pu: 'Quantidade'
  },
  invoice: {
    zh: '商品发票证明',
    en: '',
    pu: 'Fatura de mercadoria'
  },
  clickUpload: {
    zh: '点击上传',
    en: '',
    pu: 'Clicar para upload'
  },
  addWP: {
    zh: '添加物品',
    en: '',
    pu: 'Adicionar objeto'
  },
  Delete: {
    zh: '删除',
    en: 'Delete',
    pu: 'Excluir'
  },
  // ------------------------------------,
  infoOrder: {
    zh: '下单信息',
    en: '',
    pu: 'Informação do pedido'
  },
  totalWeight: {
    zh: '总重量(KG)',
    en: '',
    pu: 'Peso total(KG)'
  },
  totalPrice: {
    zh: '总价格(USD)',
    en: '',
    pu: 'Preço total(KG)'
  },
  confirm: {
    zh: '确定',
    en: 'determine',
    pu: 'Confirmar'
  },
  // 提示信息-----------------------
  orderWasPlacedSuccessfully: {
    zh: '下单成功',
    en: 'The order was placed successfully',
    pu: 'A ordem foi feita com sucesso'
  },
  uploadOnlyXlsx: {
    zh: '上传文件只能是xlsx格式',
    en: '',
    pu: 'O arquivo carregado só pode ser feito em formato xlsx'
  },
  uploadNot2MB: {
    zh: '上传文件大小不能超过 2MB',
    en: '',
    pu: 'O tamanho do arquivo carregado não pode exceder 2MB'
  },
  emailNotFormat: {
    zh: '邮箱格式不正确',
    en: '',
    pu: 'Formato de e-mail incorreto'
  },
  input11PhoneNumber: {
    zh: '请输入正确的号码',
    en: '',
    pu: 'Por favor, insira um número correto'
  },
  input8ZipCode: {
    zh: '请输入8位数字邮编',
    en: '',
    pu: 'Digite CEP de 8 dígitos '
  },
  float2Number: {
    zh: '请输入最多小数点后三位的数字',
    en: '',
    pu: 'Por favor, digite o número até três casas decimais'
  },
  inputNum: {
    zh: '请输入数字',
    en: '',
    pu: 'Favor digitar o número'
  },
  password: {
    zh: '密码由8-12位小写或大写字母、数字组成',
    en: '',
    pu: 'A senha consiste em 8 a 12 letras minúsculas ou maiúsculas e números'
  },
  enterpriseTaxNumTips: {
    zh: '数字长度为11，格式为`xxx.xxx.xxx-yy`',
    en: '',
    pu: 'Número com 11 dígitos, formato `xxx.xxx.xxx-yy`'
  },
  notNull: {
    zh: '不能为空',
    en: 'Cannot be empty',
    pu: 'Não pode estar vazio'
  },
  inputEnOrZh: {
    zh: '请输入英文或中文',
    en: '',
    pu: 'Por favor, digite em inglês ou chinês'
  },
  notNumber: {
    zh: '不能有数字',
    en: '',
    pu: 'Não pode ter números'
  },
  inputCodeErr: {
    zh: '邮编输入错误，请重新输入',
    en: '',
    pu: 'CEP incorreto, por favor, digite novamente'
  },

  packageNeedOneInvoice: {
    zh: '每个包裹至少需要一张商品发票',
    en: '',
    pu: 'É necessário pelo menos uma fatura de mercadoria para cada pacote'
  },
  inputRightCustomerNum: {
    zh: '请填写正确的客户单号',
    en: '',
    pu: 'Favor preencher o número correto do pedido do cliente'
  },
  customerNumberUsable: {
    zh: '客户单号可用',
    en: '',
    pu: 'Número de pedido do cliente disponível'
  },
  uploadPDF: {
    zh: '请上传pdf文件',
    en: '',
    pu: 'Favor carregar arquivo pdf'
  },
  noMoreThan2M: {
    zh: '上传文件大小不能超过 2MB',
    en: '',
    pu: 'O tamanho do arquivo carregado não pode exceder 2MB'
  },
  uploadInvoiceErr: {
    zh: '上传发票报错',
    en: '',
    pu: 'Erro no carregamento da fatura'
  },
  CommercialOwner: {
    zh: '商户',
    en: '',
    pu: 'Comerciante'
  },
  InvoiceAmount: {
    zh: '开票金额',
    en: '',
    pu: 'Valor faturado'
  },
  InvoiceAmountThen: {
    zh: '开票金额不得小于等于0',
    en: '',
    pu: 'O valor faturado não deve ser menor ou igual a 0'
  },
  InvoiceAmountMore: {
    zh: '发票金额超出(0,999,999,999,999.99]的限制',
    en: '',
    pu: 'O valor da fatura excede o limite de (0,999,999,999,999.99]'
  },
  twoDecimalPlaces: {
    zh: '精确到两位小数',
    en: '',
    pu: 'Precisão de duas casas decimais'
  },
  input030: {
    zh: '请输入(0,30]范围内的数值',
    en: '',
    pu: 'Digite um valor no intervalo de (0,30].'
  },
  input0500: {
    zh: '请输入(0,500]范围内的数值',
    en: '',
    pu: 'Digite um valor no intervalo de (0,500].'
  },
  inputZhenshu: {
    zh: '请输入正数',
    en: '',
    pu: 'Digite um número positivo.'
  },
  input3: {
    zh: '精确到3位小数',
    en: '',
    pu: 'Precisão de 3 casas decimais.'
  },
  input30: {
    zh: '默认为30KG',
    en: '',
    pu: 'O padrão é 30KG'
  },
  input500: {
    zh: '默认为500KG',
    en: '',
    pu: 'O padrão é 500KG'
  },
  unitPricenotthan: {
    zh: '单价不得小于0',
    en: '',
    pu: 'O preço unitário não deve ser inferior a 0'
  },
  unitPricenotMore99: {
    zh: '单价超出[0,999,999,999,999.99]的限制',
    en: '',
    pu: 'O preço unitário excede o limite de [0,999,999,999,999,999,999.99]'
  },
  collate: {
    zh: '输入有助于提升包裹揽收效率，如不知道选哪个可联系对接揽收成员进行添加和核对',
    en: '',
    pu: 'O insumo ajuda a melhorar a eficiência da coleta de pacotes. Se não souber qual escolher, entre em contato com os membros da coleta de docas para adicionar e verificar'
  },
  addressComplement: {
    zh: '地址补充',
    en: '',
    pu: 'Complementos de endereço'
  },
  input5: {
    zh: '请输入5个以内字符',
    en: '',
    pu: 'Digite 5 caracteres ou menos'
  },
  input30Str: {
    zh: '请输入30个以内字符',
    en: '',
    pu: 'Digite 30 caracteres ou menos'
  },
  input50Str: {
    zh: '请输入50个以内字符',
    en: '',
    pu: 'Digite 50 caracteres ou menos'
  },
  emailError: {
    zh: '邮箱格式错误',
    en: '',
    pu: 'Formato incorreto do e-mail'
  },
  tip1: {
    zh: 'HUB1封车时间减去HUB1解封车时间',
    en: '',
    pu: 'Tempo de bloqueio do HUB1 menos tempo de desbloqueio do HUB1'
  },
  tip2: {
    zh: 'HUB2封车时间减去HUB2解封车时间',
    en: '',
    pu: 'Tempo de bloqueio do HUB2 menos tempo de desbloqueio do HUB2'
  },
  tip301: {
    zh: 'HUB3封车时间减去HUB3解封车时间',
    en: '',
    pu: 'Tempo de bloqueio do HUB3 menos tempo de desbloqueio do HUB3'
  },
  tip3: {
    zh: '揽收时间减去客户出仓时间',
    en: '',
    pu: 'Horário da coleta menos o horário de saída do armazém do cliente'
  },
  tip4: {
    zh: '一级分拨操作时长',
    en: '',
    pu: 'Duração da operação do centro de distribuição nível 01'
  },
  tip5: {
    zh: '二级分拨操作时长',
    en: '',
    pu: 'Duração da operação do centro de distribuição nível 02'
  },
  tip300: {
    zh: '三级分拨操作时长',
    en: '',
    pu: 'Duração da operação do centro de distribuição nível 03'
  },
  BatchExport: {
    zh: '批量单号导出',
    en: '',
    pu: 'Exportação de número do lote'
  },
  shijicenter: {
    zh: '实际到件分拨中心',
    en: '',
    pu: 'Centro de distribuição que entrada atual'
  },
  tip6: {
    zh: '网点入库时间减去网点所属的上一级分拨中心发件时间',
    en: '',
    pu: 'Tempo de entrada na rede menos o tempo de despacho da distribuição secundária'
  },
  goodType: {
    zh: '货物类型',
    en: '',
    pu: 'Tipo de mercadorias'
  },
  big01: {
    zh: '大件',
    en: '',
    pu: 'Tipo de mercadorias'
  },
  Normal: {
    zh: '正常',
    en: '',
    pu: 'Normal'
  },
  normal01: {
    zh: '正常',
    en: '',
    pu: 'Normal'
  },
  area: {
    zh: '区域',
    en: '',
    pu: 'Área'
  },
  cityType: {
    zh: '城镇类型',
    en: '',
    pu: 'Tipo de cidade'
  }
};
export {
  newOrder,
  orderDetails,
  orderExport,
  orderImport,
  placeOrder
};
