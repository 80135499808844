var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "TABLE_LIST", style: {} },
    [
      _c(
        "virtual-scroll",
        {
          ref: "virtualScroll",
          staticStyle: { height: "100%", width: "100%" },
          attrs: {
            data: _vm.tableData,
            "key-prop": "id",
            "item-size": 50,
            virtualized: _vm.tableData.length > 100,
          },
          on: {
            change: function (renderData) {
              return (_vm.list = renderData)
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "el-table-infinite-scroll",
                  rawName: "v-el-table-infinite-scroll",
                  value: true,
                  expression: "true",
                },
              ],
              ref: "elTable",
              staticClass: "table-height",
              attrs: {
                data: _vm.list,
                border: "",
                height: _vm.tableHeightRow.height,
                "row-key": "id",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("virtual-column", {
                attrs: { width: "50", type: "selection" },
              }),
              _c("virtual-column", {
                attrs: {
                  label: _vm.$t("GlobalSetObj.serial"),
                  width: "50",
                  type: "index",
                },
              }),
              _vm._l(_vm.tableHeader, function (item) {
                return _c("el-table-column", {
                  key: item.prop,
                  attrs: {
                    align: item.fixed,
                    label: item.label,
                    "min-width": item.width,
                    prop: item.prop,
                    "show-overflow-tooltip": true,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            item.prop === "waybillNumber"
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#409eff",
                                      cursor: "pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editFee(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row[item.prop]))]
                                )
                              : item.prop === "status"
                              ? _c("div", [_vm._v(_vm._s(scope.row.statusStr))])
                              : item.prop === "collectType"
                              ? _c("div", [
                                  _vm._v(_vm._s(scope.row.collectTypeStr)),
                                ])
                              : item.prop === "feeSource"
                              ? _c("div", [
                                  _vm._v(_vm._s(scope.row.feeSourceStr)),
                                ])
                              : _c("div", [
                                  _vm._v(_vm._s(scope.row[item.prop])),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _vm._l(_vm.currentColumnsArr, function (item) {
                return _c("el-table-column", {
                  key: item.key,
                  attrs: {
                    label: item.label,
                    prop: item.prop,
                    "show-overflow-tooltip": true,
                    "render-header": _vm.renderHeader,
                  },
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination" },
        [
          _c("el-pagination", {
            staticStyle: { margin: "10px 0" },
            attrs: {
              "current-page": _vm.queryForm.pageNumber,
              "page-size": _vm.queryForm.pageSize,
              "page-sizes": [100, 200, 500, 1000],
              total: _vm.total,
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "current-change": _vm.onCurrentChange,
              "size-change": _vm.onSinzeChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }