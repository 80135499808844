var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "trackQueryBox" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticStyle: { "margin-bottom": "10px" }, attrs: { span: 10 } },
            [
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permit",
                          rawName: "v-permit:remove",
                          value: "btn:collection-center:order:track-query:find",
                          expression:
                            "'btn:collection-center:order:track-query:find'",
                          arg: "remove",
                        },
                      ],
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini",
                      },
                      on: { click: _vm.defaultClick },
                    },
                    [_vm._v(_vm._s(_vm.$t("collectionCenter.search")))]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "radioClass", attrs: { span: 10 } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { input: _vm.changeRadio },
                          model: {
                            value: _vm.radio,
                            callback: function ($$v) {
                              _vm.radio = $$v
                            },
                            expression: "radio",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "0" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("collectionCenter.lanshouOrderNum"))
                            ),
                          ]),
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v(_vm._s(_vm.$t("collectionCenter.Barcode"))),
                          ]),
                          _c("el-radio", { attrs: { label: "2" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("collectionCenter.parcelNum"))
                            ),
                          ]),
                          _c("el-radio", { attrs: { label: "3" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("collectionCenter.BagNumber"))
                            ),
                          ]),
                          _c("el-radio", { attrs: { label: "4" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("collectionCenter.licensePlateNumber")
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 13 } }, [
                    _c(
                      "div",
                      { staticClass: "inputNumberClass" },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            autosize: "",
                            placeholder: _vm.$t("collectionCenter.BatchQuery"),
                          },
                          on: { blur: _vm.checkData },
                          model: {
                            value: _vm.inputNumbers,
                            callback: function ($$v) {
                              _vm.inputNumbers = $$v
                            },
                            expression: "inputNumbers",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-col", { staticClass: "rightBox", attrs: { span: 14 } }, [
            _vm.dataList.length <= 0
              ? _c("div", { staticClass: "nullMsgClass" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("collectionCenter.nullMsg")) + " "
                  ),
                ])
              : _vm._e(),
            _vm.dataList.length > 0 && _vm.radio == 4
              ? _c(
                  "div",
                  _vm._l(_vm.dataList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "rightClass" },
                      [
                        _c(
                          "el-row",
                          {
                            staticClass: "msgTop",
                            attrs: { type: "flex", justify: "space-between" },
                          },
                          [
                            _c("el-col", { attrs: { span: 12 } }, [
                              _vm._v(_vm._s(item.number)),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 5 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openImg(item.reachUrl)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("collectionCenter.proofArrival")
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 5 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openImg(item.leaveUrl)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "collectionCenter.departureCertificate"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "msgClass" },
                          [
                            _vm._l(
                              item.vehicleNowTrack,
                              function (nowItem, nowIndex) {
                                return _c(
                                  "div",
                                  {
                                    key: nowIndex,
                                    staticClass: "msgContent newMsgClass",
                                    staticStyle: { "border-bottom": "none" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "collectionCenter.licensePlateNumber"
                                          )
                                        ) + "【"
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v(_vm._s(item.number))]
                                      ),
                                      _vm._v("】"),
                                    ]),
                                    _vm._v("， "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(nowItem.scanTime) +
                                          _vm._s(nowItem.status)
                                      ),
                                    ]),
                                    _vm._v("， "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "collectionCenter.theDriverName"
                                          )
                                        ) + "【"
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v(_vm._s(nowItem.driverName))]
                                      ),
                                      _vm._v("】"),
                                    ]),
                                    nowItem.operationType == 0 &&
                                    nowItem.siteName
                                      ? _c("span", [
                                          _vm._v(
                                            "，" +
                                              _vm._s(
                                                _vm.$t(
                                                  "collectionCenter.arrivalPoint"
                                                )
                                              ) +
                                              "【"
                                          ),
                                          _c(
                                            "span",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v(_vm._s(nowItem.siteName))]
                                          ),
                                          _vm._v("】"),
                                        ])
                                      : _vm._e(),
                                    nowItem.operationType == 1 &&
                                    nowItem.siteName
                                      ? _c("span", [
                                          _vm._v(
                                            "，" +
                                              _vm._s(
                                                _vm.$t(
                                                  "collectionCenter.exitNode"
                                                )
                                              ) +
                                              "【"
                                          ),
                                          _c(
                                            "span",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v(_vm._s(nowItem.siteName))]
                                          ),
                                          _vm._v("】"),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            _c(
                              "el-collapse",
                              { on: { change: _vm.handleChange } },
                              [
                                _c(
                                  "el-collapse-item",
                                  _vm._l(
                                    item.vehicleTrack,
                                    function (beforeItem, beforeIndex) {
                                      return _c(
                                        "div",
                                        {
                                          key: beforeIndex,
                                          staticClass: "msgContent",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "collectionCenter.licensePlateNumber"
                                                )
                                              ) + "【"
                                            ),
                                            _c(
                                              "span",
                                              { staticStyle: { color: "red" } },
                                              [_vm._v(_vm._s(item.number))]
                                            ),
                                            _vm._v("】"),
                                          ]),
                                          _vm._v("， "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(beforeItem.scanTime) +
                                                _vm._s(beforeItem.status)
                                            ),
                                          ]),
                                          _vm._v("， "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "collectionCenter.theDriverName"
                                                )
                                              ) + "【"
                                            ),
                                            _c(
                                              "span",
                                              { staticStyle: { color: "red" } },
                                              [
                                                _vm._v(
                                                  _vm._s(beforeItem.driverName)
                                                ),
                                              ]
                                            ),
                                            _vm._v("】"),
                                          ]),
                                          beforeItem.operationType == 0 &&
                                          beforeItem.siteName
                                            ? _c("span", [
                                                _vm._v(
                                                  "，" +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "collectionCenter.arrivalPoint"
                                                      )
                                                    ) +
                                                    "【"
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "red",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        beforeItem.siteName
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v("】"),
                                              ])
                                            : _vm._e(),
                                          beforeItem.operationType == 1 &&
                                          beforeItem.siteName
                                            ? _c("span", [
                                                _vm._v(
                                                  "，" +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "collectionCenter.exitNode"
                                                      )
                                                    ) +
                                                    "【"
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "red",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        beforeItem.siteName
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v("】"),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.dataList.length > 0 && _vm.radio != 4
              ? _c(
                  "div",
                  _vm._l(_vm.dataList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "rightClass" },
                      [
                        _c(
                          "el-row",
                          {
                            staticClass: "msgTop",
                            attrs: { type: "flex", justify: "space-between" },
                          },
                          [
                            _c("el-col", { attrs: { span: 12 } }, [
                              _vm._v(_vm._s(item.number)),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 5 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openImg(item.collectUrl)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("collectionCenter.lanshouImg")
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 5 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openImg(item.signUrl)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("collectionCenter.signImage")
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "msgClass" },
                          [
                            _vm._l(item.nowTrack, function (nowItem, nowIndex) {
                              return _c(
                                "div",
                                {
                                  key: nowIndex,
                                  staticClass: "msgContent newMsgClass",
                                  staticStyle: { "border-bottom": "none" },
                                },
                                [
                                  _c("span", [_vm._v(_vm._s(item.number))]),
                                  nowItem.status ==
                                    _vm.$t("collectionCenter.truckLoading") ||
                                  nowItem.status ==
                                    _vm.$t("collectionCenter.unloading")
                                    ? _c("span", [
                                        nowItem.status ==
                                        _vm.$t("collectionCenter.truckLoading")
                                          ? _c("span", [
                                              nowItem.siteName
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "spareNumberPoo.in"
                                                        )
                                                      ) + "【"
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "red",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            nowItem.siteName
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v("】"),
                                                  ])
                                                : _vm._e(),
                                              nowItem.signName
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "collectionCenter.bei"
                                                        )
                                                      ) + "【"
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "red",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            nowItem.signName
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      "】" +
                                                        _vm._s(nowItem.status)
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              nowItem.nextSiteName
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "collectionCenter.sendNextStop"
                                                        )
                                                      ) + "【"
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "red",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            nowItem.nextSiteName
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v("】"),
                                                  ])
                                                : _vm._e(),
                                              _c("span", [
                                                _vm._v(
                                                  "，" +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "collectionCenter.scanTime"
                                                      )
                                                    ) +
                                                    " " +
                                                    _vm._s(nowItem.scanTime)
                                                ),
                                              ]),
                                              nowItem.tagNum
                                                ? _c("span", [
                                                    _vm._v(
                                                      "，" +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "collectionCenter.carNumber"
                                                          )
                                                        ) +
                                                        "：" +
                                                        _vm._s(nowItem.tagNum)
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        nowItem.status ==
                                        _vm.$t("collectionCenter.unloading")
                                          ? _c("span", [
                                              nowItem.siteName
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "collectionCenter.arrival"
                                                        )
                                                      ) + "【"
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "red",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            nowItem.siteName
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v("】"),
                                                  ])
                                                : _vm._e(),
                                              nowItem.signName
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "collectionCenter.bbei"
                                                        )
                                                      ) + "【"
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "red",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            nowItem.signName
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      "】" +
                                                        _vm._s(nowItem.status)
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _c("span", [
                                                _vm._v(
                                                  "，" +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "collectionCenter.scanTime"
                                                      )
                                                    ) +
                                                    " " +
                                                    _vm._s(nowItem.scanTime)
                                                ),
                                              ]),
                                              nowItem.tagNum
                                                ? _c("span", [
                                                    _vm._v(
                                                      "，" +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "collectionCenter.carNumber"
                                                          )
                                                        ) +
                                                        "：" +
                                                        _vm._s(nowItem.tagNum)
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                      ])
                                    : _c("span", [
                                        nowItem.signName
                                          ? _c("span", [
                                              _vm._v("【"),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: { color: "red" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(nowItem.signName)
                                                  ),
                                                ]
                                              ),
                                              _vm._v("】"),
                                            ])
                                          : nowItem.siteName
                                          ? _c("span", [
                                              _vm._v("【"),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: { color: "red" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(nowItem.siteName)
                                                  ),
                                                ]
                                              ),
                                              _vm._v("】"),
                                            ])
                                          : _vm._e(),
                                        _vm._v(
                                          " " + _vm._s(nowItem.status) + "， "
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "collectionCenter.scanTime"
                                              )
                                            ) +
                                              " " +
                                              _vm._s(nowItem.scanTime)
                                          ),
                                        ]),
                                      ]),
                                ]
                              )
                            }),
                            _c(
                              "el-collapse",
                              { on: { change: _vm.handleChange } },
                              [
                                _c(
                                  "el-collapse-item",
                                  _vm._l(
                                    item.trackStatuses,
                                    function (beforeItem, beforeIndex) {
                                      return _c(
                                        "div",
                                        {
                                          key: beforeIndex,
                                          staticClass: "msgContent",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.number)),
                                          ]),
                                          beforeItem.status ==
                                            _vm.$t(
                                              "collectionCenter.truckLoading"
                                            ) ||
                                          beforeItem.status ==
                                            _vm.$t("collectionCenter.unloading")
                                            ? _c("span", [
                                                beforeItem.status ==
                                                _vm.$t(
                                                  "collectionCenter.truckLoading"
                                                )
                                                  ? _c("span", [
                                                      beforeItem.siteName
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "spareNumberPoo.in"
                                                                )
                                                              ) + "【"
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color: "red",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    beforeItem.siteName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v("】"),
                                                          ])
                                                        : _vm._e(),
                                                      beforeItem.signName
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "collectionCenter.bei"
                                                                )
                                                              ) + "【"
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color: "red",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    beforeItem.signName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              "】" +
                                                                _vm._s(
                                                                  beforeItem.status
                                                                )
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      beforeItem.nextSiteName
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "collectionCenter.sendNextStop"
                                                                )
                                                              ) + "【"
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color: "red",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    beforeItem.nextSiteName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v("】"),
                                                          ])
                                                        : _vm._e(),
                                                      _c("span", [
                                                        _vm._v(
                                                          "，" +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "collectionCenter.scanTime"
                                                              )
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              beforeItem.scanTime
                                                            )
                                                        ),
                                                      ]),
                                                      beforeItem.tagNum
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "，" +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "collectionCenter.carNumber"
                                                                  )
                                                                ) +
                                                                "：" +
                                                                _vm._s(
                                                                  beforeItem.tagNum
                                                                )
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ])
                                                  : _vm._e(),
                                                beforeItem.status ==
                                                _vm.$t(
                                                  "collectionCenter.unloading"
                                                )
                                                  ? _c("span", [
                                                      beforeItem.siteName
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "collectionCenter.arrival"
                                                                )
                                                              ) + "【"
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color: "red",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    beforeItem.siteName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v("】"),
                                                          ])
                                                        : _vm._e(),
                                                      beforeItem.signName
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "collectionCenter.bbei"
                                                                )
                                                              ) + "【"
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color: "red",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    beforeItem.signName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              "】" +
                                                                _vm._s(
                                                                  beforeItem.status
                                                                )
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      _c("span", [
                                                        _vm._v(
                                                          "，" +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "collectionCenter.scanTime"
                                                              )
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              beforeItem.scanTime
                                                            )
                                                        ),
                                                      ]),
                                                      beforeItem.tagNum
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "，" +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "collectionCenter.carNumber"
                                                                  )
                                                                ) +
                                                                "：" +
                                                                _vm._s(
                                                                  beforeItem.tagNum
                                                                )
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ])
                                                  : _vm._e(),
                                              ])
                                            : _c("span", [
                                                beforeItem.signName
                                                  ? _c("span", [
                                                      _vm._v("【"),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "red",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              beforeItem.signName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v("】"),
                                                    ])
                                                  : beforeItem.siteName
                                                  ? _c("span", [
                                                      _vm._v("【"),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "red",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              beforeItem.siteName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v("】"),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(beforeItem.status) +
                                                    "， "
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "collectionCenter.scanTime"
                                                      )
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        beforeItem.scanTime
                                                      )
                                                  ),
                                                ]),
                                              ]),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }