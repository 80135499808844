var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "query-container" }, [
    _c(
      "div",
      { staticClass: "query-form" },
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.params,
              "label-width": "160px",
              "label-position": _vm.$i18n.locale != "zh" ? "top" : "right",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: _vm.$t("basicData.PayablePrincipal") } },
              [
                _c("QueryProviderCmp", {
                  attrs: { val: _vm.params.providerId },
                  on: {
                    change: function (val) {
                      return (_vm.params.providerId = val)
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: _vm.$t("basicData.Outlets") } },
              [
                _c("QuerySiteCmp", {
                  attrs: {
                    val: _vm.params.siteId,
                    "provider-id": _vm.params.providerId,
                  },
                  on: {
                    change: function (val) {
                      return (_vm.params.siteId = val)
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: _vm.$t("receivablePayable.customer") } },
              [
                _c("QueryCustomerCmp", {
                  attrs: { val: _vm.params.customerId },
                  on: {
                    change: function (val) {
                      return (_vm.params.customerId = val)
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: _vm.$t("basicData.BusinessTime") } },
              [
                _c("el-date-picker", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    type: "datetimerange",
                    "picker-options": _vm.dateLimit,
                    "default-time": ["00:00:00", "23:59:59"],
                    "range-separator": _vm.$t("operationCenter.to"),
                    "start-placeholder": _vm.$t("operationCenter.startDate"),
                    "end-placeholder": _vm.$t("operationCenter.endDate"),
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                  },
                  model: {
                    value: _vm.queryDate,
                    callback: function ($$v) {
                      _vm.queryDate = $$v
                    },
                    expression: "queryDate",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "template",
                  { slot: "label" },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: _vm.$t("operationCenter.PleaseSelect"),
                        },
                        model: {
                          value: _vm.params.numberField,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "numberField", $$v)
                          },
                          expression: "params.numberField",
                        },
                      },
                      _vm._l(_vm.ORDER_TYPE, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c("InputTextareaCmp", {
                  ref: "InputTextareaRef",
                  attrs: {
                    val: _vm.textVal,
                    "max-length": 20000,
                    autosize: { minRows: 2, maxRows: 4 },
                  },
                }),
              ],
              2
            ),
            [1, 2].includes(_vm.payableType)
              ? _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("basicData.揽收操作人") } },
                  [
                    _c("CollectorsCmp", {
                      on: {
                        change: function (val) {
                          return (_vm.params.collectorIdList = val)
                        },
                      },
                      model: {
                        value: _vm.params.collectorIdList,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "collectorIdList", $$v)
                        },
                        expression: "params.collectorIdList",
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("basicData.派送操作人") } },
                  [
                    _c("SendCmp", {
                      on: {
                        change: function (val) {
                          return (_vm.params.dispatcherList = val)
                        },
                      },
                      model: {
                        value: _vm.params.dispatcherList,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "dispatcherList", $$v)
                        },
                        expression: "params.dispatcherList",
                      },
                    }),
                  ],
                  1
                ),
            _c(
              "el-form-item",
              { attrs: { label: _vm.$t("basicData.BatchNo") } },
              [
                _c("el-input", {
                  attrs: { placeholder: _vm.$t("GlobalSetObj.pleaseInput") },
                  model: {
                    value: _vm.params.billBatchNumber,
                    callback: function ($$v) {
                      _vm.$set(_vm.params, "billBatchNumber", $$v)
                    },
                    expression: "params.billBatchNumber",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: _vm.$t("receivablePayable.WhetherItHasBeenPaid"),
                },
              },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      filterable: "",
                      clearable: "",
                      placeholder: _vm.$t("operationCenter.PleaseSelect"),
                    },
                    model: {
                      value: _vm.params.isPaid,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "isPaid", $$v)
                      },
                      expression: "params.isPaid",
                    },
                  },
                  [
                    _c("el-option", {
                      attrs: { label: _vm.$t("GlobalSetObj.yes"), value: 1 },
                    }),
                    _c("el-option", {
                      attrs: { label: _vm.$t("GlobalSetObj.No"), value: 0 },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: _vm.$t(
                    "receivablePayable.WhetherToGenerateAStatement"
                  ),
                },
              },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      filterable: "",
                      clearable: "",
                      placeholder: _vm.$t("operationCenter.PleaseSelect"),
                    },
                    model: {
                      value: _vm.params.isGenBill,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "isGenBill", $$v)
                      },
                      expression: "params.isGenBill",
                    },
                  },
                  [
                    _c("el-option", {
                      attrs: { label: _vm.$t("GlobalSetObj.yes"), value: 1 },
                    }),
                    _c("el-option", {
                      attrs: { label: _vm.$t("GlobalSetObj.No"), value: 0 },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: _vm.$t("basicData.ExpenseType"),
                  prop: "feeName",
                },
              },
              [
                _c("FeeCodeCmp", {
                  attrs: {
                    val: _vm.params.feeCode,
                    businessType: _vm.payableType,
                  },
                  on: {
                    change: function (val) {
                      return (_vm.params.feeCode = val)
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: _vm.$t("collectionCenter.orderStatus") } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      filterable: "",
                      clearable: "",
                      placeholder: _vm.$t("operationCenter.PleaseSelect"),
                    },
                    model: {
                      value: _vm.params.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "status", $$v)
                      },
                      expression: "params.status",
                    },
                  },
                  _vm._l(_vm.ORDER_STATUS, function (item) {
                    return _c("el-option", {
                      key: item.code,
                      attrs: { label: item.label, value: item.code },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            [1, 2].includes(_vm.payableType)
              ? _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("newOrder.CollectionType") } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          clearable: "",
                          placeholder: _vm.$t("basicData.PleaseSelect"),
                        },
                        model: {
                          value: _vm.params.collectType,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "collectType", $$v)
                          },
                          expression: "params.collectType",
                        },
                      },
                      [
                        _c("el-option", {
                          key: 1,
                          attrs: {
                            label: _vm.$t("collectionCenter.dropOff"),
                            value: 1,
                          },
                        }),
                        _c("el-option", {
                          key: 2,
                          attrs: {
                            label: _vm.$t("collectionCenter.doorPickUp"),
                            value: 2,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              { attrs: { label: _vm.$t("newOrder.SenderState") } },
              [
                _c("el-input", {
                  attrs: { placeholder: _vm.$t("GlobalSetObj.pleaseInput") },
                  model: {
                    value: _vm.params.senderState,
                    callback: function ($$v) {
                      _vm.$set(_vm.params, "senderState", $$v)
                    },
                    expression: "params.senderState",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "query-footer" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary", size: "small" },
            on: { click: _vm.onSearch },
          },
          [_vm._v(_vm._s(_vm.$t("basicData.Search")))]
        ),
        _c(
          "el-button",
          {
            attrs: { type: "success", size: "small" },
            on: { click: _vm.onReset },
          },
          [_vm._v(_vm._s(_vm.$t("basicData.reset")))]
        ),
        _c(
          "el-button",
          { attrs: { size: "small" }, on: { click: _vm.onClose } },
          [_vm._v(_vm._s(_vm.$t("basicData.Cancel")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }