<template>
  <div class="listQueryBox">
    <div style="margin-bottom: 20px;">
      <el-form label-width="120px" class="selectClass">
        <el-row
          type="flex" justify="start" style="flex-wrap: wrap;"
          class="listQueryTime"
        >
          <el-col :span="7" style="width: auto;">
            <el-row>
              <el-col class="col2">
                <el-select
                  v-model="selectTime" :placeholder="$t('collectionCenter.pleaseChoose')" class="selectTimeClass autoWidth"
                  @change="changeTime"
                >
                  <template slot="prefix">
                    {{ (timeList.find(item => item.value === selectTime) || {}).time }}
                  </template>
                  <el-option
                    v-for="(item, index) in timeList"
                    :key="index"
                    :label="item.time"
                    :value="item.value"
                  />
                </el-select>
              </el-col>
              <!-- 订单创建 selectTime == 't0' -->
              <!-- 揽收扫描 selectTime == 't1' -->
              <!-- 实际揽收 selectTime == 't2' -->
              <!-- 签收时间 selectTime == 't3' -->
              <el-col style="width: auto;" class="timeSelectClass">
                <el-form-item class="delMl" label-width="0px">
                  <el-date-picker
                    ref="dateRef"
                    v-model="timeValue"
                    :clearable="false"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    :default-time="['00:00:00', '23:59:59']"
                    :range-separator="$t('collectionCenter.to')"
                    :start-placeholder="$t('collectionCenter.StartTime')"
                    :end-placeholder="$t('collectionCenter.EndTime')"
                    @change="dateChange"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8" style="width: auto;" class="textareaBox">
            <!-- 客户单号： -->
            <el-form-item :label="$t('collectionCenter.customerNumber')">
              <el-input
                v-model="queryForm.params.customerNumber"
                clearable
                type="textarea"
                autosize
                :placeholder="$t('collectionCenter.BatchQuery')"
                @blur="filter"
                @keydown.enter.native="customerNumberEnter"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8" style="width: auto;" class="textareaBox">
            <!-- 主运单号： -->
            <el-form-item :label="$t('collectionCenter.mainWaybillNumber')">
              <el-input
                v-model="queryForm.params.masterWaybillNumber"
                clearable
                type="textarea"
                autosize
                :placeholder="$t('collectionCenter.BatchQuery')"
                @blur="filter"
                @keydown.enter.native="masterWaybillNumberEnter"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8" style="width: auto;">
            <!-- 订单状态： -->
            <el-form-item :label="$t('collectionCenter.orderStatus')">
              <el-select
                v-model="queryForm.params.orderState" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                filterable
              >
                <el-option
                  v-for="(item, index) in orderTypeList"
                  :key="index"
                  :label="item.collectStatusName"
                  :value="item.collectStatus"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="width: auto;">
            <!-- 支付方式： -->
            <el-form-item :label="$t('ClaimsManagement.PaymentMethod') + '：'">
              <el-select v-model="queryForm.params.payType" :placeholder="$t('collectionCenter.pleaseChoose')" clearable>
                <el-option :label="$t('collectionCenter.tudo')" :value="2" /><!-- 全部 -->
                <el-option label="OnLine" :value="0" />
                <el-option label="OffLine" :value="1" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;">
            <!--揽收方式：-->
            <el-form-item :label="$t('collectionCenter.lanshouType')">
              <el-select v-model="queryForm.params.pickupType" clearable :placeholder="$t('collectionCenter.pleaseChoose')">
                <!--全部-->
                <el-option key="0" :label="$t('collectionCenter.tudo')" :value="null" />
                <!--上门交件-->
                <el-option key="1" :label="$t('collectionCenter.dropOff')" :value="1" />
                <!--上门揽收-->
                <el-option key="2" :label="$t('collectionCenter.doorPickUp')" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="12">
          <el-button
            v-permit:remove="'btn:collection-center:order:stockUp-receiveOrders:find'"
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}</el-button>
          <!-- 查询 -->
          <el-button
            v-permit:remove="'btn:collection-center:order:stockUp-receiveOrders:reset'"
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}</el-button>
          <!-- 重置 -->
          <el-button
            v-permit:remove="'btn:collection-center:order:stockUp-receiveOrders:export'"
            icon="el-icon-upload2"
            size="mini"
            :class="idList.length <=0 && isClick ? 'onlyClickOnce10' : ''"
            @click="download"
          >{{ $t('collectionCenter.export') }}</el-button>
          <!-- 导出 -->
          <el-button
            v-permit:remove="'btn:collection-center:order:stockUp-receiveOrders:exportAll'"
            icon="el-icon-upload"
            size="mini"
            :class="isClickAll ? 'onlyClickOnce10' : ''"
            @click="downloadAll"
          >{{ $t('collectionCenter.allExport') }}</el-button><!-- 全部导出 -->
        </el-col>
      </el-row>
      <el-divider />
    </div>
    <div>
      <el-table
        ref="tableRef"
        border
        show-summary
        :summary-method="getSummaries"
        :data="tableDate"
        :max-height="600"
        @select="batchSelect"
        @select-all="batchSelectAll"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <!-- 序号 -->
        <el-table-column
          :label="$t('collectionCenter.ind')" type="index" width="60"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          :label="$t('collectionCenter.orderD')"
          align="center"
        /><!-- 下单日期 :label="$t('collectionCenter.xxx')" -->
        <el-table-column
          prop="customerNumber"
          :label="$t('collectionCenter.customerNum')"
          align="center"
        /><!-- 客户单号 -->
        <el-table-column
          prop="masterWaybillNumber"
          :label="$t('collectionCenter.mainWaybillNum')"
          align="center"
        /><!-- 主运单号 -->
        <!--        <el-table-column-->
        <!--          prop="customerCode"-->
        <!--          :label="$t('collectionCenter.addresserCode')"-->
        <!--          align="center"-->
        <!--        />&lt;!&ndash; 发件人编码 &ndash;&gt;-->
        <el-table-column
          prop="pickupTypeDesc"
          :label="$t('collectionCenter.lanshouTypee')"
          align="center"
        /><!-- 揽收方式 -->
        <el-table-column
          prop="senderCompany"
          :label="$t('collectionCenter.SenderCompany')"
          align="center"
          show-overflow-tooltip
        /><!-- 发件人公司 -->
        <el-table-column
          prop="senderName"
          :label="$t('collectionCenter.addresser')"
          align="center"
        /><!-- 发件人 -->
        <el-table-column
          prop="senderPostalCode"
          :label="$t('collectionCenter.senderZipCode')"
          align="center"
        /><!-- 发件人邮编 -->
        <el-table-column
          prop="senderAddress"
          :label="$t('collectionCenter.SenderAddress')"
          align="center"
          show-overflow-tooltip
        /><!-- 发件人地址 -->
        <el-table-column
          prop="senderPhone"
          :label="$t('collectionCenter.senderMobile')"
          align="center"
        /><!-- 发件人联系电话 -->
        <el-table-column
          prop="payTypeName"
          :label="$t('ClaimsManagement.PaymentMethod')"
          align="center"
        /><!-- 支付方式 -->
        <el-table-column
          prop="receiveName"
          :label="$t('collectionCenter.recipientS')"
          align="center"
        /><!-- 收件人 -->
        <el-table-column
          prop="receivePostalCode"
          :label="$t('collectionCenter.Recipientzc')"
          align="center"
        /><!-- 收件人邮编 -->
        <el-table-column
          prop="receiveAddress"
          :label="$t('collectionCenter.ReceivingAddress')"
          align="center"
          show-overflow-tooltip
        /><!-- 收件人地址 -->
        <el-table-column
          prop="receivePhone"
          :label="$t('collectionCenter.RecipientPhoneNumber')"
          align="center"
        /><!-- 收件人联系电话 -->
        <el-table-column
          prop="count"
          :label="$t('collectionCenter.estimatedCollectedQuantity')"
          align="center"
        /><!-- 预计揽收件数 -->
        <el-table-column
          prop="actualCount"
          :label="$t('collectionCenter.actualCollectedQuantity')"
          align="center"
        ><!-- 实际揽收件数 -->
          <template slot-scope="scope">
            <div :class="scope.row.actualCount < scope.row.count ? 'redFont' : ''">{{ scope.row.actualCount }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="weight"
          :label="$t('collectionCenter.predictWeight')"
          align="center"
        /><!-- 预计重量 -->
        <el-table-column
          prop="actualWeight"
          :label="$t('collectionCenter.actualCollectedWeight')"
          align="center"
        /><!-- 实际揽收重量 -->
        <el-table-column
          prop="collectTime"
          :label="$t('newOrder.pickUpDate')"
          align="center"
        /><!-- 揽收日期 -->
        <!-- <el-table-column
          prop="pickUpCode"
          :label="$t('collectionCenter.fetchCode')"
          align="center"
        />取件码 -->
        <el-table-column
          prop="orderStateName"
          :label="$t('collectionCenter.OrderStatus')"
          align="center"
        /><!-- 订单状态 -->
        <el-table-column
          prop="nfeInvoiceValue"
          :label="$t('collectionCenter.allDeclaredValue')"
          align="center"
        /><!-- 总申明价值 -->
        <el-table-column
          prop="nfeInvoiceType"
          :label="$t('collectionCenter.commodityInvoiceType')"
          align="center"
        /><!-- 商品发票类型 -->
        <el-table-column
          prop="nfeInvoiceKey"
          :label="$t('collectionCenter.commodityInvoiceKey')"
          align="center"
          show-overflow-tooltip
        /><!-- 商品发票密钥 -->
        <el-table-column
          prop="logisticsInvoiceType"
          :label="$t('collectionCenter.LogisticsInvoiceType')"
          align="center"
        /><!-- 物流发票类型 -->
        <el-table-column
          prop="logisticsInvoiceSecretKey"
          :label="$t('collectionCenter.LogisticsInvoiceKey')"
          align="center"
        /><!-- 物流发票密钥 -->
        <el-table-column
          v-if="isDetail"
          :label="$t('collectionCenter.operation')"
          fixed="right"
          width="100"
          align="center"
        ><!-- 操作 -->
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="cellClick(scope.row)"
            >{{ $t('collectionCenter.detail') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100, 500, 1000]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getListPage"
        @size-change="getPageSize"
      />
    </div>

    <div v-if="drawerIsOpen" class="drawerOpen">
      <el-dialog
        title=""
        :visible.sync="drawerIsOpen"
        width="80%"
      >
        <div>
          <div class="msgClass">
            <div class="msgTitle" />
            <div class="msgContent">
              <!-- 基本信息 -->
              <div class="l">{{ $t('collectionCenter.basicInformation') }}</div>
              <div class="r">
                <div class="msgRow">
                  <div>
                    <!-- 客户单号 -->
                    <span class="name">{{ $t('collectionCenter.customerNum') }}</span>
                    <span class="code">{{ detailList.customerNumber }}</span>
                  </div>
                  <div>
                    <!-- 主运单号 -->
                    <span class="name">{{ $t('collectionCenter.mainWaybillNum') }}</span>
                    <span class="code">{{ detailList.masterWaybillNumber }}</span>
                  </div>
                </div>
                <div class="msgRow">
                  <div>
                    <!-- 验证码 -->
                    <span class="name">{{ $t('collectionCenter.verificationCode') }}</span>
                    <span class="code">{{ detailList.pickUpCode }}</span>
                  </div>
                </div>
                <div class="msgRow">
                  <div>
                    <!-- 件数 -->
                    <span class="name">{{ $t('collectionCenter.quantity') }}</span>
                    <span class="code">{{ detailList.count }}</span>
                  </div>
                  <div>
                    <!-- 预计重量 -->
                    <span class="name">{{ $t('collectionCenter.predictWeight') }}</span>
                    <span class="code">{{ detailList.weight }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="msgContent">
              <!-- 揽收人信息 -->
              <div class="l">{{ $t('collectionCenter.collectingInformation') }}</div>
              <div class="r">
                <div class="msgRow">
                  <div>
                    <!-- 联系人 -->
                    <span class="name">{{ $t('collectionCenter.lanshouPeople') }}</span>
                    <span class="code">{{ detailList.senderName }}</span>
                  </div>
                  <div>
                    <!-- 手机 -->
                    <span class="name">{{ $t('collectionCenter.lanshouPhone') }}</span>
                    <span class="code">{{ detailList.senderPhone }}</span>
                  </div>
                </div>
                <div class="msgRow">
                  <div>
                    <!-- 地址 -->
                    <span class="name">{{ $t('collectionCenter.lanshouAddress') }}</span>
                    <span class="code">{{ detailList.senderAddress }}</span>
                  </div>
                  <div>
                    <!-- 揽收-邮编 -->
                    <span class="name">{{ $t('collectionCenter.lanshouZipcode') }}</span>
                    <span class="code">{{ detailList.senderPostalCode }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="msgContent">
              <!-- 收件人信息 -->
              <div class="l">{{ $t('collectionCenter.recipientInformation') }}</div>
              <div class="r">
                <div class="msgRow">
                  <div>
                    <!-- 联系人 -->
                    <span class="name">{{ $t('collectionCenter.receivePeople') }}</span>
                    <span class="code">{{ detailList.receiveName }}</span>
                  </div>
                  <div>
                    <!-- 手机 -->
                    <span class="name">{{ $t('collectionCenter.receivePhone') }}</span>
                    <span class="code">{{ detailList.receivePhone }}</span>
                  </div>
                </div>
                <div class="msgRow">
                  <div>
                    <!-- 地址 -->
                    <span class="name">{{ $t('collectionCenter.receiveAddress') }}</span>
                    <span class="code">{{ detailList.receiveAddress }}</span>
                  </div>
                  <div>
                    <!-- 收件-邮编 -->
                    <span class="name">{{ $t('collectionCenter.receiveZipcode') }}</span>
                    <span class="code">{{ detailList.receivePostalCode }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- 揽收清单 -->
            <div class="msgTitle">{{ $t('collectionCenter.listingQuery') }}</div>
            <div class="msgContent">
              <!-- 揽收总计 -->
              <div class="l">{{ $t('collectionCenter.pickUpTotal') }}</div>
              <div class="r">
                <div class="msgRow">
                  <div>
                    <!-- 实收件数 -->
                    <span class="name">{{ $t('collectionCenter.actualReceivedNumber') }}</span>
                    <span class="code">{{ detailList.actualCount }}</span>
                  </div>
                  <!--                  <div>-->
                  <!--                    &lt;!&ndash; 实收重量 &ndash;&gt;-->
                  <!--                    <span class="name">{{ $t('collectionCenter.actualReceivedWeight') }}</span>-->
                  <!--                    <span class="code">{{ detailList.actualWeight }}</span>-->
                  <!--                  </div>-->
                  <!--                </div>-->
                  <!--                <div class="msgRow">-->
                  <div>
                    <!-- 订单状态 -->
                    <span class="name">{{ $t('collectionCenter.OrderStatus') }}</span>
                    <span class="code">{{ detailList.orderStateName }}</span>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div>
            <el-table
              border
              :data="detailTableDate"
              :max-height="400"
            >
              <!-- 序号 -->
              <el-table-column
                :label="$t('collectionCenter.ind')" type="index" width="60"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{ (queryDetailForm.pageNumber - 1) * queryDetailForm.pageSize + scope.$index + 1 }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="customerSubNumber"
                :label="$t('collectionCenter.scanOrderNumber')"
                align="center"
              /><!-- 扫码单号 -->
              <el-table-column
                prop="weighingWeight"
                :label="$t('collectionCenter.actualWeight')"
                align="center"
              /><!-- 称重重量(KG) -->
              <el-table-column
                :label="$t('operatingPlatform.volumeWeight')"
                align="center"
                prop="volumeWeight"
              /><!-- 体积重 -->
              <el-table-column
                prop="subOrderStateName"
                :label="$t('collectionCenter.operationType')"
                align="center"
              /><!-- 操作类型 -->
              <el-table-column
                prop="collectTime"
                :label="$t('collectionCenter.lanshouT')"
                align="center"
              /><!-- 揽收时间 -->
              <el-table-column
                prop="collectUserName"
                :label="$t('collectionCenter.LanShouPeople')"
                align="center"
              /><!-- 揽收人 -->
              <el-table-column
                prop="collectSiteName"
                :label="$t('collectionCenter.collectionDot')"
                align="center"
              /><!-- 揽收网点 -->
              <el-table-column
                prop="collectSignImg"
                :label="$t('collectionCenter.collectionSignaturePhoto')"
                align="center"
              ><!-- 揽收签名图片 -->
                <template slot-scope="scope">
                  <!-- 查看 -->
                  <div v-show="scope.row.collectSignImg" style="color: #551a8b;" @click="openUrl(scope.row.collectSignImg)">
                    {{ $t('collectionCenter.View') }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="collectPhoto"
                :label="$t('collectionCenter.collectPhoto')"
                align="center"
              ><!-- 揽收照片 -->
                <template slot-scope="scope">
                  <!-- 查看 -->
                  <div v-show="scope.row.collectPhoto" style="color: #551a8b;" @click="openUrl(scope.row.collectPhoto)">
                    {{ $t('collectionCenter.View') }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="dispatchUserName"
                :label="$t('collectionCenter.signer')"
                align="center"
              /><!-- 签收人 -->
              <el-table-column
                prop="dispatchSignSiteName"
                :label="$t('collectionCenter.deliveryDot')"
                align="center"
              /><!-- 签收网点 -->
              <el-table-column
                prop="dispatchTime"
                :label="$t('collectionCenter.signT')"
                align="center"
              /><!-- 签收时间 -->
              <el-table-column
                prop="dispatchSignImg"
                :label="$t('collectionCenter.signForSignaturePhoto')"
                align="center"
              ><!-- 签收签名图片 -->
                <template slot-scope="scope">
                  <!-- 查看 -->
                  <div v-show="scope.row.dispatchSignImg" style="color: #551a8b;" @click="openUrl(scope.row.dispatchSignImg)">
                    {{ $t('collectionCenter.View') }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="itemValue"
                :label="$t('collectionCenter.suborderDeclaredValue')"
                align="center"
              /><!-- 子单申明价值 -->
              <el-table-column
                prop="itemCnName"
                :label="$t('collectionCenter.tradeName')"
                align="center"
              ><!-- 商品名称 -->
                <template slot-scope="scope">
                  {{ scope.row.itemCnName || scope.row.itemName }}
                </template>
              </el-table-column>
              <el-table-column
                prop="weight"
                :label="$t('collectionCenter.ForecastWeight')"
                align="center"
              /><!-- 预报重量 -->
              <el-table-column
                prop="quantity"
                :label="$t('newOperationCenter.NumberPackages')"
                align="center"
              /><!-- 包裹数 -->
              <el-table-column
                prop="actualWeight"
                :label="$t('collectionCenter.ActualWeight')"
                align="center"
              /><!-- 实际重量 -->
            </el-table>
          </div>
          <div class="right">
            <el-pagination
              layout="total, sizes, prev, pager, next, jumper"
              :current-page.sync="queryDetailForm.pageNumber"
              :page-sizes="[10, 20, 50, 100]"
              :page-size.sync="queryDetailForm.pageSize"
              :total="detailTotal"
              background
              style="margin: 10px 0"
              @current-change="getDetailPage"
              @size-change="searchDetailPage"
            />
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  stockOrderPage,
  querySubDetailedPage,
  stockOrderExport,
  stockOrderVO,
  stockOrderAllExport
} from '@/api/lanshou';
import dayjs from 'dayjs';

export default {
  name: 'StockUpReceiveOrders',
  data() {
    return {
      isClickAll: false,
      isClick: false,
      isDetail: this.$store.getters.button_permissions.includes('btn:collection-center:order:stockUp-receiveOrders:detail'),
      timeValue: [],
      drawerIsOpen: false,
      direction: 'ltr',
      drawerList: {},
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          startTime: '', // 开始时间
          endTime: '', // 结束时间
          customerNumber: '', // 客户单号
          masterWaybillNumber: '', // 主运单号
          orderState: '', // 订单状态
          startCollectTime: '', // 揽收开始时间
          endCollectTime: '', // 揽收结束时间
          payType: '',
          pickupType: ''
        }
      },
      orderTypeList: [ // 订单状态
        { collectStatus: 0, collectStatusName: this.$t('collectionCenter.notLanShou') }, // '未揽收'
        { collectStatus: 1, collectStatusName: this.$t('collectionCenter.hasLanShou') }, // '已揽收'
        { collectStatus: 2, collectStatusName: this.$t('collectionCenter.haveBeenSigned') }, // 已签收
        { collectStatus: 3, collectStatusName: this.$t('collectionCenter.cancelled') }, // 已取消
        { collectStatus: 4, collectStatusName: this.$t('collectionCenter.truckLoading') }, // 装车
        { collectStatus: 5, collectStatusName: this.$t('collectionCenter.unloading') }, // 卸车
        { collectStatus: 6, collectStatusName: this.$t('operatingPlatform.collectWarehousing') } // 网点揽收入库
      ],
      idList: [],
      total: 0,
      tableDate: [],
      detailList: {}, // 详情信息
      queryDetailForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          stockOrderId: ''
        }
      },
      detailTotal: 0,
      detailTableDate: [], // 详情表格
      selectTime: 1, // 选择时间
      timeList: [
        { value: 1, time: this.$t('collectionCenter.orderD') }, // '下单日期'
        { value: 2, time: this.$t('newOrder.pickUpDate') } // '揽收日期'
      ]
    };
  },
  watch: {
    'queryForm.params.customerNumber'(val) {
      if (!val) return;
      const wuliu = val.split('\n');
      let wuliuNumber = [];
      if (wuliu.length >= 1000) {
        wuliuNumber = wuliu.slice(0, 1000).join('\n');
        this.queryForm.params.customerNumber = wuliuNumber;
      }
      if (wuliu.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    },
    'queryForm.params.masterWaybillNumber'(val) {
      if (!val) return;
      const wuliu = val.split('\n');
      let wuliuNumber = [];
      if (wuliu.length >= 1000) {
        wuliuNumber = wuliu.slice(0, 1000).join('\n');
        this.queryForm.params.masterWaybillNumber = wuliuNumber;
      }
      if (wuliu.length > 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
      }
    }
  },
  created() {
    this.defaultCondition();
    this.searchClick();
    this.isClick = false;
    this.isClickAll = false;
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('collectionCenter.summary');
          return;
        }
        if (column.label === this.$t('collectionCenter.estimatedCollectedQuantity') ||
          (column.label === this.$t('collectionCenter.actualCollectedQuantity'))
        ) {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += '';
          } else {
            sums[index] = '';
          }
        }
      });
      return sums;
    },
    filter(value) {
      if (!value || typeof value !== 'string') return;
      const val = value.split('\n');
      // 过滤空项
      const numLen = val.filter(function(f) {
        return f && f.trim();
      });
      // 去除空格
      const bghTrim = numLen.map(item => {
        return item.replace(/\s/g, '').trim();
      });
      return bghTrim.join('\n');
    },
    customerNumberEnter() {
      const e = window.event || arguments[0];
      const customerNum = this.filter(this.queryForm.params.customerNumber);
      this.queryForm.params.customerNumber = customerNum;
      const numberLen = customerNum.split('\n');
      if (numberLen.length >= 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
        e.returnValue = false;
      }
      return true;
    },
    masterWaybillNumberEnter() {
      const e = window.event || arguments[0];
      const masterWaybillNum = this.filter(this.queryForm.params.masterWaybillNumber);
      this.queryForm.params.masterWaybillNumber = masterWaybillNum;
      const numberLen = masterWaybillNum.split('\n');
      if (numberLen.length >= 1000) {
        this.$message.warning(this.$t('collectionCenter.intercept')); // '单次查询最多1000条，已截取前1000条数据'
        e.returnValue = false;
      }
      return true;
    },
    downloadAll() {
      this.isClickAll = true;
      const query = JSON.parse(localStorage.getItem('sroQueryForm')) || this.queryForm;
      stockOrderAllExport(query.params).then(res => {
        if (res.status === 'OK') {
          this.isClickAll = false;
          const h = this.$createElement;
          this.$msgbox({
            // 提示
            title: this.$t('collectionCenter.tips'),
            message: h('p', null, [
              // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，'
              h('span', this.$t('collectionCenter.exportChangeTip')),
              // 请勿重复导出!
              h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
            ]),
            showCancelButton: true,
            // 立即前往
            confirmButtonText: this.$t('collectionCenter.Immediately'),
            // 关闭
            cancelButtonText: this.$t('collectionCenter.close')
          }).then(action => {
            if (action === 'confirm') {
              this.$router.push({ path: '/resource/exportTaskList' });
            }
          }).catch(() => {});
        }
      }).catch(err => {
        console.log(err);
        this.isClickAll = false;
      });
    },
    dateChange(val) {
      // this.$refs.dateRef.focus();
      if (val === null) {
        this.timeValue = [];
        this.queryForm.params.startTime = '';
        this.queryForm.params.endTime = '';
        this.queryForm.params.endCollectTime = '';
        this.queryForm.params.startCollectTime = '';
        return;
      }
      this.timeValue = [val[0] + ' 00:00:00', val[1] + ' 23:59:59'];
      if (val.length && val[1]) {
        const endTime = dayjs(val[0]).subtract(-3, 'month').format('YYYY-MM-DD') + ' 23:59:59';
        if (val[1] > endTime) {
          this.$message({
            // '只允许查3个月内的数据'
            message: this.$t('collectionCenter.OnlyAllowedThreeMonthsOfData'),
            type: 'warning'
          });
          const d = new Date();
          const today = dayjs(d).format('YYYY-MM-DD');
          this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
          if (this.selectTime === 1) {
            this.queryForm.params.startTime = today + ' 00:00:00';
            this.queryForm.params.endTime = today + ' 23:59:59';
            this.queryForm.params.startCollectTime = '';
            this.queryForm.params.endCollectTime = '';
          } else {
            this.queryForm.params.startCollectTime = today + ' 00:00:00';
            this.queryForm.params.endCollectTime = today + ' 23:59:59';
            this.queryForm.params.startTime = '';
            this.queryForm.params.endTime = '';
          }
        }
      }
      if (this.selectTime === 1) {
        this.queryForm.params.startTime = val[0] + ' 00:00:00';
        this.queryForm.params.endTime = val[1] + ' 23:59:59';
        this.queryForm.params.startCollectTime = '';
        this.queryForm.params.endCollectTime = '';
      } else {
        this.queryForm.params.startCollectTime = val[0] + ' 00:00:00';
        this.queryForm.params.endCollectTime = val[1] + ' 23:59:59';
        this.queryForm.params.startTime = '';
        this.queryForm.params.endTime = '';
      }
    },
    getListPage() { // 表格
      this.idList = [];
      const queryForm = JSON.parse(localStorage.getItem('sroQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      stockOrderPage(queryForm).then(res => {
        if (res.status === 'OK') {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        } else {
          this.tableDate = [];
          this.total = 0;
        }
      });
    },
    defaultCondition() { // 默认查询条件
      const d = new Date();
      const today = dayjs(d).format('YYYY-MM-DD');
      // const day = dayjs(d).subtract(3, 'day').format('YYYY-MM-DD 00:00:00');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
      this.selectTime = 1;
      this.queryForm.params = {
        startTime: today + ' 00:00:00', // 开始时间
        endTime: today + ' 23:59:59', // 结束时间
        customerNumber: '', // 客户单号
        masterWaybillNumber: '', // 主运单号
        orderState: '',
        startCollectTime: '', // 揽收开始时间
        endCollectTime: '', // 揽收结束时间
        payType: '',
        pickupType: ''
      };
    },

    getPageSize() {
      this.queryForm.pageNumber = 1;
      const query = JSON.parse(localStorage.getItem('sroQueryForm')) || this.queryForm;
      const queryForm = {
        pageNumber: 1,
        pageSize: this.queryForm.pageSize,
        params: query.params
      };
      stockOrderPage(queryForm).then(res => {
        if (res.status === 'OK') {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        } else {
          this.tableDate = [];
          this.total = 0;
        }
      });
    },
    searchClick() {
      if ((!this.timeValue) && (!this.queryForm.params.customerNumber && !this.queryForm.params.masterWaybillNumber)) {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
      } else {
        if (this.queryForm.params.customerNumber || this.queryForm.params.masterWaybillNumber) {
          this.timeValue = [];
          this.queryForm.params.startTime = '';
          this.queryForm.params.endTime = '';
          this.queryForm.params.startCollectTime = '';
          this.queryForm.params.endCollectTime = '';
          this.queryForm.params.orderState = '';
          this.queryForm.params.payType = '';
          this.queryForm.params.pickupType = '';
        }
        this.queryForm.pageNumber = 1;
        localStorage.setItem('sroQueryForm', JSON.stringify(this.queryForm));
        // 查询
        this.getListPage();
      }
    },
    resetClick() { // 重置
      this.defaultCondition();
    },
    batchSelect(selection, row) {
      const collectListIdList = [];
      selection.forEach(list => {
        collectListIdList.push(list.id);
      });
      this.idList = collectListIdList;
    },
    batchSelectAll(selection) {
      const collectListIdListAll = [];
      selection.forEach(list => {
        collectListIdListAll.push(list.id);
      });
      this.idList = collectListIdListAll;
    },
    download() { // 导出
      this.isClick = true;
      if (this.idList.length <= 0) {
        // 请勾选要导出的数据
        this.$message.warning(this.$t('collectionCenter.PleaseTickTheDataExport'));
      } else {
        stockOrderExport(this.idList).then(res => {
          if (res.status === 'OK') {
            this.isClick = false;
            this.idList = [];
            this.$refs.tableRef.clearSelection();
            const h = this.$createElement;
            this.$msgbox({
              // 提示
              title: this.$t('collectionCenter.tips'),
              message: h('p', null, [
                // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源任务列表中下载，'
                h('span', this.$t('collectionCenter.exportChangeTip')),
                // 请勿重复导出!
                h('span', { style: 'color: red' }, this.$t('collectionCenter.notRepeatTheExport'))
              ]),
              showCancelButton: true,
              // 立即前往
              confirmButtonText: this.$t('collectionCenter.Immediately'),
              // 关闭
              cancelButtonText: this.$t('collectionCenter.close')
            }).then(action => {
              if (action === 'confirm') {
                this.$router.push({ path: '/resource/exportTaskList' });
              }
            }).catch(() => {});
          }
        }).catch(err => {
          console.log(err);
          this.isClick = false;
        });
      }
    },
    cellClick(row) { // 查看详情
      stockOrderVO(row.id).then(od => {
        if (od.status === 'OK') {
          this.detailList = od.data;
          this.detailList.weight = row.weight;
        }
      });
      this.queryDetailForm.params.stockOrderId = row.id;
      this.getDetailPage();
      this.drawerIsOpen = true;
    },
    getDetailPage() { // 详情表格数据
      querySubDetailedPage(this.queryDetailForm).then(res => {
        if (res.status === 'OK') {
          this.detailTableDate = res.data.records;
          this.detailTotal = res.data.total;
        } else {
          this.detailTableDate = [];
          this.detailTotal = 0;
        }
      });
    },
    searchDetailPage() {
      this.queryDetailForm.pageNumber = 1;
      this.getDetailPage();
    },
    openUrl(url) {
      window.open(url);
    },
    /**
     * 查询时间切换
     */
    changeTime() {
      this.queryForm.params.startCollectTime = '';
      this.queryForm.params.endCollectTime = '';
      this.queryForm.params.startTime = '';
      this.queryForm.params.endTime = '';
      if (this.selectTime === 1) {
        this.queryForm.params.startTime = this.timeValue[0];
        this.queryForm.params.endTime = this.timeValue[1];
      } else {
        this.queryForm.params.startCollectTime = this.timeValue[0];
        this.queryForm.params.endCollectTime = this.timeValue[1];
      }
    }
  }
};
</script>

<style lang="scss" scoped>

  // 防止按钮重复点击
  @keyframes throttle{
    from{
      filter: opacity(40%);
      pointer-events: none;
    }
    to{
      pointer-events: all;
    }
  }
  .onlyClickOnce2{
    animation: throttle 2s step-end forwards;
  }
  .onlyClickOnce2:active{
    filter: opacity(40%);
    animation: none;
  }
  .onlyClickOnce10{
    animation: throttle 10s step-end forwards;
  }
  .onlyClickOnce10:active{
    filter: opacity(40%);
    animation: none;
  }

  .textareaBox ::v-deep textarea.el-textarea__inner {
    min-height: 40px !important;
    max-height: 150px !important;
  }
  .selectClass ::v-deep .el-date-editor .el-range-separator{
    width: 28px;
  }
  .redFont{
    color: red;
    font-weight: bold;
  }
  .drawerOpen ::v-deep .el-dialog{
    margin: 10vh auto !important;
  }
  .msgClass{
    border:1px solid #e6e6e6;
    box-sizing: border-box;
    overflow: hidden;
    .msgTitle{
      text-align: center;
      line-height: 25px;
      height: 25px;
      font-size: 15px;
      background: #f0f0f0;
      border-bottom: 1px solid #e6e6e6;
      box-sizing: border-box;
    }
    .msgContent:not(:last-child){
      border-bottom: 1px solid #e6e6e6;
      box-sizing: border-box;
    }
    .msgContent{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .l{
        width: 150px;
        min-width: 120px;
        height: 75px;
        background: #f0f0f0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #e6e6e6;
        box-sizing: border-box;
      }
      .r{
        width: 100%;
        .msgRow{
          display: flex;
          justify-content: space-between;
          align-items: center;
          div{
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
        }
        .name{
          width: 180px;
          text-align: right;
          display: inline-block;
          height: 25px;
          line-height: 25px;
          margin-right: 8px;
        }
        .code{
          width: 260px;
          text-align: left;
          display: inline-block;
        }
      }
    }
  }
  .selectClass ::v-deep .el-select{
    width: 100%;
  }
  .listQueryBox{
    padding: 20px;
    box-sizing: border-box;
  }
  .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .listQueryBox .listQueryTime .delMl ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }
  .col2 {
    width: auto !important;
  }
  .listQueryBox ::v-deep .el-date-editor{
    width: 100%;
  }
  .listQueryBox ::v-deep .el-dialog__wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .listQueryBox ::v-deep .el-dialog{
    margin-top: 100px !important;
  }
  .listQueryBox ::v-deep .el-table{
    font-size: 10px !important;
  }
  .listQueryBox .selectTimeClass{
    margin-left: 2px;
  }
  .listQueryBox .delMl ::v-deep .el-form-item__content{
    margin-left: 0 !important;
  }

  .delMl ::v-deep .el-date-editor.el-input, .el-date-editor.el-input__inner{
    width: 100%;
  }

  .listQueryBox .selectTimeClass ::v-deep.el-input--suffix .el-input__inner{
    border: none !important;
    font-size: 14px;
    color: #606266;
    font-weight: bold;
    text-align: right;
    padding-left: 0;
    box-sizing: border-box;
  }
  .autoWidth ::v-deep .el-input__prefix{ // 下拉框自适应占位
    position: relative;
    left: 0;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    padding: 0 30px;
    padding-left: 0;
    margin-left: 20px;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    visibility: hidden;
    white-space: nowrap;
  }
  .autoWidth ::v-deep input {
    position: absolute;
  }
</style>
