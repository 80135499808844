<template>
  <el-form
    ref="ruleForm"
    :model="formData"
    label-width="0"
    class="demo-ruleForm"
    size="small"
    :rules="rules"
  >
    <el-table :data="formData.arr" border>
      <!-- >公里数开始KM(不包含) -->
      <el-table-column
        prop="startDistance"
        :label="$t('basicData.KilometersStartKM') + '*'"
        width="200"
      >
        <template slot="header">
          <div>
            <span>{{ $t('basicData.KilometersStartKM') }}</span>
            <span style="color: red;">*</span>
          </div>
        </template>

        <template slot-scope="scope">
          <el-form-item
            :prop="`arr.${scope.$index}.startDistance`"
            :rules="{
              required: true,
              trigger: 'blur',
              validator:(rule, value, callback)=>startCheck(rule, value, callback, scope.row)
            }"
          >
            <el-input-number
              v-model="scope.row.startDistance" :precision="2" :min="0"
              :max="999999999" :controls="false" :placeholder="$t('basicData.KilometersStartKM')"
            />
          </el-form-item>
        </template>

      </el-table-column>

      <!-- 公里数结束KM(包含) -->
      <el-table-column
        prop="endDistance"
        :label="$t('basicData.MileageEndKM')"
        width="200"
      >
        <template slot="header">
          <div>
            <span>{{ $t('basicData.MileageEndKM') }}</span>
            <span style="color: red;">*</span>
          </div>
        </template>
        <template slot-scope="scope">
          <el-form-item
            :prop="`arr.${scope.$index}.endDistance`"
            :rules="{
              required: true,
              trigger: 'blur',
              validator:(rule, value, callback)=>startCheck(rule, value, callback, scope.row)
            }"
          >
            <el-input-number
              v-model="scope.row.endDistance" :precision="2" :min="0.01"
              :max="999999999" :controls="false" :placeholder="$t('basicData.MileageEndKM')"
            />
          </el-form-item>
        </template>
      </el-table-column>

      <!-- 司机类型 -->
      <el-table-column
        prop="vehicleType"
        :label="$t('basicData.DriverType')"
        width="200"
      >
        <template slot="header">
          <div>
            <span>{{ $t('basicData.DriverType') }}</span>
            <span style="color: red;">*</span>
          </div>
        </template>
        <template slot-scope="scope">
          <el-form-item :prop="`arr.${scope.$index}.vehicleType`" :rules="rules.vehicleType">
            <el-select
              v-model="scope.row.vehicleType"
              :placeholder="$t('basicData.DriverType')"
            >
              <el-option
                v-for="item of DRIVER_TYPE"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>

      <!-- 起底日任务量 -->
      <el-table-column
        prop="standardCargo"
        :label="$t('basicData.DailyTaskCapacityFromBase') + '(>=)*'"
        width="200"
      >
        <template slot="header">
          <div>
            <span>{{ $t('basicData.DailyTaskCapacityFromBase') }}(>=)</span>
            <span style="color: red;">*</span>
          </div>
        </template>

        <template slot-scope="scope">
          <el-form-item :prop="`arr.${scope.$index}.standardCargo`" :rules="rules.standardCargo">
            <el-input-number
              v-model="scope.row.standardCargo" :precision="0" :min="1"
              :max="999999999" :controls="false" :placeholder="$t('basicData.DailyTaskCapacityFromBase')"
            />
          </el-form-item>
        </template>
      </el-table-column>

      <!-- 费用/周期 -->
      <el-table-column
        :label="$t('basicData.CostCycle')"
        min-width="200"
      >
        <template slot-scope="scope">
          <!-- required 基础派送费-->
          <el-form-item :label="$t('basicData.BasicDeliveryFee') + '(PT005)'" label-width="200px"> 
            <div class="flex">
              <!-- :rules="{
                  required: true,
                  trigger: 'blur',
                  validator:(rule, value, callback)=>deliveryChargeCheck(rule, value, callback, scope.row)
                }" -->
              <el-form-item :prop="`arr.${scope.$index}.standardPrice`">
                <el-input-number
                  v-model="scope.row.standardPrice" :precision="3" :min="0"
                  :max="999999999" :controls="false" :placeholder="$t('GlobalSetObj.pleaseInput')"
                />
              </el-form-item>
              <!-- :rules="rules.billingMode" -->
              <el-form-item :prop="`arr.${scope.$index}.billingMode`" label-width="10px">
                <!-- 计费模式 -->
                <el-select v-model="scope.row.billingMode" :placeholder="$t('GlobalSetObj.pleaseChoose')" style="width: 100px">
                  <el-option
                    v-for="item in CHARGE_MODE"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </div>
          </el-form-item>

          <el-form-item :label="$t('basicData.ExceedingTargetIncentiveBonus') + '(PT012)'" label-width="200px">
            <!-- 超目标单量奖金 -->
            <el-input-number
              v-model="scope.row.overshipmentPrice" :precision="3" :min="0"
              :max="999999999" :controls="false" :placeholder="$t('basicData.ExceedingTargetIncentiveBonus')"
            /> / {{ $t('collectionCenter.ticket') }}
          </el-form-item>

          <!-- 出勤激励奖金 -->
          <el-form-item :label="$t('basicData.AttendanceIncentiveBonus') + '(PT006)'" label-width="200px">
            <el-input-number
              v-model="scope.row.incentiveBonus" :precision="3" :min="0"
              :controls="false" :max="999999999" placeholder="出勤激励奖金"
            />
          </el-form-item>

          <!-- 超目标单量奖金 -->
          <el-form-item :label="$t('basicData.SingleVolumeBonusOverTarget')+'(PT007)'" label-width="200px">
            <el-input-number
              v-model="scope.row.price" :precision="3" :min="0"
              :max="999999999" :controls="false" :placeholder="$t('basicData.SingleVolumeBonusOverTarget')"
            />
          </el-form-item>

          <!-- 燃油补贴 -->
          <el-form-item :label="$t('basicData.FuelSubsidy')+'(PT009)'" label-width="200px">
            <el-input-number
              v-model="scope.row.subsidy" :precision="3" :min="0"
              :max="999999999" :controls="false" placeholder="$t('basicData.FuelSubsidy')"
            />
          </el-form-item>

          <div class="flex">
            <!-- 减免 -->
            <el-form-item :label="`${$t('basicData.Remission')}INSS11%(PT010)`" label-width="200px">
              <el-input-number
                v-model="scope.row.inssExemption" :precision="2" :min="0"
                :max="100" :controls="false" :placeholder="$t('basicData.Remission')"
              />%{{ $t('basicData.TaxBase') }}
            </el-form-item>

            <!-- INSS税基 -->
            <el-form-item label="" label-width="10px">
              <el-input-number
                v-model="scope.row.inssTaxBase" :precision="2" :min="0"
                :max="100" :controls="false" :placeholder="$t('basicData.Remission')"
              />%{{ $t('basicData.Remission') }}
            </el-form-item>
          </div>

          <div class="flex">
            <!-- 减免 -->
            <el-form-item :label="`${$t('basicData.Remission')}Sest Senat 2.5%(PT011)`" label-width="200px">
              <el-input-number
                v-model="scope.row.sestSenatExemption" :precision="2" :min="0"
                :max="100" :controls="false" :placeholder="$t('basicData.Remission')"
              />%{{ $t('basicData.TaxBase') }}
            </el-form-item>

            <el-form-item label="" label-width="10px">
              <el-input-number
                v-model="scope.row.sestSenatTaxBase" :precision="2" :min="0"
                :max="100" :controls="false" :placeholder="$t('basicData.Remission')"
              />%{{ $t('basicData.Remission') }}
            </el-form-item>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="100">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="danger"
            @click="handleDel(scope.$index)"
          >{{ $t('basicData.Delete') }}</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-form>
</template>

<script>
import { DRIVER_TYPE, CHARGE_MODE, validateDriverType } from './utils';
import { cloneDeep } from 'lodash-es';

export default {
  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },

  data() {
    // 校验 起始/结束公里数
    const startCheck = (rule, value, callback, row) => {
      const { startDistance, endDistance } = row;

      if (startDistance === undefined) {
        // 公里数不能为空
        return callback(new Error(this.$t('customerManagements.notNull')));
      }
      if (endDistance === undefined) {
        return callback(new Error(this.$t('customerManagements.notNull')));
      }

      // 校验 必须大于起始KM
      if (endDistance <= startDistance) {
        return callback(new Error(this.$t('basicData.TheStartValueCannotBeGreaterThanTheEndValue')));
      }

      // 区间不能重叠
      {
        const { arr } = this.formData;

        const isBool = validateDriverType(arr);
        if (!isBool) {
          return callback(new Error('区间不能重叠'));
        }
      }

      // 区间不能重叠 暂时不做校验
      // {
      //   const { arr } = this.formData;

      //   if (arr.length > 1) {
      //     const minArr = arr.map(item => {
      //       return item.startDistance;
      //     });

      //     const maxArr = arr.map(item => {
      //       return item.endDistance;
      //     });

      //     if (Math.max(...minArr) < Math.min(...maxArr)) {
      //       return callback(new Error('区间不能重叠'));
      //     }
      //   }
      // }

      callback();
    };

    // 保底薪校验
    const deliveryChargeCheck = (rule, value, callback, row) => {
      if (!value) {
        return callback(new Error(this.$t('customerManagements.pleaseInput')));
      }
      // const { standardPrice, overshipmentPrice } = row;

      // // 如果数据不填或者是0
      // if (!standardPrice && !overshipmentPrice) {
      //   return callback(new Error(this.$t('customerManagements.notNull')));
      // }

      callback();
    };

    return {
      formData: {
        arr: []
      },

      rules: {
        // 保底薪
        // standardPrice: [
        //   { required: true, message: this.$i18n.t('customerManagements.pleaseInput'), trigger: 'blur' }
        // ],
        vehicleType: [
          { required: true, message: this.$i18n.t('customerManagements.pleaseChoose'), trigger: 'change' }
        ],
        standardCargo: [
          { required: true, message: this.$i18n.t('customerManagements.pleaseInput'), trigger: 'blur' }
        ],

        // billingMode: [
        //   { required: true, message: this.$i18n.t('customerManagements.pleaseChoose'), trigger: 'change' }
        // ]
      },
      startCheck,
      deliveryChargeCheck
    };
  },

  watch: {
    'tableData': {
      handler(val, oldVal) {
        // console.log(val, oldVal, 'watch价格小屋');
        // if (oldVal === undefined) {
        //   // 第一次赋值
        //   this.formData.arr = [];
        //   return;
        // }

        // if (val.length === 0 && oldVal.length === 0) {
        //   return;
        // }

        // // 新的值与旧的值一样
        // if (JSON.stringify(val) === JSON.stringify(this.formData.arr)) {
        //   return;
        // }
        // this.formData.arr = cloneDeep(this.tableData);
        this.formData.arr = val;
      },
      immediate: true
    },

    // 将数据传给父组件
    formData: {
      handler(val, oldVal) {
        if (!oldVal || !val) {
          return;
        }
        this.$emit('change', val.arr);
      },
      deep: true
      // immediate: true
    }
  },

  created() {
    this.DRIVER_TYPE = DRIVER_TYPE;
    this.CHARGE_MODE = CHARGE_MODE;
  },

  methods: {
    // 提交校验
    submitForm() {
      return this.$refs['ruleForm'].validate().then(() => {
        return true;
      }).catch(res => {
        return false;
      });
    },

    // 删除
    handleDel(index) {
      this.formData.arr.splice(index, 1);
    }

    // 获取数据
    // getData() {
    //   return this.formData.arr;
    // }
  }
};
</script>

<style scoped>
.flex {
  display: flex;
}

.el-form-item {
  margin-bottom: 5px;
}
.el-form-item .el-form-item {
  margin-bottom: 0;
}

.demo-ruleForm /deep/ .el-form-item__error {
  position: sticky;
  text-align: left;
}
</style>
