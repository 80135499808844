<template>
  <div class="nodeTable">
    <el-table
      :data="dataList"
      max-height="300"
      border
      style="width: 100%"
    >
      <!--序号-->
      <el-table-column :label="$t('operationCenter.ind')" width="60px" type="index" />
      <!-- 操作时间 -->
      <el-table-column :label="$t('GlobalSetObj.operationTime')" prop="createTime" width="180" />
      <!-- 操作节点 -->
      <el-table-column :label="$t('userManage.node')" prop="operationNode" width="180">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.operationNode] && status[scope.row.operationNode].type || ''">
            <!-- {{ ([0,1,3].includes(scope.row.operationNode) ?
                (status[scope.row.operationNode] && status[scope.row.operationNode].name).slice(1, 3) :
                status[scope.row.operationNode] && status[scope.row.operationNode].name) || ''
            }} -->
            {{ status[scope.row.operationNode] && status[scope.row.operationNode].name || '' }}
          </el-tag>
        </template>
      </el-table-column>
      <!-- 操作人 -->
      <el-table-column :label="$t('GlobalSetObj.operator')" prop="createUserName" width="180"/>
      <!-- 操作详情 -->
      <el-table-column :label="$t('GlobalSetObj.details')" prop="remarks" :show-overflow-tooltip="true"/>
    </el-table>
  </div>
</template>

<script>
import { STATUS } from '../../content.js';

export default {
  props: {
    dataList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  data() {
    return {
      status: STATUS,
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped>

</style>