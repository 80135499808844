<template> <!-- 生成应付账单 -->
  <el-dialog
    :title="$t('receivablePayable.GenerateBillsPayable')"
    width="600px"
    :append-to-body="true"
    :visible.sync="isShow"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-form
      ref="ruleForm" :model="ruleForm" :rules="rules" size="small"
      label-width="100px" class="demo-ruleForm" label-position="top"
    >
      <!-- 选择生成应付时间 -->
      <el-form-item :label="$t('receivablePayable.SelectBillingDate')" prop="TimeCollection">
        <el-date-picker
          v-model="ruleForm.TimeCollection"
          style="width: 100%"
          type="daterange"
          range-separator="-"
          :start-placeholder="$t('operationCenter.startDate')"
          :end-placeholder="$t('operationCenter.endDate')"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <!-- 账单类型 -->
      <el-form-item :label="$t('collectionCenter.TypeBill')" prop="payableType">
        <el-select
          v-model="ruleForm.payableType" :placeholder="$t('GlobalSetObj.pleaseChoose')" :disabled="true"
          style="width: 100%"
        >
          <el-option
            v-for="item in PAYABLE_TYPE" :key="item.value" :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <!--取 消-->
      <el-button size="small" @click="onClose">{{ $t('basicData.Cancel') }}</el-button>
      <!--确 定-->
      <el-button size="small" type="primary" @click="submitForm('ruleForm')">{{ $t('basicData.determine') }}</el-button>
    </div>

  </el-dialog>
</template>

<script>
import { cloneDeep } from 'lodash-es';
import { apiPayableSiteBillGenerateBillNumber } from '@/api/finance/receivables';
import { PAYABLE_TYPE } from '@/views/finance/payableManage/feesPayable/enums.js';

export default {
  name: '',

  props: {
    // 多选
    selectData: {
      type: Array,
      default: () => {
        return [];
      }
    },

    // 搜索
    queryForm: {
      type: Object,
      default: () => {
        return {};
      }
    },

    // 后续处理函数
    cb: {
      type: Function,
      default: () => {
        return () => {};
      }
    },

    // 账单类型
    // 1: 安俊 2: 第三方 3: AJD 4: DSP
    payableType: {
      type: Number | String,
      required: true
    }
  },

  data() {
    return {
      isShow: false,
      isPayableTypeDisabled: false,
      ruleForm: {
        TimeCollection: [],
        payableType: ''
      },

      rules: {
        TimeCollection: [
          { required: true, message: this.$t('receivablePayable.TheTimeCannotBeEmpty'), trigger: 'change' }
        ],

        payableType: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: 'change' }
        ]
      }

    };
  },

  created() {
    this.PAYABLE_TYPE = PAYABLE_TYPE;
  },

  methods: {
    // 关闭
    onClose() {
      this.isShow = false;
    },

    /**
     * 打开
     * @description 打开弹窗
     * @param {number?} val 弹窗数据
     */
    open(val = 0) {
      this.isShow = true;
      this.ruleForm.TimeCollection = [];

      // 设置禁用项
      // if (val === 0) {
      //   // 解禁
      //   this.isPayableTypeDisabled = false;
      // } else {
      //   this.isPayableTypeDisabled = true;
      // }

      this.ruleForm.payableType = this.payableType;

      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate();
      });
    },

    // 确定
    submitForm(formName) {
      // 表单校验
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let row = await this.setData(); // 获取数据
          if (row) {
            this.asyncApiPayableSiteBillGenerateBillNumber(row); // 生成应付账单
          }
        } else {
          return;
        }
      });
    },

    // 数据处理
    async setData() {
      const ids = [];
      const businessTypes = [];
      const paramForm = cloneDeep(this.queryForm.params);
      paramForm.siteType = paramForm.siteType || null;

      // 勾选了使用勾选的数据
      if (this.selectData.length !== 0) {
        businessTypes.push(this.selectData[0].businessType);
        this.selectData.forEach(item => {
          ids.push(item.id);
        });
      }

      // 使用搜索条件的数据
      if (businessTypes.length === 0) {
        businessTypes.push(paramForm.businessType);
      }
      const tips = ids.length > 0 ? this.$i18n.t('receivablePayable.AreYouSureToGenerateAccountsPayableForTheSelectedData') // 确定给选中数据生成应付？
        : this.$i18n.t('receivablePayable.UncheckedDataWillGenerateAccountsPayableForAllQueriedDataConfirmToGenerateAccountsPayable'); // '未勾选数据将会给查询到的所有数据都生成应付，确定生成应付？';

      try {
        await this.$confirm(tips,
          this.$i18n.t('basicData.tips'), // '提示'
          {
            confirmButtonText: this.$i18n.t('basicData.determine'), // '确定',
            cancelButtonText: this.$i18n.t('basicData.Cancel'), // '取消',
            type: 'warning'
          }
        );

        const { TimeCollection, payableType } = this.ruleForm;

        paramForm.nameStartTime = TimeCollection[0];
        paramForm.nameEndTime = TimeCollection[1];
        paramForm.payableType = payableType;

        const param = {
          ...paramForm,
          businessType: businessTypes[0],
          ids: ids
        };

        // 揽收人
        if (!Array.isArray(param.collectorIdList)) {
          param.collectorIdList = [];
        }

        return param;
      } catch (error) {
        console.error(error);
        return undefined;
      }
    },

    // 确定
    asyncApiPayableSiteBillGenerateBillNumber(param) {
      apiPayableSiteBillGenerateBillNumber(param).then(res => {
        this.cb(res.data);
        this.onClose();
      }).catch(err => {
        console.error(err);
      });
    }

  }

};
</script>

<style lang="scss" scoped>
</style>
