<!-- 添加订单 -->
<template>
  <el-form
    ref="ruleForm" v-loading="loading" :model="ruleForm" size="small" label-position="top"
    :rules="rules" class="demo-ruleForm"
  >
    <!-- 业务类型 -->
    <el-form-item :label="$t('collectionCenter.businessT')" prop="businessType">
      <el-select v-model="ruleForm.businessType" :disabled="isBusinessTypeSelect" style="width: 100%;">
        <el-option v-for="item in PAYABLE_TYPE" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
    </el-form-item>
    
    <!-- 条形码/扫描单号 -->
    <el-form-item :label="`${$t('collectionCenter.Barcode')}/${ $t('GlobalSetObj.ScanNumber') }`" prop="ids">
      <el-input
        v-model="ruleForm.ids" type="textarea" :autosize="{ minRows: 6, maxRows: 10}"
        :placeholder="$t('trajectory.multipleOrders')"
      />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm('ruleForm')">{{ $t('GlobalSetObj.determine') }}</el-button>
      <el-button @click="onClose">{{ $t('GlobalSetObj.cancel') }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { apiGetClAndLgInfo } from '@/api/finance/basicData';
import { PAYABLE_TYPE } from '@/views/finance/payableManage/feesPayable/enums.js';

const reg = /[(\r\n)\r\n]+/gm;

export default {
  name: '',

  props: {
    // 业务类型
    businessType: {
      type: Number,
      required: true
    },

    // 业务类型 是否禁用
    isBusinessTypeSelect: {
      type: Boolean,
      default: false
    }
  },

  data() {
    const idsCheck = (rule, value, callback) => {
      const arr = this.getCodeArr(value);
      if (arr.length === 0) {
        return callback(new Error('请输入条形码/扫描单号'));
      }

      callback();
    };

    return {
      loading: false,

      ruleForm: {
        businessType: '',
        ids: ''
      },

      rules: {
        businessType: [
          { required: true, message: '请选择业务类型', trigger: 'change' }
        ],
        ids: [
          { required: true, validator: idsCheck, trigger: 'blur' }
        ]
      },
      PAYABLE_TYPE: PAYABLE_TYPE
    };
  },

  watch: {
    'businessType': {
      handler(val) {
        this.ruleForm.businessType = val;
      },

      immediate: true
    }
  },

  created() {
    this.resetForm();
  },

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const { businessType, ids } = this.ruleForm;
          const arr = this.getCodeArr(ids);
          const obj = {
            businessType,
            ids: arr
          };

          this.asyncApiGetClAndLgInfo(obj);
        }
      });
    },

    // 获取条形码
    getCodeArr(str) {
      reg.lastIndex = 0;
      return str.split(reg).map(item => {
        return item.trim();
      }).filter(item => !!item);
    },

    asyncApiGetClAndLgInfo(data) {
      this.loading = true;
      apiGetClAndLgInfo(data, false).then(res => {
        const { successResult = [], failResult = [] } = res.data;

        // 存在错误
        if (failResult.length > 0) {
          const arr = failResult.join('、');
          this.$message({
            message: `${arr} ${this.$t('orderCenterCont.orderInformationIncorrect')}`, // 订单信息错误
            type: 'warning',
            duration: 5 * 1000,
            showClose: true
          });
        }

        if (successResult.length === 0 && failResult.length === 0) {
          this.$message.warning(this.$t('operationCenterRoute.TheOrderWasNotQueried')); // 未查询到订单
          return [];
        }

        const arr = this.setDataType(successResult);

        this.$emit('submit', arr);
        this.resetForm();
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    // 重置
    resetForm() {
      this.ruleForm.ids = '';
    },

    // 设置数据格式
    setDataType(arr) {
      return arr.map(item => {
        const { id, businessType, waybillNumber, customerNumber, providerId, providerName, siteId, siteName, customerAlias, chargeWeight, scanOrderNumber } = item;
        const obj = {
          id,
          businessType, // 业务类型
          waybillNumber, // 业务订单
          scanOrderNumber, // 揽收单号
          customerNumber, // 客户单号
          providerId, // 业务主体id
          providerName, // 业务主体
          siteId, // 网点
          siteName, 
          customerAlias, // 客户
          chargeWeight// 计费重量
        };
        return obj;
      });
    },

    onClose() {
      this.resetForm();
      this.$emit('close');
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
