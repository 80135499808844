<template>
  <div class="basicTable">
    <el-row :gutter="20">
      <template v-if="[0,2].includes(status)">
        <!-- 添加 -->
        <el-button
          type="primary"
          size="small"
          :disabled="!isAddDisabled"
          @click="isAdd = true"
        >{{ $t('GlobalSetObj.append') }}</el-button>
        <!-- 导入 -->
        <el-button
          type="info"
          size="small"
          :disabled="!isAddDisabled"
          @click="isImport = true"
        >{{ $t('CenterForSorting.Import') }}</el-button>
        <!-- 移除 -->
        <el-button
          type="danger"
          size="small"
          :disabled="!multipleSelection.length"
          @click="onRemove"
        >{{ $t('ClaimsManagement.Remove') }}</el-button>
      </template>
      <template v-else>
        <!-- 导出勾选数据 :disabled="!multipleSelection.length"-->
        <el-button
          type="info"
          size="small"
          @click="onExport"
        >{{ $t('collectionCenter.export') }}</el-button>
      </template>

      <el-col :span="8">
        <StatisticiansTipsCmp :arr="arr" />
      </el-col>
    </el-row>

    <el-table
      :data="tableData"
      max-height="350"
      border
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <!--序号-->
      <el-table-column type="index" :label="$t('operationCenter.ind')" width="60px" />
      <!-- 单号 -->
      <el-table-column :label="$t('ChannelManage.oddNumbers')" prop="barCode" width="180" />
      <!-- 客户单号 -->
      <el-table-column :label="$t('collectionCenter.customerNum')" prop="customerNumber" width="180" />
      <!-- 申报价值 -->
      <el-table-column :label="$t('orderCenterCont.DeclaredValue')" prop="totalDeclaredValue" width="120"/>
      <!-- 当前轨迹 -->
      <el-table-column :label="$t('ClaimsManagement.CurrentTrajectory')" prop="trajectory" width="160" />
      <!-- 轨迹时间 -->
      <el-table-column :label="$t('ClaimsManagement.TrackTime')" prop="trajectoryTime" width="160" />
      <template v-if="!isColumn">
        <!-- 实际入库网点 -->
        <el-table-column :label="$t('GlobalSetObj.infeatSite')" prop="deliverWarehouseStationName" width="160"/>
        <!-- 实际派送网点 -->
        <el-table-column :label="$t('GlobalSetObj.entrega')" prop="deliverStationName" width="160"/>
        <!-- 派送员 -->
        <el-table-column :label="$t('orderCenterCont.deliveryPerson')" prop="dispatchName" width="160"/>
        <!-- 网点派件时间 -->
        <el-table-column :label="$t('orderCenterCont.deliveryTime')" prop="deliveredTime" width="160"/>
        <!-- 签收时间 -->
        <el-table-column :label="$t('GlobalSetObj.SigningTime')" width="160" prop="signTime"/>
      </template>
      <template v-else>
        <!-- 揽收人 -->
        <el-table-column :label="$t('collectionCenter.LanShouPeople')"prop="dispatchName" />
        <!-- 揽收时间 -->
        <el-table-column :label="$t('collectionCenter.lanshouT')" prop="deliveredTime" />
        <!-- 揽收网点 -->
        <el-table-column :label="$t('collectionCenter.siteName')" prop="deliverStationName" />
      </template>
    </el-table>

    <!-- 添加单号 -->
    <el-dialog
      :title="$t('orderCenterCont.AddOrder')"
      :visible.sync="isAdd"
      width="500px"
      append-to-body
      :close-on-click-modal="false"
      :before-close="onClose">
      <el-input
        v-loading="loading"
        type="textarea"
        :placeholder="$t('trajectory.multipleOrders')"
        v-model="orderList"
        :autosize="{ minRows: 5, maxRows: 10}"
      />
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="onClose">{{ $t('GlobalSetObj.cancel') }}</el-button>
        <el-button size="small" type="primary" @click="add">{{ $t('GlobalSetObj.determine') }}</el-button>
      </span>
    </el-dialog>

    <!-- 导入单号 -->
    <el-dialog
      :title="$t('CenterForSorting.Import')"
      :visible.sync="isImport"
      width="500px"
      append-to-body
      :close-on-click-modal="false"
      :before-close="onClose">
      <ImportExcelCmp
        import-tmp-url="https://anjunggl-1313947203.cos.ap-guangzhou.myqcloud.com/upload-file/workOrder/%E7%B4%A2%E8%B5%94%E6%A8%A1%E6%9D%BF-202410241030-20241025T104256.xlsx?q-sign-algorithm=sha1&q-ak=AKID2vc8UcWIfqskPtEKDtMfookXDD1e6JP6&q-sign-time=1729824176;2045184176&q-key-time=1729824176;2045184176&q-header-list=host&q-url-param-list=&q-signature=263596cbd8621e8468e0302c265fa0254729354d" :key-and-val-obj="excelKeyAndValue"
        @change="onImportClick"
      />
    </el-dialog>
  </div>
</template>

<script>
import StatisticiansTipsCmp from '@/components/StatisticiansTipsCmp/index.vue';
import { apiDemageClaimformOrderDetail, apiDemageClaimformExport, apiDemageClaimFormRemoveBill  } from '@/api/compensationManagement/list';
import { goExportList } from '@/utils/goExportList.js';
import { cloneDeep } from 'lodash-es';
import Big from 'big.js'; 

export default {
  props: {
    // 订单id
    id: {
      type: Number | String,
      default: '',
    },
    // 节点状态
    status: {
      type: String | Number,
      default: '',
    },
    // 基础信息
    formData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 表格数据
    dataList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  components: {
    StatisticiansTipsCmp,
    ImportExcelCmp: () => import('../../components/ImportExcelCmp.vue'),
  },
  
  watch: {
    'dataList': {
      immediate: true,
      handler(val) {
        this.tableData = val; // 赋值
      }
    },

    // 监听订单数据更新票数/价值汇总
    'tableData': {
      immediate: true,
      handler(val) {
        this.arr[0].value = val.length;

        this.arr[1].value = this.totalValue();
      }
    },

    // 监听金额变化
    'formData.claimAmount': {
      immediate: true,
      handler(val) {
        if (!this.tableData.length ) {
          this.arr[1].value = val || 0;
        }
      }
    },
  },

  computed: {
    // 是否可添加/导入
    isAddDisabled() {
      // if (this.formData.customerId && this.formData.claimsNode) {
      if (this.formData.claimsNode && this.formData.claimsType) {
        return true;
      }
      return false;
    },

    // 业务类型切换column
    isColumn() {
      if (this.formData.claimsNode === 4) {
        return true;
      }
      return false;
    },
  },

  data() {
    return {
      arr: [
        {
          name: this.$t('ClaimsManagement.TotalNumberOfVotes'), // 总票数
          value: 0,
          suffix: ';'
        },
        {
          name: this.$t('ClaimsManagement.TotalDeclaredValue'), // 总申报价值
          value: 0,
          suffix: ''
        }
      ],
      isAdd: false, // 是否添加
      isImport: false, // 是否导入
      orderList: '', // 添加单号
      tableData: [], // 订单数据
      multipleSelection: [], // 多选
      loading: false,

      excelKeyAndValue: {
        'barCode': {
          key: 'barCode',
          header: '订单号*'
        }
      },
    }
  },

  methods: {
    getTableList() {
      return cloneDeep(this.tableData);
    },

    // 计算申报价值汇总且同步到索赔金额
    totalValue() {
      let count = new Big(0);
      if (this.tableData.length > 0) {
        count = this.tableData.reduce((sum, item) => sum.plus(item.totalDeclaredValue), new Big(0));
        count = count.round(3, Big.RM).toString();
        // 当tableData数据大于dataList数据时， 说明数据已更新， 统计汇总更新索赔金额
        if (this.tableData.length > this.dataList.length) {
          this.$emit('change', count);
        }
        return count;
      } else {
        return this.formData.claimAmount || 0;
      }
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 添加关联订单
    add() {
      if(!this.orderList) {
        return this.$message.warning(this.$t('GlobalSetObj.pleaseInputContent'));
      }

      const arr = this.orderList.split(/[(\r\n)\r\n]+/).map(item => {
        return item.trim();
      }).filter(item => !!item);

      if (arr.length === 0) {
        return this.$message.warning(this.$t('GlobalSetObj.pleaseInputContent'));
      }

      if (arr.length > 500) {
        return this.$message.warning(this.$t('compensationManagement.Maximum500Orders'));
      }

      this.checkOrderNumber(arr);
    },

    // 导入关联订单
    onImportClick(val) {
      // 去重
      const codeArr = new Set([...val.map(item => {
        return item.barCode;
      })]);

      this.checkOrderNumber([...codeArr]);
    },

    /**
     * 校验订单号
     * @param arr 单号数据[]
     */
    checkOrderNumber(arr) {
      if (arr.length > 500) {
        return this.$message.warning(`${this.$t('userManage.upTo500ID')}`);;
      }

      const { claimsNode, customerId, claimsType } = this.formData;

      // 供应商、类型、关联单号
      const obj = {
        claimsNode,
        // customerId,
        claimsType,
        orderNumbers: arr
      };

      this.loading = true;

      apiDemageClaimformOrderDetail(obj, false).then(res => {
        try {
          if (res.code === 200) {
            this.onClose();

            const data = res.data;
            const tableList = cloneDeep(this.tableData);
            // 1.成功
            // 1.1判断是否已添加---添加过滤
            // 1.2未添加---新增
            if (data?.claimRelatedOrdersVOList && data?.claimRelatedOrdersVOList.length > 0) {
              this.tableData = union(tableList, data?.claimRelatedOrdersVOList, 'barCode') || [];
            }
            
            // 失败单号
            if (data?.outNumber && data?.outNumber.length > 0) {
              if (data?.outNumber.length > 10) {
                const list = data?.outNumber.map(item => {
                  return {
                    code: item
                  };
                });
                const h = this.$createElement;
                this.$notify.error({
                  title: this.$t('GlobalSetObj.tips'),
                  dangerouslyUseHTMLString: true,
                  message: h('el-table', {props:{data:list,border:true}, style:"width: 100%; overflow-y: auto;"}, 
                    [
                      h('el-table-column', {props:{prop: "code", label: this.$t('compensationManagement.WrongTrackingNumber')}})
                    ]
                  ),
                  duration: 0
                });
              } else {
                const arr = data?.outNumber.join('、');
                this.$message({
                  message: `${arr} ${this.$t('operationCenter.TheOrderWasNotQueried')}`,
                  type: 'warning',
                  duration: 5 * 1000,
                  showClose: true
                });
              }
            }
            // 未查询到订单
            if (!data?.claimRelatedOrdersVOList && !data?.outNumber) {
              this.$message.warning(`${this.$t('operationCenter.TheOrderWasNotQueried')}`);;
              return [];
            }
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      }).catch(err => {
        console.error(err);
        this.loading = false;
      });
    },

    // 关闭弹窗
    onClose() {
      this.isAdd = false;
      this.isImport = false;
      this.orderList = '';
    },

    // 处理删除请求
    onRemove() {
      // id存在编辑状态 不存在新增状态
      if (this.id) {
        const ids = this.multipleSelection.map(item => item.id);

        apiDemageClaimFormRemoveBill({ claimBillIds: ids })
          .then(res => {
            if (res.code === 200) {
              this.filterTableData();
            }
          })
          .catch(console.error);
      } else {
        this.filterTableData();
      }
    },
    filterTableData() {
      const addArr = this.multipleSelection.map((item) => {
        return item.orderNumber;
      });

      this.tableData = this.tableData.filter(item => {
        return !addArr.includes(item.orderNumber);
      });
    },

    // 导出关联订单
    onExport() {
      // 未勾选默认导出所有
      // 已勾选导出勾选
      const isChecked = this.multipleSelection.length > 0 ? this.multipleSelection : this.tableData;
      const idArr = isChecked.map(item => {
        return item.id;
      });

      const obj = {
        id: this.id,
        ids: idArr
      };

      apiDemageClaimformExport(obj).then(res => {
        if (res.status === 'OK') {
          // 关闭弹窗
          this.$emit('close');
          goExportList(this);
        }
      }).catch(err => {
        console.error(err);
      });
    },

    /**
     * 清空关联订单
     * 新增状态切换供应商or业务类型重置
     */
    cleraTable() {
      this.tableData = [];
    }
  },
};

// 订单去重方法
const union = function (a, b, k) {
  return a.concat(b.filter(i => (k ? !a.map(i => i[k]).includes(i[k]) : !a.includes(i))))
}
</script>

<style scoped>
.el-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 10px;
}
</style>
<style>
.el-notification {
  overflow-y: scroll;
  height: calc(100% - 30px);
}
.el-notification__group {
  width: 100% !important;
}
</style>