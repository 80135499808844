var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "ruleForm",
      staticClass: "demo-ruleForm",
      attrs: {
        model: _vm.ruleForm,
        size: "small",
        "label-position": "top",
        rules: _vm.rules,
      },
    },
    [
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("collectionCenter.businessT"),
            prop: "businessType",
          },
        },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: { disabled: _vm.isBusinessTypeSelect },
              model: {
                value: _vm.ruleForm.businessType,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleForm, "businessType", $$v)
                },
                expression: "ruleForm.businessType",
              },
            },
            _vm._l(_vm.PAYABLE_TYPE, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            label:
              _vm.$t("collectionCenter.Barcode") +
              "/" +
              _vm.$t("GlobalSetObj.ScanNumber"),
            prop: "ids",
          },
        },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              autosize: { minRows: 6, maxRows: 10 },
              placeholder: _vm.$t("trajectory.multipleOrders"),
            },
            model: {
              value: _vm.ruleForm.ids,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "ids", $$v)
              },
              expression: "ruleForm.ids",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("GlobalSetObj.determine")))]
          ),
          _c("el-button", { on: { click: _vm.onClose } }, [
            _vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel"))),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }