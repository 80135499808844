<template>
  <div class="navbar">
    <hamburger
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <breadcrumb class="breadcrumb-container" />
    <div class="right-menu">
      <el-dropdown class="avatar-container">
        <div class="avatar-wrapper">
          <div class="imgs">
            <el-image
              :src="avatar + '?imageView2/1/w/80/h/80'"
              fit="cover"
              style="width: 40px; height: 40px"
            />
          </div>
          <div class="imgs_box">
            <p>{{ orgName }}</p>
            <p>{{ realName }}</p>
          </div>
          <i class="el-icon-arrow-down" style="margin-left: 5px" />
        </div>

        <!-- 右上角下拉菜单 -->
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <el-dropdown-item @click.native="goChangePwd">
            <span style="display: block">{{ $t('login.changePwd') }}</span>
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="logout">
            <span style="display: block">{{ $t('login.lginOut') }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 切换语言 -->
    <div class="todo">
      <lang-select />
    </div>
    <!--  消息提示  -->
    <div class="messageTip" @click="handleMessage">
      <el-popover
        ref="popoverRef123" :width="350" placement="bottom"
        trigger="click"
      >
        <template #reference>
          <div style="color: #FFFFFF;">
            <el-badge
              v-if="getmessageNumber01 > 0" :max="99" :value="getmessageNumber01"
              class="item"
            >
              <i class="el-icon-bell" style="font-size: 25px;" />
            </el-badge>
            <i v-else class="el-icon-bell" style="font-size: 25px;" />
          </div>
        </template>
        <el-tabs v-model="activeName">
          <el-tab-pane :label="`${$t('GlobalSetObj.info')}(${messageList.length})`" name="second">
            <div v-if="messageList.length" class="message-list" style="height: 300px">
              <div
                v-for="item in messageList" :key="item.id" class="message-item"
                @click="handleMessageInfo(item)"
              >
                <el-badge v-if="item.isShow" :value="item.isShow" class="item">
                  <img alt="" class="message-icon" src="@/assets/images/msg02.png">
                </el-badge>
                <img
                  v-else alt="" class="message-icon"
                  src="@/assets/images/msg02.png"
                >
                <div class="message-content">
                  <el-tooltip
                    :content="item.title" class="item" effect="dark"
                    placement="top-start"
                  >
                    <span class="message-title sle">{{ item.title }}</span>
                  </el-tooltip>
                  <span class="message-date">{{ item.titleTime }}</span>
                </div>
              </div>
            </div>
            <div v-else class="message-empty">
              <img alt="notData" src="@/assets/images/notData.png">
              <div>暂无消息</div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-popover>
    </div>
    <el-popover placement="bottom" trigger="click" width="600">
      <msg-item
        :key="newKey" :all-total="allTotal" :grid-data="gridData"
        @getMsg="getMsg"
      />
      <div slot="reference" class="numberMsg">
        <el-badge
          v-if="msgNum" :max="99" :value="msgNum"
          class="item"
        >
          <i class="el-icon-sort" style="font-size: 25px;" />
        </el-badge>
        <i v-else class="el-icon-sort" style="font-size: 25px;" />
      </div>
    </el-popover>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Breadcrumb from '@/components/Breadcrumb';
import store from '@/store';
import Hamburger from '@/components/Hamburger';
import { closeBag } from '@/utils/closeBag';
import { MessageBox } from 'element-ui';
import { getOrganizationTree } from '@/api/organization';
import { getNoReadMsg, readMessageUser, selectMessageUser } from '@/api/messageCenter';
import dayjs from 'dayjs';

export default {
  components: {
    Breadcrumb,
    Hamburger,
    // todos,
    msgItem: () => import('@/components/msgContainer'),
    'lang-select': () => import('@/components/LangSelect/index.vue')
  },
  data() {
    return {
      realName: store.getters.realName,
      orgName: '', // 职位名称
      msgNum: 0,
      visible: false,
      showTheme: false,
      newKey: new Date().getTime(),
      isLockScreen: '',
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {}
      },
      gridData: [],
      allTotal: 0,
      activeName: 'second',
      messageList: [],
      getmessageNumber: 0,
      intervalTime: null
    };
  },
  computed: {
    ...mapGetters(['sidebar', 'avatar']),
    getmessageNumber01() {
      return this.$store.state.user.getmessageNumber;
    }
  },

  created() {
    this.isLockScreen = localStorage.getItem('UnlockScreen');
    this.getOrg();
  },
  mounted() {
    this.handleMessage(1);
    this.roundRobin();
    this.$nextTick(() => {
      this.getUnreadMsg();
      setInterval(() => {
        this.getUnreadMsg();
      }, 60000);
    });
    this.bus.$on('upDateMsg', () => {
      this.getUnreadMsg();
    });
    // 监听消息
    this.bus.$on('getMessage', () => {
      this.handleMessage();
    });
  },
  beforeDestroy() {
    clearInterval(this.intervalTime);
    this.intervalTime = null;
  },
  methods: {
    roundRobin() {
      this.intervalTime = window.setInterval(() => {
        this.handleMessage(1);
      }, 180000);
    },
    // 查询系统消息
    async handleMessage(flag) {
      // 当前时间
      const currentTime = new Date();
      const data = {
        receiveId: store.getters.userId,
        clientType: 1
      };
      const res = await selectMessageUser(data);
      if (res.status === 'OK') {
        this.getmessageNumber = 0;
        this.messageList = res.data;
        if (res.data && res.data.length > 0) {
          this.messageList.forEach(item => {
            const createTime = new Date(item.createTime);
            const timeDiff = currentTime - createTime;
            const minutesDiff = Math.floor(timeDiff / (1000 * 60));
            if (minutesDiff < 1) {
              item.titleTime = `刚刚`;
            } else if (minutesDiff < 60) {
              item.titleTime = `${minutesDiff}分钟前`;
            } else if (minutesDiff < 1440) {
              const hoursDiff = Math.floor(minutesDiff / 60);
              item.titleTime = `${hoursDiff}小时前`;
            } else {
              const daysDiff = Math.floor(minutesDiff / 1440);
              item.titleTime = `${daysDiff}天前`;
            }
            if (item.isRead === 0 || item.isRead === null) {
              item.isShow = 1; // 未读
              // 未读
              this.getmessageNumber = this.getmessageNumber + 1;
            } else {
              item.isShow = 0; // 已读
            }
          });
          this.$store.commit('user/set_getmessageNumber', this.getmessageNumber);
          if (flag === 1) {
            if (res.data[0].isPop === 1) {
              if (res.data[0].isRead === 0 || res.data[0].isRead === null) {
                res.data[0].messageInfo = true;
                res.data[0].messageInfoTime = 60;
                this.$store.commit('user/set_getmessageShow', res.data[0]);
              }
            }
          }
        }
      }
    },
    // 点击通知  == > 设置已读
    async handleMessageInfo(item) {
      console.log(item);
      item.messageInfo = true;
      item.messageInfoTime = 60;
      this.$store.commit('user/set_getmessageShow', { messageInfo: false });
      const data = {
        receiveId: store.getters.userId,
        clientType: 1,
        messageId: item.messageId
      };
      const res = await readMessageUser(data);
      if (res.status === 'OK') {
        this.$store.commit('user/set_getmessageShow', item);
        // this.$refs.popoverRef123.hide();
        await this.handleMessage();
      }
    },
    getUnreadMsg() {
      getNoReadMsg(this.queryForm)
        .then((res) => {
          const { status, data } = res;
          if (status !== 'OK') {
            return;
          }
          const resultData = data.records.map((item) => {
            if (item.msgType === 10) {
              item.msgData = JSON.parse(item.msgData);
            }
            return item;
          });
          this.gridData = resultData;
          this.allTotal = data.total;
          this.newKey = new Date().getTime();
          // if (this.gridData.length > 0) {
          //   this.$notify({
          //     title: '通知',
          //     dangerouslyUseHTMLString: true,
          //     message:
          //       '您有 <span style="color: red;font-size: 16px;font-weight: bold;">' +
          //       this.allTotal +
          //       '</span> 条消息需处理',
          //     position: 'top-right',
          //     type: 'warning',
          //     offset: 60
          //   });
          // }
          this.msgNum = this.allTotal;
          this.visible = false;
          // if (!type) {
          //   this.searchUnread();
          // }
          // this.getMsg({ num: this.allTotal, show: false });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getOrg() {
      const { orgId } = this.$store.state.user;
      getOrganizationTree()
        .then((res) => {
          if (res.status === 'OK') {
            res.data.forEach((arr) => {
              if (arr.id === orgId) {
                this.orgName = arr.name;
              }
            });
          }
        })
        .catch({});
    },
    // 取消自动锁屏
    UnlockScreen(type) {
      if (type === 1) {
        localStorage.setItem('UnlockScreen', 'true');
        this.isLockScreen = 'true';
        return;
      }
      localStorage.setItem('UnlockScreen', 'false');
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    clearSession() {
      sessionStorage.clear();
      // 退出登录
      this.$store.dispatch('user/logout');
      // localStorage.clear();  不能全部清空,需要记录电子秤品牌
      // 上门交件——start
      localStorage.removeItem('scanTableData');
      localStorage.removeItem('collectOrderList');
      localStorage.removeItem('customerId');
      localStorage.removeItem('customerName');
      localStorage.removeItem('merchantCode');
      localStorage.removeItem('merchantName');
      // 上门交件——end

      // 报表——start
      localStorage.removeItem('MROddHQueryForm');
      localStorage.removeItem('MROddMXQueryForm');
      localStorage.removeItem('MRTimeMXQueryForm');
      localStorage.removeItem('MRTimeHZQueryForm');
      localStorage.removeItem('TFOddHZQueryForm');
      localStorage.removeItem('TFOddMXQueryForm');
      localStorage.removeItem('TFTimeHZQueryForm');
      localStorage.removeItem('TFTimeMXQueryForm');
      localStorage.removeItem('RDHZQueryForm');
      localStorage.removeItem('carMQueryForm'); // 装车卸车数据监控
      localStorage.removeItem('collectTimeReportForm'); // 揽收时效报表
      localStorage.removeItem('kpiQueryForm');
      localStorage.removeItem('ctcQueryForm');
      // 报表——end

      // 查询条件——start
      localStorage.removeItem('lanshouOrdersQueryForm'); // 揽收订单
      localStorage.removeItem('sroQueryForm'); // 备货揽收订单
      localStorage.removeItem('ptpQueryForm'); // 轨迹推送
      localStorage.removeItem('rdpQueryForm'); // 揽收数据处理
      localStorage.removeItem('scQueryForm'); // 上门交件
      localStorage.removeItem('sftqQueryForm'); // 签收查询
      localStorage.removeItem('smsQueryForm'); // 短信管理
      localStorage.removeItem('mmQueryForm'); // 商户管理
      localStorage.removeItem('vmQueryForm'); // 车辆管理
      localStorage.removeItem('zzQueryForm'); // 邮编管理
      localStorage.removeItem('pdQueryForm'); // 揽收异常件
      localStorage.removeItem('billQueryForm'); // 账单推送
      localStorage.removeItem('qmQueryForm'); // 报价维护
      localStorage.removeItem('maQueryForm'); // 寄件区域
      localStorage.removeItem('cnQueryForm'); // 车签号
      localStorage.removeItem('umQueryForm'); // 员工管理
      localStorage.removeItem('ciQueryForm'); // 揽收商家查询
      localStorage.removeItem('packetQueryForm'); // 稽包查询
      localStorage.removeItem('rivqForm'); // 揽收信息确认查询
      localStorage.removeItem('tcvcqForm'); // 转运中心车辆确认查询
      // 查询条件——end

      localStorage.removeItem('siteQueryForm'); // 网点管理

      // 清除缓存
      localStorage.removeItem('singleScanTableData');
      localStorage.removeItem('totalTableData');
      localStorage.removeItem('signScanList');

      localStorage.removeItem('vue_admin_template_token-management');
      localStorage.removeItem('orgIdAndName');
      localStorage.removeItem('userIdAndName');
      localStorage.removeItem('positionList');
      localStorage.removeItem('organizationTree');
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
    async logout() {
      const listTrackings = JSON.parse(localStorage.getItem('listTrackings'));
      if (listTrackings && listTrackings.length) {
        MessageBox.confirm(this.$t('navbar.alertLogoutMessage'), {
          confirmButtonText: this.$t('GlobalSetObj.yes'),
          cancelButtonText: this.$t('GlobalSetObj.No'),
          showClose: true,
          type: 'warning'
        })
          .then(() => {
            console.log(listTrackings);
            closeBag(
              listTrackings,
              this.$store.state.user.id,
              dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')
            )
              .then(() => {
                localStorage.removeItem('listTrackings');
                localStorage.removeItem('tableBag');
              })
              .finally(() => {
                this.clearSession();
              });
          })
          .catch((err) => {
            console.log(err);
            this.clearSession();
          });
      } else {
        this.clearSession();
      }
    },

    goChangePwd() {
      this.$router.push({ name: 'changepwd' });
    },
    // 获取消息数据
    getMsg(val) {
      this.getUnreadMsg();
      // this.msgNum = val.num;
      // this.visible = val.show;
    },

    // 锁屏
    handleLock() {
      try {
        sessionStorage.setItem('isLock', '1');
        sessionStorage.setItem('lastHistory', this.$route.path);
        setTimeout(() => {
          this.$router.replace({ path: '/lock' });
        }, 500);
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.themesHeadTop {
  width: 100%;
  height: 20px;
}

.themesNavTop {
  width: 30px;
  height: 100px;
}

.themesHeadLeft {
  float: left;
  width: calc(100% - 30px);
  height: 20px;
  display: inline-block;
}

.themesNavLeft {
  float: left;
  width: 30px;
  height: 120px;
  display: inline-block;
}

.themesCheckBorder {
  border: 1px solid #43a047;
}

.themesNoCheck {
  border: 1px solid #ccc;
}

.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;
        // border: 1px solid #000;
        // width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        padding: 0 10px;

        .imgs {
          line-height: 1;
        }

        p {
          margin: 0;
          padding: 0;
          line-height: 1;
        }

        .imgs_box {
          margin-left: 10px;

          p:nth-child(1) {
            margin-bottom: 5px;
          }
        }

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }

        &:hover .imgs_box p {
          color: #409eff;
          font-weight: bold;
        }
      }
    }
  }

  .showTodosStyle {
    float: right;
    margin-top: 5px;
    margin-right: 20px;
  }

  .messageTip {
    float: right;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
  }
}

.numberMsg {
  float: right;
  margin-top: 10px;
  margin-right: 20px;
  cursor: pointer;
}

.message-list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .message-item {
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #e4e7ed;
    cursor: pointer;

    &:last-child {
      border: none;
    }

    .message-icon {
      width: 40px;
      height: 40px;
      //margin: 0 0 0 5px;
    }

    .message-content {
      display: flex;
      flex-direction: column;
      margin-left: 15px;

      .message-title {
        margin-bottom: 5px;
        width: 220px;
      }

      .sle {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .message-date {
        font-size: 12px;
        color: #909399
      }
    }
  }
}

.msgNumCon {
  position: absolute;
  top: 4px;
  left: 28px;
  line-height: 16px;
  text-align: center;
  padding: 0 6px;
  background: #f56c6c !important;
  color: #fff !important;
  font-size: 12px;
  border-radius: 10px;
}

::v-deep .el-badge__content {
  color: #FFFFFF !important;
}

.todo {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  margin-right: 20px;
  cursor: pointer;

  i {
    margin: 0;
    padding: 0;
    font-size: 30px;
    color: rgb(64, 158, 255);
  }
}
</style>
