<template>
  <div class="trackQueryBox">
    <!-- 轨迹查询 -->
    <el-row>
      <el-col :span="10" style="margin-bottom: 10px;">
        <div>
          <!-- 查询 -->
          <el-button
            v-permit:remove="'btn:collection-center:order:track-query:find'"
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="defaultClick"
          >{{ $t('collectionCenter.search') }}</el-button>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="10">
        <el-row>
          <el-col :span="10" class="radioClass">
            <!-- 按钮选项 -->
            <el-radio-group v-model="radio" @input="changeRadio">
              <el-radio label="0">{{ $t('collectionCenter.lanshouOrderNum') }}</el-radio><!-- 揽收订单号 -->
              <el-radio label="1">{{ $t('collectionCenter.Barcode') }}</el-radio><!-- 条形码 -->
              <el-radio label="2">{{ $t('collectionCenter.parcelNum') }}</el-radio><!-- 包裹号 -->
              <el-radio label="3">{{ $t('collectionCenter.BagNumber') }}</el-radio><!-- 袋号 -->
              <el-radio label="4">{{ $t('collectionCenter.licensePlateNumber') }}</el-radio><!-- 车牌号 -->
            </el-radio-group>
          </el-col>
          <el-col :span="13">
            <!-- 单号 -->
            <div class="inputNumberClass">
              <el-input
                v-model="inputNumbers"
                type="textarea"
                autosize
                :placeholder="$t('collectionCenter.BatchQuery')"
                @blur="checkData"
              /><!-- 支持批量查询(回车换行分割) -->
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="14" class="rightBox">
        <!-- 右侧详情 -->
        <div v-if="dataList.length <= 0" class="nullMsgClass">
          {{ $t('collectionCenter.nullMsg') }}
        </div>
        <div v-if="dataList.length > 0 && radio == 4">
          <div v-for="(item, index) in dataList" :key="index" class="rightClass">
            <el-row type="flex" justify="space-between" class="msgTop">
              <el-col :span="12">{{ item.number }}</el-col>
              <el-col :span="5">
                <el-button size="mini" @click="openImg(item.reachUrl)">{{ $t('collectionCenter.proofArrival') }}</el-button><!-- 到达凭证 -->
              </el-col>
              <el-col :span="5">
                <el-button size="mini" @click="openImg(item.leaveUrl)">{{ $t('collectionCenter.departureCertificate') }}</el-button><!-- 离开凭证 -->
              </el-col>
            </el-row>
            <div class="msgClass">
              <div v-for="(nowItem, nowIndex) in item.vehicleNowTrack" :key="nowIndex" class="msgContent newMsgClass" style="border-bottom: none;">
                <!-- 车牌号 -->
                <span>{{ $t('collectionCenter.licensePlateNumber') }}【<span style="color:red;">{{ item.number }}</span>】</span>，
                <!-- 车辆状态 -->
                <span>{{ nowItem.scanTime }}{{ nowItem.status }}</span>，
                <!-- 司机  -->
                <span>{{ $t('collectionCenter.theDriverName') }}【<span style="color:red;">{{ nowItem.driverName }}</span>】</span>
                <!-- 到达网点 -->
                <span v-if="nowItem.operationType == 0 && nowItem.siteName">，{{ $t('collectionCenter.arrivalPoint') }}【<span style="color:red;">{{ nowItem.siteName }}</span>】</span>
                <!-- 离开网点 -->
                <span v-if="nowItem.operationType == 1 && nowItem.siteName">，{{ $t('collectionCenter.exitNode') }}【<span style="color:red;">{{ nowItem.siteName }}</span>】</span>
              </div>
              <el-collapse @change="handleChange">
                <el-collapse-item><!-- 历史数据 -->
                  <div v-for="(beforeItem, beforeIndex) in item.vehicleTrack" :key="beforeIndex" class="msgContent">
                    <!-- 车牌号 -->
                    <span>{{ $t('collectionCenter.licensePlateNumber') }}【<span style="color:red;">{{ item.number }}</span>】</span>，
                    <!-- 车辆状态 -->
                    <span>{{ beforeItem.scanTime }}{{ beforeItem.status }}</span>，
                    <!-- 司机  -->
                    <span>{{ $t('collectionCenter.theDriverName') }}【<span style="color:red;">{{ beforeItem.driverName }}</span>】</span>
                    <!-- 到达网点 -->
                    <span v-if="beforeItem.operationType == 0 && beforeItem.siteName">，{{ $t('collectionCenter.arrivalPoint') }}【<span style="color:red;">{{ beforeItem.siteName }}</span>】</span>
                    <!-- 离开网点 -->
                    <span v-if="beforeItem.operationType == 1 && beforeItem.siteName">，{{ $t('collectionCenter.exitNode') }}【<span style="color:red;">{{ beforeItem.siteName }}</span>】</span>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </div>

        <div v-if="dataList.length > 0 && radio != 4">
          <div v-for="(item, index) in dataList" :key="index" class="rightClass">
            <el-row type="flex" justify="space-between" class="msgTop">
              <el-col :span="12">{{ item.number }}</el-col>
              <el-col :span="5">
                <el-button size="mini" @click="openImg(item.collectUrl)">{{ $t('collectionCenter.lanshouImg') }}</el-button><!-- 揽收图片 -->
              </el-col>
              <el-col :span="5">
                <el-button size="mini" @click="openImg(item.signUrl)">{{ $t('collectionCenter.signImage') }}</el-button><!-- 签收图片 -->
              </el-col>
            </el-row>
            <div class="msgClass">
              <div v-for="(nowItem, nowIndex) in item.nowTrack" :key="nowIndex" class="msgContent newMsgClass" style="border-bottom: none;">
                <span>{{ item.number }}</span>
                <span v-if="nowItem.status == $t('collectionCenter.truckLoading') || nowItem.status == $t('collectionCenter.unloading')">
                  <span v-if="nowItem.status == $t('collectionCenter.truckLoading')">
                    <!--
                    装车轨迹查询：G230207044751879在【操作人所属网点名称】被【操作人员】扫描发往下一站【下一站站点名称】，扫描时间15:16:34 2024-05-24 ，车签号为：AJV93932463
                    -->
                    <span v-if="nowItem.siteName">{{ $t('spareNumberPoo.in') }}【<span style="color:red;">{{ nowItem.siteName }}</span>】</span>
                    <span v-if="nowItem.signName">{{ $t('collectionCenter.bei') }}【<span style="color:red;">{{ nowItem.signName }}</span>】{{ nowItem.status }}</span>
                    <span v-if="nowItem.nextSiteName">{{ $t('collectionCenter.sendNextStop') }}【<span style="color:red;">{{ nowItem.nextSiteName }}</span>】</span>
                    <!-- 扫描时间 -->
                    <span>，{{ $t('collectionCenter.scanTime') }} {{ nowItem.scanTime }}</span>
                    <!-- 车签号 -->
                    <span v-if="nowItem.tagNum">，{{ $t('collectionCenter.carNumber') }}：{{ nowItem.tagNum }}</span>
                  </span>
                  <span v-if="nowItem.status == $t('collectionCenter.unloading')">
                    <!--
                    卸车轨迹查询：G230207044751879到达【操作人所属网点名称】并被【操作人员名称】扫描，扫描时间19:16:34 2024-05-24 ，车签号为：AJV93932463
                    -->
                    <span v-if="nowItem.siteName">{{ $t('collectionCenter.arrival') }}【<span style="color:red;">{{ nowItem.siteName }}</span>】</span>
                    <span v-if="nowItem.signName">{{ $t('collectionCenter.bbei') }}【<span style="color:red;">{{ nowItem.signName }}</span>】{{ nowItem.status }}</span>
                    <!-- 扫描时间 -->
                    <span>，{{ $t('collectionCenter.scanTime') }} {{ nowItem.scanTime }}</span>
                    <!-- 车签号 -->
                    <span v-if="nowItem.tagNum">，{{ $t('collectionCenter.carNumber') }}：{{ nowItem.tagNum }}</span>
                  </span>
                </span>
                <span v-else>
                  <!--                                 签收人名称                    签收   -->
                  <span v-if="nowItem.signName">【<span style="color:red;">{{ nowItem.signName }}</span>】</span>
                  <span v-else-if="nowItem.siteName">【<span style="color:red;">{{ nowItem.siteName }}</span>】</span>
                  {{ nowItem.status }}，
                  <!-- 扫描时间 -->
                  <span>{{ $t('collectionCenter.scanTime') }} {{ nowItem.scanTime }}</span>
                </span>
              </div>
              <el-collapse @change="handleChange">
                <el-collapse-item><!-- 历史数据 -->
                  <div v-for="(beforeItem, beforeIndex) in item.trackStatuses" :key="beforeIndex" class="msgContent">
                    <span>{{ item.number }}</span>
                    <span v-if="beforeItem.status == $t('collectionCenter.truckLoading') || beforeItem.status == $t('collectionCenter.unloading')">
                      <span v-if="beforeItem.status == $t('collectionCenter.truckLoading')">
                        <span v-if="beforeItem.siteName">{{ $t('spareNumberPoo.in') }}【<span style="color:red;">{{ beforeItem.siteName }}</span>】</span>
                        <span v-if="beforeItem.signName">{{ $t('collectionCenter.bei') }}【<span style="color:red;">{{ beforeItem.signName }}</span>】{{ beforeItem.status }}</span>
                        <span v-if="beforeItem.nextSiteName">{{ $t('collectionCenter.sendNextStop') }}【<span style="color:red;">{{ beforeItem.nextSiteName }}</span>】</span>
                        <!-- 扫描时间 -->
                        <span>，{{ $t('collectionCenter.scanTime') }} {{ beforeItem.scanTime }}</span>
                        <!-- 车签号 -->
                        <span v-if="beforeItem.tagNum">，{{ $t('collectionCenter.carNumber') }}：{{ beforeItem.tagNum }}</span>
                      </span>
                      <span v-if="beforeItem.status == $t('collectionCenter.unloading')">
                        <span v-if="beforeItem.siteName">{{ $t('collectionCenter.arrival') }}【<span style="color:red;">{{ beforeItem.siteName }}</span>】</span>
                        <span v-if="beforeItem.signName">{{ $t('collectionCenter.bbei') }}【<span style="color:red;">{{ beforeItem.signName }}</span>】{{ beforeItem.status }}</span>
                            <!-- 扫描时间 -->
                        <span>，{{ $t('collectionCenter.scanTime') }} {{ beforeItem.scanTime }}</span>
                            <!-- 车签号 -->
                        <span v-if="beforeItem.tagNum">，{{ $t('collectionCenter.carNumber') }}：{{ beforeItem.tagNum }}</span>
                      </span>
                    </span>
                    <span v-else>
                      <span v-if="beforeItem.signName">【<span style="color:red;">{{ beforeItem.signName }}</span>】</span>
                      <span v-else-if="beforeItem.siteName">【<span style="color:red;">{{ beforeItem.siteName }}</span>】</span>
                      {{ beforeItem.status }}，
                      <!-- 扫描时间 -->
                      <span>{{ $t('collectionCenter.scanTime') }} {{ beforeItem.scanTime }}</span>
                    </span>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  trackQuery
} from '@/api/lanshou';
export default {
  name: 'TrackQuery',
  data() {
    return {
      radio: '', // 单选框——选择单号
      inputNumbers: '', // 文本框——过滤空值后拼接
      trackForm: { // 查询条件
        number: '',
        numbers: []
      },
      dataList: [] // 获取的详情信息
    };
  },
  methods: {
    checkData() {
      this.trackForm.numbers = [];
      const isNumbers = []; // 过滤掉空值
      const numberList = this.inputNumbers.split('\n'); // 包含空值
      numberList.forEach(item => {
        if (item) {
          isNumbers.push(item);
        }
      });
      if (isNumbers.length > 50) {
        this.$message.warning(this.$t('collectionCenter.intercept50')); // '单次查询最多50条，已截取前50条数据'
      }
      if (isNumbers.length >= 50) {
        this.trackForm.numbers = isNumbers.slice(0, 50);
        this.inputNumbers = isNumbers.slice(0, 50).join('\n');
      } else {
        isNumbers.forEach(item => {
          this.trackForm.numbers.push(item);
          // this.inputNumbers = isNumbers.join('\n');
        });
      }
    },
    changeRadio(val) {
      this.trackForm.number = Number(this.radio);
    },
    defaultClick() { // 查看详情
      if (this.trackForm.number === '') {
        this.$message.warning(this.$t('collectionCenter.chooseNumber')); // '请选择单号类型'
      } else if (this.trackForm.numbers.length <= 0) {
        this.$message.warning(this.$t('collectionCenter.inputNumFind')); // '请在文本框内输入单号进行查询'
      } else {
        this.queryData();
      }
    },
    queryData() {
      if (this.trackForm.number !== '' && this.trackForm.numbers.length > 0) {
        trackQuery(this.trackForm).then(res => {
          if (res.status === 'OK') {
            this.dataList = res.data;
            console.log('res.data', res.data);
          }
        });
      }
    },
    handleChange(val) {
      // console.log(val);
    },
    openImg(url) {
      if (url) {
        window.open(url);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .rightBox{
    padding: 2px;
    border: 1px solid #e4e7ed;
    box-sizing: border-box;
    .nullMsgClass{
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #909399;
      font-size: 13px;
    }
  }
  .inputNumberClass ::v-deep textarea.el-textarea__inner {
    min-height: 106px !important;
    max-height: 600px !important;
  }
  .trackQueryBox{
    padding: 20px;
    box-sizing: border-box;
  }
  .radioClass{
    margin-top: 10px;
  }
  .radioClass ::v-deep .el-radio{
    display: block;
    margin-bottom: 10px;
    white-space: normal;
    margin-right: 5px;
  }
  .radioClass ::v-deep .el-radio-group{
    width: 100%;
  }
  .msgTop ::v-deep .el-button{
    width: 100%;
    white-space: normal;
  }
  .rightClass{
    border: 3px double #e4e7ed;
    padding: 5px;
    box-sizing: border-box;
    margin-bottom: 20px;
    cursor: context-menu;
    .msgTop{
      border-bottom: 1px solid #e4e7ed;
      padding-bottom: 5px;
      box-sizing: border-box;
      margin-bottom: 5px;
      align-items: center;
    }
    .msgClass{
      border: 1px solid #e4e7ed;
      box-sizing: border-box;
      overflow-y: auto;
      max-height: 300px;
      position: relative;
      .msgContent{
        padding: 5px 2px;
        box-sizing: border-box;
        font-size: 16px;
      }
      .msgContent:not(:last-child){
        border-bottom: 1px solid #e4e7ed;
      }
    }
  }
  .msgClass ::v-deep .el-collapse-item__content .msgContent:not(:last-child){
    border-bottom: 1px solid #e4e7ed;
  }
  .msgClass ::v-deep .el-collapse-item__content{
    padding-bottom: 0px !important;
  }
  // .msgClass ::v-deep .el-collapse{
  //   position: relative;
  // }
  .msgClass ::v-deep .el-collapse-item.is-active .el-collapse-item__header{
    top: 0px;
  }
  .msgClass ::v-deep .el-collapse-item .el-collapse-item__header{
    bottom: 0px;
  }
  .msgClass ::v-deep .el-collapse-item__header{
    font-size: 16px;
    position: absolute;
    right: 2px;
    height: 27px;
    border-bottom: none;
  }
  .msgClass ::v-deep .el-collapse{
    border-bottom: none;
  }
</style>
