var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c(
      "div",
      { staticClass: "formClass" },
      [
        _c(
          "el-form",
          {
            ref: "queryFormCope",
            staticClass: "queryFormClass",
            attrs: {
              model: _vm.formData,
              "label-width": _vm.$i18n.locale == "zh" ? "120px" : "",
              "label-position": _vm.$i18n.locale != "zh" ? "top" : "right",
            },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _vm.operationType === 2
                  ? _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.$t("basicData.FeeCode"),
                              prop: "code",
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.formData.code))])]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.ExpenseName"),
                          prop: "name",
                          rules: {
                            required: true,
                            message: _vm.$i18n.t("basicData.CannotBeEmpty"),
                            trigger: ["blur", "change"],
                          },
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: _vm.$t("basicData.PleaseEnter"),
                            maxlength: 20,
                            "show-word-limit": "",
                          },
                          model: {
                            value: _vm.formData.name,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData,
                                "name",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formData.name",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.ExpenseName") + "(PU)",
                          prop: "namePu",
                          rules: {
                            required: true,
                            message: _vm.$i18n.t("basicData.CannotBeEmpty"),
                            trigger: ["blur", "change"],
                          },
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: _vm.$t("basicData.PleaseEnter"),
                          },
                          model: {
                            value: _vm.formData.namePu,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData,
                                "namePu",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formData.namePu",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("collectionCenter.businessT"),
                          prop: "businessType",
                          rules: {
                            required: true,
                            message: _vm.$i18n.t("basicData.CannotBeEmpty"),
                            trigger: ["blur", "change"],
                          },
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                            },
                            model: {
                              value: _vm.formData.businessType,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "businessType", $$v)
                              },
                              expression: "formData.businessType",
                            },
                          },
                          _vm._l(_vm.BUSINESS_TYPE_TWO, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.status"),
                          prop: "state",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "small" },
                            model: {
                              value: _vm.formData.state,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "state", $$v)
                              },
                              expression: "formData.state",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: false } }, [
                              _vm._v(_vm._s(_vm.$t("basicData.disable"))),
                            ]),
                            _c("el-radio-button", { attrs: { label: true } }, [
                              _vm._v(_vm._s(_vm.$t("basicData.enable"))),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("basicData.remark"),
                          prop: "remark",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            rows: 4,
                            maxlength: 200,
                            "show-word-limit": "",
                            placeholder: _vm.$t("basicData.PleaseEnter"),
                          },
                          model: {
                            value: _vm.formData.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "remark", $$v)
                            },
                            expression: "formData.remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "permit",
            rawName: "v-permit:remove",
            value: "btn:finance:basicData:payable:feesType-editAdd",
            expression: "'btn:finance:basicData:payable:feesType-editAdd'",
            arg: "remove",
          },
        ],
        staticStyle: {
          "text-align": "right",
          padding: "10px",
          "border-top": "1px solid #ccc",
        },
      },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary", size: "small" },
            on: {
              click: function ($event) {
                return _vm.saveFormData(1)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("basicData.Save")))]
        ),
        _c(
          "el-button",
          {
            attrs: { size: "small" },
            on: {
              click: function ($event) {
                return _vm.saveFormData(2)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("basicData.Cancel")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }