<template>
  <div class="TABLE_LIST" :style="{}">
    <virtual-scroll
      ref="virtualScroll"
      :data="tableData"
      key-prop="id"
      :item-size="50"
      style="height: 100%; width: 100%"
      :virtualized="tableData.length > 100"
      @change="(renderData) => list = renderData"
    >
      <el-table
        ref="elTable"
        v-el-table-infinite-scroll="true"
        :data="list"
        class="table-height"
        border
        :height="tableHeightRow.height"
        row-key="id"
        @selection-change="handleSelectionChange"
      >
        <virtual-column width="50" type="selection" />
        <virtual-column :label="$t('GlobalSetObj.serial')" width="50" type="index" />
        <el-table-column
          v-for="item in tableHeader"
          :key="item.prop"
          :align="item.fixed"
          :label="item.label"
          :min-width="item.width"
          :prop="item.prop"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div
              v-if="item.prop === 'waybillNumber'"
              style="color: #409eff;cursor: pointer;"
              @click="editFee(scope.row)"
            >{{ scope.row[item.prop] }}</div>

            <div v-else-if="item.prop === 'status'">{{ scope.row.statusStr }}</div>

            <div v-else-if="item.prop === 'collectType'">{{ scope.row.collectTypeStr }}</div>

            <div v-else-if="item.prop === 'feeSource'">{{ scope.row.feeSourceStr }}</div>

            <div v-else>{{ scope.row[item.prop] }}</div>
          </template>
        </el-table-column>

        <el-table-column
          v-for="item in currentColumnsArr"
          :key="item.key"
          :label="item.label"
          :prop="item.prop"
          :show-overflow-tooltip="true"
          :render-header="renderHeader"
        >
          <!-- <template slot-scope="scope">
            <div>{{ scope.row[item.prop] }}</div>
          </template> -->
        </el-table-column>
      </el-table>
    </virtual-scroll>

    <!-- 分页工具 -->
    <div class="pagination">
      <el-pagination
        :current-page.sync="queryForm.pageNumber"
        :page-size.sync="queryForm.pageSize"
        :page-sizes="[100, 200, 500, 1000]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 10px 0"
        @current-change="onCurrentChange"
        @size-change="onSinzeChange"
      />
    </div>
  </div>
</template>

<script>
import VirtualScroll, { VirtualColumn } from 'el-table-virtual-scroll';
import ElTableInfiniteScroll from 'el-table-infinite-scroll';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import { WAYBILL_STATUS, ORDER_TYPE_LIST, FEE_SOURCE, COLLECT_TYPE } from '@/views/finance/enums.js';
import { asyncGetList } from './utils.js';

export default {

  directives: {
    ElTableInfiniteScroll
  },

  components: {
    VirtualScroll,
    VirtualColumn
  },

  mixins: [estimateTableHeight],

  props: {
    // 搜索条件
    queryForm: {
      type: Object,
      required: true
    },

    // 列表需要展示的列
    tableHeader: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      isSearch: false,
      tableData: [], // 总数据
      list: [],
      currentColumnsArr: [], // 扩展列
      total: 0
    };
  },

  methods: {
    // 搜索
    onSearch() {
      this.isSearch = false;
      this.asyncGetTable();
    },

    // 刷新
    onRefresh() {
      if (this.tableData.length === 0) {
        return;
      }
      this.asyncGetTable();
    },

    // 翻页
    onCurrentChange(val) {
      this.queryForm.pageNumber = val;
      this.asyncGetTable();
    },

    // 每页显示数量变化
    onSinzeChange(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.asyncGetTable();
    },

    // 获取列表数据
    asyncGetTable() {
      asyncGetList(this.queryForm, this).then(res => {
        if (res.status === 'OK') {
          const { data, total, columns } = res;
          this.total = total;
          if (data.length > 0) {
            // 扩展列
            const { list, currentColumnsRow } = this.setColumns(columns);
            this.currentColumnsArr = list;

            const arr = this.setRowData(data, currentColumnsRow);
            this.tableData = arr;

            // this.$forceUpdate();
          } else {
            this.tableData = [];
            this.$message.warning(this.$t('operatingPlatform.nullMsg'));
          }
        }
      });
    },

    // 扩展列
    setColumns(arr) {
      const currentColumnsRow = {};
      const list = arr.map((item, index) => ({
        prop: item.prop,
        key: item.prop + index,
        label: item.label
        // label: label.split('_')[0],
        // fixed: 'left',
        // highlight: false
      }));

      arr.forEach(el => {
        currentColumnsRow[el.prop] = '';
      });

      return { list, currentColumnsRow };
    },

    // 列表行数据处理
    setRowData(arr, currentColumnsRow) {
      const ORDER_TYPE_LIST_EDIT = [...ORDER_TYPE_LIST,
        { collectStatus: 100, collectStatusName: this.$t('GlobalSetObj.financialProcessing') }, // 待取件
        { collectStatus: 101, collectStatusName: this.$t('GlobalSetObj.financialClosedLoop') }];
      const WAYBILL_STATUS_EDIT = [...WAYBILL_STATUS,
        { code: 100, label: this.$t('GlobalSetObj.financialProcessing') }, // 待取件
        { code: 101, label: this.$t('GlobalSetObj.financialClosedLoop') }];
      // 将数组
      return arr.map((item, index) => {
        const {
          id, waybillNumber, orderNumber, customerNumber, payableType,
          providerName, siteName, businessType, collectType, operationMode, collectorsName, dispatcherName, remark, chargeWeight, totalFee, status, partitionName, receiveCity, receiveZipcode, receiveState, senderState, customerAlias, customerId, feeSource, siteId, siteType, createUser } = item;

        // 订单状态
        const statusStr = this.queryForm.params.businessType === 2
          ? ORDER_TYPE_LIST_EDIT.find(item => item.collectStatus === status)?.collectStatusName || ''
          : WAYBILL_STATUS_EDIT.find(item => item.code === status)?.label || '';

        // 来源
        const feeSourceStr = FEE_SOURCE[feeSource]?.label || '';

        // 揽收类型
        const collectTypeStr = COLLECT_TYPE[collectType]?.label || '';

        // 扩展列
        const row = {
          ...currentColumnsRow
        };
        for (const key in row) {
          // 存在
          if (item[key]) {
            row[key] = item[key];
          }
        }

        const obj = {
          id,
          waybillNumber, // 业务单号
          orderNumber, // 揽收单号
          customerNumber, // 客户编号
          providerName, // 应付主体
          siteName, // 应付网点
          siteId, // 网点id
          customerId, // 客户
          customerAlias,
          status, // 订单状态
          statusStr,
          collectType, // 揽收方式
          collectTypeStr,
          collectorsName, // 揽收人
          senderState, // 发件人州
          feeSource, // 来源
          feeSourceStr,
          remark, // 备注
          chargeWeight, // 计费重量
          totalFee, // 应付总费用
          businessType, // 业务类型
          operationMode, // 派送模式 0 AJD 1 DSP
          payableType, // 应付类型
          dispatcherName, // 派送人
          partitionName, // 所属分区
          receiveCity, // 收件人城市
          receiveZipcode, // 收件人邮编
          receiveState, // 收件人州

          // operationModeStr,
          // statusStr,
          // siteType, // 网点
          // siteTypeStr,
          // createUser,
          ...row
        };
        return obj;
      });
    },

    // 多选
    handleSelectionChange(arr) {
      this.$emit('selection-change', arr);
    },

    // 详情查看
    editFee(row) {
      this.$emit('editFee', row);
    },

    /**
     * 重新table中el-columns，防止动态height丢失滚动条
     * @param h { createElement }
     */
    renderHeader(h, { column, $index }) {
      let realWidth = 0;
      const span = document.createElement('span');
      span.textContent = column.label;
      document.body.appendChild(span);
      realWidth = span.offsetWidth; // 内容宽度+左右padding
      // realWidth = span.getBoundingClientRect().width;
      // console.log(realWidth, '999999999999999', column.label);
      column.minWidth = realWidth + 10;
      document.body.removeChild(span);
      return h('span', column.label);
    }
  }
};
</script>

<style scoped>
/* 设置列宽超出... */
::v-deep .el-table th.el-table__cell > .cell {
  /* display: inline-block; */
  /* -webkit-box-sizing: border-box; */
  /* box-sizing: border-box; */
  /* position: relative; */
  /* vertical-align: middle; */
  /* padding-left: 10px; */
  /* padding-right: 10px; */
  width: 100%;
  text-overflow: clip !important;
  white-space: nowrap !important;
}
</style>
